import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbSemantic } from 'semantic-ui-react';

const Breadcrumb = memo(({ location, history, title }) => {
  return (
    <BreadcrumbSemantic className="mna-breadcrumbs">
      <BreadcrumbSemantic.Section link onClick={() => history.goBack()}>
        {title}
      </BreadcrumbSemantic.Section>
      <BreadcrumbSemantic.Divider icon="right chevron" />
      <BreadcrumbSemantic.Section active>
        {(location.state && location.state.title) || `Edit`}
      </BreadcrumbSemantic.Section>
    </BreadcrumbSemantic>
  );
});

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

export default Breadcrumb;
