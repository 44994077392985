import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import numeral from 'numeral';
import FormGroup from '../shared/Form/FormGroup';
import Select from '../shared/Form/Select/Select';
import Input from '../shared/Form/Input';
import removeIco from '../../asset/img/remove.svg';
import Button from '../shared/Form/Button';
import Open from '../../asset/img/open.svg';


function formatNumeral(num) {
  if (num === '-') {
    return num
  } 
  return numeral(num).format('0,0')
}

const fyEndingOptions = () => {
  const currentYear = new Date().getFullYear();
  const start = currentYear + 2;
  const finish = currentYear - 5;
  const array = [];
  for (let i = start; i >= finish; i--) {
    array.push({
      key: i,
      label: i,
      value: i,
    });
  }
  return array;
};

const projectedNumbersOptions = [
  { key: 'projectedNumbersAccept', label: 'Projected', value: true },
  { key: 'projectedNumbers', label: 'Actual', value: false },
];

const FinancialData = ({
  financials,
  onChange,
  setFieldValue,
  errors,
  deletedFinancials,
  isCreate,
}) => {
  return (
    <div className="financial-data">
      <FieldArray
        name="financials"
        render={arrayHelpers => (
          <>
            {financials &&
              financials.map((item, i) =>
                i === 0 ? (
                  <div key={i}>
                    <Row middle="xs">
                      <Col md={11} className="form-group__area form-group__area-financial">
                        <FormGroup
                          labelText="FY Ending"
                          name={`financials[${i}].fyEnding`}
                        >
                          <Select
                            setFieldValue={setFieldValue}
                            options={fyEndingOptions()}
                            placeholder="yyyy"
                            name={`financials[${i}].fyEnding`}
                            value={item.fyEnding}
                            type="number"
                          />
                        </FormGroup>
                        <FormGroup
                          labelText="turnover (£)"
                          name={`financials[${i}].turnover`}
                        >
                          <Input
                            placeholder="e.g 500,000"
                            name={`financials[${i}].turnover`}
                            type="text"
                            value={
                              (item.turnover && formatNumeral(item.turnover)) || ''
                            }
                            onChange={onChange}
                          />
                        </FormGroup>
                        <FormGroup
                          labelText="EBITDA (£)"
                          name={`financials[${i}].netProfit`}
                        >
                          <Input
                            placeholder="e.g 100,000"
                            name={`financials[${i}].netProfit`}
                            type="text"
                            value={
                              (item.netProfit && formatNumeral(item.netProfit))  || ''
                            }
                            onChange={onChange}
                          />
                        </FormGroup>
                        <FormGroup
                          labelText="figures are"
                          name={`financials[${i}].projectedNumbers`}
                        >
                          <Select
                            name={`financials[${i}].projectedNumbers`}
                            value={item.projectedNumbers}
                            options={projectedNumbersOptions}
                            setFieldValue={setFieldValue}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <Button
                          icon
                          onClick={() => {
                            arrayHelpers.remove(i);
                            if (!isCreate) {
                              const deleted = {
                                id: item.id,
                                willDeleted: true,
                              };

                              setFieldValue('deletedFinancials', [
                                ...deletedFinancials,
                                deleted,
                              ]);
                            }
                          }}
                          className="margin-top__small mna-btn--icon-removed"
                        >
                          <img src={removeIco} alt="remove row" />
                        </Button>
                      </Col>
                    </Row>

                    {errors.financials && errors.financials[i] && (
                      <Row className="margin-bottom__medium">
                        <Col md={12}>
                          <p className="form-group__error">
                            Please check <strong>'FY ENDING'</strong>, <strong>'TURNOVER'</strong> and <strong>'FIGURES ARE'</strong> must be filled
                          </p>
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : (
                  <div key={i}>
                    <Row middle="xs">
                      <Col md={11} className="form-group__area form-group__area-financial">
                        <FormGroup name={`financials[${i}].fyEnding`}>
                          <Select
                            options={fyEndingOptions()}
                            placeholder="yyyy"
                            name={`financials[${i}].fyEnding`}
                            value={item.fyEnding}
                            type="number"
                            setFieldValue={setFieldValue}
                          />
                        </FormGroup>
                        <FormGroup name={`financials[${i}].turnover`}>
                          <Input
                            placeholder="e.g 500,000"
                            name={`financials[${i}].turnover`}
                            type="text"
                            value={
                              (item.turnover && formatNumeral(item.turnover)) || ''
                            }
                            onChange={onChange}
                          />
                        </FormGroup>
                        <FormGroup name={`financials[${i}].netProfit`}>
                          <Input
                            placeholder="e.g 100,000"
                            name={`financials[${i}].netProfit`}
                            type="text"
                            value={
                              (item.netProfit && formatNumeral(item.netProfit)) || ''
                            }
                            onChange={onChange}
                          />
                        </FormGroup>
                        <FormGroup name={`financials[${i}].projectedNumbers`}>
                          <Select
                            name={`financials[${i}].projectedNumbers`}
                            value={item.projectedNumbers}
                            options={projectedNumbersOptions}
                            setFieldValue={setFieldValue}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <Button
                          icon
                          onClick={() => {
                            arrayHelpers.remove(i);
                            if (!isCreate && item.id) {
                              const deleted = {
                                id: item.id,
                                willDeleted: true,
                              };

                              setFieldValue('deletedFinancials', [
                                ...deletedFinancials,
                                deleted,
                              ]);
                            }
                          }}
                          className="margin-bottom__medium mna-btn--icon-removed"
                        >
                          <img src={removeIco} alt="remove row" />
                        </Button>
                      </Col>
                    </Row>
                    {errors.financials && errors.financials[i] && (
                      <Row className="margin-bottom__medium">
                        <Col md={12}>
                          <p className="form-group__error">
                            Please check <strong>'FY ENDING'</strong>, <strong>'TURNOVER'</strong> and <strong>'FIGURES ARE'</strong> must be filled
                          </p>
                        </Col>
                      </Row>
                    )}
                  </div>
                )
              )}
            <Row>
              <Col xs>
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      // fyEnding: '',
                      // turnover: '',
                      // netProfit: '',
                      // projectedNumbers: '',
                    })
                  }
                  icon
                  type="button"
                  className="financial-data__add-row"
                >
                  <img src={Open} alt="" />
                  Add row
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </div>
  );
};

FinancialData.propTypes = {
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  financials: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.any),
  deletedFinancials: PropTypes.arrayOf(PropTypes.object),
  isCreate: PropTypes.bool,
};

export default FinancialData;
