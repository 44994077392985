import { toast } from 'react-toastify';
import { Contacts } from '../../../api/Api';
import { settingsToast } from '../../../utils/settings';
import { closeModal } from '../modal/modalActions';
import { editMode } from '../mode/modeActions';

export const ContactsIsLoading = () => ({
  type: 'CONTACTS_IS_LOADING',
});

export const ContactsDetailsHasError = payload => ({
  type: 'CONTACTS_ERROR',
  hasError: payload,
});

export const ContactsDetailsIsSuccess = payload => ({
  type: 'CONTACTS_SUCCESS',
  data: payload.data,
});

export const CurrentContactsIsSuccess = payload => ({
  type: 'CONTACTS_CURRENT_SUCCESS',
  data: payload.data,
});

export const ContactsRequestDataSuccess = payload => ({
  type: 'CONTACTS_POST_DATA_SUCCESS',
  postResponse: payload,
});

export const ContactsDelete = payload => ({
  type: 'CONTACTS_REMOVE',
  deletedResponse: payload,
});

export const ContactsTable = payload => ({
  type: 'CONTACTS_TABLE_VIEW',
  data: payload
});

export const ContactsAdditionalOptions = payload => ({
  type: 'CONTACTS_ADDITIONAL_OPTIONS',
  data: payload
});

export function getContactsData(params) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {
      const data = await Contacts.fetch(params);
      dispatch(ContactsDetailsIsSuccess(data));
    } catch (e) {
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function getContactsDetails(id) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {
      const data = await Contacts.fetchDetails(id);
      dispatch(CurrentContactsIsSuccess(data));
    } catch (e) {
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function createContact(data, params) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {

      const response = await Contacts.create(data);
      dispatch(ContactsRequestDataSuccess(response));
      dispatch(closeModal());
      dispatch(getContactsData(params));
      toast.success(`Create`, settingsToast);
      dispatch(editMode(false));
    } catch (e) {
      toast.error(
        'Something went wrong, check form fields or internet connection',
        settingsToast
      );
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function updateContact(id, data, params) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {
      await Contacts.update(id, data);
      dispatch(closeModal());
      dispatch(getContactsData(params));
      toast.success(`Information was updated`, settingsToast);
    } catch (e) {
      toast.error(
        'Something went wrong, check form fields or internet connection',
        settingsToast
      );
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function migrateContact(from_id, to_id) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {
      const params = {
        from_id,
        to_id
      }
      await Contacts.migrate(params);
      // dispatch(closeModal());
      toast.success(`Contact was migrated`, settingsToast);
    } catch (e) {
      toast.error(
        'Something went wrong, check form fields or internet connection',
        settingsToast
      );
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function deleteContact(id, data) {
  return async dispatch => {
    dispatch(ContactsIsLoading());
    try {
      await Contacts.del(id, data);
      dispatch(ContactsDelete(data));
      dispatch(closeModal());
      toast.success('Contact was deleted', settingsToast);
    } catch (e) {
      console.error(e);
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function tableOptions(data) {
  return async dispatch => {
    dispatch(ContactsIsLoading(data));
    try {
      dispatch(ContactsTable(data));
    } catch (e) {
      console.error(e);
      dispatch(ContactsDetailsHasError(e));
    }
  };
}

export function additionalOptions(data) {
  return async dispatch => {
    dispatch(ContactsIsLoading(data));
    try {
      dispatch(ContactsAdditionalOptions(data));
    } catch (e) {
      console.error(e);
      dispatch(ContactsDetailsHasError(e));
    }
  };
}