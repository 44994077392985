import axios from 'axios';
import {
  BASE_URL,
  OPPORTUNITY_URL,
  USERS_URL,
  CREATE_URL,
  OPPORTUNITY_UPDATE_URL,
  OPPORTUNITY_UPDATESINGLE_URL,
  OPPORTUNITY_DELETE_URL,
  PARTNERS_URL,
  ACQUIRER_CRITERIA_URL,
  ACQUIRER_CRITERIA_DELETE_URL,
  ACQUIRER_CRITERIA_UPDATE_URL,
  ACQUIRER_CRITERIA_CLONE_URL,
  ACQUIRER_CRITERIA_CREATE_URL,
  ACQUIRER_CRITERIA_HIDEACQUIRER_URL,
  ACQUIRER_CRITERIA_SHOWACQUIRER_URL,
  ACQUIRERS_URL,
  ACQUIRERS_UPDATE_URL,
  ACQUIRERS_UPDATESINGLE_URL,
  ACQUIRERS_SHOWACQUIRER_URL,
  ACQUIRERS_HIDEACQUIRER_URL,
  ACQUIRERS_DELETE_URL,
  MATCHES_URL,
  MATCH_HISTORY_URL,
  MATCHES_UPDATE_URL,
  CONTACTS_URL,
  CONTACTS_CREATE_URL,
  TRACKERS_URL,
  TRACKERS_DELETE_URL,
  EMAIL_HISTORY_URL,
  REMINDERS_URL
} from '../constants/api';

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

const Reminders = {
  create: data => API.post(`${REMINDERS_URL}`, data)
};

const Opportunity = {
  fetch: params => API.get(`${OPPORTUNITY_URL}`, { params }),
  fetchDetails: id => API.get(`${OPPORTUNITY_URL}/${id}`),
  create: data => API.post(`${OPPORTUNITY_URL}`, data),
  del: data => API.post(`${OPPORTUNITY_DELETE_URL}`, data),
  update: data => API.post(`${OPPORTUNITY_UPDATE_URL}`, data),
  updateSingle: data => API.post(`${OPPORTUNITY_UPDATESINGLE_URL}`, data),
  fetchReport: id => API.get(`${OPPORTUNITY_URL}/${id}/report`)
};

const Partners = {
  fetch: () => API.get(PARTNERS_URL)
};

const Acquirers = {
  fetch: params => API.get(`${ACQUIRERS_URL}`, { params }),
  fetchDetails: id => API.get(`${ACQUIRERS_URL}/${id}`),
  create: data => API.post(`${ACQUIRERS_URL}`, data),
  del: data => API.post(`${ACQUIRERS_DELETE_URL}`, data),
  update: data => API.post(`${ACQUIRERS_UPDATE_URL}`, data),
  hideAcquirer: data => API.post(`${ACQUIRERS_HIDEACQUIRER_URL}`, data),
  showAcquirer: data => API.post(`${ACQUIRERS_SHOWACQUIRER_URL}`, data),
  fetchReport: id => API.get(`${ACQUIRERS_URL}/${id}/report`)
};

const Matches = {
  fetch: params => API.get(`${MATCHES_URL}`, { params }),
  fetchDetails: id => API.get(`${MATCHES_URL}/${id}`),
  create: data => API.post(`${MATCHES_URL}`, data),
  del: data => API.post(`${MATCHES_URL}/delete`, data),
  update: data => API.post(`${MATCHES_UPDATE_URL}`, data)
};

const MatchHistory = {
  fetch: params => API.get(`${MATCH_HISTORY_URL}`, { params })
};

const Trackers = {
  fetch: params => API.get(`${TRACKERS_URL}`, { params }),
  create: data => API.post(`${TRACKERS_URL}`, data),
  getTrackerByAssociatedId: (data, table) =>
    API.get(`${TRACKERS_URL}/${data}/${table}`),
  del: data => API.post(`${TRACKERS_DELETE_URL}`, data)
};

const EmailHistory = {
  fetch: params => API.get(`${EMAIL_HISTORY_URL}`, { params }),
  getByMatchId: id => API.get(`${EMAIL_HISTORY_URL}/${id}`)
};

const AcquirerCriteria = {
  fetch: params => API.get(`${ACQUIRER_CRITERIA_URL}`, { params }),
  fetchDetails: id => API.get(`${ACQUIRER_CRITERIA_URL}/${id}`),
  create: data => API.post(`${ACQUIRER_CRITERIA_CREATE_URL}`, data),
  del: data => API.post(`${ACQUIRER_CRITERIA_DELETE_URL}`, data),
  update: data => API.put(`${ACQUIRER_CRITERIA_UPDATE_URL}`, data),
  clone: id => API.post(`${ACQUIRER_CRITERIA_CLONE_URL}/${id}`),
  hideAcquirer: data => API.post(`${ACQUIRER_CRITERIA_HIDEACQUIRER_URL}`, data),
  showAcquirer: data => API.post(`${ACQUIRER_CRITERIA_SHOWACQUIRER_URL}`, data)
};

const User = {
  fetchAll: params => API.get(`${USERS_URL}`, { params }),
  fetchByAuthId: id => API.get(`${USERS_URL}/${id}`),
  fetchDetails: id => API.get(`${USERS_URL}/details/${id}`),
  update: (id, data) => API.put(`${USERS_URL}/${id}`, data),
  create: (id, data) => API.put(`${CREATE_URL}`, data),
  rerunMatches: () => API.post(`${MATCHES_URL}/rerun`),
};

const Contacts = {
  fetch: params => API.get(`${CONTACTS_URL}`, { params }),
  fetchDetails: id => API.get(`${CONTACTS_URL}/details/${id}`),
  update: (id, data, params) =>
    API.put(`${CONTACTS_URL}/${id}`, data, { params }),
  create: (data, params) =>
    API.post(`${CONTACTS_CREATE_URL}`, data, { params }),
  del: (id, data) => API.delete(`${CONTACTS_URL}/${id}`, data),
  migrate: data => API.post(`${CONTACTS_URL}/migrate`, data)
};

export {
  API,
  Opportunity,
  User,
  Acquirers,
  Partners,
  Trackers,
  AcquirerCriteria,
  Matches,
  Contacts,
  EmailHistory,
  MatchHistory,
  Reminders
};
