export const countriesCodes = [
  { key: 'Abkhazia', text: '+7 840', value: '+7 840', content: 'Abkhazia' },
  { key: 'Afghanistan', text: '+93', value: '+93', content: 'Afghanistan' },
  { key: 'Albania', text: '+355', value: '+355', content: 'Albania' },
  { key: 'Algeria', text: '+213', value: '+213', content: 'Algeria' },
  { key: 'American Samoa', text: '+1 684', value: '+1 684', content: 'American Samoa' },
  { key: 'Andorra', text: '+376', value: '+376', content: 'Andorra' },
  { key: 'Angola', text: '+244', value: '+244', content: 'Angola' },
  { key: 'Anguilla', text: '+1 264', value: '+1 264', content: 'Anguilla' },
  {
    key: 'Antigua and Barbuda',
    text: '+1 268',
    value: '+1 268',
    content: 'Antigua and Barbuda'
  },
  { key: 'Argentina', text: '+54', value: '+54', content: 'Argentina' },
  { key: 'Armenia', text: '+374', value: '+374', content: 'Armenia' },
  { key: 'Aruba', text: '+297', value: '+297', content: 'Aruba' },
  { key: 'Ascension', text: '+247', value: '+247', content: 'Ascension' },
  { key: 'Australia', text: '+61', value: '+61', content: 'Australia' },
  {
    key: 'Australian External Territories',
    text: '+672',
    value: '+672',
    content: 'Australian External Territories'
  },
  { key: 'Austria', text: '+43', value: '+43', content: 'Austria' },
  { key: 'Azerbaijan', text: '+994', value: '+994', content: 'Azerbaijan' },
  { key: 'Bahamas', text: '+1 242', value: '+1 242', content: 'Bahamas' },
  { key: 'Bahrain', text: '+973', value: '+973', content: 'Bahrain' },
  { key: 'Bangladesh', text: '+880', value: '+880', content: 'Bangladesh' },
  { key: 'Barbados', text: '+1 246', value: '+1 246', content: 'Barbados' },
  { key: 'Barbuda', text: '+1 268', value: '+1 268', content: 'Barbuda' },
  { key: 'Belarus', text: '+375', value: '+375', content: 'Belarus' },
  { key: 'Belgium', text: '+32', value: '+32', content: 'Belgium' },
  { key: 'Belize', text: '+501', value: '+501', content: 'Belize' },
  { key: 'Benin', text: '+229', value: '+229', content: 'Benin' },
  { key: 'Bermuda', text: '+1 441', value: '+1 441', content: 'Bermuda' },
  { key: 'Bhutan', text: '+975', value: '+975', content: 'Bhutan' },
  { key: 'Bolivia', text: '+591', value: '+591', content: 'Bolivia' },
  {
    key: 'Bosnia and Herzegovina',
    text: '+387',
    value: '+387',
    content: 'Bosnia and Herzegovina'
  },
  { key: 'Botswana', text: '+267', value: '+267', content: 'Botswana' },
  { key: 'Brazil', text: '+55', value: '+55', content: 'Brazil' },
  {
    key: 'British Indian Ocean Territory',
    text: '+246',
    value: '+246',
    content: 'British Indian Ocean Territory'
  },
  {
    key: 'British Virgin Islands',
    text: '+1 284',
    value: '+1 284',
    content: 'British Virgin Islands'
  },
  { key: 'Brunei', text: '+673', value: '+673', content: 'Brunei' },
  { key: 'Bulgaria', text: '+359', value: '+359', content: 'Bulgaria' },
  { key: 'Burkina Faso', text: '+226', value: '+226', content: 'Burkina Faso' },
  { key: 'Burundi', text: '+257', value: '+257', content: 'Burundi' },
  { key: 'Cambodia', text: '+855', value: '+855', content: 'Cambodia' },
  { key: 'Cameroon', text: '+237', value: '+237', content: 'Cameroon' },
  { key: 'Cape Verde', text: '+238', value: '+238', content: 'Cape Verde' },
  { key: 'Cayman Islands', text: '+345', value: '+345', content: 'Cayman Islands' },
  {
    key: 'Central African Republic',
    text: '+236',
    value: '+236',
    content: 'Central African Republic'
  },
  { key: 'Chad', text: '+235', value: '+235', content: 'Chad' },
  { key: 'Chile', text: '+56', value: '+56', content: 'Chile' },
  { key: 'China', text: '+86', value: '+86', content: 'China' },
  { key: 'Christmas Island', text: '+61', value: '+61', content: 'Christmas Island' },
  {
    key: 'Cocos-Keeling Islands',
    text: '+61',
    value: '+61',
    content: 'Cocos-Keeling Islands'
  },
  { key: 'Colombia', text: '+57', value: '+57', content: 'Colombia' },
  { key: 'Comoros', text: '+269', value: '+269', content: 'Comoros' },
  { key: 'Congo', text: '+242', value: '+242', content: 'Congo' },
  {
    key: 'Congo, Dem. Rep. of (Zaire)',
    text: '+243',
    value: '+243',
    content: 'Congo, Dem. Rep. of (Zaire)'
  },
  { key: 'Cook Islands', text: '+682', value: '+682', content: 'Cook Islands' },
  { key: 'Costa Rica', text: '+506', value: '+506', content: 'Costa Rica' },
  { key: 'Croatia', text: '+385', value: '+385', content: 'Croatia' },
  { key: 'Cuba', text: '+53', value: '+53', content: 'Cuba' },
  { key: 'Curacao', text: '+599', value: '+599', content: 'Curacao' },
  { key: 'Cyprus', text: '+537', value: '+537', content: 'Cyprus' },
  { key: 'Czech Republic', text: '+420', value: '+420', content: 'Czech Republic' },
  { key: 'Denmark', text: '+45', value: '+45', content: 'Denmark' },
  { key: 'Diego Garcia', text: '+246', value: '+246', content: 'Diego Garcia' },
  { key: 'Djibouti', text: '+253', value: '+253', content: 'Djibouti' },
  { key: 'Dominica', text: '+1 767', value: '+1 767', content: 'Dominica' },
  { key: 'Dominican Republic', text: '+1 809', value: '+1 809', content: 'Dominican Republic' },
  { key: 'Easter Island', text: '+56', value: '+56', content: 'Easter Island' },
  { key: 'Ecuador', text: '+593', value: '+593', content: 'Ecuador' },
  { key: 'Egypt', text: '+20', value: '+20', content: 'Egypt' },
  { key: 'El Salvador', text: '+503', value: '+503', content: 'El Salvador' },
  { key: 'Equatorial Guinea', text: '+240', value: '+240', content: 'Equatorial Guinea' },
  { key: 'Eritrea', text: '+291', value: '+291', content: 'Eritrea' },
  { key: 'Estonia', text: '+372', value: '+372', content: 'Estonia' },
  { key: 'Ethiopia', text: '+251', value: '+251', content: 'Ethiopia' },
  { key: 'Falkland Islands', text: '+500', value: '+500', content: 'Falkland Islands' },
  { key: 'Faroe Islands', text: '+298', value: '+298', content: 'Faroe Islands' },
  { key: 'Fiji', text: '+679', value: '+679', content: 'Fiji' },
  { key: 'Finland', text: '+358', value: '+358', content: 'Finland' },
  { key: 'France', text: '+33', value: '+33', content: 'France' },
  { key: 'French Antilles', text: '+596', value: '+596', content: 'French Antilles' },
  { key: 'French Guiana', text: '+594', value: '+594', content: 'French Guiana' },
  { key: 'French Polynesia', text: '+689', value: '+689', content: 'French Polynesia' },
  { key: 'Gabon', text: '+241', value: '+241', content: 'Gabon' },
  { key: 'Gambia', text: '+220', value: '+220', content: 'Gambia' },
  { key: 'Georgia', text: '+995', value: '+995', content: 'Georgia' },
  { key: 'Germany', text: '+49', value: '+49', content: 'Germany' },
  { key: 'Ghana', text: '+233', value: '+233', content: 'Ghana' },
  { key: 'Gibraltar', text: '+350', value: '+350', content: 'Gibraltar' },
  { key: 'Greece', text: '+30', value: '+30', content: 'Greece' },
  { key: 'Greenland', text: '+299', value: '+299', content: 'Greenland' },
  { key: 'Grenada', text: '+1 473', value: '+1 473', content: 'Grenada' },
  { key: 'Guadeloupe', text: '+590', value: '+590', content: 'Guadeloupe' },
  { key: 'Guam', text: '+1 671', value: '+1 671', content: 'Guam' },
  { key: 'Guatemala', text: '+502', value: '+502', content: 'Guatemala' },
  { key: 'Guinea', text: '+224', value: '+224', content: 'Guinea' },
  { key: 'Guinea-Bissau', text: '+245', value: '+245', content: 'Guinea-Bissau' },
  { key: 'Guyana', text: '+595', value: '+595', content: 'Guyana' },
  { key: 'Haiti', text: '+509', value: '+509', content: 'Haiti' },
  { key: 'Honduras', text: '+504', value: '+504', content: 'Honduras' },
  { key: 'Hong Kong SAR China', text: '+852', value: '+852', content: 'Hong Kong SAR China' },
  { key: 'Hungary', text: '+36', value: '+36', content: 'Hungary' },
  { key: 'Iceland', text: '+354', value: '+354', content: 'Iceland' },
  { key: 'India', text: '+91', value: '+91', content: 'India' },
  { key: 'Indonesia', text: '+62', value: '+62', content: 'Indonesia' },
  { key: 'Iran', text: '+98', value: '+98', content: 'Iran' },
  { key: 'Iraq', text: '+964', value: '+964', content: 'Iraq' },
  { key: 'Ireland', text: '+353', value: '+353', content: 'Ireland' },
  { key: 'Israel', text: '+972', value: '+972', content: 'Israel' },
  { key: 'Italy', text: '+39', value: '+39', content: 'Italy' },
  { key: 'Ivory Coast', text: '+225', value: '+225', content: 'Ivory Coast' },
  { key: 'Jamaica', text: '+1 876', value: '+1 876', content: 'Jamaica' },
  { key: 'Japan', text: '+81', value: '+81', content: 'Japan' },
  { key: 'Jordan', text: '+962', value: '+962', content: 'Jordan' },
  { key: 'Kazakhstan', text: '+7 7', value: '+7 7', content: 'Kazakhstan' },
  { key: 'Kenya', text: '+254', value: '+254', content: 'Kenya' },
  { key: 'Kiribati', text: '+686', value: '+686', content: 'Kiribati' },
  { key: 'Kuwait', text: '+965', value: '+965', content: 'Kuwait' },
  { key: 'Kyrgyzstan', text: '+996', value: '+996', content: 'Kyrgyzstan' },
  { key: 'Laos', text: '+856', value: '+856', content: 'Laos' },
  { key: 'Latvia', text: '+371', value: '+371', content: 'Latvia' },
  { key: 'Lebanon', text: '+961', value: '+961', content: 'Lebanon' },
  { key: 'Lesotho', text: '+266', value: '+266', content: 'Lesotho' },
  { key: 'Liberia', text: '+231', value: '+231', content: 'Liberia' },
  { key: 'Libya', text: '+218', value: '+218', content: 'Libya' },
  { key: 'Liechtenstein', text: '+423', value: '+423', content: 'Liechtenstein' },
  { key: 'Lithuania', text: '+370', value: '+370', content: 'Lithuania' },
  { key: 'Luxembourg', text: '+352', value: '+352', content: 'Luxembourg' },
  { key: 'Macau SAR China', text: '+853', value: '+853', content: 'Macau SAR China' },
  { key: 'Macedonia', text: '+389', value: '+389', content: 'Macedonia' },
  { key: 'Madagascar', text: '+261', value: '+261', content: 'Madagascar' },
  { key: 'Malawi', text: '+265', value: '+265', content: 'Malawi' },
  { key: 'Malaysia', text: '+60', value: '+60', content: 'Malaysia' },
  { key: 'Maldives', text: '+960', value: '+960', content: 'Maldives' },
  { key: 'Mali', text: '+223', value: '+223', content: 'Mali' },
  { key: 'Malta', text: '+356', value: '+356', content: 'Malta' },
  { key: 'Marshall Islands', text: '+692', value: '+692', content: 'Marshall Islands' },
  { key: 'Martinique', text: '+596', value: '+596', content: 'Martinique' },
  { key: 'Mauritania', text: '+222', value: '+222', content: 'Mauritania' },
  { key: 'Mauritius', text: '+230', value: '+230', content: 'Mauritius' },
  { key: 'Mayotte', text: '+262', value: '+262', content: 'Mayotte' },
  { key: 'Mexico', text: '+52', value: '+52', content: 'Mexico' },
  { key: 'Micronesia', text: '+691', value: '+691', content: 'Micronesia' },
  { key: 'Island', text: '+1 808', value: '+1 808', content: 'Island' },
  { key: 'Moldova', text: '+373', value: '+373', content: 'Moldova' },
  { key: 'Monaco', text: '+377', value: '+377', content: 'Monaco' },
  { key: 'Mongolia', text: '+976', value: '+976', content: 'Mongolia' },
  { key: 'Montenegro', text: '+382', value: '+382', content: 'Montenegro' },
  { key: 'Montserrat', text: '+1664', value: '+1664', content: 'Montserrat' },
  { key: 'Morocco', text: '+212', value: '+212', content: 'Morocco' },
  { key: 'Myanmar', text: '+95', value: '+95', content: 'Myanmar' },
  { key: 'Namibia', text: '+264', value: '+264', content: 'Namibia' },
  { key: 'Nauru', text: '+674', value: '+674', content: 'Nauru' },
  { key: 'Nepal', text: '+977', value: '+977', content: 'Nepal' },
  { key: 'Netherlands', text: '+31', value: '+31', content: 'Netherlands' },
  {
    key: 'Netherlands Antilles',
    text: '+599',
    value: '+599',
    content: 'Netherlands Antilles'
  },
  { key: 'Nevis', text: '+1 869', value: '+1 869', content: 'Nevis' },
  { key: 'New Caledonia', text: '+687', value: '+687', content: 'New Caledonia' },
  { key: 'New Zealand', text: '+64', value: '+64', content: 'New Zealand' },
  { key: 'Nicaragua', text: '+505', value: '+505', content: 'Nicaragua' },
  { key: 'Niger', text: '+227', value: '+227', content: 'Niger' },
  { key: 'Nigeria', text: '+234', value: '+234', content: 'Nigeria' },
  { key: 'Niue', text: '+683', value: '+683', content: 'Niue' },
  { key: 'Norfolk Island', text: '+672', value: '+672', content: 'Norfolk Island' },
  { key: 'North Korea', text: '+850', value: '+850', content: 'North Korea' },
  {
    key: 'Northern Mariana Islands',
    text: '+1 670',
    value: '+1 670',
    content: 'Northern Mariana Islands'
  },
  { key: 'Norway', text: '+47', value: '+47', content: 'Norway' },
  { key: 'Oman', text: '+968', value: '+968', content: 'Oman' },
  { key: 'Pakistan', text: '+92', value: '+92', content: 'Pakistan' },
  { key: 'Palau', text: '+680', value: '+680', content: 'Palau' },
  {
    key: 'Palestinian Territory',
    text: '+970',
    value: '+970',
    content: 'Palestinian Territory'
  },
  { key: 'Panama', text: '+507', value: '+507', content: 'PaPanamalau' },
  { key: 'Papua New Guinea', text: '+675', value: '+675', content: 'Papua New Guinea' },
  { key: 'Paraguay', text: '+595', value: '+595', content: 'Paraguay' },
  { key: 'Peru', text: '+51', value: '+51', content: 'Peru' },
  { key: 'Philippines', text: '+63', value: '+63', content: 'Philippines' },
  { key: 'Poland', text: '+48', value: '+48', content: 'Poland' },
  { key: 'Portugal', text: '+351', value: '+351', content: 'Portugal' },
  { key: 'Puerto Rico', text: '+1 787', value: '+1 787', content: 'Puerto Rico' },
  { key: 'Qatar', text: '+974', value: '+974', content: 'Qatar' },
  { key: 'Reunion', text: '+262', value: '+262', content: 'Reunion' },
  { key: 'Romania', text: '+40', value: '+40', content: 'Romania' },
  { key: 'Russia', text: '+7', value: '+7', content: 'Russia' },
  { key: 'Rwanda', text: '+250', value: '+250', content: 'Rwanda' },
  { key: 'Samoa', text: '+685', value: '+685', content: 'Samoa' },
  { key: 'San Marino', text: '+378', value: '+378', content: 'San Marino' },
  { key: 'Saudi Arabia', text: '+966', value: '+966', content: 'Saudi Arabia' },
  { key: 'Senegal', text: '+221', value: '+221', content: 'Senegal' },
  { key: 'Serbia', text: '+381', value: '+381', content: 'Serbia' },
  { key: 'Seychelles', text: '+248', value: '+248', content: 'Seychelles' },
  { key: 'Sierra Leone', text: '+232', value: '+232', content: 'Sierra Leone' },
  { key: 'Singapore', text: '+65', value: '+65', content: 'Singapore' },
  { key: 'Slovakia', text: '+421', value: '+421', content: 'Slovakia' },
  { key: 'Slovenia', text: '+386', value: '+386', content: 'Slovenia' },
  { key: 'Solomon Islands', text: '+677', value: '+677', content: 'Solomon Islands' },
  { key: 'South Africa', text: '+27', value: '+27', content: 'South Africa' },
  {
    key: 'South Georgia and the South Sandwich Islands',
    text: '+500',
    value: '+500',
    content: 'South Georgia and the South Sandwich Islands'
  },
  { key: 'South Korea', text: '+82', value: '+82', content: 'South Korea' },
  { key: 'Spain', text: '+34', value: '+34', content: 'Spain' },
  { key: 'Sri Lanka', text: '+94', value: '+94', content: 'Sri Lanka' },
  { key: 'Sudan', text: '+249', value: '+249', content: 'Sudan' },
  { key: 'Suriname', text: '+597', value: '+597', content: 'Suriname' },
  { key: 'Swaziland', text: '+268', value: '+268', content: 'Swaziland' },
  { key: 'Sweden', text: '+46', value: '+46', content: 'Sweden' },
  { key: 'Switzerland', text: '+41', value: '+41', content: 'Switzerland' },
  { key: 'Syria', text: '+963', value: '+963', content: 'Syria' },
  { key: 'Taiwan', text: '+886', value: '+886', content: 'Taiwan' },
  { key: 'Tajikistan', text: '+992', value: '+992', content: 'Tajikistan' },
  { key: 'Tanzania', text: '+255', value: '+255', content: 'Tanzania' },
  { key: 'Thailand', text: '+66', value: '+66', content: 'Thailand' },
  { key: 'Timor', text: '+670', value: '+670', content: 'Timor' },
  { key: 'Togo', text: '+228', value: '+228', content: 'Togo' },
  { key: 'Tokelau', text: '+690', value: '+690', content: 'Tokelau' },
  { key: 'Tonga', text: '+676', value: '+676', content: 'Tonga' },
  {
    key: 'Trinidad and Tobago',
    text: '+1 868',
    value: '+1 868',
    content: 'Trinidad and Tobago'
  },
  { key: 'Tunisia', text: '+216', value: '+216', content: 'Tunisia' },
  { key: 'Turkey', text: '+90', value: '+90', content: 'Turkey' },
  { key: 'Turkmenistan', text: '+993', value: '+993', content: 'Turkmenistan' },
  {
    key: 'Turks and Caicos Islands',
    text: '+1 649',
    value: '+1 649',
    content: 'Turks and Caicos Islands'
  },
  { key: 'Tuvalu', text: '+688', value: '+688', content: 'Tuvalu' },
  {
    key: 'U.S. Virgin Islands',
    text: '+1 340',
    value: '+1 340',
    content: 'U.S. Virgin Islands'
  },
  { key: 'Uganda', text: '+256', value: '+256', content: 'Uganda' },
  { key: 'Ukraine', text: '+380', value: '+380', content: 'Ukraine' },
  {
    key: 'United Arab Emirates',
    text: '+971',
    value: '+971',
    content: 'United Arab Emirates'
  },
  { key: 'United Kingdom', text: '+44', value: '+44', content: 'United Kingdom' },
  { key: 'United States/Canada', text: '+1', value: '+1', content: 'United States/Canada' },
  { key: 'Uruguay', text: '+598', value: '+598', content: 'Uruguay' },
  { key: 'Uzbekistan', text: '+998', value: '+998', content: 'Uzbekistan' },
  { key: 'Vanuatu', text: '+678', value: '+678', content: 'Vanuatu' },
  { key: 'Venezuela', text: '+58', value: '+58', content: 'Venezuela' },
  { key: 'Vietnam', text: '+84', value: '+84', content: 'Vietnam' },
  { key: 'Wallis and Futuna', text: '+681', value: '+681', content: 'Wallis and Futuna' },
  { key: 'Yemen', text: '+967', value: '+967', content: 'Yemen' },
  { key: 'Zambia', text: '+260', value: '+260', content: 'Zambia' },
  { key: 'Zanzibar', text: '+255', value: '+255', content: 'Zanzibar' },
  { key: 'Zimbabwe', text: '+263', value: '+263', content: 'Zimbabwe' },
];
