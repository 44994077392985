import { toast } from 'react-toastify';
import { settingsToast } from '../../../utils/settings';
import { AcquirerCriteria, Trackers } from '../../../api/Api';
import {
  acquirerCriteriaHasError,
  acquirerCriteriaIsLoading,
  acquirerCriteriaSuccess,
  acquirerCriteriaRequestDataSuccess,
  acquirerCriteriaDelete,
  acquirerCriteriaClone
} from './acquirerCriteriaActions';
import Routes from '../../../constants/routes';
import { closeModal } from '../modal/modalActions';
import {
  arraysEqual,
  formatString,
  columnToReadable,
  isDefined,
  compareDiff
} from '../../../utils/utils';
import { deleteMatches } from '../matches/request';

// GET BASE DATA AcquirerCriteriaData for table
export function getAcquirerCriteriaData(params) {
  return async dispatch => {
    dispatch(acquirerCriteriaIsLoading());
    try {
      const data = await AcquirerCriteria.fetch(params);
      dispatch(acquirerCriteriaSuccess(data));
    } catch (e) {
      dispatch(acquirerCriteriaHasError(e));
    }
  };
}

export function getAcquirerCriteriaDetails(id) {
  return async dispatch => {
    dispatch(acquirerCriteriaIsLoading());
    try {
      const data = await AcquirerCriteria.fetchDetails(id);
      let track = await Trackers.getTrackerByAssociatedId(
        data.data.id,
        'acquirercriteria'
      );
      data.data.track = track.data;
      dispatch(acquirerCriteriaSuccess(data));
    } catch (e) {
      dispatch(acquirerCriteriaHasError(e));
    }
  };
}

const compareDiffSectors = (oldSectors, newSectors) => {
  // Find sectors which have been added or removed from the old list
  let addedSectors = newSectors.filter(
    newSector =>
      !oldSectors.some(oldSector => oldSector.value === newSector.value)
  );
  let removedSectors = oldSectors.filter(
    oldSector =>
      !newSectors.some(newSector => newSector.value === oldSector.value)
  );

  const returnLabel = sector => {
    let labels = sector.map(item => {
      if (item.label) {
        return item.label;
      }
    });
    return labels.join(', ');
  };

  return {
    'Added Sectors': returnLabel(addedSectors),
    'Removed Sector': returnLabel(removedSectors)
  };
};

// CREATE ACQUIRER CRITERIA
export function createAcquirerCriteria(action, data, props) {
  return async dispatch => {
    dispatch(acquirerCriteriaIsLoading());
    try {
      if (action == 'update') {
        data.acquirerProfiles[0].richDescription = JSON.stringify(
          data.acquirerProfiles[0].richDescription
        );

        const track_keys = [
          'notes',
          'description',
          'fee',
          'keywords',
          'netProfitRangeHigh',
          'netProfitRangeLow',
          'region',
          'sector',
          'turnoverRangeHigh',
          'turnoverRangeLow',
          'richDescription'
        ];
        const prev_data = await AcquirerCriteria.fetchDetails(
          data.acquirerProfiles[0].id
        );
        var tracked_changes = [];
        let old_data = prev_data.data;
        let new_data = data.acquirerProfiles[0];

        let obj_diff = compareDiff(old_data, new_data);

        Object.keys(obj_diff).forEach(function(e) {
          if (!['richDescription', 'subSector', 'sector'].includes(e)) {
            let item_id = new_data.id;
            let key = e;
            let new_value = obj_diff[e];
            tracked_changes.push({
              key: key,
              newvalue: formatString(new_value),
              associatedid: item_id
            });
          }

          if (e == 'sector') {
            let item_id = new_data.id;
            let key = 'sector';
            let old_value = JSON.parse(old_data.sector);
            let new_value = JSON.parse(obj_diff[e]);

            // Load old_value and new_value into JSON

            let diff = compareDiffSectors(old_value, new_value);

            Object.keys(diff).forEach(function(e) {
              let item_id = new_data.id;
              let key = e;
              let new_value = diff[e];

              if (new_value.length > 0) {
                // Load new value as string
                new_value = JSON.stringify(new_value);

                tracked_changes.push({
                  key: key,
                  newvalue: formatString(new_value),
                  associatedid: item_id
                });
              }
            });
          }
        });

        for (const t of tracked_changes) {
          let payload = {
            user: 1,
            table: 'acquirercriteria',
            changedid: columnToReadable(t.key),
            newvalue: formatString(t.newvalue),
            associatedid: formatString(t.associatedid)
          };
          await Trackers.create(payload);
        }
      }

      const response = await AcquirerCriteria[action](data);
      dispatch(acquirerCriteriaRequestDataSuccess(response));
      toast.success(`Saved`, settingsToast);
      dispatch(() => props.history.push(`${Routes.ACQUIRER_CRITERIA}`));
    } catch (e) {
      console.error(e);
      toast.error(
        'Something went wrong, check form fields or internet connection',
        settingsToast
      );
      dispatch(acquirerCriteriaHasError(e));
    }
  };
}

export function deleteAcquirerCriteria(data) {
  return async dispatch => {
    dispatch(acquirerCriteriaIsLoading());
    try {
      let id = data.acquirerProfiles['0'];
      const full_data = await AcquirerCriteria.fetchDetails(id);
      dispatch(deleteMatches({ acquirerCriteria: full_data['data']['id'] }));
      let parsed_update = {
        acquirerCriteria: [{ id: full_data['data']['id'], hidden: true }]
      };
      const response = await AcquirerCriteria.hideAcquirer(parsed_update);
      let payload = {
        user: 1,
        table: 'acquirerProfiles',
        trackertype: 'deleted',
        backupdata: JSON.stringify(full_data)
      };
      await Trackers.create(payload);

      dispatch(acquirerCriteriaDelete(response));
      dispatch(closeModal());
      toast.success('Deleted', settingsToast);
    } catch (e) {
      console.error(e);
      dispatch(acquirerCriteriaHasError(e));
    }
  };
}

// CLONE
export function cloneAcquirerCriteria(id, props) {
  return async dispatch => {
    dispatch(acquirerCriteriaIsLoading());
    try {
      const response = await AcquirerCriteria.clone(id);
      dispatch(acquirerCriteriaClone(response));
      dispatch(closeModal());
      toast.success(
        `You have successfully cloned the Acquirer Criteria. You're now viewing the new clone.`,
        settingsToast
      );
      dispatch(() =>
        props.history.push(`${Routes.ACQUIRER_CRITERIA}/${response.data.id}`)
      );
    } catch (e) {
      console.error(e);
      dispatch(acquirerCriteriaHasError(e));
    }
  };
}
