import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Loader } from 'semantic-ui-react';
import { useAuth0 } from '../../auth/react-auth0-spa';
import PageHeader from '../../components/PageHeader/PageHeader';
import Routes from '../../constants/routes';
import TableWrap from '../../components/TableWrap/TableWrap';
import headerTitles from './data';
import TableBody from './TableBody';
import { useMountAsyncEffect } from '../../utils/hooks';

const Acquirers = ({
  data,
  getAcquirersData,
  isLoading,
  location,
  optionsData,
  props,
  getUserNameOptions,
  partnerId,
  limit,
  setLimitItems
}) => {
  const { loading, user } = useAuth0();
  const [table, setTableData] = useState({
    companyName: '',
    activePage: 1
  });
  const { store } = useContext(ReactReduxContext);
  let a = store.getState();
  useMountAsyncEffect(getUserNameOptions);
  useEffect(() => {
    const fetchData = async () => {
      if(a.user.userAuth.partnerId){
      await getAcquirersData({
        limit,
        partnerId: a.user.userAuth.partnerId
      });
    }
    };
    fetchData();
  }, [getAcquirersData, partnerId, limit,a.user.userAuth.partnerId]);

  const changeLimitOnPage = async option => {
    await setLimitItems(parseInt(option.value, 10));
  };

  const setSearchTerm = async e => {
    const { value } = e.target;

    setTableData({
      ...table,
      companyName: value
    });

    return debounce(async () => {
      await getAcquirersData({
        companyName: value,
        limit
      });
    }, 500)();
  };

  const redirectToDetailsPage = id => props.history.push(`acquirers/${id}`);

  const onChangePage = (e, data) => {
    const { activePage } = data;
    const offset = (activePage - 1) * parseInt(limit, 10);

    setTableData({
      ...table,
      activePage
    });

    return debounce(async () => {
      await getAcquirersData({
        companyName: table.companyName,
        limit,
        offset
      });
    }, 500)();
  };
  const onChangeActivePage = (e, data) => {
    const { value } = data;
    setTableData({
      ...table,
      activePage: parseInt(value, 10)
    });
    const offset = (value - 1) * limit;

    return debounce(async () => {
      await getAcquirersData({
        companyName: table.companyName,
        limit,
        offset
      });
    }, 500)();
  };
  return (
    <>
      <PageHeader
        title="Acquirers"
        linkText="Add Acquirers"
        stateText="Create"
        location={location}
        path={`${Routes.ACQUIRERS}/create`}
      />
      <Loader active={loading || !user || isLoading} />
      {(data && data.items && (
        <>
          <TableWrap
            data={data}
            headerTitles={headerTitles}
            setSearchTerm={setSearchTerm}
            currentPage={table.activePage}
            searchQuery={table.companyName}
            changeItemsOnPage={changeLimitOnPage}
            onPageChange={onChangePage}
            onChangeActivePage={onChangeActivePage}
            tableBody={
              <TableBody
                data={data}
                owner={optionsData.users}
                redirectToDetailsPage={redirectToDetailsPage}
              />
            }
            limit={limit.toString()}
          />
        </>
      )) ||
        'no data yet'}
      {data && data.totalSize > 0 && <>Found {data && data.totalSize} items</>}
    </>
  );
};

Acquirers.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  getAcquirersData: PropTypes.func,
  getUserNameOptions: PropTypes.func,
  isLoading: PropTypes.bool,
  optionsData: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  partnerId: PropTypes.string,
  limit: PropTypes.number,
  setLimitItems: PropTypes.func
};

export default Acquirers;
