import React from 'react';

const SelectedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
    <defs>
      <clipPath id="a">
        <path d="M8.845 11.459a.5.5 0 1 0-.69.723l2.808 2.68a.5.5 0 0 0 .69 0l4.192-4a.5.5 0 1 0-.69-.724l-3.847 3.67z" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-95571268-8DEB-4AF0-AB59-533125A3AF3A)">
      <path
        fill=""
        d="M8.845 11.459a.5.5 0 1 0-.69.723l2.808 2.68a.5.5 0 0 0 .69 0l4.192-4a.5.5 0 1 0-.69-.724l-3.847 3.67z"
      />
      <g clipPath="url(#a)">
        <path fill="" d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default SelectedIcon;
