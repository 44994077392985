import React from 'react';

const MatchesIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="mna-sidebar__ico"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="4frha">
        <path d="M20.603 3.431a4.797 4.797 0 0 1 0 6.768l-4.328 4.33a4.782 4.782 0 0 1-6.764 0 .823.823 0 0 1 0-1.16.822.822 0 0 1 1.159 0 3.148 3.148 0 0 0 4.446 0l4.329-4.33a3.164 3.164 0 0 0-1.412-5.294 3.16 3.16 0 0 0-3.055.816L11.69 7.87a.842.842 0 0 1-1.159 0 .823.823 0 0 1 0-1.16l3.308-3.31a4.782 4.782 0 0 1 6.764 0zM12.997 16.65a.823.823 0 0 1 0 1.16l-2.842 2.794A4.743 4.743 0 0 1 6.777 22 4.778 4.778 0 0 1 3.4 13.845l3.15-3.14a4.782 4.782 0 0 1 6.764 0 .823.823 0 0 1-1.159 1.158 3.158 3.158 0 0 0-4.447 0l-3.15 3.122a3.164 3.164 0 0 0 1.424 5.29 3.16 3.16 0 0 0 3.053-.822l2.774-2.804a.842.842 0 0 1 1.188 0z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g />
        <g>
          <path d="M20.603 3.431a4.797 4.797 0 0 1 0 6.768l-4.328 4.33a4.782 4.782 0 0 1-6.764 0 .823.823 0 0 1 0-1.16.822.822 0 0 1 1.159 0 3.148 3.148 0 0 0 4.446 0l4.329-4.33a3.164 3.164 0 0 0-1.412-5.294 3.16 3.16 0 0 0-3.055.816L11.69 7.87a.842.842 0 0 1-1.159 0 .823.823 0 0 1 0-1.16l3.308-3.31a4.782 4.782 0 0 1 6.764 0zM12.997 16.65a.823.823 0 0 1 0 1.16l-2.842 2.794A4.743 4.743 0 0 1 6.777 22 4.778 4.778 0 0 1 3.4 13.845l3.15-3.14a4.782 4.782 0 0 1 6.764 0 .823.823 0 0 1-1.159 1.158 3.158 3.158 0 0 0-4.447 0l-3.15 3.122a3.164 3.164 0 0 0 1.424 5.29 3.16 3.16 0 0 0 3.053-.822l2.774-2.804a.842.842 0 0 1 1.188 0z" />
        </g>
        <g clipPath="url(#4frha)">
          <g>
            <path fill="#99a9bb" d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MatchesIco;
