import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import 'react-toastify/dist/ReactToastify.minimal.css';
import 'react-toastify/dist/ReactToastify.min.css';

import PageHeader from '../../components/PageHeader/PageHeader';

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableBodyProps,
    headerGroups,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'createdAt',
            desc: true
          }
        ]
      }
    },
    usePagination
  );

  return (
    <>
      <div className="pagination">
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          className="restore-select"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <button
          className="restore-leftpage"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <span>
          {' '}
          {pageIndex + 1} of {pageOptions.length}
        </span>
        <button
          className="restore-rightpage"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
      </div>
      <table className="restore-table">
        <thead className="restore-table-header">
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={'a' + i} className="restore-table-header__row"
            >
              {headerGroup.headers.map((column, j) => (
                <th
                  key={'b'+j}
                  className="restore-table-header__column"
                  width={column.width}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="restore-table-body" {...getTableBodyProps()}></tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
    </>
  );
}

const MatchHistory = ({
  getMatchHistoryData,
}) => {

  const [matchHistory, setMatchHistory] = React.useState(null);

  useEffect(() => {
    getMatchHistoryData().then(data => {
      if (data) {
        setMatchHistory(data.data);
      }
    })

  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Stage',
        width: '10%',
      },
      {
        Header: 'Created',
        width: '15%',
      },
      {
        Header: 'Acquirer',
        width: '20%',
      },
      {
        Header: 'Opportunity',
        width: '20%',
      },
      {
        Header: 'Last Updated',
        width:'15%',
      },
      {
        Header: 'Action',
        width: '10%',
      },
    ],
    []
  );

  var data = []

  return (
    <>
      <PageHeader title="Match History" nobutton="hiddenbutton" />
      <Table columns={columns} data={data} />
    </>
  );
};

export default MatchHistory;
