import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Keyword from './keyword';

const KeywordInput = ({ keywords, setFieldValue }) => {
  const [keyword, setKeyword] = useState({
    query: '',
  });

  const onRemoveItem = index => {
    const data = keywords.filter((item, i) => i !== index);
    setFieldValue('keywords', data);
  };

  const renderKewords = () =>
    keywords &&
    keywords.map((item, index) => (
      <Keyword
        item={item}
        key={`${item}-${index}`}
        index={index}
        onRemoveItem={onRemoveItem}
      />
    ));

  const handleKeyDown = e => {
    const { value } = e.target;
    // 13 - ENTER
    if (e.keyCode === 13 && value.length > 0) {
      setFieldValue('keywords', [...keywords, value]);
      setKeyword(() => {
        return {
          query: '',
        };
      });
    }
    // 8 - Delete
    if (keywords.length && e.keyCode === 8 && !keyword.query.length) {
      const data = keywords.slice(0, keywords.length - 1);
      setFieldValue('keywords', data);
    }
  };

  const onChange = e => {
    setKeyword({
      ...keyword,
      query: e.target.value,
    });
  };

  return (
    <div className="mna-keyword">
      {renderKewords()}
      <input
        type="text"
        name="keyword"
        className="mna-keyword__input"
        value={keyword.query}
        onKeyDown={handleKeyDown}
        onChange={onChange}
      />
    </div>
  );
};

KeywordInput.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func.isRequired,
};

export default KeywordInput;
