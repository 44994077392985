import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, Modal } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup, Input, PhoneInput, Select } from '../../components/shared';
import { useAuth0 } from '../../auth/react-auth0-spa';
import PageHeader from '../../components/PageHeader/PageHeader';
import TableWrap from '../../components/TableWrap/TableWrap';
import { headerTitles, typesList, statusOptions } from './data';
import TableBody from './TableBody';
import Button from '../../components/shared/Form/Button';
import { handleDropdownChange } from '../../utils/utils';
import partnerOptions from '../../Data/Select/PartnerOptions';
import Type from '../../components/Type/Type';
import typeOptions from '../../Data/Select/Types';
import ContactFilterModal from '../../components/Modal/ContactFilterModal';
const Contacts = ({
  data,
  allData,
  getContactsData,
  getContactsDetails,
  openModal,
  closeModal,
  isLoading,
  props,
  partnerId,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleSubmit,
  touched,
  values,
  errors,
  editMode,
  createMode,
  isCreate,
  modalIsOpen,
  deleteContact,
  currentContact,
  userAuth,
  tableOptions,
  sortParams,
  hasPartner,
  migrateContact,
  limit,
  setLimitItems
}) => {
  const { loading, user } = useAuth0();
  const { permissions } = userAuth;
  const [table, setTableData] = useState({
    searchQuery: '',
    activePage: 1,
    type: null,
    partnerId
  });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [isOpenMigrateModal, setIsOpenMigrateModal] = useState(false);
  const [isMigrateLoading, setIsMigrateLoading] = useState(false);

  const [filters, setFilters] = useState({ count: 0 });
  const tableDateForView = {
    search: table.searchQuery,
    limit,
    offset: (table.activePage - 1) * limit,
    type: table.type,
    partnerId
  };
  const setActivePage = (limitItems, totalItems, currentActive) => {
    const count = totalItems / limitItems;
    const active = count < currentActive ? Math.floor(count) : currentActive;
    return active || 1;
  };
  useEffect(() => {
    async function fetchDada() {
      Promise.all([
        getContactsData({
          ...tableDateForView,
          limit,
          search: table.searchQuery,
          partnerId
        }),
        tableOptions(tableDateForView),
        setFilters({
          count: (partnerId !== null && !hasPartner && 1) || filters.count
        })
      ]);
    }
    fetchDada();
    // eslint-disable-next-line
  }, [limit, partnerId]);
  const getListOfAllContacts = async () => {
    // Return all contacts with infinity limit
    await getContactsData({
      search: '',
      limit: 100,
      offset: 0
    });
  };
  const changeLimitOnPage = async option => {
    const { totalSize } = data;
    await setLimitItems(parseInt(option.value, 10));
    await setTableData({
      ...table,
      activePage: setActivePage(option.value, totalSize, table.activePage)
    });
    await getContactsData({
      limit: parseInt(option.value, 10),
      partnerId
    });
  };
  const setSearchTerm = async e => {
    const { value } = e.target;
    setTableData({
      ...table,
      searchQuery: value
    });
    return debounce(async () => {
      await getContactsData({
        search: value,
        limit,
        partnerId
      });
    }, 500)();
  };
  const getDetailsPage = id => {
    openModal();
    editMode();
    getContactsDetails(id);
  };
  const onClick = () => {
    openModal();
    createMode();
  };
  const onChangePage = (e, data) => {
    const { activePage } = data;
    const offset = (activePage - 1) * limit;
    setTableData({
      ...table,
      activePage
    });
    return debounce(async () => {
      await getContactsData({
        company: table.searchQuery,
        limit,
        offset,
        partnerId
      });
    }, 500)();
  };
  const onChangeActivePage = (e, data) => {
    const { value } = data;
    setTableData({
      ...table,
      activePage: parseInt(value, 10)
    });
    const offset = (value - 1) * limit;
    return debounce(async () => {
      await getContactsData({
        search: table.searchQuery,
        limit,
        offset,
        partnerId
      });
    }, 500)();
  };
  const onSumbit = async () => {
    await tableOptions({
      ...tableDateForView
    });
    await handleSubmit();
  };
  const applyFilters = () => {
    const type =
      values.types === 'None' || !values.types ? null : [values.types];
    setTableData({
      ...table,
      activePage: 1,
      type
    });
    const defaultOption = hasPartner ? 1 : 0;
    setFilters({
      count: type && filters.count + 1 - defaultOption
    });
    return debounce(async () => {
      await tableOptions({
        ...tableDateForView,
        type,
        offset: 0
      });
      await getContactsData({
        ...tableDateForView,
        type,
        offset: 0,
        partnerId
      });
      await setIsOpenFilterModal(false);
    }, 500)();
  };
  const onDelete = async () => {
    await deleteContact(currentContact.id, currentContact);
    await setIsOpenDeleteModal(false);
    await getContactsData(sortParams);
  };
  const migrateUser = async () => {
    // Get the ID of the user to migrate from
    const fromUserId = values.id;
    // ID of the user to migrate
    const toUserId = values.migrateUser;

    // Send the request to the /users/migrate endpoint
    await migrateContact(fromUserId, toUserId);

    // close modals
    setIsOpenMigrateModal(false);
    closeModal();
    return;
  };

  useEffect(() => {
    // if partnerId is null, then we need to set the partnerId to the user's partnerId
    if (values.partnerId === null) {
      setFieldValue('partnerId', userAuth.partnerId);
    }
  }),
    [values.partnerId, userAuth.partnerId];

  return (
    <>
      <PageHeader title="Contacts" linkText="Add contact" onClick={onClick} />
      <Loader active={loading || isLoading || !user} />
      {(data && data.items && (
        <>
          <TableWrap
            countFilterOptions={filters.count}
            data={data}
            headerTitles={headerTitles}
            setSearchTerm={setSearchTerm}
            currentPage={table.activePage}
            searchQuery={table.searchQuery}
            changeItemsOnPage={changeLimitOnPage}
            setFieldValue={setFieldValue}
            onPageChange={onChangePage}
            onChangeActivePage={onChangeActivePage}
            isFilters
            openModal={() => setIsOpenFilterModal(true)}
            tableBody={
              <TableBody data={data} getDetailsPage={getDetailsPage} />
            }
            limit={limit.toString()}
          />
        </>
      )) ||
        'no data yet'}
      <Modal open={modalIsOpen} onClose={closeModal} className="mna-modal">
        <header className="mna-modal__header text-center">
          <h2 className="title title-main">
            {isCreate ? 'Add Contact' : 'Edit Contact'}
          </h2>
        </header>
        <div className="mna-modal__content">
          <form className="form-wrap">
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup labelText="first name" name="firstName">
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onKeyUp={() => setFieldTouched('firstName', true)}
                  />
                  <p className="form-group__error">
                    {touched.firstName && errors.firstName}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup labelText="last name" name="lastName">
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onKeyUp={() => setFieldTouched('lastName', true)}
                  />
                  <p className="form-group__error">
                    {touched.lastName && errors.lastName}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup labelText="Email" name="email">
                  <Input
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onKeyUp={() => setFieldTouched('email', true)}
                    //disabled={!isCreate && permissions !== 'SuperAdmin'}
                  />
                  <p className="form-group__error">
                    {touched.email && errors.email}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup labelText="Phone Number" name="phone">
                  <PhoneInput
                    onChange={handleChange}
                    dropdownOnChange={(e, data) =>
                      handleDropdownChange(e, setFieldValue, data)
                    }
                    name="phone"
                    value={values.phone || ''}
                    codeCountry={values.areaCode || '+44'}
                    onKeyUp={() => setFieldTouched('phone', true)}
                  />
                  <p className="form-group__error">
                    {touched.phone && errors.phone}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup labelText="Company" name="company">
                  <Input
                    name="company"
                    value={values.company || ''}
                    onChange={handleChange}
                    onKeyUp={() => setFieldTouched('company', true)}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup labelText="partner" name="partnerId">
                  <Select
                    name="partnerId"
                    id="partnerId"
                    customClassName="smaller-font"
                    options={
                      hasPartner
                        ? [
                            {
                              label: userAuth.partner.name,
                              key: userAuth.partner.name,
                              value: userAuth.partnerId
                            }
                          ]
                        : partnerOptions
                    }
                    value={
                      values.partnerId ||
                      (hasPartner ? userAuth.partnerId : partnerId)
                    }
                    onCustomClick={e => {
                      console.log(`Clicked on ${values.partnerId}`);
                    }}
                    setFieldValue={setFieldValue}
                    //isDisabled={!isCreate && permissions !== 'SuperAdmin'}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup labelText="type" name="type">
                  <Type
                    isReadable={!isCreate}
                    options={typesList}
                    values={values.type || []}
                    onChange={handleChange}
                    name="type"
                    type="checkbox"
                    column
                  />
                </FormGroup>
                <p className="form-group__error">
                  {touched.type && errors.type}
                </p>
                {values.type && values.type.includes('Partner/Internal') && (
                  <>
                    <FormGroup labelText="password" name="password">
                      <Input
                        name="password"
                        type="password"
                        value={values.password || ''}
                        onChange={handleChange}
                        onKeyUp={() => setFieldTouched('dateOfBirth', true)}
                        //disabled={!isCreate && permissions !== 'SuperAdmin'}
                      />
                    </FormGroup>
                    <p className="form-group__error">
                      {touched.password && errors.password}
                    </p>
                  </>
                )}
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup labelText="date of birth" name="dateOfBirth">
                  <Input
                    name="dateOfBirth"
                    type="date"
                    value={values.dateOfBirth || ''}
                    onChange={handleChange}
                    onKeyUp={() => setFieldTouched('dateOfBirth', true)}
                  />
                </FormGroup>
              </Col>
              {!isCreate && (
                <Col xs={12} sm={6}>
                  <FormGroup labelText="status" name="status">
                    <Select
                      name="status"
                      id="status"
                      options={statusOptions}
                      value={values.status || ''}
                      setFieldValue={setFieldValue}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </form>
        </div>
        <div
          className={`mna-modal__actions${
            !isCreate && permissions === 'SuperAdmin'
              ? ' mna-modal__actions--alt'
              : ''
          }`}
        >
          {!isCreate && permissions === 'SuperAdmin' && (
            <Button
              basic
              text="Delete"
              onClick={() => setIsOpenDeleteModal(true)}
              type="button"
              danger
            />
          )}
          <div className="mna-modal__actions-buttons">
            <Button
              basic
              successWithoutBg
              text="Migrate"
              onClick={async () => {
                await getListOfAllContacts();
                setIsOpenMigrateModal(true);
              }}
            />
            <Button basic success text="Save" onClick={onSumbit} />
          </div>
        </div>
        {/* Migrate Modal */}
        <Modal
          open={isOpenMigrateModal}
          onClose={() => setIsOpenMigrateModal(false)}
          className="mna=modal"
        >
          <header className="mna-modal__header">
            <h2 className="mna-modal__title text-center">Migrate User</h2>
          </header>
          <div className="mna-modal__content">
            <div className="mna-modal__content-inner">
              {!isMigrateLoading ? (
                <div className="mna-modal__content-inner-form">
                  <FormGroup labelText="User" name="migrateUser">
                    <Select
                      name="migrateUser"
                      id="migrateUser"
                      setFieldValue={setFieldValue}
                      value={values.migrateUser || ''}
                      // searchable
                      isSearchable={true}
                      options={
                        data.items &&
                        data.items.map(user => ({
                          label: user.firstName + ' ' + user.lastName,
                          key: user.id,
                          value: user.id
                        }))
                      }
                    />
                  </FormGroup>
                  <p>Transfer the user's data to another user.</p>
                </div>
              ) : (
                <>Loading...</>
              )}
            </div>
          </div>
          <div className="mna-modal__actions">
            <Button
              basic
              successWithoutBg
              text="Cancel"
              onClick={() => setIsOpenMigrateModal(false)}
            />
            <Button
              basic
              success
              text="Migrate"
              onClick={async () => {
                setIsMigrateLoading(true);
                await migrateUser();

                setIsMigrateLoading(false);

                setIsOpenMigrateModal(false);
              }}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenDeleteModal}
          onClose={() => setIsOpenDeleteModal(false)}
          className="mna-modal"
        >
          <header className="mna-modal__header text-center">
            <h2 className="title title-main">Delete Contact</h2>
          </header>
          <div className="mna-modal__content">Are you sure?</div>
          <div className="mna-modal__actions">
            <Button
              basic
              successWithoutBg
              text="No"
              onClick={() => setIsOpenDeleteModal(false)}
            />
            <Button basic success text="Yes" onClick={onDelete} />
          </div>
        </Modal>
      </Modal>
      <ContactFilterModal
        header="Select Filter"
        btnSaveText="Save"
        btnCancelText="Cancel"
        modalIsOpen={isOpenFilterModal}
        onFilterData={applyFilters}
        options={typeOptions}
        setFieldValue={setFieldValue}
        values={values}
        closeModal={() => setIsOpenFilterModal(false)}
      />
    </>
  );
};
Contacts.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  getContactsData: PropTypes.func,
  getContactsDetails: PropTypes.func,
  isLoading: PropTypes.bool,
  partnerId: PropTypes.string,
  props: PropTypes.objectOf(PropTypes.object),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  editMode: PropTypes.func,
  createMode: PropTypes.func,
  isCreate: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  touched: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  values: PropTypes.objectOf(PropTypes.any),
  userAuth: PropTypes.objectOf(PropTypes.any),
  deleteContact: PropTypes.func,
  tableOptions: PropTypes.func,
  currentContact: PropTypes.objectOf(PropTypes.any),
  sortParams: PropTypes.objectOf(PropTypes.any),
  hasPartner: PropTypes.bool,
  limit: PropTypes.number,
  setLimitItems: PropTypes.func
};
export default Contacts;
