import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import PartnerLogo from '../../components/shared/PartnerLogo/PartnerLogo';

const TableBody = ({ data, getDetailsPage }) => {
  return (
    <>
      {data &&
        data.items.map(
          ({ company, id, firstName, lastName, type, email, partnerId }) => (
            <Table.Row key={id} onClick={() => getDetailsPage(id)}>
              <Table.Cell>
                <div className="title title--table">{`${firstName} ${lastName}`}</div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">{company}</div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table title--ellipsis">
                  {email}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">
                  {type && type.join(', ')}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="mna-table__logo">
                  <PartnerLogo id={partnerId} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        )}
    </>
  );
};

TableBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  getDetailsPage: PropTypes.func,
};

export default TableBody;
