// OPPORTUNITY Page
export const OPPORTUNITY_IS_LOADING = 'OPPORTUNITY_IS_LOADING';
export const OPPORTUNITY_SUCCESS = 'OPPORTUNITY_SUCCESS';
export const OPPORTUNITY_REPORT_SUCCESS = 'OPPORTUNITY_REPORT_SUCCESS';
export const OPPORTUNITY_ERROR = 'OPPORTUNITY_ERROR';
export const OPPORTUNITY_CLONE = 'OPPORTUNITY_CLONE';
export const OPPORTUNITY_REMOVE = 'OPPORTUNITY_REMOVE';
export const OPPORTUNITY_POST_DATA = 'OPPORTUNITY_POST_DATA';
export const OPPORTUNITY_REPORT_IS_LOADING = 'OPPORTUNITY_REPORT_IS_LOADING';
export const OPPORTUNITY_DELETE_USER = 'OPPORTUNITY_DELETE_USER';
export const OPPORTUNITY_POST_DATA_SUCCESS = 'OPPORTUNITY_POST_DATA_SUCCESS';
export const OPPORTUNITY_POST_DATA_ERROR = 'OPPORTUNITY_POST_DATA_ERROR';

//RESTORE page
export const RESTORE_SUCCESS = 'RESTORE_SUCCESS'

//EMAIL HISTORY
export const EMAIL_HISTORY_IS_LOADING = 'EMAIL_HISTORY_IS_LOADING';
export const EMAIL_HISTORY_SUCCESS = 'EMAIL_HISTORY_SUCCESS';
export const EMAIL_HISTORY_ERROR = 'EMAIL_HISTORY_ERROR';

// ACQUIRER CRITERIA Page
export const ACQUIRER_CRITERIA_IS_LOADING = 'ACQUIRER_CRITERIA_IS_LOADING';
export const ACQUIRER_CRITERIA_SUCCESS = 'ACQUIRER_CRITERIA_SUCCESS';
export const ACQUIRER_CRITERIA_ERROR = 'ACQUIRER_CRITERIA_ERROR';
export const ACQUIRER_CRITERIA_REMOVE = 'ACQUIRER_CRITERIA_REMOVE';
export const ACQUIRER_CRITERIA_CLONE = 'ACQUIRER_CRITERIA_CLONE';
export const ACQUIRER_CRITERIA_POST_DATA_SUCCESS =
  'ACQUIRER_CRITERIA_POST_DATA_SUCCESS';

export const ACQUIRERS_IS_LOADING = 'ACQUIRERS_IS_LOADING';
export const ACQUIRERS_SUCCESS = 'ACQUIRERS_SUCCESS';
export const ACQUIRERS_ERROR = 'ACQUIRERS_ERROR';
export const ACQUIRERS_REMOVE = 'ACQUIRERS_REMOVE';
export const ACQUIRERS_POST_DATA_SUCCESS = 'ACQUIRERS_POST_DATA_SUCCESS';

// MATCHES
export const MATCHES_IS_LOADING = 'MATCHES_IS_LOADING';
export const MATCHES_SUCCESS = 'MATCHES_SUCCESS';
export const MATCHES_ERROR = 'MATCHES_ERROR';
export const MATCHES_REMOVE = 'MATCHES_REMOVE';
export const MATCHES_DETAILS = 'MATCHES_DETAILS';
export const MATCHES_POST_DATA_SUCCESS = 'MATCHES_POST_DATA_SUCCESS';

// USER
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_ERROR = 'USER_ERROR';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';

export const GET_USER = 'GET_USER';
export const USER_OPTIONS_SUCCESS = 'USER_OPTIONS_SUCCESS';

// SIDEBAR
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// MODAL
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

// GLOBAL
export const SET_PARTNER = 'SET_PARTNER';

// SELECT OPTIONS
export const SELECT_OPTIONS_ERROR = 'SELECT_OPTIONS_ERROR';
export const PARTNERS_OPTIONS_SUCCESS = 'PARTNERS_OPTIONS_SUCCESS';
export const BUSINESSES_OPTIONS_SUCCESS = 'BUSINESSES_OPTIONS_SUCCESS';
export const ACQUIRERS_OPTIONS_SUCCESS = 'ACQUIRERS_OPTIONS_SUCCESS';
export const ACQUIRER_CRITERIA_OPTIONS_SUCCESS =
  'ACQUIRER_CRITERIA_OPTIONS_SUCCESS';
export const USER_NAME_OPTIONS_SUCCESS = 'USER_NAME_OPTIONS_SUCCESS';
export const CONTACTS_OPTIONS_SUCCESS = 'CONTACTS_OPTIONS_SUCCESS';
