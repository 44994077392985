import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import numeral from 'numeral';

const { diff } = require('deep-object-diff');

// HELPER, TO GET VALUE FROM SEMANTIC DROPDOWN IN FORMIK
export const handleDropdownChange = (e, setFieldValue, { name, value }) => {
  setFieldValue(name, value);
};

export function daysSince(d) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const oneHour = 60 * 60 * 1000;
  const oneMinute = 60 * 1000;

  const firstDate = new Date(d);
  const secondDate = new Date();

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  if (diffDays === 0) {
    var hours = Math.round(Math.abs((firstDate - secondDate) / oneHour));
    if (hours === 0) {
      return (
        Math.round(Math.abs((firstDate - secondDate) / oneMinute)).toString() +
        ' minutes'
      );
    }
    return hours.toString() + ' hours';
  }

  return diffDays.toString() + ' days';
}

export function truncate(str, length) {
  if (!length) length = 300;
  if (typeof str === 'string') {
    str = str.substring(0, length);
    if (str.length === length) str = str + '...';
  }
  return str;
}

// HELPER TO REMOVE TYPE OF SECTION (Matches, AC, Opportunities etc)
export const deleteType = (name, data, fn) => {
  const arr = [];
  const obj = {
    name: arr.push(data)
  };

  return fn(obj);
};

export function returnObjectValue(obj) {
  if (obj==='true') return 'True'
  if (obj === 'false') return 'False'
  return obj;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function compareDiff(a, b) {
  let obj_diff = diff(a, b);
  const ignore_list = ['createdAt', 'updatedAt', 'clearNamesVia', 'sourceUser'];
  Object.keys(obj_diff).forEach(function(e) {
    // All this just for contacts
    if (e === 'contacts') {
      delete obj_diff['contacts'];
      delete obj_diff['contactRoles'];

      let contact_roles = [];

      a[e].forEach(function(f) {
        let id = f.contact.id;
        let role = f.role;
        let detail = { role: role, contactId: id };
        contact_roles.push(detail);
      });

      let contact_diff = diff(b['contactRoles'], contact_roles);

      if (Object.keys(contact_diff).length > 0) {
        obj_diff.contacts = contact_diff;
        var count = 0;
        Object.keys(contact_diff).forEach(function(e) {
          count += 1;
          if (contact_diff[e] !== undefined) {
            let id = contact_diff[e].contactId;
            let role = contact_diff[e].role;
            var description;

            if (id && role) {
              description = 'Contact Updated: ' + id + ' New Role: ' + role;
            }
            if (id && !role) {
              description = 'New Contact: ' + id;
            }
            if (!id && role) {
              description = 'Role Updated: ' + role;
            }
            obj_diff['contact' + count.toString()] = description;
          }
        });

        delete obj_diff['contacts'];
      }
    }
    if (e === 'keywords') {
      var count = 0;
      Object.keys(obj_diff['keywords']).forEach(function(e) {
        count = count + 1;
        var description;
        description = obj_diff['keywords'][e];
        obj_diff['keywords' + count.toString()] = description;
      });

      delete obj_diff.keywords;
    }
    /*
        if (e === 'sector') {
            if (typeof obj_diff['sector'] !== 'string') {
            var count = 0
            Object.keys(obj_diff['sector']).forEach(function(e) {
                count = count + 1
                var description
                description = obj_diff['sector'][e]
                obj_diff['sector'+count.toString()] = description
            })
            delete obj_diff.sector
        }
        }
        if (e === 'subSector') {
            if (typeof obj_diff['subSector'] !== 'string') {
                var count = 0
                Object.keys(obj_diff['subSector']).forEach(function(e) {
                    count = count + 1
                    var description
                    description = obj_diff['subSector'][e]
                    obj_diff['subSector'+count.toString()] = description
                })
                delete obj_diff.subSector
            }
        }
        */
    if (e === 'region') {
      if (typeof obj_diff['region'] !== 'string') {
        var count = 0;
        Object.keys(obj_diff['region']).forEach(function(e) {
          count = count + 1;
          var description;
          description = obj_diff['region'][e];
          obj_diff['region' + count.toString()] = description;
        });
        delete obj_diff.region;
      }
    }
    if (e === 'financials') {
      delete obj_diff['financials'];
      a[e].forEach(function(f) {
        delete f.createdAt;
        delete f.updatedAt;
        delete f.acquirerId;
        delete f.businessId;
        f.turnover = parseInt(f.turnover);
        f.netProfit = parseInt(f.netProfit);
      });

      let financials_diff = diff(a[e], b[e]);

      if (Object.keys(financials_diff).length > 0) {
        var count = 0;
        Object.keys(financials_diff).forEach(function(e) {
          count += 1;
          let description = Object.keys(financials_diff[e])
            .map(function(key) {
              return key + '=' + financials_diff[e][key];
            })
            .join(', ');

          obj_diff['financial' + count.toString()] = description;
        });
      }
    }
    if (e === 'type') {
      if (typeof obj_diff['type'] !== 'string') {
        let a = { 0: 'Distressed', 1: undefined, 2: 'Pitch' };
        var type_results = [];

        Object.keys(obj_diff[e]).forEach(function(g) {
          let defined = obj_diff[e][g] ? true : false;
          if (defined) type_results.push(obj_diff[e][g]);
        });

        let results = type_results.join(', ');
        obj_diff['type'] = results;
      }
    }
  });
  ignore_list.forEach(function(e) {
    delete obj_diff[e];
  });
  Object.keys(obj_diff).forEach(function(e) {
    if (!isDefined(obj_diff[e])) delete obj_diff[e];
    if (obj_diff[e] == null) delete obj_diff[e];
  });

  return obj_diff;
}

export function parseTable(table) {
  if (table === 'acquirers') {
    return 'Acquirers';
  }

  if (table === 'acquirerProfiles') {
    return 'Acquirer Profiles';
  }

  if (table === 'opportunities') {
    return 'Opportunities';
  }
}

export default function removeNullValues(data) {
  for (const i in data) {
    if (!data[i]) {
      data[i] = '';
    } else {
      continue;
    }
  }
  return data;
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function formatString(a) {
  if (typeof a == 'boolean') {
    if (!a) {
      return 'false';
    } else {
      return 'true';
    }
  }
  if (a === 0) {
    return '0';
  }
  if (!a) {
    return a;
  } else {
    return a.toString();
  }
}

export function formatNullValue(a) {
  if (!a) {
    return 'Empty';
  } else {
    return a.toString();
  }
}

export function defaultSectors(s) {
  return ['yo', 'hi'];
}
export function renderSectors(sector, limit) {
  if (sector) {
    var sectorArray = [];
    let parsedSector = JSON.parse(sector);
    const parsedSubSectors = parsedSector.filter(s => !!s);
    if (parsedSubSectors.length) {
      for (var i = 0; i < parsedSubSectors.length; i++) {
        if (i < limit) {
          sectorArray.push(parsedSubSectors[i].label);
        } else {
          sectorArray.push('...');
          break;
        }
      }
      return sectorArray.join(', ');
    }
    return '';
  }
}

export function recursivelyRenderDescription(description, limit) {
  var descriptionList = [];

  function renderDescription(description, descriptionList) {
    description.map(c => {
      if ('text' in c) {
        descriptionList.push(c.text);
      } else if ('children' in c) {
        renderDescription(c.children, descriptionList);
      } else {
        return;
      }
    });
  }
  renderDescription(description, descriptionList);

  return descriptionList.join(' ');
}

export function columnToReadable(c) {
  const convertDict = {
    notes: 'Notes',
    businessName: 'Business Name',
    areaCode: 'Area Code',
    clearNames: 'Clear Names',
    codeName: 'Code Name',
    companyNumber: 'Company Number',
    description: 'Description',
    fee: 'Fee',
    financialsVerified: 'Financials Verified',
    forSale: 'For Sale',
    idVerified: 'ID Verified',
    inExclusivity: 'In Exclusivity',
    managed: 'Managed',
    overseasBuyers: 'Overseas Buyers',
    partnerId: 'Partner ID',
    peBuyers: 'PE Buyers',
    peBuyer: 'PE Buyer',
    remarketedDate: 'Remarketed Date',
    sector: 'Sector',
    sensitive: 'Sensitive',
    shareholdingVerified: 'Verified Shareholding',
    richDescription: 'Rich Description',
    subSector: 'Sub-sector',
    anonDescription: 'Anonymised Description',
    companyDescription: 'Company Description',
    companyName: 'Company Name',
    companyNumber: 'Company Number',
    managed: 'Managed',
    keywords: 'Keywords',
    netProfitRangeHigh: 'High EBITDA Range',
    netProfitRangeLow: 'Low EBITDA Range',
    region: 'Region',
    vendorScore: 'Vendor Score',
    acquirerScore: 'Acquirer Score',
    sector: 'Sector',
    approved: 'Approved',
    stage: 'Stage',
    turnoverRangeHigh: 'High Turnover Range',
    turnoverRangeLow: 'Low Turnover Range',
    type: 'Type'
  };
  if (c in convertDict) return convertDict[c];

  if (c.substring(0, 7) == 'contact') {
    return 'Contacts';
  }
  if (c.substring(0, 6) == 'region') {
    return 'Region';
  }
  if (c.substring(0, 7) == 'keyword') {
    return 'Keywords';
  }
  if (c.substring(0, 6) == 'sector') {
    return 'Sector';
  }
  if (c.substring(0, 7) == 'subSect') {
    return 'Sub-sector';
  }

  if (c.substring(0, 7) == 'financi') {
    return 'Financial';
  } else return c;
}

export function isDefined(variable) {
  if (typeof variable !== 'undefined') return true;
  else return false;
}

// YYYYMMDD
export const formatDate = date =>
  date ? new Date(`${date}`).toISOString().slice(0, 10) : null;

// GET MAX NUMBER FROM PROPERTY
export const getMaxNumFromArray = (data, name) => {
  const arr = data.map(item => +item[name]);
  return Math.max(...arr);
};

export function kFormatter(num, digits) {
  if (!num) {
    return 0;
  }
  if (num === 0 || num === '0') {
    return '-'

  }
  const lookup = [
    { value: -1, symbol: '' },
    { value: -1e3, symbol: 'k' },
    { value: -1e6, symbol: 'm' },
    { value: -1e9, symbol: 'G' },
    { value: -1e12, symbol: 'T' },
    { value: -1e15, symbol: 'P' },
    { value: -1e18, symbol: 'E' },
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function(item) {
      return num >= item.value;
    });
  return (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol;
}

export function getDataByLastAvailableYear(financials) {
  var turnover, netProfit;

  if (financials.length) {
    let latest_year = financials[0];
    turnover = latest_year.turnover;
    netProfit = latest_year.netProfit;
  }

  return { turnover: kFormatter(turnover, 3), ebitda: kFormatter(netProfit, 3) };
}

// get data by the most recent year
export const getDataByLastYear = (array, year, name) => {
  const currentYear = new Date().getFullYear();
  try {
    const data = array.reduce((prev, current) =>
      prev[year] > current[year] && prev[year] < currentYear + 1
        ? prev
        : current[year] < currentYear + 1
        ? current
        : prev
    );
    return data[name];
  } catch {
    console.log('Error in financial match data');
  }

  return null;
};

export const isNumExist = num => num && typeof parseInt(num, 10) === 'number';

// for clearnamesvia, associated partner, owner, sourse
export const createDynamicOptions = (array, isPartners, isProfile) => {
  return array.map(item => {
    let { firstName, lastName } = item;

    if (item.businessName) {
      item.companyName = item.businessName;
    }

    let label = `${firstName} ${lastName}`;

    if (isProfile) {
      const description = item.description ? ` - ${item.description}` : '';

      label = `${item.acquirer.companyName}${description}`;
    }

    if (isPartners) {
      label = get(item, 'name', `${item.companyName}`);
      if (item.defaultContactId) {
        const contactPartner = item.defaultContactId.toString();
        return {
          key: item.id,
          value: `${item.id}`,
          label,
          contactPartner
        };
      }
      return {
        key: item.id,
        value: `${item.id}`,
        label
      };
    }

    return {
      key: item.id,
      value: `${item.id}`,
      label
    };
  });
};

export const createDynamicNamesOptions = array => {
  return array.map(item => {
    const { firstName, lastName, company } = item;
    const label = `${firstName} ${lastName}${company ? `, ${company}` : ''}`;

    return {
      key: item.id,
      value: `${item.id}`,
      label
    };
  });
};

export const sleep = (ms = 0) => new Promise(r => setTimeout(r, ms));

export const removeFieldsWithEmptyStrings = obj =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v !== ''));

export const getSubSectorOptions = (arr, name) => {
  return arr.some(item => item.label === name)
    ? arr.find(option => option.label === name).options
    : null;
};

export const isValuePropsAreEqual = (prev, next) => prev.value === next.value;

export const setNumberIsValueExist = value =>
  !isEmpty(value) ? parseInt(value, 10) : null;

export const transformFinance = item => {
  return {
    turnover: parseInt(numeral(item.turnover).format('1000'), 10),
    netProfit: parseInt(numeral(item.netProfit).format('1000'), 10),
    fyEnding: item.fyEnding,
    projectedNumbers: item.projectedNumbers
  };
};

export const setPartnerContact = (id, options, role) => {
  if (isNull(id)) return [];

  const contactId =
    options && options.find(item => item.value === id).contactPartner;
  if (!contactId) return [];

  return [
    {
      role,
      contactId
    }
  ];
};

export const getCorrectAvatarInitials = ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return '';
  }

  if (firstName && !lastName) {
    return firstName[0];
  }

  if (!firstName && lastName) {
    return lastName[0];
  }

  return `${firstName[0]}${lastName[0]}`;
};
