import { SET_PARTNER } from '../types';

export function changePartner(data) {
  return {
    type: SET_PARTNER,
    data,
  };
}

export function setLimitItems(data) {
  return {
    type: 'SET_LIMIT_ITEMS',
    data,
  };
}
