import React from 'react';

const ContactsIco = () => (
  <svg
    className="mna-sidebar__ico"
    width="16"
    height="20"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.4001V3.21658C0 1.43999 1.41349 0 3.15738 0H14.6586C15.3993 0 16 0.612002 16 1.36655V14.9334C16 15.643 15.4687 16.2265 14.7893 16.2935V18.5002H15.2639C15.6704 18.5002 16 18.836 16 19.2501C16 19.6324 15.7192 19.9479 15.3563 19.9942L15.2639 20H14.0533H2.55206C1.14231 20 0 18.8363 0 17.4001ZM13.3172 18.5002V16.3H2.55206C1.95535 16.3 1.47215 16.7922 1.47215 17.4001C1.47215 17.9675 1.89308 18.4341 2.43434 18.4938L2.55206 18.5002H13.3172ZM14.5274 14.8001V1.49921L3.15738 1.49975C2.22654 1.49975 1.47215 2.26828 1.47215 3.21658V15.0439L1.52476 15.0193C1.79415 14.8985 2.08795 14.8238 2.39656 14.8049L2.55206 14.8002L14.5274 14.8001ZM8 8.14998C7.06916 8.14998 6.31477 7.38145 6.31477 6.43315C6.31477 5.48486 7.06916 4.71633 8 4.71633C8.93085 4.71633 9.68523 5.48486 9.68523 6.43315C9.68523 7.38145 8.93085 8.14998 8 8.14998ZM3.63196 12.5999C3.63196 10.9559 4.50736 9.51992 5.80913 8.74945C5.21327 8.16439 4.84262 7.34291 4.84262 6.43315C4.84262 4.65657 6.25611 3.21658 8 3.21658C9.74389 3.21658 11.1574 4.65657 11.1574 6.43315C11.1574 7.34291 10.7867 8.16439 10.1909 8.74945C11.4926 9.51992 12.368 10.9559 12.368 12.5999C12.368 13.014 12.0385 13.3498 11.632 13.3498H4.36804C3.96151 13.3498 3.63196 13.014 3.63196 12.5999ZM8 9.64973C6.67659 9.64973 5.56026 10.5545 5.21466 11.7899L5.19855 11.8496H10.8001L10.7853 11.7899C10.4541 10.606 9.4151 9.7257 8.16431 9.6544L8 9.64973Z"
      fill="#99a9bb"
    />
  </svg>
);

export default ContactsIco;
