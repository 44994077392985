const headerTitles = [
  '#',
  'vendor',
  'status',
  'sector',
  'region',
  'turnover',
  'EBITDA',
  'notes',
  'partner'
];

export const typeRadioOptions = [
  {
    key: 'distressed',
    name: 'distressed',
    text: 'Distressed',
    checked: false
  },
  {
    key: 'investment',
    name: 'investment',
    text: 'Investment',
    checked: false
  },
  {
    key: 'pitch',
    name: 'pitch',
    text: 'Pitch',
    checked: false
  }
];

export const radioOptionsBuyers = [
  {
    key: 'inExclusivity',
    name: 'inExclusivity',
    checked: false,
    text: 'Exclusivity'
  },
  {
    key: 'overseasBuyers',
    name: 'overseasBuyers',
    checked: false,
    text: 'Overseas Buyers'
  },
  {
    key: 'peBuyers',
    name: 'peBuyers',
    checked: false,
    text: 'PE Buyers'
  }
];

export const statusOption = [
  {
    key: 'forSale',
    name: 'forSale',
    value: 'forSale',
    label: 'For Sale'
  },
  {
    key: 'offMarket',
    name: 'offMarket',
    value: 'offMarket',
    label: 'Off Market'
  },
  {
    key: 'onHold',
    name: 'onHold',
    value: 'onHold',
    label: 'On Hold'
  },
  {
    key: 'cold',
    name: 'cold',
    value: 'cold',
    label: 'Cold'
  },
  {
    key: 'suspend',
    name: 'suspend',
    value: 'suspend',
    label: 'Suspend'
    
  }
];

export default headerTitles;
