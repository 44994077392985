import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import {
  getMatchesDetails,
  onRequestApproveMatches,
  setMatchesDetails,
  deleteMatches,
  deleteMatch
} from '../../redux/actions/matches/request';
import {getByMatchId} from '../../redux/actions/emailHistory/emailHistoryActions';
import { getUserNameOptions } from '../../redux/actions/options/optionsActions';
import { openModal, closeModal } from '../../redux/actions/modal/modalActions';
import MatchesEdit from '../../pages/Matches/MatchesEdit';
import PartnerOptions from '../../Data/Select/PartnerOptions';

const mapStateToProps = store => {
  return {
    data: store.matches.data,
    matchesIsLoading: store.matches.isLoading,
    options: store.options,
    modalIsOpens: store.modal.modalIsOpen,
    hasError: store.matches.hasError,
    emailHistory: store.emailHistory.data,
  };
};

const mapDispatchToProps = (dispatch, { props }) => ({
  getMatchesDetails: id => dispatch(getMatchesDetails(id)),
  deleteMatches: ids => dispatch(deleteMatches(ids)),
  deleteMatch: id => dispatch(deleteMatch([id])),
  onRequestApproveMatches: data =>
    dispatch(onRequestApproveMatches(data, props)),
  setMatchesDetails: data => dispatch(setMatchesDetails(data, props)),
  getByMatchId: id => dispatch(getByMatchId(id)),
  getUserNameOptions: params => dispatch(getUserNameOptions(params)),
  openModals: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal())
});

const getPartnerName = id => {
  const partner = PartnerOptions.find(opt => opt.value === id);
  return partner ? partner.label : '';
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const match = props.data;
      
      const clearNamesViaId = get(match, 'business.clearNamesVia', '');
      const owner =
        props.options &&
        props.options.users &&
        props.options.users.find(item => +item.value === clearNamesViaId);

      return {
        stage: get(match, 'stage'),
        notes: isNull(get(match, 'notes')) ? '' : get(match, 'notes', ''),
        acquirerScore: get(match, 'acquirerScore'),
        vendorScore: get(match, 'vendorScore'),
        description: get(match, 'business.description', ''),
        opportunityRichDescription: get(match, 'business.richDescription'),
        acquirerDescription: get(match, 'acquirer.companyDescription', ''),
        anonDescription: get(match, 'acquirer.anonDescription', ''),
        acquirerRichDescription: get(match, 'acquirer.richDescription', ''),
        terribleStuff: get(match, 'acquirer.terribleStuff', ''),
        opportunityName: get(match, 'business.businessName', ''),
        opportunityStatus: get(match, 'business.companyName', ''),
        financials: get(match, 'business.financials', []),
        region: get(match, 'business.region', ''),
        sectors: () => {
          const sector = get(match, 'business.sector');
          const subSector = get(match, 'business.subSector');
          return subSector || sector;
        },
        partner: getPartnerName(get(match, 'business.partnerId')),
        partnerAcquirer: getPartnerName(get(match, 'acquirer.partnerId')),
        businessPartnerId: get(match, 'business.partnerId'),
        acquirerPartnerId: get(match, 'acquirer.partnerId'),
        turnoverRangeLow: get(match, 'profile.turnoverRangeLow', ''),
        turnoverRangeHigh: get(match, 'profile.turnoverRangeHigh', ''),
        netProfitRangeLow: get(match, 'profile.netProfitRangeLow', ''),
        netProfitRangeHigh: get(match, 'profile.netProfitRangeHigh', ''),
        acquirerName: get(match, 'acquirer.companyName', ''),
        regionAcquirer: get(match, 'profile.region', []),
        sectorAcquirer: get(match, 'profile.sector', []),
        subSectorAcquirer: get(match, 'profile.subSector', []),
        owner,
        acquirerId: get(match, 'acquirer.id', ''),
        opportunityId: get(match, 'business.id', ''),

        approved: get(match, 'approved', false),
        codeName: get(match, 'business.codeName', ''),
        clearNames: get(match, 'business.clearNames', false),
      };
    }
  })
)(MatchesEdit);
