import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';

const Icon = ({ color, icon, size }) => {
  return (
    <ReactSVG
      src={icon}
      fallback={() => <span>Error!</span>}
      loading={() => <span />}
      renumerateIRIElements={false}
      style={{
        fill: color,
        height: size,
        width: size,
      }}
      className="icon-svg"
    />
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Icon;
