import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import {
  getMatchesData,
  onRequestApproveMatches,
  setMatchesDetails,
  setNewUpdatedAt,
} from '../../redux/actions/matches/request';
import { matchesDetails } from '../../redux/actions/matches/matchesActions';
import Matches from '../../pages/Matches/Matches';
import { openModal, closeModal } from '../../redux/actions/modal/modalActions';
import { setNumberIsValueExist } from '../../utils/utils';
import {
  getBusinessesOptions,
  getPartnersOptions,
  getAcquirersOptions,
  getAcquirerCriteriaOptions,
  getContactsOptions
} from '../../redux/actions/options/optionsActions';

const mapStateToProps = store => {
  return {
    data: store.matches.data,
    postResponse: store.matches.postResponse,
    modalIsOpen: store.modal.modalIsOpen,
    isLoading: store.matches.isLoading,
    partnerId: store.globalOptions.partnerId,
    optionsData: store.options,
    hasPartner: !!store.user.userAuth.partnerId,
    authUser: store.user.userAuth
  };
};

const mapDispatchToProps = dispatch => ({
  getMatchesData: params => dispatch(getMatchesData(params)),
  openModal: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal()),
  setDetailsMatches: item => dispatch(matchesDetails(item)),
  onRequestApproveMatches: data => dispatch(onRequestApproveMatches(data)),
  setMatchesDetails: data => dispatch(setMatchesDetails(data, null)),
  setNewUpdatedAt: data => dispatch(setNewUpdatedAt(data)),
  getPartnersOptions: () => dispatch(getPartnersOptions()),
  getBusinessesOptions: params => dispatch(getBusinessesOptions(params)),
  getAcquirerCriteriaOptions: params =>
    dispatch(getAcquirerCriteriaOptions(params)),
  getAcquirersOptions: params => dispatch(getAcquirersOptions(params)),
  getContactsOptions: params => dispatch(getContactsOptions(params))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      return {
        partnerId: props.partnerId,
        acquirerId: '',
        businessId: '',
        profileId: '',
        contactId: '',
        tableLimit: 0,
        stage: null,
        search: '',
      };
    },
    handleSubmit: async (values, { props }) => {
      props.closeModal();


      sessionStorage.setItem('preferences', JSON.stringify(values));
      const params = {
        partnerId: props.partnerId,
        acquirerId: setNumberIsValueExist(values.acquirerId),
        businessId: setNumberIsValueExist(values.businessId),
        profileId: setNumberIsValueExist(values.profileId),
        contactId: setNumberIsValueExist(values.contactId),
        search: setNumberIsValueExist(values.search),
        tableLimit: setNumberIsValueExist(values.tableLimit),

      };

      if (values.stage) {
        params.stage = values.stage;
      }

      return props.getMatchesData(params);
    }
  })
)(Matches);
