import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Open from '../../../asset/img/open.svg';
import Close from '../../../asset/img/close.svg';
import Button from '../Form/Button';

const Collapse = ({ defaultOpen, title, children }) => {
  const [collapse, setCollapse] = useState({
    isVisible: defaultOpen,
  });

  const onOpen = () =>
    setCollapse({
      isVisible: !collapse.isVisible,
    });

  const { isVisible } = collapse;

  const buttonContent = isVisible ? (
    <img src={Close} alt="" />
  ) : (
    <img src={Open} alt="" />
  );

  return (
    <div className="mna-collapse">
      <Row between="xs" className="mna-collapse__header" onClick={onOpen}>
        <Col xs={6}>
          <h5 className="title title--collapse">{title}</h5>
        </Col>
        <Col xs={6} className="text-right">
          <Button text={buttonContent} onClick={onOpen} icon />
        </Col>
      </Row>
      {collapse.isVisible && (
        <div className="mna-collapse__body">{children}</div>
      )}
    </div>
  );
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
};

export default Collapse;
