import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import AcquirersCreate from '../../pages/Acquirers/AcquirersCreate';
import {
  getAcquirersDetails,
  createAcquirers,
  deleteAcquirers,
} from '../../redux/actions/acquirers/request';
import {
  getMatchesData,
  onRequestApproveMatches,
  setMatchesDetails,
  setNewUpdatedAt,
} from '../../redux/actions/matches/request';
import { CREATE, UPDATE } from '../../constants/api';
import { getValidationSchema } from './ValidationSchema';
import { openModal } from '../../redux/actions/modal/modalActions';
import {createContact} from '../../redux/actions/contacts/contactsActions'
import {
  getPartnersOptions,
  getUserNameOptions,
  getContactsOptions,
} from '../../redux/actions/options/optionsActions';
import { getBasicFields } from './FormikHelpers';
import { additionalOptions } from '../../redux/actions/contacts/contactsActions';

const mapStateToProps = store => {
  return {
    data: store.acquirers.data,
    authId: store.user.userAuth.id,
    partnerId: store.globalOptions.partnerId,
    isLoading: store.user.isLoading,
    acquirersLoading: store.acquirers.isLoading,
    modalIsOpen: store.modal.modalIsOpen,
    postResponse: store.acquirers.data,
    hasError: store.acquirers.hasError,
    optionsData: store.options,
    contactOptions: store.contacts.additionalOptions,
    authUser: store.user.userAuth,
    hasPartner: !!store.user.userAuth.partnerId,
    matchesData: store.matches.data,
  };
};

const mapDispatchToProps = (dispatch, { props }) => ({
  getAcquirersDetails: id => dispatch(getAcquirersDetails(id)),
  getUserNameOptions: params => dispatch(getUserNameOptions(params)),
  getPartnersOptions: () => dispatch(getPartnersOptions()),
  getContactsOptions: params => dispatch(getContactsOptions(params)),
  deleteAcquirers: data => dispatch(deleteAcquirers(data, props)),
  openModal: () => dispatch(openModal()),
  createAcquirers: (action, data) =>
    dispatch(createAcquirers(action, data, props)),
  additionalOptions: data => dispatch(additionalOptions(data)),
  createContact: (data, params) => dispatch(createContact(data, params)),
  getMatchesData: params => dispatch(getMatchesData(params)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const current = props.data;

      const contactRoles = get(current, 'contacts', []);
      if (props.isCreate) {
        return {
          ...getBasicFields(),
        };
      }
      return {
        companyDescription: get(current, 'companyDescription', ''),
        richDescription: get(current, 'richDescription', ''),
        companyName: get(current, 'companyName', ''),
        companyNumber: isNull(get(current, 'companyNumber'))
          ? ''
          : get(current, 'companyNumber', ''),
        anonDescription: isNull(get(current, 'anonDescription'))
          ? ''
          : get(current, 'anonDescription', ''),
        notes: get(current, 'notes', ''),
        idVerified: get(current, 'idVerified', false),
        peBuyer: get(current, 'peBuyer', false),
        managed: get(current, 'managed', false),
        fee: get(current, 'fee', ''),
        feesEnabled: get(current, 'feesEnabled', false),
        type: get(current, 'type', ''),
        partnerId: isNull(get(current, 'partnerId')) ? null : get(current, 'partnerId', ''),
        ownerId: get(current, 'ownerId', null),
        contactRoles: contactRoles.length
          ? contactRoles.map(item => {
              return {
                role: item.role,
                contactId: item.contact.id.toString(),
              };
            })
          : [],
        deletedContactsData: [],
      };
    },
    handleSubmit: async (values, { props }) => {
      const {
        contactRoles,
        companyNumber,
        deletedContactsData,
        ownerId,
        richDescription,
        ...rest
      } = values;

      const richDescriptionParsed = JSON.stringify(richDescription)

      const validatedContactRoles = contactRoles.map(item => {
        return {
          role: item.role,
          contactId: parseInt(item.contactId, 10),
        };
      });

      const partnerId = Number(values.partnerId)

      if (props.isCreate) {
        const {
          contactRoles,
          companyNumber,
          ownerId,
          deletedContactsData,
          ...userDetails
        } = values;
        const data = {
          companyNumber: companyNumber.toString(),
          contactRoles: validatedContactRoles,
          richDescription: richDescriptionParsed,
          ...userDetails,
          partnerId,
        };

        return props.createAcquirers(CREATE, data);
      }
      // if edit screen
      const obj = {
        aсquirers: [
          {
            id: props.data.id,
            companyNumber: companyNumber.toString(),
            contactRoles: validatedContactRoles,
            richDescription : richDescriptionParsed,
            ...rest,
            partnerId,
          },
        ],
      };
      return props.createAcquirers(UPDATE, obj);
    },
    validationSchema: props => {
      return getValidationSchema(props);
    },
  })
)(AcquirersCreate);
