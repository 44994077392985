const VENDORS = '/vendors';
const ACCOUNT = '/account';
const OPPORTUNITIES = '/opportunities';
const ACQUIRER_CRITERIA = '/acquirer-criteria';
const ACQUIRERS = '/acquirers';
const MATCHES = '/matches';
const CONTACTS = '/contacts';
const RESTORE = '/restore';
const EMAILHISTORY ='/email-history'
const MATCHHISTORY = '/match-history';

const Routes = {
  VENDORS,
  ACCOUNT,
  OPPORTUNITIES,
  ACQUIRER_CRITERIA,
  ACQUIRERS,
  MATCHES,
  CONTACTS,
  RESTORE,
  EMAILHISTORY,
  MATCHHISTORY,
};

export default Routes;
