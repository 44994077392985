import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Loader } from 'semantic-ui-react';
import numeral from 'numeral';
import EditLayout from '../../components/EditLayout/EditLayout';
import ActionCard from '../../components/shared/Paper/ActionCard';
import Paper from '../../components/shared/Paper/Paper';
import Collapse from '../../components/shared/Collapse/Collapse';
import FormGroup from '../../components/shared/Form/FormGroup';
import Input from '../../components/shared/Form/Input';
import Select from '../../components/shared/Form/Select/Select';
import { isNumExist } from '../../utils/utils';
import Textarea from '../../components/shared/Form/Textarea';
import KeywordInput from '../../components/shared/Form/KeywordInput/KeywordInput';
import { useAuth0 } from '../../auth/react-auth0-spa';
import RegionsOptions from '../../Data/Select/RegionsOptions';
import ModalManager from '../../components/Modal/Modal';
import * as DeleteModalData from '../../components/Modal/DeleteModalData';
import SectorsData from '../../components/Sectors/Sectors';
import Routes from '../../constants/routes';
import partners from '../../Data/partners';
import ContactData from '../../components/ContactData/ContactData';
import Table from '../../components/shared/Table/Table';
import SectorTree from '../../components/Sectors/SectorTree';
import RichEditor from '../../components/RichText/RichEditor';
import BasicTable from '../../components/Table/BasicTable';
import { criteriaStatusOptions } from './data';
import { fa } from 'faker/lib/locales';

const AcquirerCriteriaEdit = ({
  data,
  errors,
  handleSubmit,
  handleChange,
  props,
  values,
  setFieldValue,
  deleteAcquirerCriteria,
  openModal,
  modalIsOpen,
  touched,
  setFieldTouched,
  optionsData,
  acquirerCriteriaLoading,
  getAcquirerCriteriaDetails,
  getAcquirersDetails,
  cloneAcquirerCriteria,
  isCreate,
  getUserNameOptions,
  getAcquirersOptions,
  getContactsOptions,
  getPartnersOptions,
  partnerId,
  authUser,
  createContact
}) => {
  const { loading } = useAuth0();
  const { id } = useParams();
  const { store } = useContext(ReactReduxContext);
  let a = store.getState();

  const [reloadContacts, setReloadContacts] = useState(false);

  const [contactOptions, setContactOptions] = useState([]);

  useEffect(() => {
    let contacts = getUserNameOptions({
      type: ['Acquirer', 'Opportunity', 'Partner/Internal']
    });

    setContactOptions(contacts);
  }, []);

  useEffect(() => {
    getUserNameOptions({
      type: ['Acquirer', 'Opportunity', 'Partner/Internal']
    });
  }, [reloadContacts]);

  const initialValue = [
    {
      type: 'paragraph',
      default: true,
      children: [{ text: '' }]
    }
  ];
  const [input, setInput] = useState(initialValue);

  useEffect(() => {
    if (data.richDescription) {
      setInput(data.richDescription);
    }
  }, [data.richDescription]);

  useEffect(() => {
    async function fetchOptions() {
      if (a.acquirerCriteria.data.acquirerId && a.user.userAuth.partnerId) {
        Promise.all([
          getUserNameOptions(),
          getAcquirersOptions(),
          getPartnersOptions(),
          getContactsOptions({
            // acquirerId: a.acquirerCriteria.data.acquirerId,
            type: ['Acquirer', 'Opportunities', 'Partner/Internal'],
            partnerId: a.user.userAuth.partnerId,
            includeNullPartners: false
          })
        ]);
        console.log("Acq id: ", a.acquirerCriteria.data.acquirerId);
        console.log("PartnerId: ", a.user.userAuth.partnerId);
      }
    }
    async function fetchDada() {
      await getAcquirerCriteriaDetails(id);
    }

    async function fetchAcquirers() {
      await getAcquirersDetails(values.acquirerId);
    }
    if (values.acquirerId) {
      fetchAcquirers();
    }
    if (isNumExist(id) && id) {
      fetchDada();
    }
    fetchOptions();
    // eslint-disable-next-line
  }, [a.user.userAuth.partnerId, a.acquirerCriteria.data.acquirerId]);

  const deleteSection = async () => {
    const { id } = data;
    await deleteAcquirerCriteria({
      acquirerProfiles: [id]
    });
    props.history.goBack();
  };

  const cloneSection = async () => {
    const { id } = data;
    await cloneAcquirerCriteria(id, data);
  };

  const customOptions = [
    ...(optionsData.contacts || []),
    {
      value: 'custom',
      custom: true,
      link: Routes.CONTACTS,
      title: 'Fetch all contacts'
    }
  ];

  return (
    <form className="form-wrap">
      <EditLayout
        handleChange={handleChange}
        objWithData={values.notes}
        cancelText="Cancel"
        saveText="Save"
        isSubmit={false}
        pageHeaderTitle="Acquirer Criteria"
        history={props.history}
        location={props.location}
        handleSubmit={handleSubmit}
        pathBack={Routes.ACQUIRER_CRITERIA}
      >
        <Loader active={loading || acquirerCriteriaLoading} />

        <Paper>
          <Collapse defaultOpen title="Details">
            <Row>
              <Col xs={12}>
                <FormGroup labelText="Acquirer" name="acquirerId">
                  <Select
                    isAsync
                    id="acquirerId"
                    name="acquirerId"
                    options={optionsData.acquirers}
                    placeholder=""
                    value={values.acquirerId.toString()}
                    setFieldValue={setFieldValue}
                    onChangeCustom={async acquirer =>  {
                      getAcquirersDetails(acquirer.key);
                      // if isCreate
                      if (isCreate) {
                        // fetch the contacts for the acquirer

                        await getContactsOptions({
                          acquirerId: acquirer.key,
                          type: ['Acquirer', 'Opportunities', 'Partner/Internal'],
                          partnerId: values.partnerId || partnerId,
                          includeNullPartners: true
                        });

                        await getContactsOptions({
                          type: ['Acquirer', 'Opportunities', 'Partner/Internal'],
                          partnerId: values.partnerId || partnerId,
                          includeNullPartners: true
                        });
                        
                      }
                    }}
                  />
                  <p className="form-group__error">
                    {touched.acquirerId && errors.acquirerId}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup
                  labelText="Acquisition Title"
                  name="acquisitionTitle"
                >
                  <Input
                    id="acquisitionTitle"
                    name="acquisitionTitle"
                    value={values.acquisitionTitle}
                    onChange={handleChange}
                    placeholder=""
                  />
                  <p className="form-group__error">
                    {touched.acquisitionTitle && errors.acquisitionTitle}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup
                  labelText="Acquisition Description"
                  name="description"
                >
                  <RichEditor
                    value={input}
                    setValue={setInput}
                    name="richDescription"
                    setFieldValue={setFieldValue}
                  />

                  <p className="form-group__error">
                    {touched.description && errors.description}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup labelText="keywords" name="keywords">
                  <KeywordInput
                    name="keywords"
                    setFieldValue={setFieldValue}
                    keywords={values.keywords}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup labelText="status" name="status">
                  <Select
                    id="status"
                    name="status"
                    options={criteriaStatusOptions}
                    placeholder=""
                    value={values.status}
                    setFieldValue={setFieldValue}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </Paper>
        <Paper>
          <Collapse title="Contacts" defaultOpen>
            <ContactData
              errors={errors}
              touched={touched}
              contacts={
                contactOptions.length ? contactOptions : values.contactRoles
              }
              contactsOption={customOptions}
              onChange={handleChange}
              defaultContact={false}
              setFieldValue={setFieldValue}
              isCreate={isCreate}
              deletedContactsData={values.deletedContactsData}
              isManaged={values.managed}
              authUser={authUser}
              createContact={createContact}
              getContactsOptions={getContactsOptions}
              getUserNameOptions={getUserNameOptions}
              userType={'Acquirer'}
            />
          </Collapse>
        </Paper>

        <Paper>
          <Collapse defaultOpen title="Sectors">
            <SectorTree
              sectors={values.sectors}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errors={errors}
            />
          </Collapse>
        </Paper>
        <Paper>
          <Collapse defaultOpen title="Financial Data">
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup
                  labelText="Turnover range (£)"
                  name="turnoverRangeLow"
                >
                  <div className="form-group__range">
                    <div>
                      <Input
                        placeholder="e.g 100,000"
                        type="text"
                        name="turnoverRangeLow"
                        onChange={handleChange}
                        value={
                          (values.turnoverRangeLow &&
                            numeral(values.turnoverRangeLow).format('0,0')) ||
                          ''
                        }
                      />
                    </div>
                    <div>
                      <Input
                        placeholder="e.g 100,000"
                        type="text"
                        name="turnoverRangeHigh"
                        onChange={handleChange}
                        value={
                          (values.turnoverRangeHigh &&
                            numeral(values.turnoverRangeHigh).format('0,0')) ||
                          ''
                        }
                      />
                    </div>
                  </div>

                  <p className="form-group__error">
                    {touched.turnoverRangeLow &&
                      errors.turnoverRangeLow &&
                      touched.turnoverRangeHigh &&
                      errors.turnoverRangeHigh}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup labelText="EBITDA (£)" name="netProfitRangeLow">
                  <div className="form-group__range">
                    <div>
                      <Input
                        placeholder="e.g 100,000"
                        type="text"
                        name="netProfitRangeLow"
                        onChange={handleChange}
                        value={
                          (values.netProfitRangeLow &&
                            numeral(values.netProfitRangeLow).format('0,0')) ||
                          ''
                        }
                      />
                    </div>
                    <div>
                      <Input
                        placeholder="e.g 100,000"
                        type="text"
                        name="netProfitRangeHigh"
                        onChange={handleChange}
                        value={
                          (values.netProfitRangeHigh &&
                            numeral(values.netProfitRangeHigh).format('0,0')) ||
                          ''
                        }
                      />
                    </div>
                  </div>

                  <p className="form-group__error">
                    {touched.netProfitRangeLow &&
                      errors.netProfitRangeLow &&
                      touched.netProfitRangeHigh &&
                      errors.netProfitRangeHigh}
                  </p>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </Paper>
        <Paper>
          <Collapse defaultOpen title="Regions">
            <FormGroup labelText="Regions (max 5)" name="region">
              <Select
                value={[...values.region]}
                id="region"
                options={RegionsOptions}
                name="region"
                multiple
                maximum={5}
                isSearchable
                setFieldValue={setFieldValue}
              />
              <p className="form-group__error">
                {touched.region &&
                  errors.region &&
                  touched.region &&
                  errors.region}
              </p>
            </FormGroup>
          </Collapse>
        </Paper>

        {data.track && data.track.length > 0 && (
          <Paper>
            <Collapse title="History">
              <BasicTable
                data={data.track}
                columns={[
                  { Time: 'createdAt' },
                  { Field: 'changedid' },
                  { 'New Value': 'newvalue' }
                ]}
              />
            </Collapse>
          </Paper>
        )}
      </EditLayout>

      {!isCreate && (
        <>
          <ActionCard
            successWithoutBg
            onClick={cloneSection}
            buttonText="Clone"
            title="Clone"
            text="Make a duplicate of this Acquirer Criteria with all its settings."
          />
          <ActionCard
            danger
            onClick={() => openModal()}
            buttonText="Suspend"
            title="Suspend"
            text="Once you Suspend an Acquirer Criteria, all related Matches will remain, but no future matching will be made unless restored. This can be achieved by going into the Restore area"
          />
        </>
      )}
      <ModalManager
        header="Suspend an Acquirer Criteria"
        modalIsOpen={modalIsOpen}
        content="Once you Suspend an Acquirer Criteria, all related Matches will remain, but no future matching will be made unless restored. This can be achieved by going into the Restore area"
        onAction={deleteSection}
        btnCancelText={DeleteModalData.btnCancelText}
        btnSaveText={DeleteModalData.btnSaveText}
      />
    </form>
  );
};

AcquirerCriteriaEdit.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  getUserNameOptions: PropTypes.func.isRequired,
  deleteAcquirerCriteria: PropTypes.func,
  setFieldTouched: PropTypes.func.isRequired,
  getAcquirersOptions: PropTypes.func.isRequired,
  acquirerCriteriaLoading: PropTypes.bool,
  getAcquirerCriteriaDetails: PropTypes.func.isRequired,
  cloneAcquirerCriteria: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  optionsData: PropTypes.objectOf(PropTypes.array),
  isCreate: PropTypes.bool,
  props: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.any),
  openModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  touched: PropTypes.objectOf(PropTypes.any),
  partnerId: PropTypes.string,
  getContactsOptions: PropTypes.func,
  getPartnersOptions: PropTypes.func,
  getAcquirersDetails: PropTypes.func,
  authUser: PropTypes.objectOf(PropTypes.any)
};
export default AcquirerCriteriaEdit;
