export default [
  {
    key: 'Africa',
    value: 'Africa',
    label: 'Africa',
  },
  {
    key: 'Asia',
    value: 'Asia',
    label: 'Asia',
  },
  {
    key: 'Australasia',
    value: 'Australasia',
    label: 'Australasia',
  },
  {
    key: 'Europe',
    value: 'Europe',
    label: 'Europe',
  },
  {
    key: 'Europe - UK',
    value: 'Europe - UK',
    label: 'Europe - UK',
  },
  {
    key: 'Europe - UK - England',
    value: 'Europe - UK - England',
    label: 'Europe - UK - England',
  },
  {
    key: 'Europe - UK - England - London',
    value: 'Europe - UK - England - London',
    label: 'Europe - UK - England - London',
  },
  {
    key: 'Europe - UK - England - Midlands',
    value: 'Europe - UK - England - Midlands',
    label: 'Europe - UK - England - Midlands',
  },
  {
    key: 'Europe - UK - England - N East',
    value: 'Europe - UK - England - N East',
    label: 'Europe - UK - England - N East',
  },
  {
    key: 'Europe - UK - England - N West',
    value: 'Europe - UK - England - N West',
    label: 'Europe - UK - England - N West',
  },
  {
    key: 'Europe - UK - England - South',
    value: 'Europe - UK - England - South',
    label: 'Europe - UK - England - South',
  },
  {
    key: 'Europe - UK - England - S East',
    value: 'Europe - UK - England - S East',
    label: 'Europe - UK - England - S East',
  },
  {
    key: 'Europe - UK - England - S West',
    value: 'Europe - UK - England - S West',
    label: 'Europe - UK - England - S West',
  },
  {
    key: 'Europe - UK - Ireland',
    value: 'Europe - UK - Ireland',
    label: 'Europe - UK - Ireland',
  },
  {
    key: 'Europe - UK - Northern Ireland',
    value: 'Europe - UK - Northern Ireland',
    label: 'Europe - UK - Northern Ireland',
  },
  {
    key: 'Europe - UK - Scotland',
    value: 'Europe - UK - Scotland',
    label: 'Europe - UK - Scotland',
  },
  {
    key: 'Europe - UK - Wales',
    value: 'Europe - UK - Wales',
    label: 'Europe - UK - Wales',
  },
  {
    key: 'Europe - DACH',
    value: 'Europe - DACH',
    label: 'Europe - DACH',
  },
  {
    key: 'Europe - DACH - Austria',
    value: 'Europe - DACH - Austria',
    label: 'Europe - DACH - Austria',
  },
  {
    key: 'Europe - DACH - Germany',
    value: 'Europe - DACH - Germany',
    label: 'Europe - DACH - Germany',
  },
  {
    key: 'Europe - DACH - Switzerland',
    value: 'Europe - DACH - Switzerland',
    label: 'Europe - DACH - Switzerland',
  },
  {
    key: 'Europe - Benelux',
    value: 'Europe - Benelux',
    label: 'Europe - Benelux',
  },
  {
    key: 'Europe - Benelux - Belgium',
    value: 'Europe - Benelux - Belgium',
    label: 'Europe - Benelux - Belgium',
  },
  {
    key: 'Europe - Benelux - Luxembourg',
    value: 'Europe - Benelux - Luxembourg',
    label: 'Europe - Benelux - Luxembourg',
  },
  {
    key: 'Europe - Benelux - Netherlands',
    value: 'Europe - Benelux - Netherlands',
    label: 'Europe - Benelux - Netherlands',
  },
  {
    key: 'Europe - Nordic',
    value: 'Europe - Nordic',
    label: 'Europe - Nordic',
  },
  {
    key: 'Europe - Nordic - Denmark',
    value: 'Europe - Nordic - Denmark',
    label: 'Europe - Nordic - Denmark',
  },
  {
    key: 'Europe - Nordic - Iceland',
    value: 'Europe - Nordic - Iceland',
    label: 'Europe - Nordic - Iceland',
  },
  {
    key: 'Europe - Nordic - Finland',
    value: 'Europe - Nordic - Finland',
    label: 'Europe - Nordic - Finland',
  },
  {
    key: 'Europe - Nordic - Norway',
    value: 'Europe - Nordic - Norway',
    label: 'Europe - Nordic - Norway',
  },
  {
    key: 'Europe - Nordic - Sweden',
    value: 'Europe - Nordic - Sweden',
    label: 'Europe - Nordic - Sweden',
  },
  {
    key: 'Europe - CEE',
    value: 'Europe - CEE',
    label: 'Europe - CEE',
  },
  {
    key: 'Europe - Other',
    value: 'Europe - Other',
    label: 'Europe - Other',
  },
  {
    key: 'Europe - Southern Europe',
    value: 'Europe - Southern Europe',
    label: 'Europe - Southern Europe',
  },
  {
    key: 'Europe - Southern Europe - France',
    value: 'Europe - Southern Europe - France',
    label: 'Europe - Southern Europe - France',
  },
  {
    key: 'Europe - Southern Europe - Italy',
    value: 'Europe - Southern Europe - Italy',
    label: 'Europe - Southern Europe - Italy',
  },
  {
    key: 'Europe - Southern Europe - Spain',
    value: 'Europe - Southern Europe - Spain',
    label: 'Europe - Southern Europe - Spain',
  },
  {
    key: 'Europe - Channel Islands',
    value: 'Europe - Channel Islands',
    label: 'Europe - Channel Islands',
  },
  {
    key: 'N America',
    value: 'N America',
    label: 'N America',
  },
  {
    key: 'S America',
    value: 'S America',
    label: 'S America',
  },
  {
    key: 'RoW',
    value: 'RoW',
    label: 'RoW',
  },
  {
    key: 'RoW - Middle East',
    value: 'RoW - Middle East',
    label: 'RoW - Middle East',
  },
];
