export default [
  {
    label: 'None',
    key: null,
    value: 0
  },
  {
    label: 'Langcliffe',
    key: 'Langcliffe',
    value: 1
  },
  {
    label: 'Unloq',
    key: 'Unloq',
    value: 1112
  },
  {
    label: 'TML',
    key: 'TML',
    value: 2
  },
  {
    label: 'Anderson Moore',
    key: 'Anderson Moore',
    value: 3
  }
];
