export const headerTitles = [
  'name',
  'company',
  'email',
  'type',
  'partner'
];

export const typesList = [
  {
    key: 'Opportunity',
    name: 'Opportunity',
    text: 'Opportunity',
    checked: false
  },
  {
    key: 'Acquirer',
    name: 'Acquirer',
    text: 'Acquirer',
    checked: false
  },
  {
    key: 'Partner/Internal',
    name: 'Partner/Internal',
    text: 'Partner/Internal',
    checked: false
  }
];

export const statusOptions = [
  {
    key: 'active',
    name: 'active',
    value: 'active',
    label: 'Active'
  },
  {
    key: 'suspended',
    name: 'suspended',
    value: 'suspended',
    label: 'Suspended'
  },
]