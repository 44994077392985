import { connect } from 'react-redux';
import { getRestoreData, restoreObject } from '../../redux/actions/restore/restoreActions';
import Restore from '../../pages/Restore/Restore';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => {
    var data = {"items":store.restore.data}
  return {
    data: data,
    isLoading: store.restore.isLoading,
    partnerId: store.globalOptions.partnerId,
    limit: store.globalOptions.limit
  };
};

const mapDispatchToProps = (dispatch, {props}) => ({
  getRestoreData: params => dispatch(getRestoreData(params)),
  setLimitItems: data => dispatch(setLimitItems(data)),
  restoreObject: (method, data, trackerid) => dispatch(restoreObject(method, data, trackerid, props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restore);
