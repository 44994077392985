export default [
  {
    label: 'Aerospace & Defence',
    options: [
      {
        key: 'Aerospace & Defence - Defence',
        value: 'Aerospace & Defence - Defence',
        label: 'Aerospace & Defence - Defence',
      },
      {
        key: 'Aerospace & Defence - Electronics',
        value: 'Aerospace & Defence - Electronics',
        label: 'Aerospace & Defence - Electronics',
      },
      {
        key: 'Aerospace & Defence - Mechanical Components',
        value: 'Aerospace & Defence - Mechanical Components',
        label: 'Aerospace & Defence - Mechanical Components',
      },
      {
        key: 'Aerospace & Defence - Other',
        value: 'Aerospace & Defence - Other',
        label: 'Aerospace & Defence - Other',
      },
      {
        key: 'Aerospace & Defence - Services',
        value: 'Aerospace & Defence - Services',
        label: 'Aerospace & Defence - Services',
      },
      {
        key: 'Aerospace & Defence - Systems & Sub Systems',
        value: 'Aerospace & Defence - Systems & Sub Systems',
        label: 'Aerospace & Defence - Systems & Sub Systems',
      },
    ],
  },
  {
    label: 'Agriculture',
    options: [
      {
        key: 'Agriculture - Arable',
        value: 'Agriculture - Arable',
        label: 'Agriculture - Arable',
      },
      {
        key: 'Agriculture - Dairy',
        value: 'Agriculture - Dairy',
        label: 'Agriculture - Dairy',
      },
      {
        key: 'Agriculture - Forestry',
        value: 'Agriculture - Forestry',
        label: 'Agriculture - Forestry',
      },
      {
        key: 'Agriculture - Machinery & Plant',
        value: 'Agriculture - Machinery & Plant',
        label: 'Agriculture - Machinery & Plant',
      },
    ],
  },
  {
    label: 'Automobiles & Parts',
    options: [
      {
        key: 'Automobiles & Parts',
        value: 'Automobiles & Parts',
        label: 'Automobiles & Parts',
      },
      {
        key: ' Automobiles & Parts - Automobiles',
        value: ' Automobiles & Parts - Automobiles',
        label: ' Automobiles & Parts - Automobiles',
      },
    ],
  },
  {
    label: 'Banking',
    options: [
      {
        key: 'Banking',
        value: 'Banking',
        label: 'Banking',
      },
    ],
  },
  {
    label: 'Building Products',
    options: [
      {
        key: 'Building Products  - Off Site Manufacturing',
        value: 'Building Products  - Off Site Manufacturing',
        label: 'Building Products  - Off Site Manufacturing',
      },
      {
        key: 'Building Products - Street Furniture',
        value: 'Building Products - Street Furniture',
        label: 'Building Products - Street Furniture',
      },
      {
        key: 'Building Products - Products',
        value: 'Building Products - Products',
        label: 'Building Products - Products',
      },
    ],
  },
  {
    label: 'Chemicals',
    options: [
      {
        key: 'Chemicals - Commodity Chemicals',
        value: 'Chemicals - Commodity Chemicals',
        label: 'Chemicals - Commodity Chemicals',
      },
      {
        key: 'Chemicals - Speciality Chemicals',
        value: 'Chemicals - Speciality Chemicals',
        label: 'Chemicals - Speciality Chemicals',
      },
    ],
  },
  {
    label: 'Commercial Property',
    options: [
      {
        key: 'Commercial Property ',
        value: 'Commercial Property ',
        label: 'Commercial Property ',
      },
    ],
  },
  {
    label: 'Construction',
    options: [
      {
        key: 'Construction - Civil Engineering',
        value: 'Construction - Civil Engineering',
        label: 'Construction - Civil Engineering',
      },
      {
        key: 'Construction - Fit-out Services',
        value: 'Construction - Fit-out Services',
        label: 'Construction - Fit-out Services',
      },
      {
        key: 'Construction - House Builders',
        value: 'Construction - House Builders',
        label: 'Construction - House Builders',
      },
      {
        key: 'Construction - Infrastructure',
        value: 'Construction - Infrastructure',
        label: 'Construction - Infrastructure',
      },
      {
        key: 'Construction - M&E Contractors',
        value: 'Construction - M&E Contractors',
        label: 'Construction - M&E Contractors',
      },
      {
        key: 'Construction - Main Contractor New Build / refurb',
        value: 'Construction - Main Contractor New Build / refurb',
        label: 'Construction - Main Contractor New Build / refurb',
      },
      {
        key: 'Construction - Social Housing',
        value: 'Construction - Social Housing',
        label: 'Construction - Social Housing',
      },
    ],
  },

  {
    label: 'Consumer',
    options: [
      {
        key: 'Consumer - Clothing & Accessories',
        value: 'Consumer - Clothing & Accessories',
        label: 'Consumer - Clothing & Accessories',
      },
      {
        key: 'Consumer - Consumer Electronics',
        value: 'Consumer - Consumer Electronics',
        label: 'Consumer - Consumer Electronics',
      },
      {
        key: 'Consumer - Durable Household Products',
        value: 'Consumer - Durable Household Products',
        label: 'Consumer - Durable Household Products',
      },
      {
        key: 'Consumer - Footwear',
        value: 'Consumer - Footwear',
        label: 'Consumer - Footwear',
      },
      {
        key: 'Consumer - Furnishings',
        value: 'Consumer - Furnishings',
        label: 'Consumer - Furnishings',
      },
      {
        key: 'Consumer - Non Durable Household Products',
        value: 'Consumer - Non Durable Household Products',
        label: 'Consumer - Non Durable Household Products',
      },
      {
        key: 'Consumer - Personal Products',
        value: 'Consumer - Personal Products',
        label: 'Consumer - Personal Products',
      },
      {
        key: 'Consumer - Pets & Pet Products',
        value: 'Consumer - Pets & Pet Products',
        label: 'Consumer - Pets & Pet Products',
      },
      {
        key: 'Consumer - Recreational Products',
        value: 'Consumer - Recreational Products',
        label: 'Consumer - Recreational Products',
      },
      {
        key: 'Consumer - Toys',
        value: 'Consumer - Toys',
        label: 'Consumer - Toys',
      },
    ],
  },

  {
    label: 'Distribution',
    options: [
      {
        key: 'Distribution - Auto Parts',
        value: 'Distribution - Auto Parts',
        label: 'Distribution - Auto Parts',
      },
      {
        key: 'Distribution - Building Materials',
        value: 'Distribution - Building Materials',
        label: 'Distribution - Building Materials',
      },
      {
        key: 'Distribution - Chemicals',
        value: 'Distribution - Chemicals',
        label: 'Distribution - Chemicals',
      },
      {
        key: 'Distribution - Electronics & Electrical Equipment',
        value: 'Distribution - Electronics & Electrical Equipment',
        label: 'Distribution - Electronics & Electrical Equipment',
      },
      {
        key: 'Distribution - Industrials',
        value: 'Distribution - Industrials',
        label: 'Distribution - Industrials',
      },
      {
        key: 'Distribution – Packaging',
        value: 'Distribution – Packaging',
        label: 'Distribution – Packaging',
      },
    ],
  },
  {
    label: 'Engineering',
    options: [
      {
        key: 'Electronic & Electrical Equipment',
        value: 'Electronic & Electrical Equipment',
        label: 'Electronic & Electrical Equipment',
      },
      {
        key: 'Electronic & Electrical Equipment - Electrical Components',
        value: 'Electronic & Electrical Equipment - Electrical Components',
        label: 'Electronic & Electrical Equipment - Electrical Components',
      },
      {
        key:
          'Electronic & Electrical Equipment - Electronic Security Equipment',
        value:
          'Electronic & Electrical Equipment - Electronic Security Equipment',
        label:
          'Electronic & Electrical Equipment - Electronic Security Equipment',
      },
    ],
  },
  {
    label: 'Financial',
    options: [
      {
        key: 'Financial General - Asset Managers',
        value: 'Financial General - Asset Managers',
        label: 'Financial General - Asset Managers',
      },
      {
        key: 'Financial General - Consumer Finance',
        value: 'Financial General - Consumer Finance',
        label: 'Financial General - Consumer Finance',
      },
      {
        key: 'Financial General - IFAs',
        value: 'Financial General - IFAs',
        label: 'Financial General - IFAs',
      },
      {
        key: 'Financial General - Insurance/Loss Adjusters',
        value: 'Financial General - Insurance/Loss Adjusters',
        label: 'Financial General - Insurance/Loss Adjusters',
      },
      {
        key: 'Financial General - Investment Services',
        value: 'Financial General - Investment Services',
        label: 'Financial General - Investment Services',
      },
      {
        key: 'Financial General - Mortgage Finance',
        value: 'Financial General - Mortgage Finance',
        label: 'Financial General - Mortgage Finance',
      },
      {
        key: 'Financial General - Private Equity',
        value: 'Financial General - Private Equity',
        label: 'Financial General - Private Equity',
      },
      {
        key: 'Financial General - Speciality Finance',
        value: 'Financial General - Speciality Finance',
        label: 'Financial General - Speciality Finance',
      },
    ],
  },
  {
    label: 'Food & Beverages',
    options: [
      {
        key: 'Food & Beverages - Alcoholic Drinks',
        value: 'Food & Beverages - Alcoholic Drinks',
        label: 'Food & Beverages - Alcoholic Drinks',
      },
      {
        key: 'Food & Beverages - Animal Feeds',
        value: 'Food & Beverages - Animal Feeds',
        label: 'Food & Beverages - Animal Feeds',
      },
      {
        key: 'Food & Beverages - Bakery',
        value: 'Food & Beverages - Bakery',
        label: 'Food & Beverages - Bakery',
      },
      {
        key: 'Food & Beverages - Beverages (Tea/Coffee)',
        value: 'Food & Beverages - Beverages (Tea/Coffee)',
        label: 'Food & Beverages - Beverages (Tea/Coffee)',
      },
      {
        key: 'Food & Beverages - Canned Goods',
        value: 'Food & Beverages - Canned Goods',
        label: 'Food & Beverages - Canned Goods',
      },
      {
        key: 'Food & Beverages - Cereals',
        value: 'Food & Beverages - Cereals',
        label: 'Food & Beverages - Cereals',
      },
      {
        key: 'Food & Beverages - Confectionary',
        value: 'Food & Beverages - Confectionary',
        label: 'Food & Beverages - Confectionary',
      },
      {
        key: 'Food & Beverages - Dairy',
        value: 'Food & Beverages - Dairy',
        label: 'Food & Beverages - Dairy',
      },
      {
        key: 'Food & Beverages - Desserts',
        value: 'Food & Beverages - Desserts',
        label: 'Food & Beverages - Desserts',
      },
      {
        key: 'Food & Beverages - Drinks Distribution',
        value: 'Food & Beverages - Drinks Distribution',
        label: 'Food & Beverages - Drinks Distribution',
      },
      {
        key: 'Food & Beverages - Fish',
        value: 'Food & Beverages - Fish',
        label: 'Food & Beverages - Fish',
      },
      {
        key: 'Food & Beverages - Food Distribution',
        value: 'Food & Beverages - Food Distribution',
        label: 'Food & Beverages - Food Distribution',
      },
      {
        key: 'Food & Beverages - Food Productsn',
        value: 'Food & Beverages - Food Products',
        label: 'Food & Beverages - Food Products',
      },
      {
        key: 'Food & Beverages - Food Service',
        value: 'Food & Beverages - Food Service',
        label: 'Food & Beverages - Food Service',
      },
      {
        key: 'Food & Beverages - Ingredients',
        value: 'Food & Beverages - Ingredients',
        label: 'Food & Beverages - Ingredients',
      },
      {
        key: 'Food & Beverages - Meat',
        value: 'Food & Beverages - Meat',
        label: 'Food & Beverages - Meat',
      },
      {
        key: 'Food & Beverages - Prepared Food',
        value: 'Food & Beverages - Prepared Food',
        label: 'Food & Beverages - Prepared Food',
      },
      {
        key: 'Food & Beverages - Produce',
        value: 'Food & Beverages - Produce',
        label: 'Food & Beverages - Produce',
      },
      {
        key: 'Food & Beverages - Snack Foods',
        value: 'Food & Beverages - Snack Foods',
        label: 'Food & Beverages - Snack Foods',
      },
      {
        key: 'Food & Beverages - Soft Drinks',
        value: 'Food & Beverages - Soft Drinks',
        label: 'Food & Beverages - Soft Drinks',
      },
    ],
  },
  {
    label: 'Health/Pharma',
    options: [
      {
        key: 'Health/Pharma - Biotechnology',
        value: 'Health/Pharma - Biotechnology',
        label: 'Health/Pharma - Biotechnology',
      },
      {
        key: 'Health/Pharma - Contract R&D and Services',
        value: 'Health/Pharma - Contract R&D and Services',
        label: 'Health/Pharma - Contract R&D and Services',
      },
      {
        key: 'Health/Pharma - Dental',
        value: 'Health/Pharma - Dental',
        label: 'Health/Pharma - Dental',
      },
      {
        key: 'Health/Pharma - Diagnostics',
        value: 'Health/Pharma - Diagnostics',
        label: 'Health/Pharma - Diagnostics',
      },
      {
        key: 'Health/Pharma - Healthcare IT',
        value: 'Health/Pharma - Healthcare IT',
        label: 'Health/Pharma - Healthcare IT',
      },
      {
        key: 'Health/Pharma - Healthcare Supplies',
        value: 'Health/Pharma - Healthcare Supplies',
        label: 'Health/Pharma - Healthcare Supplies',
      },
      {
        key: 'Health/Pharma - Medical Devices',
        value: 'Health/Pharma - Medical Devices',
        label: 'Health/Pharma - Medical Devices',
      },
      {
        key: 'Health/Pharma - Medical Devices',
        value: 'Health/Pharma - Medical Devices',
        label: 'Health/Pharma - Medical Devices',
      },
      {
        key: 'Health/Pharma - Pharmaceuticals',
        value: 'Health/Pharma - Pharmaceuticals',
        label: 'Health/Pharma - Pharmaceuticals',
      },
      {
        key: 'Health/Pharma - Production & Sales',
        value: 'Health/Pharma - Production & Sales',
        label: 'Health/Pharma - Production & Sales',
      },
      {
        key: 'Health/Pharma - Research and R&D',
        value: 'Health/Pharma - Research and R&D',
        label: 'Health/Pharma - Research and R&D',
      },
      {
        key: 'Health/Pharma - Telehealth',
        value: 'Health/Pharma - Telehealth',
        label: 'Health/Pharma - Telehealth',
      },
    ],
  },
  {
    label: 'Healthcare',
    options: [
      {
        key: 'Healthcare Providers - Care Homes',
        value: 'Healthcare Providers - Care Homes',
        label: 'Healthcare Providers - Care Homes',
      },
      {
        key: 'Healthcare Providers - Care Homes (Dementia)',
        value: 'Healthcare Providers - Care Homes (Dementia)',
        label: 'Healthcare Providers - Care Homes (Dementia)',
      },
      {
        key: 'Healthcare Providers - Care Homes Acute Mental Health',
        value: 'Healthcare Providers - Care Homes Acute Mental Health',
        label: 'Healthcare Providers - Care Homes Acute Mental Health',
      },
      {
        key: 'Healthcare Providers - Care Homes Learning Difficulties',
        value: 'Healthcare Providers - Care Homes Learning Difficulties',
        label: 'Healthcare Providers - Care Homes Learning Difficulties',
      },
      {
        key: 'Healthcare Providers - Care Homes Specialist',
        value: 'Healthcare Providers - Care Homes Specialist',
        label: 'Healthcare Providers - Care Homes Specialist',
      },
      {
        key: "Healthcare Providers - Children's Homes",
        value: "Healthcare Providers - Children's Homes",
        label: "Healthcare Providers - Children's Homes",
      },
      {
        key: 'Healthcare Providers - Domiciliary Care',
        value: 'Healthcare Providers - Domiciliary Care',
        label: 'Healthcare Providers - Domiciliary Care',
      },
      {
        key: 'Healthcare Providers - General Healthcare',
        value: 'Healthcare Providers - General Healthcare',
        label: 'Healthcare Providers - General Healthcare',
      },
      {
        key: 'Healthcare Providers - Land Development',
        value: 'Healthcare Providers - Land Development',
        label: 'Healthcare Providers - Land Development',
      },
      {
        key: 'Healthcare Providers - Physiotherapy',
        value: 'Healthcare Providers - Physiotherapy',
        label: 'Healthcare Providers - Physiotherapy',
      },
      {
        key: 'Healthcare Providers - Private Hospitals',
        value: 'Healthcare Providers - Private Hospitals',
        label: 'Healthcare Providers - Private Hospitals',
      },
      {
        key: 'Healthcare Providers - Specialist Healthcare',
        value: 'Healthcare Providers - Specialist Healthcare',
        label: 'Healthcare Providers - Specialist Healthcare',
      },
      {
        key: 'Healthcare Providers - Supported Living',
        value: 'Healthcare Providers - Supported Living',
        label: 'Healthcare Providers - Supported Living',
      },
    ],
  },
  {
    label: 'ICT',
    options: [
      {
        key: 'ICT - Broadband & Fixed Line',
        value: 'ICT - Broadband & Fixed Line',
        label: 'ICT - Broadband & Fixed Line',
      },
      {
        key: 'ICT - Consultancy',
        value: 'ICT - Consultancy',
        label: 'ICT - Consultancy',
      },
      {
        key: 'ICT - Data Centre',
        value: 'ICT - Data Centre',
        label: 'ICT - Data Centre',
      },
      {
        key: 'ICT - Games/Gaming',
        value: 'ICT - Games/Gaming',
        label: 'ICT - Games/Gaming',
      },
      {
        key: 'ICT - Hardware',
        value: 'ICT - Hardware',
        label: 'ICT - Hardware',
      },
      {
        key: 'ICT - Helpdesks',
        value: 'ICT - Helpdesks',
        label: 'ICT - Helpdesks',
      },
      {
        key: 'ICT - Hosting',
        value: 'ICT - Hosting',
        label: 'ICT - Hosting',
      },
      {
        key: 'ICT - Managed Services',
        value: 'ICT - Managed Services',
        label: 'ICT - Managed Services',
      },
      {
        key: 'HICT - Mobile Apps',
        value: 'ICT - Mobile Apps',
        label: 'ICT - Mobile Apps',
      },
      {
        key: 'ICT - Print & Document Management',
        value: 'ICT - Print & Document Management',
        label: 'ICT - Print & Document Management',
      },
      {
        key: 'ICT - Security / Cybersecurity',
        value: 'ICT - Security / Cybersecurity',
        label: 'ICT - Security / Cybersecurity',
      },
      {
        key: 'ICT - Software Developer',
        value: 'ICT - Software Developer',
        label: 'ICT - Software Developer',
      },
      {
        key: 'ICT - Software Reseller',
        value: 'ICT - Software Reseller',
        label: 'ICT - Software Reseller',
      },
    ],
  },
  {
    label: 'Industrial',
    options: [
      {
        key: 'Industrial General - Agro Industries',
        value: 'Industrial General - Agro Industries',
        label: 'Industrial General - Agro Industries',
      },
      {
        key: 'Industrial General - Diversified Industrials',
        value: 'Industrial General - Diversified Industrials',
        label: 'Industrial General - Diversified Industrials',
      },
      {
        key: 'Industrial General - Forestry',
        value: 'Industrial General - Forestry',
        label: 'Industrial General - Forestry',
      },
      {
        key: 'Industrial General - General Manufacturing',
        value: 'Industrial General - General Manufacturing',
        label: 'Industrial General - General Manufacturing',
      },
      {
        key: 'Industrial General - Industrial Machinery',
        value: 'Industrial General - Industrial Machinery',
        label: 'Industrial General - Industrial Machinery',
      },
      {
        key: 'Industrial General - Industrial Metals',
        value: 'Industrial General - Industrial Metals',
        label: 'Industrial General - Industrial Metals',
      },
      {
        key: 'Industrial General - Oil & Gas Equipment',
        value: 'Industrial General - Oil & Gas Equipment',
        label: 'Industrial General - Oil & Gas Equipment',
      },
    ],
  },
  {
    label: 'Legal',
    options: [
      {
        key: 'Legal - Claimant Personal Injury',
        value: 'Legal - Claimant Personal Injury',
        label: 'Legal - Claimant Personal Injury',
      },
      {
        key: 'Legal - Claims Management',
        value: 'Legal - Claims Management',
        label: 'Legal - Claims Management',
      },
      {
        key: 'Legal - Commercial Property',
        value: 'Legal - Commercial Property',
        label: 'Legal - Commercial Property',
      },
      {
        key: 'Legal - Corporate/Commercial',
        value: 'Legal - Corporate/Commercial',
        label: 'Legal - Corporate/Commercial',
      },
      {
        key: 'Legal - Crime',
        value: 'Legal - Crime',
        label: 'Legal - Crime',
      },
      {
        key: 'Legal - Debt Recovery',
        value: 'Legal - Debt Recovery',
        label: 'Legal - Debt Recovery',
      },
      {
        key: 'Legal - Defendant Personal Injury',
        value: 'Legal - Defendant Personal Injury',
        label: 'Legal - Defendant Personal Injury',
      },
      {
        key: 'Legal - Estate Administration (Wills/Probate/Trusts)',
        value: 'Legal - Estate Administration (Wills/Probate/Trusts)',
        label: 'Legal - Estate Administration (Wills/Probate/Trusts)',
      },
    ],
  },
  {
    label: 'Leisure',
    options: [
      {
        key: 'Leisure - Airlines',
        value: 'Leisure - Airlines',
        label: 'Leisure - Airlines',
      },
      {
        key: 'Leisure - Gambling',
        value: 'Leisure - Gambling',
        label: 'Leisure - Gambling',
      },
      {
        key: 'Leisure - Hotels',
        value: 'Leisure - Hotels',
        label: 'Leisure - Hotels',
      },
      {
        key: 'Leisure - Recreational Services',
        value: 'Leisure - Recreational Services',
        label: 'Leisure - Recreational Services',
      },
      {
        key: 'Leisure - Restaurants & Bars',
        value: 'Leisure - Restaurants & Bars',
        label: 'Leisure - Restaurants & Bars',
      },
    ],
  },
  {
    "label": 'Manufacturing',
    "options": [
      {
        "key": 'Manufacturing',
        "value": 'Manufacturing',
        "label": 'Manufacturing',
      },
    ],
  },
  {
    label: 'Marketing Communications',
    options: [
      {
        key: 'Marketing Communications - Advertising/Marketing',
        value: 'Marketing Communications - Advertising/Marketing',
        label: 'Marketing Communications - Advertising/Marketing',
      },
      {
        key: 'Marketing Communications - Database',
        value: 'Marketing Communications - Database',
        label: 'Marketing Communications - Database',
      },
      {
        key: 'Marketing Communications - Design',
        value: 'Marketing Communications - Design',
        label: 'Marketing Communications - Design',
      },
      {
        key: 'Marketing Communications - Digital Marketing',
        value: 'Marketing Communications - Digital Marketing',
        label: 'Marketing Communications - Digital Marketing',
      },
      {
        key: 'Marketing Communications - Events',
        value: 'Marketing Communications - Events',
        label: 'Marketing Communications - Events',
      },
      {
        key: 'Marketing Communications - Field Marketing',
        value: 'Marketing Communications - Field Marketing',
        label: 'Marketing Communications - Field Marketing',
      },
      {
        key: 'Marketing Communications - Healthcare',
        value: 'Marketing Communications - Healthcare',
        label: 'Marketing Communications - Healthcare',
      },
      {
        key: 'Marketing Communications - Marketing Consultancy',
        value: 'Marketing Communications - Marketing Consultancy',
        label: 'Marketing Communications - Marketing Consultancy',
      },
      {
        key: 'Marketing Communications - Mechandising',
        value: 'Marketing Communications - Mechandising',
        label: 'Marketing Communications - Mechandising',
      },
      {
        key: 'Marketing Communications - Media Buying',
        value: 'Marketing Communications - Media Buying',
        label: 'Marketing Communications - Media Buying',
      },
      {
        key: 'Marketing Communications - Outdoor',
        value: 'Marketing Communications - Outdoor',
        label: 'Marketing Communications - Outdoor',
      },
      {
        key: 'Marketing Communications - PR',
        value: 'Marketing Communications - PR',
        label: 'Marketing Communications - PR',
      },
      {
        key: 'Marketing Communications - Print & Production',
        value: 'Marketing Communications - Print & Production',
        label: 'Marketing Communications - Print & Production',
      },
      {
        key: 'Marketing Communications - Research',
        value: 'Marketing Communications - Research',
        label: 'Marketing Communications - Research',
      },
      {
        key: 'Marketing Communications - Sales Promotion/Experiential',
        value: 'Marketing Communications - Sales Promotion/Experiential',
        label: 'Marketing Communications - Sales Promotion/Experiential',
      },
      {
        key: 'Marketing Communications - Sports Marketing',
        value: 'Marketing Communications - Sports Marketing',
        label: 'Marketing Communications - Sports Marketing',
      },
    ],
  },
  {
    label: 'Media',
    options: [
      {
        key: 'Media - Broadcasting & Entertainment',
        value: 'Media - Broadcasting & Entertainment',
        label: 'Media - Broadcasting & Entertainment',
      },
      {
        key: 'Media - Media Agencies',
        value: 'Media - Media Agencies',
        label: 'Media - Media Agencies',
      },
      {
        key: 'Media - Printing',
        value: 'Media - Printing',
        label: 'Media - Printing',
      },
      {
        key: 'Media - Publishing',
        value: 'Media - Publishing',
        label: 'Media - Publishing',
      },
      {
        key: 'Media - Support Services',
        value: 'Media - Support Services',
        label: 'Media - Support Services',
      },
    ],
  },
  {
    label: 'Mining',
    options: [
      {
        key: 'Mining ',
        value: 'Mining ',
        label: 'Mining ',
      },
    ],
  },
  {
    label: 'Oil & Gas',
    options: [
      {
        key: 'Oil & Gas ',
        value: 'Oil & Gas ',
        label: 'Oil & Gas ',
      },
    ],
  },

  {
    label: 'Packaging',
    options: [
      {
        key: 'Packaging - Machinery',
        value: 'Packaging - Machinery',
        label: 'Packaging - Machinery',
      },
      {
        key: 'Packaging - Manufacturing',
        value: 'Packaging - Manufacturing',
        label: 'Packaging - Manufacturing',
      },
      {
        key: 'Packaging - Materials',
        value: 'Packaging - Materials',
        label: 'Packaging - Materials',
      },
      {
        key: 'Packaging - Processing',
        value: 'Packaging - Processing',
        label: 'Packaging - Processing',
      },
    ],
  },

  {
    label: 'Plastics',
    options: [
      {
        key: 'Plastics - Machinery',
        value: 'Plastics - Machinery',
        label: 'Plastics - Machinery',
      },
      {
        key: 'Plastics - Material Suppliers',
        value: 'Plastics - Material Suppliers',
        label: 'Plastics - Material Suppliers',
      },
      {
        key: 'Plastics - Processors (Construction)',
        value: 'Plastics - Processors (Construction)',
        label: 'Plastics - Processors (Construction)',
      },
      {
        key: 'Plastics - Processors (Others)',
        value: 'Plastics - Processors (Others)',
        label: 'Plastics - Processors (Others)',
      },
      {
        key: 'Plastics - Processors (Packaging)',
        value: 'Plastics - Processors (Packaging)',
        label: 'Plastics - Processors (Packaging)',
      },
    ],
  },

  {
    label: 'Print',
    options: [
      {
        key: 'Print - Banners/Posters/Signs',
        value: 'Print - Banners/Posters/Signs',
        label: 'Print - Banners/Posters/Signs',
      },
      {
        key: 'Print - Corporate Gifts',
        value: 'Print - Corporate Gifts',
        label: 'Print - Corporate Gifts',
      },
      {
        key: 'Print - Direct Mail',
        value: 'Print - Direct Mail',
        label: 'Print - Direct Mail',
      },
      {
        key: 'Print - Financial/Report & Accounts/Cheques',
        value: 'Print - Financial/Report & Accounts/Cheques',
        label: 'Print - Financial/Report & Accounts/Cheques',
      },
      {
        key: 'Print - General/Web/Litho & Digital',
        value: 'Print - General/Web/Litho & Digital',
        label: 'Print - General/Web/Litho & Digital',
      },
      {
        key: 'Print - Magazines/Brochures',
        value: 'Print - Magazines/Brochures',
        label: 'Print - Magazines/Brochures',
      },
      {
        key: 'Print - Management',
        value: 'Print - Management',
        label: 'Print - Management',
      },
      {
        key: 'Print - Packaging/Labels',
        value: 'Print - Packaging/Labels',
        label: 'Print - Packaging/Labels',
      },
      {
        key: 'Print - Promotional/Diaries/Calendars',
        value: 'Print - Promotional/Diaries/Calendars',
        label: 'Print - Promotional/Diaries/Calendars',
      },
      {
        key: 'Print - Publishing/Books',
        value: 'Print - Publishing/Books',
        label: 'Print - Publishing/Books',
      },
      {
        key: 'Print - Stationery/Greetings Cards',
        value: 'Print - Stationery/Greetings Cards',
        label: 'Print - Stationery/Greetings Cards',
      },
    ],
  },

  {
    label: 'Real Estate',
    options: [
      {
        key: 'Real Estate - Agricultural Land',
        value: 'Real Estate - Agricultural Land',
        label: 'Real Estate - Agricultural Land',
      },
      {
        key: 'Real Estate - Commercial',
        value: 'Real Estate - Commercial',
        label: 'Real Estate - Commercial',
      },
      {
        key: 'Real Estate - Estate Agents',
        value: 'Real Estate - Estate Agents',
        label: 'Real Estate - Estate Agents',
      },
      {
        key: 'Real Estate - Letting Agents',
        value: 'Real Estate - Letting Agents',
        label: 'Real Estate - Letting Agents',
      },
      {
        key: 'Real Estate - Residential',
        value: 'Real Estate - Residential',
        label: 'Real Estate - Residential',
      },
    ],
  },

  {
    label: 'Recruitment',
    options: [
      {
        key: 'Recruitment - Agriculture',
        value: 'Recruitment - Agriculture',
        label: 'Recruitment - Agriculture',
      },
      {
        key: 'Recruitment - Automotive',
        value: 'Recruitment - Automotive',
        label: 'Recruitment - Automotive',
      },
      {
        key: 'Recruitment - Aviation',
        value: 'Recruitment - Aviation',
        label: 'Recruitment - Aviation',
      },
      {
        key: 'Recruitment - Blue Collar',
        value: 'Recruitment - Blue Collar',
        label: 'Recruitment - Blue Collar',
      },
      {
        key: 'Recruitment - Charity',
        value: 'Recruitment - Charity',
        label: 'Recruitment - Charity',
      },
      {
        key: 'Recruitment - Construction',
        value: 'Recruitment - Construction',
        label: 'Recruitment - Construction',
      },
      {
        key: 'Recruitment - Education',
        value: 'Recruitment - Education',
        label: 'Recruitment - Education',
      },
      {
        key: 'Recruitment - Executive Search',
        value: 'Recruitment - Executive Search',
        label: 'Recruitment - Executive Search',
      },
      {
        key: 'Recruitment - Finance',
        value: 'Recruitment - Finance',
        label: 'Recruitment - Finance',
      },
      {
        key: 'Recruitment - Healthcare',
        value: 'Recruitment - Healthcare',
        label: 'Recruitment - Healthcare',
      },
      {
        key: 'Recruitment - Hospitality',
        value: 'Recruitment - Hospitality',
        label: 'Recruitment - Hospitality',
      },
      {
        key: 'Recruitment - Industrial/Drivers/Logistics',
        value: 'Recruitment - Industrial/Drivers/Logistics',
        label: 'Recruitment - Industrial/Drivers/Logistics',
      },
      {
        key: 'Recruitment - Interim Management',
        value: 'Recruitment - Interim Management',
        label: 'Recruitment - Interim Management',
      },
      {
        key: 'Recruitment - IT',
        value: 'Recruitment - IT',
        label: 'Recruitment - IT',
      },
      {
        key: 'Recruitment - Legal & HR',
        value: 'Recruitment - Legal & HR',
        label: 'Recruitment - Legal & HR',
      },
      {
        key: 'Recruitment - Life Sciences',
        value: 'Recruitment - Life Sciences',
        label: 'Recruitment - Life Sciences',
      },
      {
        key: 'Recruitment - Marketing/Media/Creative',
        value: 'Recruitment - Marketing/Media/Creative',
        label: 'Recruitment - Marketing/Media/Creative',
      },
      {
        key: 'Recruitment - Oil & Gas/Energy',
        value: 'Recruitment - Oil & Gas/Energy',
        label: 'Recruitment - Oil & Gas/Energy',
      },
      {
        key: 'Recruitment - Other',
        value: 'Recruitment - Other',
        label: 'Recruitment - Other',
      },
      {
        key: 'Recruitment - Public Sector',
        value: 'Recruitment - Public Sector',
        label: 'Recruitment - Public Sector',
      },
      {
        key: 'Recruitment - Rail',
        value: 'Recruitment - Rail',
        label: 'Recruitment - Rail',
      },
      {
        key: 'Recruitment - Sales & Retail',
        value: 'Recruitment - Sales & Retail',
        label: 'Recruitment - Sales & Retail',
      },
      {
        key: 'Recruitment - Sec/Admin',
        value: 'Recruitment - Sec/Admin',
        label: 'Recruitment - Sec/Admin',
      },
      {
        key: 'Recruitment - Technical/Engineering',
        value: 'Recruitment - Technical/Engineering',
        label: 'Recruitment - Technical/Engineering',
      },
      {
        key: 'Recruitment -  White Collar',
        value: 'Recruitment -  White Collar',
        label: 'Recruitment -  White Collar',
      },
    ],
  },

  {
    label: 'Retail',
    options: [
      {
        key: 'Retail - Department Stores',
        value: 'Retail - Department Stores',
        label: 'Retail - Department Stores',
      },
      {
        key: 'Retail - Drug Retailers',
        value: 'Retail - Drug Retailers',
        label: 'Retail - Drug Retailers',
      },
      {
        key: 'Retail - Electricals',
        value: 'Retail - Electricals',
        label: 'Retail - Electricals',
      },
      {
        key: 'Retail - Entertainment',
        value: 'Retail - Entertainment',
        label: 'Retail - Entertainment',
      },
      {
        key: 'Retail - Etailers/Mail Order',
        value: 'Retail - Etailers/Mail Order',
        label: 'Retail - Etailers/Mail Order',
      },
      {
        key: 'Retail - Fashion & Accessories',
        value: 'Retail - Fashion & Accessories',
        label: 'Retail - Fashion & Accessories',
      },
      {
        key: 'Retail - Food & Convenience',
        value: 'Retail - Food & Convenience',
        label: 'Retail - Food & Convenience',
      },
      {
        key: 'Retail - Health & Beauty',
        value: 'Retail - Health & Beauty',
        label: 'Retail - Health & Beauty',
      },
      {
        key: 'Retail - Home, DIY & Garden',
        value: 'Retail - Home, DIY & Garden',
        label: 'Retail - Home, DIY & Garden',
      },
      {
        key: 'Retail - Motor',
        value: 'Retail - Motor',
        label: 'Retail - Motor',
      },
      {
        key: 'Retail - Speciality Retailers',
        value: 'Retail - Speciality Retailers',
        label: 'Retail - Speciality Retailers',
      },
      {
        key: 'Retail - Sport & Leisure',
        value: 'Retail - Sport & Leisure',
        label: 'Retail - Sport & Leisure',
      },
    ],
  },

  {
    label: 'Support Services',
    options: [
      {
        key: 'Support Services - Archiving/Storage',
        value: 'Support Services - Archiving/Storage',
        label: 'Support Services - Archiving/Storage',
      },
      {
        key: 'Support Services - Business Support Services',
        value: 'Support Services - Business Support Services',
        label: 'Support Services - Business Support Services',
      },
      {
        key: 'Support Services - Business Training & Education',
        value: 'Support Services - Business Training & Education',
        label: 'Support Services - Business Training & Education',
      },
      {
        key: 'Support Services - Cleantech',
        value: 'Support Services - Cleantech',
        label: 'Support Services - Cleantech',
      },
      {
        key: 'Support Services - Engineering Services',
        value: 'Support Services - Engineering Services',
        label: 'Support Services - Engineering Services',
      },
      {
        key: 'Support Services - Environment/Energy Consultancies',
        value: 'Support Services - Environment/Energy Consultancies',
        label: 'Support Services - Environment/Energy Consultancies',
      },
      {
        key: 'Support Services - Equipment Hire',
        value: 'Support Services - Equipment Hire',
        label: 'Support Services - Equipment Hire',
      },
      {
        key: 'Support Services - Event Management',
        value: 'Support Services - Event Management',
        label: 'Support Services - Event Management',
      },
      {
        key: 'Support Services - Facilities Management',
        value: 'Support Services - Facilities Management',
        label: 'Support Services - Facilities Management',
      },
      {
        key: 'Support Services - Financial Administration',
        value: 'Support Services - Financial Administration',
        label: 'Support Services - Financial Administration',
      },
      {
        key: 'Support Services - Funeral Services',
        value: 'Support Services - Funeral Services',
        label: 'Support Services - Funeral Services',
      },
      {
        key: 'Support Services - Heating, Ventilation & Air Con',
        value: 'Support Services - Heating, Ventilation & Air Con',
        label: 'Support Services - Heating, Ventilation & Air Con',
      },
      {
        key: 'Support Services - Oil & Gas Services',
        value: 'Support Services - Oil & Gas Services',
        label: 'Support Services - Oil & Gas Services',
      },
      {
        key: 'Support Services - Other Outsourced Services',
        value: 'Support Services - Other Outsourced Services',
        label: 'Support Services - Other Outsourced Services',
      },
      {
        key: 'Support Services - Outsourced Logistics',
        value: 'Support Services - Outsourced Logistics',
        label: 'Support Services - Outsourced Logistics',
      },
      {
        key: 'Support Services - Professional Services',
        value: 'Support Services - Professional Services',
        label: 'Support Services - Professional Services',
      },
      {
        key: 'Support Services - Public Sector Contracting',
        value: 'Support Services - Public Sector Contracting',
        label: 'Support Services - Public Sector Contracting',
      },
      {
        key: 'Support Services - Renewables',
        value: 'Support Services - Renewables',
        label: 'Support Services - Renewables',
      },
      {
        key: 'Support Services - Telecommunications',
        value: 'Support Services - Telecommunications',
        label: 'Support Services - Telecommunications',
      },
      {
        key: 'Support Services - Waste & Disposal Services',
        value: 'Support Services - Waste & Disposal Services',
        label: 'Support Services - Waste & Disposal Services',
      },
      {
        key: 'Support Services - Water Treatment',
        value: 'Support Services - Water Treatment',
        label: 'Support Services - Water Treatment',
      },
    ],
  },

  {
    label: 'Soft FM',
    options: [
      {
        key: 'Soft FM - Catering',
        value: 'Soft FM - Catering',
        label: 'Soft FM - Catering',
      },
      {
        key: 'Soft FM - Cleaning',
        value: 'Soft FM - Cleaning',
        label: 'Soft FM - Cleaning',
      },
      {
        key: 'Soft FM - Fire',
        value: 'Soft FM - Fire',
        label: 'Soft FM - Fire',
      },
      {
        key: 'Soft FM - Grounds Maintenance',
        value: 'Soft FM - Grounds Maintenance',
        label: 'Soft FM - Grounds Maintenance',
      },
      {
        key: 'Soft FM - Security (Electronic)',
        value: 'Soft FM - Security (Electronic)',
        label: 'Soft FM - Security (Electronic)',
      },
      {
        key: 'Soft FM - Security (Manned)',
        value: 'Soft FM - Security (Manned)',
        label: 'Soft FM - Security (Manned)',
      },
      {
        key: 'Soft FM - Security (Specialist)',
        value: 'Soft FM - Security (Specialist)',
        label: 'Soft FM - Security (Specialist)',
      },
    ],
  },

  {
    label: 'Tobacco',
    options: [
      {
        key: 'Tobacco - Tobacco',
        value: 'Tobacco - Tobacco',
        label: 'Tobacco - Tobacco',
      },
    ],
  },

  {
    label: 'Transport',
    options: [
      {
        key: 'Transport - Ambient / Dry Freight',
        value: 'Transport - Ambient / Dry Freight',
        label: 'Transport - Ambient / Dry Freight',
      },
      {
        key: 'Transport - Bulk Tipper / Tanker',
        value: 'Transport - Bulk Tipper / Tanker',
        label: 'Transport - Bulk Tipper / Tanker',
      },
      {
        key: 'Transport - Container',
        value: 'Transport - Container',
        label: 'Transport - Container',
      },
      {
        key: 'Transport - Dormant',
        value: 'Transport - Dormant',
        label: 'Transport - Dormant',
      },
      {
        key: 'Transport - Freight Forwarding/Air Freight',
        value: 'Transport - Freight Forwarding/Air Freight',
        label: 'Transport - Freight Forwarding/Air Freight',
      },
      {
        key: 'Transport - Frozen/Chilled',
        value: 'Transport - Frozen/Chilled',
        label: 'Transport - Frozen/Chilled',
      },
      {
        key: 'Transport - Multi Modal',
        value: 'Transport - Multi Modal',
        label: 'Transport - Multi Modal',
      },
      {
        key: 'Transport - Other Specialist (Transport/Warehousing)',
        value: 'Transport - Other Specialist (Transport/Warehousing)',
        label: 'Transport - Other Specialist (Transport/Warehousing)',
      },
      {
        key: 'Transport - Palletised Freight',
        value: 'Transport - Palletised Freight',
        label: 'Transport - Palletised Freight',
      },
      {
        key: 'Transport - Parcels Distribution',
        value: 'Transport - Parcels Distribution',
        label: 'Transport - Parcels Distribution',
      },
      {
        key: 'Transport - Warehousing & Distribution (Ambient)',
        value: 'Transport - Warehousing & Distribution (Ambient)',
        label: 'Transport - Warehousing & Distribution (Ambient)',
      },
      {
        key: 'Transport - Warehousing & Distribution (Frozen/Chilled)',
        value: 'Transport - Warehousing & Distribution (Frozen/Chilled)',
        label: 'Transport - Warehousing & Distribution (Frozen/Chilled)',
      },
      {
        key: 'Transport - Warehousing (Frozen/Chilled)',
        value: 'Transport - Warehousing (Frozen/Chilled)',
        label: 'Transport - Warehousing (Frozen/Chilled)',
      },
    ],
  },

  {
    label: 'Travel',
    options: [
      {
        key: 'Travel - Activity/Sport Education',
        value: 'Travel - Activity/Sport Education',
        label: 'Travel - Activity/Sport Education',
      },
      {
        key: 'Travel - Business Travel',
        value: 'Travel - Business Travel',
        label: 'Travel - Business Travel',
      },
      {
        key: 'Travel - Coach Operators',
        value: 'Travel - Coach Operators',
        label: 'Travel - Coach Operators',
      },
      {
        key: 'Travel - Cottage/Villa Letting',
        value: 'Travel - Cottage/Villa Letting',
        label: 'Travel - Cottage/Villa Letting',
      },
      {
        key: 'Travel - Personal Travel',
        value: 'Travel - Personal Travel',
        label: 'Travel - Personal Travel',
      },
      {
        key: 'Travel - Travel & Tour Operators',
        value: 'Travel - Travel & Tour Operators',
        label: 'Travel - Travel & Tour Operators',
      },
      {
        key: 'Travel - Travel Agent',
        value: 'Travel - Travel Agent',
        label: 'Travel - Travel Agent',
      },
      {
        key: 'Travel - Travel Event Management',
        value: 'Travel - Travel Event Management',
        label: 'Travel - Travel Event Management',
      },
    ],
  },

  {
    label: 'Utilities',
    options: [
      {
        key: 'Utilities - Electricity',
        value: 'Utilities - Electricity',
        label: 'Utilities - Electricity',
      },
      {
        key: 'Utilities - Gas Distribution',
        value: 'Utilities - Gas Distribution',
        label: 'Utilities - Gas Distribution',
      },
      {
        key: 'Utilities - Multi Utilities',
        value: 'Utilities - Multi Utilities',
        label: 'Utilities - Multi Utilities',
      },
      {
        key: 'Utilities - Water',
        value: 'Utilities - Water',
        label: 'Utilities - Water',
      },
    ],
  },
];
