import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');

const Numeral = ({ value, showDashIfZero }) => {
  if (!value || value === '0') {
    return showDashIfZero ? '-' : '';
  };

  return (
    <>
      {value >= 1000000
        ? numeral(value).format('$0.[0]a')
        : numeral(value).format('$0a')}
    </>
  )
};

Numeral.propTypes = {
  value: PropTypes.node.isRequired,
  showDashIfZero: PropTypes.bool,
};

export default Numeral;
