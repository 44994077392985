import React, { cloneElement, useState } from 'react';
import { toast } from 'react-toastify';
import { settingsToast } from '../../utils/settings';

import Rodal from 'rodal';
import { formatDate } from '../../utils/utils';
import { Button } from '../shared';
import { Reminders } from '../../api/Api';
import SendIcon from '@mui/icons-material/Send';

const EmailModal = ({ data, getEmailHistoryData, minify }) => {
  const [visible, setVisible] = useState(false);

  const resendEmail = async () => {
    setVisible(false);
    new Promise((resolve, reject) => {
      Reminders.create(data);
    });

    toast.success('Email sent', settingsToast);

    // wait 2 seconds
    setTimeout(() => {

      const fetchData = async () => {
        try {
          getEmailHistoryData({});

        } catch {
          // pass
        }
      };
      fetchData();
    }, 2000);
  };

  return (
    <>
      <Button
        successWithoutBg
        basic
        text="Resend"
        onClick={() => setVisible(true)}
        medium
        className="btn-export"
        style={minify ? { padding : '5px' } : {}}
      />
      <Rodal
        height={500}
        width={500}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <SendIcon fontSize="large" />
            <h2 className="title title-main">Resend Email</h2>
            <div></div>
          </div>
          <div className="modal-body">
            <div className="modal-body-row">
              <div className="modal-body-row-label">
                <span>Email</span>
              </div>
              <div className="modal-body-row-value">
                <span>{data.recipientEmail}</span>
              </div>
            </div>
            <div className="modal-body-row">
              <div className="modal-body-row-label">
                <span>Match ID</span>
              </div>
              <div className="modal-body-row-value">
                <span>
                  <a
                    target="_blank"
                    href={`https://mna.deals/matches/${data.relatedMatchId}`}
                  >
                    {data.relatedMatchId}
                  </a>
                </span>
              </div>
            </div>
            <div className="modal-body-row">
              <div className="modal-body-row-label">
                <span>Email Type</span>
              </div>
              <div className="modal-body-row-value">
                <span>{data.emailType}</span>
              </div>
            </div>
            <div className="modal-body-row">
              <div className="modal-body-row-label">
                <span>Sent</span>
              </div>
              <div className="modal-body-row-value">
                <span>{formatDate(data.createdAt)}</span>
              </div>
            </div>
            <div className="modal-body-button">
              <Button
                successWithoutBg
                basic
                text="Resend"
                onClick={() => resendEmail()}
                medium
                className="btn-export"
              />
            </div>
          </div>
        </div>
      </Rodal>
    </>
  );
};

export default EmailModal;
