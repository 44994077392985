import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { API } from '../api/Api';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState('');
  const [id, setId] = useState('');
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const token = await auth0FromHook.getTokenSilently();
        const id = user.sub.replace('auth0|', '');
        setUser(user);
        setToken(token);
        setId(id);
        //API.defaults.headers.Authorization = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5ETkVNakJFTkRBMFFqSTJOVVE1UWpoR01qbEJNemxGTURoQ1JUVTJPVVl6TnpaRk5rSXdRZyJ9.eyJpc3MiOiJodHRwczovL3Byb2R1Y3Rpb24tOW9sbXpxOXUuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYwNWEyMzAwZTI3MGMzMDA3MDQ5MjUwYSIsImF1ZCI6WyJodHRwczovL3Byb2R1Y3Rpb24tOW9sbXpxOXUuYXV0aDAuY29tL2FwaS92Mi8iLCJodHRwczovL3Byb2R1Y3Rpb24tOW9sbXpxOXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYxOTAwMTYxNSwiZXhwIjoxNjE5MDg4MDE1LCJhenAiOiJjdzNwMVlpU3BVNjJWOG15dFFFRUVhWHpSa3R0SmJaUiIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.IbuMwGR2UfqejBlSoNGxM8_PVNLd7_hQdk8Tp05i5JZU3wm2gThrI5GC7Oey_1HvWto6-9bYj3I3MJEwZDoJm6a7ui3JHg8B9xx2PPgstSQXB0ryU8OSblrJCVw9oA5mRrYd-fReCCNn9EqBHi5S5x6HdCRZBJek4URtZDnYWEIAWL_J2ZOmgtnkdLkLuBLgzFT_JSGMq5GOwEPtFEH1c16V7r71ZmRetXhNOwyg28OfMdTY1te5DhOtNvnBzq1Ja4qI7zFlhyc5hHT9Vl6xbNRzhHy1tIuLmCa4tubkNdUMS5u7Q9y8hK0pURHkccWK5uvfWqF-uYq6XAh9XJd41A`
        API.defaults.headers.Authorization = `Bearer ${token}`;
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };
  // async function getToken() {
  //   const token = await getTokenSilently();
  //   console.log(token);
  // }

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        id,
        token,
        loading,
        popupOpen,
        loginWithPopup,
        // getToken,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
      token
    >
      {children}
    </Auth0Context.Provider>
  );
};
