import * as Yup from 'yup';

export const getValidationSchema = ({ isCreate }) => {
  const ValidationSchema = Yup.object().shape({
    sector: Yup.string().required('Required'),
    businessName: Yup.string().required('Required'),
    subSector: Yup.string(),
    // phone: Yup.string().matches(/^[0-9]{9,}$/, 'At least 9 digits').notRequired(),
    clearNames: Yup.boolean().notRequired(),
    clearNamesVia: Yup.number()
      .nullable()
      .when('clearNames', {
        is: true,
        then: Yup.number().required('Required'),
      }),
    region: Yup.string().required('Required'),
    financials: Yup.array(),
    richDescription: Yup.string(),
    feesEnabled: Yup.boolean(),
    status: Yup.string().required('Required'),
    //fee: Yup.string()
    //.required()
    //.when('feesEnabled', {
    //  is: true
    //}),
    contactRoles: Yup.array()
      .of(
        Yup.object().shape(
          {
            role: Yup.string().when('contactId', {
              is: contactId => contactId,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
            contactId: Yup.string().when('role', {
              is: role => role,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
          },
          ['contactId', 'role']
        )
      )
      .test('findRole', 'Required', function(value) {
        function checkedRole(role) {
          return value && value.length && value.some(o => o.role === role);
        }

        if (!checkedRole('Owner') && !checkedRole('Partner Contact')) {
          return false;
        }
        if (!this.parent.managed && !checkedRole('Email Recipient')) {
          return false;
        }

        return true;
      })
      .required('Required'),
  });
  return ValidationSchema;
};
