import { MODAL_OPEN, MODAL_CLOSE } from '../types';

export function openModal() {
  return {
    type: MODAL_OPEN,
  };
}

export function closeModal() {
  return {
    type: MODAL_CLOSE,
  };
}
