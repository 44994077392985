import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isValuePropsAreEqual } from '../../../utils/utils';

const Input = memo(({ hasError, value, type, onChange, disabled,  ...props }) => {
  return (
    <input
      onChange={onChange}
      type={type}
      value={value}
      disabled={disabled}
      className={`mna-input ${clsx({
        hasError: 'mna-input--error',
      })}`}
      {...props}
    />
  );
}, isValuePropsAreEqual);

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Input;
