import { combineReducers } from 'redux';
import { sidebar } from './Sidebar/SidebarReducer';
import InitialState from './initialState';
import createBaseReducer from './baseReducer';
import modalReducer from './Modal/modalReducer';
import globalOptionsReducer from './GlobalOptions/globalReduces';
import optionsReducer from './Options/optionsReduces';
import modeReducer from './Mode/modeReducer';

const rootReducer = combineReducers({
  opportunity: createBaseReducer('opportunity', InitialState.opportunity),
  user: createBaseReducer('user', { ...InitialState.user, userAuth: {} }),
  acquirerCriteria: createBaseReducer(
    'acquirer_criteria',
    InitialState.acquirerCriteria
  ),
  acquirers: createBaseReducer('acquirers', InitialState.acquirers),
  contacts: createBaseReducer('contacts', {
    ...InitialState.contacts,
    currentContact: {},
    tableView: {},
    additionalOptions: {
      id: '1112',
      managed: false,
    },
  }),
  matches: createBaseReducer('matches', InitialState.matches),
  restore: createBaseReducer('restore', InitialState.restore),
  emailHistory: createBaseReducer('emailHistory', InitialState.emailHistory),
  sidebar,
  modal: modalReducer,
  globalOptions: globalOptionsReducer,
  options: optionsReducer,
  editMode: modeReducer,
});

export default rootReducer;
