import React from 'react';
import PropTypes from 'prop-types';
import Textarea from '../shared/Form/Textarea';
import note from '../../asset/img/edit-ico.svg';

const NotesBox = ({ value, onChange, name }) => {
  return (
    <>
      <h5 className="title title--collapse">Notes</h5>
      <Textarea
        placeholder="Comment"
        onChange={onChange}
        name={name}
        value={value}
        icon={note}
        basic
        large
      />
    </>
  );
};

NotesBox.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default NotesBox;
