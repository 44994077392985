import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import Account from '../../pages/Account/Account';
import { updateUserDetailsData, rerunMatches } from '../../redux/actions/user/userActions';

const mapStateToProps = store => ({
  data: store.user.userAuth,
  isLoading: store.acquirers.isLoading,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (id, data) => dispatch(updateUserDetailsData(id, data)),
  rerunMatches: () => dispatch(rerunMatches()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { firstName, lastName, email } = props.data;
      return {
        firstName,
        lastName,
        email,
      };
    },
    handleSubmit: async (values, { props }) => {
      const data = {
        ...values,
      };
      props.updateUser(props.data.id, data);
    },
    validationSchema: () => {
      const ValidationSchema = Yup.object().shape({
        email: Yup.string()
          .email('Email must be an email')
          .required('Email should not be empty'),
        firstName: Yup.string().required('First Name should not be empty'),
        lastName: Yup.string().required('Last Name should not be empty'),
      });
      return ValidationSchema;
    },
  })
)(Account);
