export default [
  {
    label: 'Live',
    value: 'Live',
    options: [
      {
        label: 'Matched',
        key: 'Matched',
        value: 'Matched'
      },
      {
        label: 'Clearing Acquirer',
        key: 'Clearing Acquirer',
        value: 'Clearing Acquirer'
      },
      {
        label: 'Clearing Opportunity',
        key: 'Clearing Opportunity',
        value: 'Clearing Opportunity'
      },
      {
        label: 'Introduced',
        key: 'Introduced',
        value: 'Introduced'
      },
      {
        label: 'Meeting',
        key: 'Meeting',
        value: 'Meeting'
      },
      {
        label: 'Offer',
        key: 'Offer',
        value: 'Offer'
      },
      {
        label: 'Legals',
        key: 'Legals',
        value: 'Legals'
      }
    ]
  },
  {
    label: 'On Hold',
    value: 'On Hold',
    options: [
      {
        label: 'Opportunity Future',
        key: 'Opportunity Future',
        value: 'Opportunity Future'
      },
      {
        label: 'No Response',
        key: 'No Response',
        value: 'No Response'
      },
      {
        label: 'Acquirer Unwilling to Engage',
        key: 'Acquirer Unwilling to Engage',
        value: 'Acquirer Unwilling to Engage'
      },
      {
        label: 'On Hold Vendor',
        key: 'On Hold Vendor',
        value: 'On Hold Vendor'
      },
      {
        label: 'On Hold Acquirer',
        key: 'On Hold Acquirer',
        value: 'On Hold Acquirer'
      },
      {
        label: 'Long Term Potential',
        key: 'Long Term Potential',
        value: 'Long Term Potential'
      }
    ]
  },
  {
    label: 'Cold',
    value: 'Cold',
    options: [
      {
        label: 'Match Unsuitable',
        key: 'Match Unsuitable',
        value: 'Match Unsuitable'
      },
      {
        label: 'Acquirer Unapproved',
        key: 'Acquirer Unapproved',
        value: 'Acquirer Unapproved'
      },
      {
        label: 'Interest Declined',
        key: 'Interest Declined',
        value: 'Interest Declined'
      },
      {
        label: 'Cold',
        key: 'Cold',
        value: 'Cold'
      },
      {
        label: 'Business Sold',
        key: 'Business Sold',
        value: 'Business Sold'
      },
      {
        label: 'Match Denied',
        key: 'Match Denied',
        value: 'Match Denied'
      }
    ]
  },
  {
    label: 'Deal Complete',
    value: 'Deal Complete',
    options: [
      {
        label: 'Deal Complete',
        key: 'Deal Complete',
        value: 'Deal Complete'
      }
    ]
  }
];

/*
  export default [
    {
        label: 'Live',
        options: [
            {
                label: 'Opportdunity Identified', -> Matched

            },
            {
                label: 'Checdking Status',

            },
            {
                label: 'Clearidng Acquirer',

            },
            {
                label: 'Acqduirer Cleared',

            },
            {
                label: 'Opportudnity Sent',

            },
            {
                label: 'Intedrest Expressed',

            },
            {
                label: 'NDA Redquested',

            },
            {
                label: 'NDdA Sent',

            },
            {
                label: 'NDA Sidgned',

            },
            {
                label: 'IM Requdested',

            },
            {
                label: 'IM Sdent',

            },
            {
                label: 'Meetding',

            },
            {
                label: 'Offder',

            },
            {
                label: 'Legadls',

  */
