import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Button from '../Form/Button';

const ActionCard = ({
  title,
  text,
  buttonText,
  onClick,
  danger,
  successWithoutBg,
}) => (
  <div className="paper paper--action">
    <Row middle="xs">
      <Col lg={10} xs={12} md={8}>
        <h5 className="title title--collapse">{title}</h5>
        <p>{text}</p>
      </Col>
      <Col lg={2} xs={12} md={4} className="text-right">
        <Button
          basic
          text={buttonText}
          onClick={onClick}
          type="button"
          danger={danger}
          successWithoutBg={successWithoutBg}
        />
      </Col>
    </Row>
  </div>
);

ActionCard.propTypes = {
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  danger: PropTypes.bool,
  successWithoutBg: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ActionCard;
