import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup, Select } from '../shared';
import Button from '../shared/Form/Button';
import KeywordInput from '../../components/shared/Form/KeywordInput/KeywordInput';
import Label from '../../components/shared/Label/Label';

const OpportunityFilterModal = ({
  header,
  modalIsOpen,
  btnCancelText,
  btnSaveText,
  closeModal,
  onFilterData,
  setFieldValue,
  values,
  statuses,
  setStatuses,
  options
}) => {
  const [selectedStatus, setSelectedStatus] = React.useState(statuses);
  return (
    <Modal open={modalIsOpen} onClose={closeModal} className="mna-modal">
      <header className="mna-modal__header text-center">
        <h2 className="title title-main">{header}</h2>
      </header>
      <div className="mna-modal__content">
        <Row className="flex-content-center">
          <Col xs={6}>
            <FormGroup labelText="Status" name="status">
              <Select
                name="status"
                customClassName={'smaller-font'}
                placeholder="Status"
                options={options}
                value={selectedStatus}
                onChangeCustom={e => {
                  setSelectedStatus(e.value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="mna-modal__actions">
        <Button
          basic
          successWithoutBg
          text={btnCancelText}
          onClick={() => {
            console.log('clicking!');
            closeModal();
          }}
        />
        <Button
          basic
          success
          text={btnSaveText}
          onClick={() => {
            onFilterData();
            setStatuses(selectedStatus);
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
};

OpportunityFilterModal.propTypes = {
  header: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  btnCancelText: PropTypes.string.isRequired,
  btnSaveText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onFilterData: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.object)
};

export default OpportunityFilterModal;
