export const getBasicFields = () => {
  return {
    ownerId: null,
    partnerId: '1112',
    idVerified: false,
    managed: false,
    peBuyer: false,
    sourceId: null,
    companyNumber: '',
    companyName: '',
    companyDescription: '',
    richDescription:'',
    anonDescription: '',
    areaCode: null,
    type: '',
    notes: '',
    contactRoles: [],
  };
};