import React, {useRef} from 'react';
import ReactDOM from "react-dom";
import Rodal from 'rodal';
import Button from '../shared/Form/Button';
import { Acquirers, Opportunity, Matches, AcquirerCriteria } from '../../api/Api';
import {renderSectors} from '../../utils/utils'
import { Col, Row } from 'react-flexbox-grid';
import PatternIcon from '@mui/icons-material/Pattern';
import {
    Paper,
  } from '../../components/shared';

import _ from "lodash";
import AsyncSelect from 'react-select/async';
import {kFormatter} from '../../utils/utils'

import { Icon } from '../shared';
import Filters from '../../asset/img/filters.svg';


// include styles
import 'rodal/lib/rodal.css';


var lookup = {
  "int": [
    { value: '1', label: 'Choose an acquirer to pick the matching criteria' }
  ],
  "comp": [
    { value: 'a', label: 'Choose an acquirer to pick the matching criteria' },
  ]
}


class RodalModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataValue: 'int',
            height:626
        };


        this.profileDropdown = React.createRef()
        this.profileData = []
    }

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    
    loadOpportunity(inputValue) {
        return new Promise((resolve, reject) => {
            Opportunity.fetch({
                'businessName': inputValue
            }).then(function (response) {
                var output = []
                let items = response.data.items
                items.forEach(function (item) {
                    let id = item.id
                    let companyName = item.businessName
                    output.push({
                        'value': id,
                        'label': companyName
                    })
                })
                resolve(output)
            })
        });
    }

    loadAcquirers(inputValue) {
        return new Promise((resolve, reject) => {
            Acquirers.fetch({
                'companyName': inputValue
            }).then(function (response) {
                var output = []
                let items = response.data.items
                items.forEach(function (item) {
                    let id = item.id
                    let companyName = item.companyName
                    output.push({
                        'value': id,
                        'label': companyName
                    })
                })
                resolve(output)
            })
        });
    }

    loadAcquirerProfiles(inputValue) {
        var acquirer_id = inputValue.value
        var self = this
        return new Promise((resolve, reject) => {
            AcquirerCriteria.fetch({
                'acquirerId': acquirer_id
            }).then(function (response) {
                var output = []
                let items = response.data.items
                items.forEach(function (item) {
                    let description = self.createCriteriaDescription(item)
                    let id = item.id
                    let companyName = item.acquirer.companyName
                    output.push({
                        'value': id,
                        'label': description
                    })
                })
                lookup.comp = output
                resolve(output)
            })
        });
    }


    createCriteriaDescription(item) {

        // Turnover: ## - ## | Net Profit: ## - ## | Region: ## | Sector: ##

        let sector = renderSectors(item.sector, 2)
        
        let turnover_low = kFormatter(item.turnoverRangeLow)
        let turnover_high = kFormatter(item.turnoverRangeHigh)

        let net_low = kFormatter(item.netProfitRangeLow)
        let net_high = kFormatter(item.netProfitRangeHigh)

        const output = sector + ' | ' + 'Turnover: ' + turnover_low + '-' + turnover_high + ' | ' + 'EBITDA: ' + net_low + '-' + net_high

        return output

        

    }

    returnAcquirerProfiles(inp) {
        return new Promise((resolve, reject) => {
            resolve(this.profileData)
        })
    }


    onChange(inputValue) {
        let profiles = this.loadAcquirerProfiles(inputValue)
        Promise.all([profiles]).then(result => {
            this.setState({
                dataValue: 'comp'
            });
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        let opportunity = data.get('Opportunity');
        let acquirer = data.get('Acquirer');
        let acquirerCriteria = data.get('acquirerCriteria')

        return new Promise((resolve, reject) => {
            let payload = {
                "acquirerId": parseInt(acquirer),
                "profileId": parseInt(acquirerCriteria),
                "businessId": parseInt(opportunity),
                "stage": "Matched",
                "vendorScore": "Created",
                "acquirerScore": "Created",
                "approved": null,
                "score": 0,
                "matchtype":"Manual"
            }
            Matches.create(payload)

            setTimeout(()=>{
                window.location.reload()
            }, 700)
        })

    }

  render() {
    const { dataValue } = this.state;
    const options = lookup[dataValue];
    return (
      <div>
        <Button
            successWithoutBg
            basic
            text="Manual Match"
            onClick={this.show.bind(this)}
            medium
            className="btn-export"
        />
        <Rodal height={500} visible={this.state.visible} onClose={this.hide.bind(this)}>
            <header className="mna-modal__header text-center">
              <PatternIcon fontSize="large" />
              <h2 className="title title-main">Manual Matching</h2>
              <div></div>
            </header>

            <form onSubmit={this.handleSubmit}>
                <Paper elavation={3}>
                    <Row center="xs">
                        <Col xs={12}>
                        Opportunity
                        <AsyncSelect
                            cacheOptions
                            loadOptions={inputValue => this.loadOpportunity(inputValue)}
                            value={this.state.opportunity}
                            name="Opportunity"
                            placeholder="Search here to find opportunity..."
                            />
                        </Col>
                    </Row>
                </Paper>

                <Paper elavation={3}>
                    <Row center="xs">
                        <Col xs={12}>
                            Acquirer
                            <AsyncSelect
                                cacheOptions
                                loadOptions={inputValue => this.loadAcquirers(inputValue)}
                                value={this.state.acquirers}
                                onChange={this.onChange.bind(this)}
                                name="Acquirer"
                                placeholder="Search here to find acquirer..."
                                />
                        </Col>
                    </Row>
                </Paper>

                <Paper elavation={3}>
                    <Row center="xs">
                        <Col xs={12}>
                            <label>Acquirer Criteria</label><br/>
                            <select name="acquirerCriteria" className="criteriaSelect">
                            {options.map(o => <option key={o.label} value={o.value}>{o.label}</option>)}
                            </select>
                        </Col>
                    </Row>
                </Paper>

                <Row center="xs">
                    <Col xs={4}>
                        <Button
                            successWithoutBg
                            basic
                            text="Match"
                            type="submit"
                            medium
                            className="btn-export"
                        />
                    </Col>
                </Row>
            </form>
            
        </Rodal>
      </div>
    );
  }
}

export default RodalModal