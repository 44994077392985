import React from 'react';

const OpportunitesIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="mna-sidebar__ico"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="y5oua">
        <path d="M12.064 18.445a.75.75 0 0 1 .75-.75h3.442a.75.75 0 1 1 0 1.5h-3.442a.75.75 0 0 1-.75-.75zm-5.363-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h6.291a.75.75 0 0 1 0 1.5h-6.29a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm9.09-.186h2.328l-2.327-2.348zM5.8 2h9.46c.48 0 .94.192 1.278.533l2.94 2.967A1.8 1.8 0 0 1 20 6.767V20.2a1.8 1.8 0 0 1-1.8 1.8H5.8A1.8 1.8 0 0 1 4 20.2V3.8A1.8 1.8 0 0 1 5.8 2zm0 1.5a.3.3 0 0 0-.3.3v16.4a.3.3 0 0 0 .3.3h12.4a.3.3 0 0 0 .3-.3V7.76h-2.858a1.35 1.35 0 0 1-1.35-1.35V3.5z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g />
        <g>
          <path
            fill="#979797"
            d="M12.064 18.445a.75.75 0 0 1 .75-.75h3.442a.75.75 0 1 1 0 1.5h-3.442a.75.75 0 0 1-.75-.75zm-5.363-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h6.291a.75.75 0 0 1 0 1.5h-6.29a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm0-3a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm9.09-.186h2.328l-2.327-2.348zM5.8 2h9.46c.48 0 .94.192 1.278.533l2.94 2.967A1.8 1.8 0 0 1 20 6.767V20.2a1.8 1.8 0 0 1-1.8 1.8H5.8A1.8 1.8 0 0 1 4 20.2V3.8A1.8 1.8 0 0 1 5.8 2zm0 1.5a.3.3 0 0 0-.3.3v16.4a.3.3 0 0 0 .3.3h12.4a.3.3 0 0 0 .3-.3V7.76h-2.858a1.35 1.35 0 0 1-1.35-1.35V3.5z"
          />
        </g>
        <g clipPath="url(#y5oua)">
          <g>
            <path fill="#99a9bb" d="M2 2h20v20H2z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OpportunitesIco;
