import React from 'react';

const AcquirersCriteriaIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="mna-sidebar__ico"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="39ksa">
        <path d="M1 13a5.5 5.5 0 1 1 9.607 3.658l2.197 2.197a.67.67 0 0 1-.95.949l-2.257-2.258A5.5 5.5 0 0 1 1 13zm9.429 0a3.929 3.929 0 1 0-7.858 0 3.929 3.929 0 0 0 7.858 0zm6.088 2.75a.75.75 0 0 1-.75.75H13.75a.75.75 0 0 1 0-1.5h2.017a.75.75 0 0 1 .75.75zm1.128-3a.75.75 0 0 1-.75.75H13.75a.75.75 0 0 1 0-1.5h3.145a.75.75 0 0 1 .75.75zm.355-3c0 .414-.232.75-.518.75h-3.964c-.286 0-.518-.336-.518-.75s.232-.75.518-.75h3.964c.286 0 .518.336.518.75zM6.701 6.017a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm9.09.242h2.328l-2.327-2.348zM5.8 3.5a.3.3 0 0 0-.3.3V7H4V3.8A1.8 1.8 0 0 1 5.8 2h9.46c.48 0 .94.192 1.278.533l2.94 2.967A1.8 1.8 0 0 1 20 6.767V20.2a1.8 1.8 0 0 1-1.8 1.8H5.8A1.8 1.8 0 0 1 4 20.2V19h1.5v1.2a.3.3 0 0 0 .3.3h12.4a.3.3 0 0 0 .3-.3V7.76h-2.858a1.35 1.35 0 0 1-1.35-1.35V3.5z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g />
        <g>
          <path
            fill="#99a9bb"
            d="M1 13a5.5 5.5 0 1 1 9.607 3.658l2.197 2.197a.67.67 0 0 1-.95.949l-2.257-2.258A5.5 5.5 0 0 1 1 13zm9.429 0a3.929 3.929 0 1 0-7.858 0 3.929 3.929 0 0 0 7.858 0zm6.088 2.75a.75.75 0 0 1-.75.75H13.75a.75.75 0 0 1 0-1.5h2.017a.75.75 0 0 1 .75.75zm1.128-3a.75.75 0 0 1-.75.75H13.75a.75.75 0 0 1 0-1.5h3.145a.75.75 0 0 1 .75.75zm.355-3c0 .414-.232.75-.518.75h-3.964c-.286 0-.518-.336-.518-.75s.232-.75.518-.75h3.964c.286 0 .518.336.518.75zM6.701 6.017a.75.75 0 0 1 .75-.75h4.805a.75.75 0 0 1 0 1.5H7.45a.75.75 0 0 1-.75-.75zm9.09.242h2.328l-2.327-2.348zM5.8 3.5a.3.3 0 0 0-.3.3V7H4V3.8A1.8 1.8 0 0 1 5.8 2h9.46c.48 0 .94.192 1.278.533l2.94 2.967A1.8 1.8 0 0 1 20 6.767V20.2a1.8 1.8 0 0 1-1.8 1.8H5.8A1.8 1.8 0 0 1 4 20.2V19h1.5v1.2a.3.3 0 0 0 .3.3h12.4a.3.3 0 0 0 .3-.3V7.76h-2.858a1.35 1.35 0 0 1-1.35-1.35V3.5z"
          />
        </g>
        <g clipPath="url(#39ksa)">
          <g>
            <path fill="#99a9bb" d="M0 1h24v24H0z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AcquirersCriteriaIco;
