import {
  MATCHES_IS_LOADING,
  MATCHES_SUCCESS,
  MATCHES_ERROR,
  MATCHES_REMOVE,
  MATCHES_POST_DATA_SUCCESS,
  MATCHES_DETAILS,
} from '../types';

export const matchesIsLoading = () => ({
  type: MATCHES_IS_LOADING,
});

export const matchesSuccess = payload => ({
  type: MATCHES_SUCCESS,
  data: payload.data,
});

export const matchesApprove = payload => ({
  type: MATCHES_SUCCESS,
  data: payload,
});

export const matchesHasError = payload => ({
  type: MATCHES_ERROR,
  hasError: payload,
});

// CREATE / UPDATE data
export const matchesRequestDataSuccess = payload => ({
  type: MATCHES_POST_DATA_SUCCESS,
  postResponse: payload.data,
});

export const matchesDelete = payload => ({
  type: MATCHES_REMOVE,
  deletedResponse: payload,
});

export const matchesDetails = payload => ({
  type: MATCHES_DETAILS,
  data: payload,
});
