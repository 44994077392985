export default [
    {
      label: 'Approved',
      key: 'true',
      value: true,
    },
    {
      label: 'Denied',
      key: 'false',
      value: false,
    }
];
