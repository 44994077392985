import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Label = ({ basic, text, success, complete, warning, danger, primary, matchHeader, ...rest }) => {
  return (
    <span
      className={clsx({
        'mna-label': basic,
        'mna-label--success': success,
        'mna-label--warning': warning,
        'mna-label--danger': danger,
        'mna-label--primary': primary,
        'mna-label--complete':complete,
        'mna-label--matchHeader': matchHeader,
      })}
      {...rest}
    >
      {text}
    </span>
  );
};

Label.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  basic: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  matchHeader: PropTypes.bool,
};

export default Label;
