const defaultState = {
  modalIsOpen: false,
};

export default function modalReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case 'MODAL_OPEN': {
      return { ...state, modalIsOpen: true };
    }

    case 'MODAL_CLOSE': {
      return { ...state, modalIsOpen: false };
    }

    default:
      return state;
  }
};
