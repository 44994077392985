import React from 'react';
import Routes from '../../constants/routes';
import OpportunitesIco from '../../asset/img/OpportunitesIco';
import MatchesIco from '../../asset/img/matches-ico';
import AcquirersCriteriaIco from '../../asset/img/acquirersCriteriaIco';
import AcIco from '../../asset/img/ac-ico';
import ContactsIco from '../../asset/img/contactsIco';

const sidebardData = [
  {
    title: 'Matches',
    path: Routes.MATCHES,
    icon: <MatchesIco width={20} height={20} />,
  },
  {
    title: 'Opportunities',
    path: Routes.OPPORTUNITIES,
    icon: <OpportunitesIco width={20} height={20} />,
  },
  {
    title: 'Acquirers',
    path: Routes.ACQUIRERS,
    icon: <AcIco width={20} height={20} />,
  },
  {
    title: 'Acquirer Criteria',
    path: Routes.ACQUIRER_CRITERIA,
    icon: <AcquirersCriteriaIco width={20} height={20} />,
  },
  {
    title: 'Contacts',
    path: Routes.CONTACTS,
    icon: <ContactsIco width={20} height={20} />,
  },
];

export default sidebardData;
