import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Loader } from 'semantic-ui-react';
import compact from 'lodash/compact';
import { useAuth0 } from '../../auth/react-auth0-spa';
import Loading from '../../components/Loading';
import PageHeader from '../../components/PageHeader/PageHeader';
import Routes from '../../constants/routes';
import TableContainer from '../../components/TableWrap/TableWrap';
import {criteriaStatusOptions, headerTitles} from './data';
import TableBody from './TableBody';
import BodyMatchesModal, {
  btnCancelText,
  btnSaveText,
  title,
} from '../../components/Modal/MatchesModalData';
import ModalManager from '../../components/Modal/Modal';

const AcquirerCriteria = ({
  data,
  getAcquirerCriteriaData,
  isLoading,
  location,
  props,
  partnerId,
  optionsData,
  openModal,
  modalIsOpen,
  handleSubmit,
  handleChange,
  setFieldValue,
  getBusinessesOptions,
  getPartnersOptions,
  getAcquirersOptions,
  getAcquirerCriteriaOptions,
  values,
  hasPartner,
  limit,
  setLimitItems,
}) => {
  const { loading, user } = useAuth0();
  const [table, setTableData] = useState({
    searchQuery: '',
    activePage: 1,
  });

  const [filters, setFilters] = useState({
    count: 0,
  });

  const [statuses, setStatuses] = useState('active');
  const [selectableStatuses, setSelectableStatuses] = useState([]);

  useEffect(() => {
    // sort statusOptions by removing where key is forSale and offMarket and replace with active
    let temp = criteriaStatusOptions.filter(status => {
      if (status.key === 'forSale' || status.key === 'offMarket' || status.key === 'suspend') {
        return false;
      }
      return true;
    });


    setSelectableStatuses(temp);
  }, []);

  useEffect(() => {
    const countFilterOptions = val => {
      const defaultOption = hasPartner ? 1 : 0;
      const count = compact(Object.values(val)).length - defaultOption;
      return setFilters({ count });
    };

    countFilterOptions(values);
  }, [values, hasPartner]);

  useEffect(() => {
    function fetchData() {
      Promise.all([
        getAcquirerCriteriaData({
          limit,
          partnerId,
          status: statuses,
          
        }),
        getAcquirerCriteriaOptions(),
        getAcquirersOptions(),
      ]);
    }
    fetchData();
  }, [
    getAcquirerCriteriaData,
    getAcquirerCriteriaOptions,
    getAcquirersOptions,
    limit,
    partnerId,
  ]);

  if (loading || !user) {
    return <Loading />;
  }

  const changeLimitOnPage = async option => {
    await setLimitItems(parseInt(option.value, 10));
    await getAcquirerCriteriaData({
      limit: parseInt(option.value, 10),
      partnerId,
      status: statuses,
    });
  };

  const setSearchTerm = async e => {
    const { value } = e.target;

    setTableData({
      ...table,
      searchQuery: value,
    });

    return debounce(async () => {
      await getAcquirerCriteriaData({
        search: value,
        limit,
        partnerId,
        status: statuses,
      });
    }, 500)();
  };

  const redirectToDetailsPage = id =>
    props.history.push(`acquirer-criteria/${id}`);

  const onChangePage = (e, data) => {
    const { activePage } = data;
    const offset = (activePage - 1) * parseInt(limit, 10);

    setTableData({
      ...table,
      activePage,
    });

    return debounce(async () => {
      await getAcquirerCriteriaData({
        search: table.searchQuery,
        limit,
        partnerId,
        acquirerId: values.acquirerId || null,
        offset,
        status: statuses,
      });
    }, 500)();
  };

  const onChangeActivePage = (e, data) => {
    const { value } = data;
    setTableData({
      ...table,
      activePage: parseInt(value, 10),
    });
    const offset = (value - 1) * limit;
    return debounce(async () => {
      await getAcquirerCriteriaData({
        search: table.searchQuery,
        limit,
        partnerId,
        acquirerId: values.acquirerId || null,
        offset,
        status: statuses,
      });
    }, 500)();
  };

  return (
    <>
      <PageHeader
        title="Acquirer Criteria"
        linkText="Add Acquirer Criteria"
        stateText="Create"
        location={location}
        path={`${Routes.ACQUIRER_CRITERIA}/create`}
      />
      <Loader active={isLoading} />
      {(data && data.items && (
        <>
          <TableContainer
            countFilterOptions={filters.count}
            isFilters
            setFieldValue={setFieldValue}
            data={data}
            headerTitles={headerTitles}
            setSearchTerm={setSearchTerm}
            currentPage={table.activePage}
            searchQuery={table.searchQuery}
            changeItemsOnPage={changeLimitOnPage}
            onPageChange={onChangePage}
            openModal={() => openModal()}
            onChangeActivePage={onChangeActivePage}
            tableBody={
              <TableBody
                data={data}
                redirectToDetailsPage={redirectToDetailsPage}
              />
            }
            limit={limit.toString()}
          />
        </>
      )) ||
        'no data yet'}
      <ModalManager
        filters
        header={title}
        modalIsOpen={modalIsOpen}
        onFilterData={handleSubmit}
        btnCancelText={btnCancelText}
        btnSaveText={btnSaveText}
        content={
          <BodyMatchesModal
            isAcquirerCriteria
            handleChange={handleChange}
            acquirer={optionsData.acquirers}
            partnerId={partnerId}
            profiles={optionsData.profiles}
            opportunities={optionsData.businesses}
            setFieldValue={setFieldValue}
            getBusinessesOptions={getBusinessesOptions}
            getPartnersOptions={getPartnersOptions}
            getAcquirersOptions={getAcquirersOptions}
            getAcquirerCriteriaOptions={getAcquirerCriteriaOptions}
            values={values}
            selectableStatuses={selectableStatuses}
            setStatuses={setStatuses}
            statuses={statuses}
          />
        }
      />
      {data && data.totalSize > 0 && <>Found {data && data.totalSize} items</>}
    </>
  );
};

AcquirerCriteria.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getAcquirerCriteriaData: PropTypes.func.isRequired,
  getBusinessesOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  getAcquirersOptions: PropTypes.func.isRequired,
  getAcquirerCriteriaOptions: PropTypes.func.isRequired,
  partnerId: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  optionsData: PropTypes.objectOf(PropTypes.array),
  openModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number,
    }),
  ]),
  props: PropTypes.objectOf(PropTypes.object),
  hasPartner: PropTypes.bool,
  limit: PropTypes.number,
  setLimitItems: PropTypes.func,
};

export default AcquirerCriteria;
