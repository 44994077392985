import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { NavLink } from 'react-router-dom';
import { customStyles } from './Select';

const CustomOption = props => {
  const { data } = props;
  return data.custom ? (
    <NavLink className="custom-select__custom-option" to={data.link}>
      + {data.title}
    </NavLink>
  ) : (
    <components.Option {...props} />
  );
};

const CustomControl = props => (
  <>
    <components.Control
      {...props}
      className={props.menuIsOpen ? 'control--open' : ''}
    />
  </>
);

const SelectCustom = ({
  onChangeCustom,
  options,
  placeholder,
  isClearable = false,
  value,
  name,
  multiple,
  maximum = 0,
  setFieldValue,
  isSearchable = false,
  isDisabled,
  complex = false,
}) => {
  const getValue = () => {
    if (!options) {
      return multiple ? [] : '';
    }

    if (complex && multiple) {
      const val = [];
      options.forEach(group => {
        group.options.forEach(opt => {
          if (value && value.includes(opt.value)) {
            val.push(opt);
          }
        });
      });
      return val;
    }
    if (complex && !multiple) {
      let val = [];
      options.forEach(group => {
        group.options.forEach(opt => {
          if (opt.value === value) {
            val = opt;
          }
        });
      });
      return val;
    }
    if (multiple) {
      return options.filter(option => value.includes(option.value));
    }
    return options.find(option => value === option.value) || '';
  };

  const onChange = option => {
    if (!option) setFieldValue(name, '');

    if (multiple) {
      if (maximum) {
        if (option && option.length <= maximum) {
          return option && setFieldValue(name, option.map(item => item.value));
        }
        return;
      }
      return option && setFieldValue(name, option.map(item => item.value));
    }

    return option && setFieldValue(name, option.value);
  };

  const formatGroupLabel = data => (
    <span className={`group-label ${data.label.replace(/ /g, '')}`}>
      {data.label}
    </span>
  );
  return (
    <>
      <Select
        components={{ Option: CustomOption, Control: CustomControl }}
        className="custom-select"
        placeholder={(placeholder && placeholder) || ''}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={getValue()}
        onChange={onChangeCustom || onChange}
        isMulti={multiple}
        name={name}
        styles={customStyles}
        isDisabled={isDisabled}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
};

SelectCustom.propTypes = {
  isAsync: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  setFieldValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maximum: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string,
};

export default SelectCustom;
