import {
  Trackers,
  Opportunity,
  AcquirerCriteria,
  Acquirers,
  EmailHistory
} from '../../../api/Api';

import { EMAIL_HISTORY_SUCCESS, EMAIL_HISTORY_IS_LOADING } from '../types';

export function emailHistorySuccess(payload) {
  return {
    type: EMAIL_HISTORY_SUCCESS,
    data: payload.data,
    emailHistory: payload.data
  };
}

export const emailHistoryIsLoading = () => ({
  type: EMAIL_HISTORY_IS_LOADING
});

export function tableSuccess(payload) {
  return {
    type: 'TABLE_SUCCESS',
    data: payload.data
  };
}

export function getEmailHistoryData(params) {
  return async dispatch => {
    dispatch(emailHistoryIsLoading());
    try {
      const data = await EmailHistory.fetch(params);
      dispatch(emailHistorySuccess(data));
    } catch (e) {
      console.log('Error', e);
    }
  };
}

export function getByMatchId(id) {
  return async dispatch => {
    dispatch(emailHistoryIsLoading());
    try {
      const data = await EmailHistory.getByMatchId(id);
      dispatch(emailHistorySuccess(data));
    } catch (e) {
      console.log('Error', e);
    }
  };
}

export async function restoreObject(table, data, trackerid, props) {
  trackerid = { id: trackerid };

  if (table === 'opportunities') {
    data = JSON.parse(data);
    data = { id: data.data['id'] };
    await Promise.all([
      Opportunity.updateSingle(data),
      Trackers.del(trackerid)
    ]);
  }
  if (table === 'acquirers') {
    data = JSON.parse(data);
    data = { id: data.data['id'] };
    await Promise.all([Acquirers.showAcquirer(data), Trackers.del(trackerid)]);
  }
  if (table == 'acquirerProfiles') {
    data = JSON.parse(data);
    data = { id: data.data['id'] };
    await Promise.all([
      AcquirerCriteria.showAcquirer(data),
      Trackers.del(trackerid)
    ]);
  }
}
