import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Label from '../../components/shared/Label/Label';
import { getDataByLastYear } from '../../utils/utils';
import { Numeral } from '../../components/shared';
import notesIco from '../../asset/img/notes-ico.svg';
import PartnerLogo from '../../components/shared/PartnerLogo/PartnerLogo';
import FlagIcon from '@mui/icons-material/Flag';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { Tooltip } from '@material-ui/core';
import { statusOption } from './data';

const TableBody = ({ data, redirectToDetailsPage }) => {
  const checkStale = matches => {
    let staleOpportunity = true;
    matches.forEach((match: IMatch) => {
      if (
        new Date(match.updatedAt) >
        new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)
      ) {
        staleOpportunity = false;
      }
    });

    return staleOpportunity;
  };

  const getStatusLabel = status => {
    const statusLabel = statusOption.find(item => item.value === status);
    return statusLabel ? statusLabel.label : '';
  };

  const calculateId = (id) => {
    return id;
  }

  return (
    <>
      {data &&
        data.items.map(
          ({
            businessName,
            imageUrl,
            id,
            financialsVerified,
            forSale,
            sector,
            matches,
            flagged,
            stale,
            region,
            lastFlagged,
            firstName,
            companyNumber,
            subSector,
            financials,
            lastName,
            notes,
            partnerId,
            codeName,
            status
          }) => (
            <Table.Row key={id} onClick={() => redirectToDetailsPage(id)}>
              <Table.Cell>
                <div className="table-icons">
                  {/* row number */}
                  {calculateId(id)}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--ellipsis title--name">
                  {businessName}
                </div>
                <div className="title">{codeName}</div>
              </Table.Cell>
              <Table.Cell>
                <Label
                  text={getStatusLabel(status)}
                  basic
                  success={status === 'forSale'}
                  primary={status === 'offMarket'}
                  warning={status === 'cold' || status === 'onHold'}
                  danger={status === 'suspend'}
                />
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">
                  {subSector || (sector || '')}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">
                  {region &&
                    region
                      .split(' - ')
                      .slice(-2)
                      .join(' - ')}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">
                  {financials.length && (
                    <Numeral
                      value={getDataByLastYear(
                        financials,
                        'fyEnding',
                        'turnover'
                      )}
                    />
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="title title--table">
                  {financials.length &&
                    (getDataByLastYear(financials, 'fyEnding', 'netProfit') && (
                      <Numeral
                        value={getDataByLastYear(
                          financials,
                          'fyEnding',
                          'netProfit'
                        )}
                      />
                    ))}
                </div>
              </Table.Cell>
              <Table.Cell className="table-icons">
                <div className="table-icons__wrapper table-icons__notes">
                  {notes && (
                    <Tooltip title="Notes">
                      <DescriptionIcon />
                    </Tooltip>
                  )}
                </div>
                <div className="table-icons__wrapper table-icons__flag">
                  {forSale &&
                    new Date(lastFlagged) <
                      new Date(Date.now() - 1000 * 60 * 60 * 24 * 7) && (
                      <Tooltip title="No edits made in over 12 months">
                        <FlagIcon
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        />
                      </Tooltip>
                    )}
                </div>
                <div className="table-icons__wrapper table-icons__stale">
                  {checkStale(matches) && (
                    <Tooltip title="No match activity in over three months">
                      <SyncProblemIcon
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="mna-table__logo">
                  <PartnerLogo id={partnerId} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        )}
    </>
  );
};

TableBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  redirectToDetailsPage: PropTypes.func
};

export default TableBody;
