import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-select';
import AsyncSelect from 'react-select/async';
import Button from '../Button';

export const customStyles = {
  container: (styles, state) => ({
    ...styles,
    zIndex: state.isFocused ? '4' : '1'
  }),
  control: (styles, state) => ({
    ...styles,
    border: '1px solid #c3ccd7',
    position: 'relative',
    minHeight: '50px',
    backgroundColor: state.isDisabled ? '#eee' : '#fff'
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    position: 'relative',
    width: '39px',
    ':before': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      content: '""',
      backgroundColor: state.isDisabled ? '#eee' : '#fff',
      zIndex: '2',
      borderRadius: '3px'
    },
    ':after': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      content: '""',
      zIndex: '2',
      marginTop: '3px',
      transform: 'translate(-50%,-50%)',
      border: '4px solid transparent',
      borderTopColor: '#c3ccd7'
    }
  }),
  input: styles => ({
    ...styles,
    padding: '2px 5px'
  }),
  singleValue: styles => ({
    ...styles,
    color: '#000',
    padding: '0 4px',
    fontWeight: 'normal'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '0 4px',
    color: '#99a9bb'
  }),
  smallFont: styles => ({
    ...styles,
    fontSize: '0.8em',
    color: '#99a9bb'
  }),
  clearIndicator: styles => ({
    ...styles,
    position: 'absolute',
    right: '28px'
  }),
  option: styles => ({
    ...styles,
    paddingBottom: '4px',
    fontWeight: '400',
    textAlign: 'left',
    fontSize: '13px'
  }),
  loadingIndicator: styles => ({
    ...styles,
    position: 'absolute',
    right: '28px'
  })
};

const Select = ({
  onChangeCustom,
  options,
  placeholder,
  isClearable = false,
  value,
  name,
  multiple,
  maximum = 0,
  onCustomClick,
  setFieldValue,
  selectValueOption,
  customClassName,
  isAsync,
  isSearchable = false,
  isDisabled,
  authUser,
  extraStyles,
  complex = false
}) => {
  const [userValue, setUserValue] = useState(null);

  function setDefaultValue(e) {
    setFieldValue('contactId', authUser.id.toString());
  }

  const getValue = () => {
    if (!options) {
      return multiple ? [] : '';
    }

    if (complex && multiple) {
      const val = [];
      options.forEach(group => {
        group.options.forEach(opt => {
          if (value && value.includes(opt.value)) {
            val.push(opt);
          }
        });
      });

      return val;
    }
    if (complex && !multiple) {
      let val = [];
      options.forEach(group => {
        group.options.forEach(opt => {
          if (opt.value === value) {
            val = opt;
          }
        });
      });
      return val;
    }
    if (multiple) {
      return options.filter(option => value.includes(option.value));
    }
    return options.find(option => value === option.value) || '';
  };

  const onChange = option => {
    if (!option) {
      setFieldValue(name, '');
    }

    if (multiple) {
      if (maximum) {
        if (option && option.length <= maximum) {
          return option && setFieldValue(name, option.map(item => item.value));
        }
        return;
      }

      return option && setFieldValue(name, option.map(item => item.value));
    }

    return option && setFieldValue(name, option.value);
  };

  const filters = val => {
    return (
      options &&
      options.filter(i => i.label.toLowerCase().includes(val.toLowerCase()))
    );
  };

  const loadOptions = (value, cb) => {
    setTimeout(() => {
      cb(filters(value));
    }, 1000);
  };

  return (
    <>
      {isAsync ? (
        <AsyncSelect
          className={`custom-select ${customClassName}`}
          name={name}
          placeholder={placeholder}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={options}
          onChange={onChangeCustom || onChange}
          isClearable={isClearable}
          value={userValue || getValue()}
          styles={customStyles}
          onClick={onCustomClick}
          isDisabled={isDisabled}
        />
      ) : (
        <>
          <Dropdown
          className={`custom-select ${customClassName}`}
          placeholder={(placeholder && placeholder) || ''}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            value={getValue()}
            onChange={onChangeCustom || onChange}
            isMulti={multiple}
            name={name}
            onClick={onCustomClick}
            styles={customStyles}
            isDisabled={isDisabled}
            //formatGroupLabel={formatGroupLabel}
          />
        </>
      )}
      {selectValueOption && (
        <Button
          basic
          small
          text="Select Logged in User"
          success
          onClick={() => setDefaultValue()}
        ></Button>
      )}
    </>
  );
};

Select.propTypes = {
  isAsync: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  setFieldValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maximum: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool
  ]),
  name: PropTypes.string
};

export default Select;
