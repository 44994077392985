import React from 'react';
import Rodal from 'rodal';
import Button from '../shared/Form/Button';
import { Acquirers, Opportunity, Matches } from '../../api/Api';
import { BASE_URL } from '../../constants/api';
import { Col, Row } from 'react-flexbox-grid';
import { Paper } from '../../components/shared';
import { restoreObject } from '../../redux/actions/restore/restoreActions';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { toTitleCase, parseTable } from '../../utils/utils';
import {
  formatNullValue,
  formatDate,
  truncate,
  columnToReadable,
  renderSectors
} from '../../utils/utils';

// include styles
import 'rodal/lib/rodal.css';

function isEmpty(str) {
  return !str || str.length === 0;
}

class RestoreModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      table: '',
      trackerid: '',
      data: ''
    };
  }

  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  handleSubmit = async e => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    var table = form_data.get('table');
    var trackerid = form_data.get('trackerid');
    var data = form_data.get('data');
    setTimeout(async function() {
      await restoreObject(table, data, trackerid);
      window.location.href = window.location.href;
    }, 1500);
    return 'done';
  };

  createDescription() {
    var businessName;
    var businessDescription;
    var companyNumber;
    let d = JSON.parse(this.props.backupdata);

    var table = this.props.table;
    var b = d.data;
    const description_length = 7;

    if (table === 'acquirers') {
      var noteable_keys = [
        'companyName',
        'anonDescription',
        'companyNumber',
        'companyDescription',
        'notes',
        'type'
      ];
    }

    if (table === 'opportunities') {
      var noteable_keys = [
        'businessName',
        'codeName',
        'description',
        'companyNumber',
        'region',
        'notes',
        'forSale'
      ];
    }

    if (table === 'acquirerProfiles') {
      var noteable_keys = [
        'description',
        'notes',
        'region',
        'netProfitRangeLow',
        'netProfitRangeHigh',
        'turnoverRangeLow',
        'turnoverRangeHigh'
      ];
    }

    var filled_values = 0;
    var found_keys = [];
    for (var i = 0; i < noteable_keys.length; i++) {
      filled_values = filled_values + 1;
      let key = noteable_keys[i];
      if (!isEmpty(b[key])) {
        found_keys.push(key);
      }
    }
    var description_list = [];
    found_keys.length = description_length;
    found_keys.forEach(function(f) {
      if (Array.isArray(b[f])) {
        b[f] = b[f][0];
      }

      if (f === 'sector') {
        b[f] = renderSectors(b[f], 5);
      }

      description_list.push({
        header: columnToReadable(f),
        description: truncate(b[f])
      });
    });

    const listItems = description_list.map(d => (
      <ul class="description">
        <li class="description--header">
          <b>{d.header}</b>
        </li>
        <li class="description--body">{d.description}</li>
      </ul>
    ));

    return (
      <>
        <div className="modal-alert">{listItems}</div>
      </>
    );
  }

  render() {
    return (
      <div>
        <Button
          successWithoutBg
          basic
          text="Restore"
          onClick={this.show.bind(this)}
          medium
          className="btn-export"
        />
        <Rodal
          height={500}
          width={500}
          visible={this.state.visible}
          onClose={this.hide.bind(this)}
        >
          <div className="matches_card__heading">
            <h3>Restore {parseTable(this.props.table)} Item</h3>
          </div>

          <form className="rodal-form" onSubmit={this.handleSubmit}>
            <Paper elavation={3}>
              <Row center="xs">
                <Col xs={12}>{this.createDescription()}</Col>
              </Row>
            </Paper>

            <input type="hidden" value={this.props.table} name="table"></input>
            <input
              type="hidden"
              value={this.props.id.toString()}
              name="trackerid"
            ></input>
            <input
              type="hidden"
              value={this.props.backupdata}
              name="data"
            ></input>

            <Row center="xs">
              <Col xs={4}>
                <Button
                  successWithoutBg
                  basic
                  text="Restore"
                  type="submit"
                  medium
                  className="btn-export"
                />
              </Col>
            </Row>
          </form>
        </Rodal>
      </div>
    );
  }
}

export default RestoreModal;
