import React from 'react';
import { useTable, usePagination } from 'react-table';
import PageHeader from '../../components/PageHeader/PageHeader';
import { truncate, daysSince } from '../../utils/utils';
import EmailModal from '../Modal/EmailModal';
import EmailFilterModal from '../../components/Modal/EmailFilterModal';

import '../../asset/styles/components/email-modal.scss';

function parseRow(row, data) {
  let row_data = data[parseInt(row.id)];

  if (row.values) {
    row.values.receiver = truncate(row_data.recipientEmail, 80);
    row.values.createdAt = (
      <>
        <p>{daysSince(row_data.createdAt)} ago</p>
      </>
    );
    row.values.type = row_data.emailType;
  }

  return row;
}

export function Table({
  columns,
  data,
  type,
  getEmailHistoryData,
  appliedFilters,
  setAppliedFilters
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'createdAt',
            desc: true
          }
        ]
      }
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <PageHeader title="Email History" nobutton="hiddenbutton" />

      <div className="header-bar">
        <div className="header-bar-left">
          <EmailFilterModal
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        </div>
        <div className="header-bar-right">
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
            className="restore-select"
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <button
            className="restore-leftpage"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <span>
            {' '}
            {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className="restore-rightpage"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
        </div>
      </div>
      <table className="restore-table" {...getTableProps()}>
        <thead className="restore-table-header">
          {headerGroups.map(headerGroup => {
            return (
              <tr
                className="restore-table-header__row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      className={
                        type === 'restore'
                          ? 'restore-table-header__column'
                          : 'restore-table-header__column'
                      }
                      width={column.width}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody className="restore-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            parseRow(row, data);
            prepareRow(row);

            row.cells.pop();

            return (
              <tr className="restore-table-body__row" {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className="restore-table-body__column"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
                <td className="restore-table-body__column" width="10%">
                  <EmailModal
                    getEmailHistoryData={getEmailHistoryData}
                    data={data[parseInt(row.id)]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
