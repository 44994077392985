import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup, Input, PhoneInput, Select, Button } from '../../components/shared';

const NewContactForm = ({
    title,
    handleChange,
    addContact
}) => {
  return (
    
    <>
        <br/>
        <Col xs={6}>
          <h5 className="title title--collapse">{title}</h5>
        </Col>

        <br></br>

        <Row>
            <Col xs={12} sm={6}>
            <FormGroup labelText="first name" name="firstName">
                <Input
                name="firstName"
                onChange={handleChange}
                />
                <p className="form-group__error">
                </p>
            </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
            <FormGroup labelText="last name" name="lastName">
                <Input
                name="lastName"
                onChange={handleChange}
                />
                <p className="form-group__error">
                </p>
            </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col xs={12} sm={6}>
            <FormGroup labelText="Email" name="email">
                <Input
                name="email"
                onChange={handleChange}
                />
                <p className="form-group__error">
                </p>
            </FormGroup>
            </Col>

            <Col xs={12} sm={6}>

             <FormGroup labelText="Add Contact">               
                <Button
                basic
                success
                text={'Add Contact'}
                onClick={function(e) {addContact(true)}}
                type="button"
                />
                </FormGroup>

            </Col>
        </Row>
    </>
  );
};


export default NewContactForm;
