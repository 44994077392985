const defaultState = {
  isCreate: false,
};

export default function modeReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case 'MODE_CREATE': {
      return { ...state, isCreate: true };
    }

    case 'MODE_EDIT': {
      return { ...state, isCreate: false };
    }

    default:
      return state;
  }
};
