export const getBasicFields = () => {
  return {
    remarketedDate: '',
    idVerified: false,
    shareholdingVerified: false,
    partnerId: '1112',
    businessName: '',
    description: '',
    companyNumber: '',
    sector: '',
    subSector: '',
    region: '',
    forSale: 'true',
    financialsVerified: false,
    notes: '',
    sensitive: false,
    clearNames: false,
    clearNamesVia: null,
    overseasBuyers: true,
    peBuyers: true,
    inExclusivity: false,
    sourceUser: '',
    codeName: '',
    financials: [],
    type: [],
    managed: false,
    feesEnabled: false,
    contactRoles: [],
    richDescription: '',
    status: 'forSale',
  };
};
