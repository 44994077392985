export default {
  1: {
    id: 1,
    name: 'Langcliffe',
    feeShare: 'Langcliffe fee share details to go here',
    level: null,
    feeDue: true,
    agreementDate: '2019-07-08T13:49:48.341Z',
    notes: 'Updated notes'
  },
  1112: {
    id: 1112,
    name: 'Unloq',
    feeShare: 'Unloq fee share details to go here',
    level: null,
    feeDue: true,
    agreementDate: '2019-08-19T05:08:58.681Z',
    notes: 'Updated notes'
  }
};
