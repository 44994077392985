import React from 'react'
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.minimal.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { useEffect, useState } from 'react';
import { Table } from '../../components/TableWrap/Table';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const EmailHistory = ({ data, getEmailHistoryData }) => {
  
  const [appliedFilters, setAppliedFilters] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      getEmailHistoryData(appliedFilters);
    };
    fetchData();
  }, [appliedFilters]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Recipient Emails',
        accessor: 'receiver',
        width: '50%'
      },
      {
        Header: 'Sent',
        accessor: 'createdAt',
        width: '20%'
      },
      {
        Header: 'Email Type',
        accessor: 'type',
        width: '20%'
      },
      {
        Header: 'Actions',
        width: '10%'
      }
    ],
    []
  );
  return (
    <>
      
      {data && (
        <Styles>
          <Table
            columns={columns}
            type={'email'}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            data={data}
            getEmailHistoryData={getEmailHistoryData}
          />
        </Styles>
      )}
    </>
  );
};

export default EmailHistory;
