import React, { memo } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { countriesCodes } from '../../../Data/countriesCodes';

const isPropsAreEqual = (prev, next) => {
  return (
    prev.value === next.value &&
    prev.disabled === next.disabled &&
    prev.codeCountry === next.codeCountry &&
    prev.options === next.options
  );
};

const PhoneInput = memo(
  ({
    onChange,
    dropdownOnChange,
    defaultValue,
    value,
    codeCountry,
    ...rest
  }) => {
    const DropdownPhone = (
      <Dropdown
        className="mna-phone__dropdown mna-select"
        placeholder="+44"
        id="areaCode"
        name="areaCode"
        search
        options={countriesCodes}
        onChange={dropdownOnChange}
        value={codeCountry}
      />
    );
    return (
      <div className="mna-phone">
        <Input
          className="mna-phone__input-wrap"
          label={DropdownPhone}
          onChange={onChange}
          {...rest}
          name="phone"
          type="text"
          value={value}
          labelPosition="left"
          placeholder="Phone"
        />
      </div>
    );
  },
  isPropsAreEqual
);

PhoneInput.defaultProps = {
  defaultValue: '+44',
};

PhoneInput.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  dropdownOnChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  codeCountry: PropTypes.string,
  value: PropTypes.string,
};

export default PhoneInput;
