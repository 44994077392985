import { TOGGLE_SIDEBAR } from '../types';

export function toggleSidebar() {
    onClickToggleSidebar();
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function onClickToggleSidebar() {
  return dispatch => dispatch(toggleSidebar());
}