import { hot } from 'react-hot-loader/root';

import React, { useEffect } from 'react';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.minimal.css';
import './asset/styles/App.scss';
import clsx from 'clsx';
import { Loader } from 'semantic-ui-react';
import PrivateRoute from './components/HOC/ProtectedRoute';
import Routes from './constants/routes';
import { useAuth0 } from './auth/react-auth0-spa';
import SidebarContainer from './components/Sidebar/SidebarContainer';
import {
  AcquirerCriteriaEditContainer,
  OpportunitiesEditContainer,
  OpportunitiesContainer,
  AcquirerCriteriaContainer,
  AcquirersEditContainer,
  AcquirersContainer,
  AccountContainer,
  MatchesContainer,
  MatchesEditContainer,
  ContactsContainer,
  RestoreContainer,
  EmailHistoryContainer,
  MatchHistoryContainer
} from './Containers';
import Toast from './components/shared/Toast/Toast';
import { fetchUserDetailsByAuthId } from './redux/actions/user/userActions';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App({ sidebarIsOpen, authDetails }) {
  const { loading, id } = useAuth0();
  useEffect(() => {
    const fetchData = async () => {
      await fetchUserDetailsByAuthId(id);
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <SidebarContainer authDetails={authDetails} />
        <div
          className={`main-wrap ${clsx({
            'sidebar--open': sidebarIsOpen
          })}`}
        >
          <Toast autoClose={4000} position="bottom-center" />
          <Switch>
            <PrivateRoute
              path={Routes.MATCHES}
              exact
              render={props => <MatchesContainer props={props} />}
            />
            <PrivateRoute
              path={`${Routes.MATCHES}/:id`}
              exact
              render={props => <MatchesEditContainer props={props} />}
            />
            {/* OPPORTUNITIES PAGES */}
            <PrivateRoute
              path={Routes.OPPORTUNITIES}
              exact
              render={props => <OpportunitiesContainer props={props} />}
            />
            <PrivateRoute
              path={`${Routes.OPPORTUNITIES}/create`}
              exact
              render={props => (
                <OpportunitiesEditContainer props={props} isCreate />
              )}
            />
            <PrivateRoute
              path={`${Routes.OPPORTUNITIES}/:id`}
              exact
              render={props => <OpportunitiesEditContainer props={props} />}
            />
            {/* ACQUIRER_CRITERIA PAGES */}
            <PrivateRoute
              exact
              path={Routes.ACQUIRER_CRITERIA}
              render={props => <AcquirerCriteriaContainer props={props} />}
            />
            <PrivateRoute
              path={`${Routes.ACQUIRER_CRITERIA}/create`}
              exact
              render={props => (
                <AcquirerCriteriaEditContainer props={props} isCreate />
              )}
            />
            <PrivateRoute
              exact
              path={`${Routes.ACQUIRER_CRITERIA}/:id`}
              render={props => <AcquirerCriteriaEditContainer props={props} />}
            />
            {/* ACQUIRERS PAGES */}
            <PrivateRoute
              path={Routes.ACQUIRERS}
              exact
              render={props => <AcquirersContainer props={props} />}
            />
            <PrivateRoute
              path={`${Routes.ACQUIRERS}/create`}
              exact
              render={props => (
                <AcquirersEditContainer props={props} isCreate />
              )}
            />
            <PrivateRoute
              path={`${Routes.ACQUIRERS}/:id`}
              exact
              render={props => <AcquirersEditContainer props={props} />}
            />
            <PrivateRoute
              path={`${Routes.CONTACTS}`}
              exact
              render={props => <ContactsContainer props={props} />}
            />
            <PrivateRoute
              path={Routes.ACCOUNT}
              exact
              render={props => <AccountContainer props={props} />}
            />

            <PrivateRoute
              path={Routes.RESTORE}
              exact
              render={props => <RestoreContainer props={props} />}
            />

            <PrivateRoute
              path={Routes.EMAILHISTORY}
              exact
              render={props => <EmailHistoryContainer props={props} />}
            />

            <PrivateRoute
              path={Routes.MATCHHISTORY}
              exact
              render={props => <MatchHistoryContainer props={props} />}
            />

            {/* SHOULD ALWAYS IN THE END */}
            <Redirect from="/" to="/matches" />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = store => ({
  sidebarIsOpen: store.sidebar.sidebarIsOpen,
  authDetails: store.user.userAuth
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetailsByAuthId: id => dispatch(fetchUserDetailsByAuthId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(App));
