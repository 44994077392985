import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Form } from 'semantic-ui-react';
import SearchInput from '../shared/Form/SearchInput';
import Select from '../shared/Form/Select/Select';
import FormGroup from '../shared/Form/FormGroup';
import { Button, Icon } from '../shared';
import Filters from '../../asset/img/filters.svg';

const Toolbar = ({
  setSearchTerm,
  value,
  onChangeLimit,
  showItemsOnPage,
  totalPages,
  setSearchQuery,
  currentPage,
  onPageChange,
  tableData,
  openModal,
  matches,
  isFilters,
  options,
  setFieldValue,
  countFilterOptions,
  onChangeActivePage,
  noPagination,
  matchesCount
}) => (
  <div className="toolbar">
    <div>
      <SearchInput
        onChange={e => {
          setSearchTerm(e);
          //setSearchQuery(e.target.value)
        }}
        value={value}
        placeholder="Search by Name or Company"
      />
      {isFilters && (
        <Button
          basic
          className={`mna-btn--filter flex${
            countFilterOptions > 0 ? ' mna-btn--filter-active' : ''
          }`}
          onClick={openModal}
        >
          Filters {countFilterOptions > 0 ? `(${countFilterOptions})` : ''}
          <Icon icon={Filters} size="18px" />
        </Button>
      )}
    </div>
    <div className="toolbar__pages">
      {matchesCount > 0 && (
        <div className="toolbar__pages__count">
          {matchesCount} {matchesCount > 1 ? 'matches' : 'match'}
        </div>
      )}

      <FormGroup inline>
        {(!matches && tableData.length && (
          <Select
            options={options}
            onChangeCustom={onChangeLimit}
            setFieldValue={setFieldValue}
            value={showItemsOnPage}
          />
        )) ||
          null}
        {(tableData && tableData.length && !noPagination && (
          <div className="mna-pagination">
            <Pagination
              totalPages={totalPages}
              ellipsisItem={null}
              lastItem={totalPages}
              firstItem={null}
              siblingRange={0}
              boundaryRange={0}
              onPageChange={onPageChange}
              activePage={currentPage}
            />
            <Form.Input
              name="activePage"
              min={1}
              className="pagina-active"
              max={totalPages}
              onChange={onChangeActivePage}
              type="number"
              value={parseInt(currentPage, 10)}
            />
          </div>
        )) ||
          null}
      </FormGroup>
    </div>
  </div>
);

Toolbar.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func,
  isFilters: PropTypes.bool,
  matches: PropTypes.bool,
  value: PropTypes.string,
  showItemsOnPage: PropTypes.string,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object),
  countFilterOptions: PropTypes.number,
  onChangeActivePage: PropTypes.func
};

export default Toolbar;
