import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import { useAuth0 } from '../../auth/react-auth0-spa';
import Routes from '../../constants/routes';
import PageHeader from '../../components/PageHeader/PageHeader';
import TableWrap from '../../components/TableWrap/TableWrap';
import headerTitles, { statusOption } from './data';
import 'react-toastify/dist/ReactToastify.minimal.css';
import 'react-toastify/dist/ReactToastify.min.css';
import TableBody from './TableBody';
import OpportunityFilterModal from '../../components/Modal/OpportunityFilterModal';

const Opportunities = ({
  data,
  getOpportunitiesData,
  isLoading,
  props,
  partnerId,
  setFieldValue,
  limit,
  setLimitItems
}) => {
  const { loading, user } = useAuth0();
  const [stale, setStale] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [statuses, setStatuses] = useState('active');
  const [selectableStatuses, setSelectableStatuses] = useState([]);

  const filterStaleOpportunities = () => {
    const fetchData = async () => {
      await getOpportunitiesData({
        limit,
        staleFilter: true,
        status: statuses
      });
    };
    fetchData();
  };

  const removeFilterStaleOpportunities = () => {
    const fetchData = async () => {
      await getOpportunitiesData({
        limit,
        staleFilter: false,
        status: statuses
      });
    };
    fetchData();
  };

  const [table, setTableData] = useState({
    searchQuery: '',
    activePage: 1
  });

  useEffect(() => {
    const fetchData = async () => {
      await getOpportunitiesData({
        limit,
        businessName: table.searchQuery,
        partnerId,
        staleFilter: stale,
        status: statuses
      });
    };
    fetchData();
    // eslint-disable-next-line
  }, [table.limit, partnerId]);

  useEffect(() => {
    const fetchData = async () => {
      await getOpportunitiesData({
        limit,
        businessName: table.searchQuery,
        partnerId,
        staleFilter: stale,
        status: statuses
      });
    };
    fetchData();
    // eslint-disable-next-line
  }, [statuses]);

  useEffect(() => {
    // sort statusOptions by removing where key is forSale and offMarket and replace with active
    let temp = statusOption.filter(status => {
      if (status.key === 'forSale' || status.key === 'offMarket' || status.key === 'suspend') {
        return false;
      }
      return true;
    });

    // add active to the top of the array
    let active = {
      key: 'active',
      name: 'active',
      value: 'active',
      label: 'Active'
    };
    temp.unshift(active);

    setSelectableStatuses(temp);
  }, []);

  const changeLimitOnPage = async option => {
    await setLimitItems(parseInt(option.value, 10));
    await getOpportunitiesData({
      limit: parseInt(option.value, 10),
      staleFilter: stale,
      status: statuses
    });
  };

  const setSearchTerm = async e => {
    const { value } = e.target;

    setTableData({
      ...table,
      searchQuery: value,
      staleFilter: stale
    });

    return debounce(async () => {
      await getOpportunitiesData({
        businessName: value,
        limit: table.limit,
        staleFilter: stale,
        status: statuses
      });
    }, 500)();
  };

  const redirectToDetailsPage = id => props.history.push(`opportunities/${id}`);

  const onChangePage = (e, data) => {
    const { activePage } = data;
    const offset = (activePage - 1) * parseInt(limit, 10);

    setTableData({
      ...table,
      activePage
    });

    return debounce(async () => {
      await getOpportunitiesData({
        businessName: table.searchQuery,
        limit,
        offset,
        partnerId,
        staleFilter: stale,
        status: statuses
      });
    }, 500)();
  };

  const onChangeActivePage = (e, data) => {
    const { value } = data;
    setTableData({
      ...table,
      activePage: parseInt(value, 10)
    });
    const offset = (value - 1) * limit;
    return debounce(async () => {
      await getOpportunitiesData({
        businessName: table.searchQuery,
        limit,
        offset,
        partnerId,
        staleFilter: stale,
        status: statuses
      });
    }, 500)();
  };

  console.log(data);

  return (
    <>
      <PageHeader
        title="Opportunities"
        linkText="Add opportunity"
        stateText="Create"
        location={props.location}
        filterStaleOpportunities={filterStaleOpportunities}
        removeFilterStaleOpportunities={removeFilterStaleOpportunities}
        stale={stale}
        setStale={setStale}
        isOpportunities
        path={`${Routes.OPPORTUNITIES}/create`}
      />
      <Loader active={loading || isLoading || !user} />
      {(data && data.items && (
        <>
          <TableWrap
            data={data}
            isFilters
            headerTitles={headerTitles}
            setSearchTerm={setSearchTerm}
            currentPage={table.activePage}
            searchQuery={table.searchQuery}
            openModal={() => setOpenModal(true)}
            changeItemsOnPage={changeLimitOnPage}
            setFieldValue={setFieldValue}
            onPageChange={onChangePage}
            onChangeActivePage={onChangeActivePage}
            withNotes
            tableBody={
              <TableBody
                data={data}
                redirectToDetailsPage={redirectToDetailsPage}
              />
            }
            limit={limit.toString()}
          />
      {data && data.totalSize > 0 && <>Found {data && data.totalSize} items</>}
        </>
      )) ||
        'no data yet'}
      <OpportunityFilterModal
        header="Select Filter"
        btnSaveText="Save"
        btnCancelText="Cancel"
        modalIsOpen={openModal}
        onFilterData={() => {
          console.log('filtering');
        }}
        options={selectableStatuses}
        closeModal={() => setOpenModal(false)}
        statuses={statuses}
        setStatuses={setStatuses}
      />
    </>
  );
};

Opportunities.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  getOpportunitiesData: PropTypes.func,
  isLoading: PropTypes.bool,
  partnerId: PropTypes.string,
  props: PropTypes.objectOf(PropTypes.object),
  setFieldValue: PropTypes.func,
  limit: PropTypes.number,
  setLimitItems: PropTypes.func
};

export default Opportunities;
