import React from 'react';
import PropTypes from 'prop-types';
import langcliffe from '../../../asset/img/langcliffe.png';
import unloq from '../../../asset/img/unloq.png';
import nopartner from '../../../asset/img/nopartner.png';
import tml from '../../../asset/img/TML Logo.png'
import andersonmoore from '../../../asset/img/Anderson Moore.jpg'

const PartnerLogo = ({ id, className }) => {
  switch (id) {
    case 1:
      return <img className={className} src={langcliffe} alt="langcliffe" />;
    case 2:
      return <img className={className} src={tml} alt="TML" />;
    case 3:
      return <img className={className} src={andersonmoore} alt="Anderson Moore" />;
    case 1112:
      return <img className={className} src={unloq} alt="unloq" />;
    default:
      return <img className={className} src={nopartner} alt="" />;
  }
};

PartnerLogo.propTypes = {
  id: PropTypes.number
};

export default PartnerLogo;
