import { Checkbox } from 'semantic-ui-react';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Switcher = memo(({ name, value, onChange, ...rest }) => {
  return (
    <Checkbox
      className="mna-switcher"
      value={value}
      name={name}
      onChange={onChange}
      toggle
      {...rest}
    />
  );
});

Switcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default Switcher;
