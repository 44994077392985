import React from 'react';

const AcIco = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="mna-sidebar__ico"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="8skfa">
        <path d="M15 13.558c0-.409.336-.74.75-.74s.75.331.75.74c0 .41-.336.74-.75.74a.745.745 0 0 1-.75-.74zm-3.05-10.03L7.793 5.214h4.914zm1.55 8.401v3.357c0 .164.134.296.3.296h7.4c.166 0 .3-.132.3-.296V11.93a.298.298 0 0 0-.3-.296h-7.4c-.166 0-.3.133-.3.296zm.846-6.714h2.36l-.756-1.69-2.002.801zM21 20.223c0 .981-.806 1.777-1.8 1.777H2.8c-.994 0-1.8-.796-1.8-1.777V6.993c0-.982.806-1.778 1.8-1.778h1.046l7.67-3.112a1.357 1.357 0 0 1 1.748.694l.078.174 2.178-.873a1.357 1.357 0 0 1 1.743.697l1.083 2.42h.854c.994 0 1.8.796 1.8 1.777v3.16h.2c.994 0 1.8.796 1.8 1.777v3.357c0 .982-.806 1.777-1.8 1.777H21zM2.8 6.696c-.166 0-.3.133-.3.296v13.23c0 .164.134.297.3.297h16.4c.166 0 .3-.133.3-.296v-3.16h-5.7c-.994 0-1.8-.795-1.8-1.777V11.93c0-.981.806-1.777 1.8-1.777h5.7v-3.16a.298.298 0 0 0-.3-.296z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g />
        <g>
          <path
            fill="#979797"
            d="M15 13.558c0-.409.336-.74.75-.74s.75.331.75.74c0 .41-.336.74-.75.74a.745.745 0 0 1-.75-.74zm-3.05-10.03L7.793 5.214h4.914zm1.55 8.401v3.357c0 .164.134.296.3.296h7.4c.166 0 .3-.132.3-.296V11.93a.298.298 0 0 0-.3-.296h-7.4c-.166 0-.3.133-.3.296zm.846-6.714h2.36l-.756-1.69-2.002.801zM21 20.223c0 .981-.806 1.777-1.8 1.777H2.8c-.994 0-1.8-.796-1.8-1.777V6.993c0-.982.806-1.778 1.8-1.778h1.046l7.67-3.112a1.357 1.357 0 0 1 1.748.694l.078.174 2.178-.873a1.357 1.357 0 0 1 1.743.697l1.083 2.42h.854c.994 0 1.8.796 1.8 1.777v3.16h.2c.994 0 1.8.796 1.8 1.777v3.357c0 .982-.806 1.777-1.8 1.777H21zM2.8 6.696c-.166 0-.3.133-.3.296v13.23c0 .164.134.297.3.297h16.4c.166 0 .3-.133.3-.296v-3.16h-5.7c-.994 0-1.8-.795-1.8-1.777V11.93c0-.981.806-1.777 1.8-1.777h5.7v-3.16a.298.298 0 0 0-.3-.296z"
          />
        </g>
        <g clipPath="url(#8skfa)">
          <g>
            <path fill="#99a9bb" d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AcIco;
