import React, { useState } from 'react';
import Rodal from 'rodal';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup } from '../shared';
import Filters from '../../asset/img/filters.svg';
import { Icon } from '../shared';

import { Button, Input } from '../shared';

const EmailFilterModal = ({ appliedFilters, setAppliedFilters }) => {
  const [visible, setVisible] = useState(false);
  const [matchInput, setMatchInput] = useState('');

  return (
    <>
      <Button
        style={{ 'margin-left': 0 }}
        basic
        className={`mna-btn--filter flex${
          Object.keys(appliedFilters).length > 0
            ? ' mna-btn--filter-active'
            : ''
        }`}
        onClick={() => setVisible(true)}
      >
        Filters{' '}
        {Object.keys(appliedFilters).length > 0
          ? `(${Object.keys(appliedFilters).length})`
          : ''}
        <Icon icon={Filters} size="18px" />
      </Button>

      <Rodal
        height={500}
        width={500}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <Icon icon={Filters} size="40px" className="mna-modal__icon" />
            <h2 className="title title-main">Email Filters</h2>
            <div></div>
          </div>
          <div className="modal-body">
            <Row className="flex-content-center">
              <Col xs={6}>
                <FormGroup labelText="Match ID" name="matchId">
                  <Input
                    type="text"
                    autocomplete="off"
                    name="remarketedDate"
                    value={matchInput}
                    onChange={e => {
                      if (!isNaN(e.target.value) || e.target.value === '') {
                        setMatchInput(e.target.value);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="modal-body-button">
              <Col xs={12}>
                <Button
                  success
                  basic
                  text="Apply"
                  onClick={() => {
                    if (matchInput) {
                      setAppliedFilters({ relatedMatchId: parseInt(matchInput) });
                    } else {
                      setAppliedFilters({})
                    }
                    setVisible(false);
                  }}
                  medium
                  className="btn-export"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Rodal>
    </>
  );
};

export default EmailFilterModal;
