import React from 'react';

const LogoutIco = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <clipPath id="hye7a">
        <path d="M3.779 12.75h9.602l-2.658 2.658a.75.75 0 0 0 1.06 1.06l3.939-3.938a.75.75 0 0 0 0-1.06l-3.938-3.938a.75.75 0 0 0-1.061 1.06l2.658 2.658H3.779a.75.75 0 0 0 0 1.5zM18 4.5A1.5 1.5 0 0 1 19.5 6v12a1.5 1.5 0 0 1-1.5 1.5H6A1.5 1.5 0 0 1 4.5 18v-3H3v3a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v3h1.5V6A1.5 1.5 0 0 1 6 4.5z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g>
          <g />
          <g>
            <path
              fill="#979797"
              d="M3.779 12.75h9.602l-2.658 2.658a.75.75 0 0 0 1.06 1.06l3.939-3.938a.75.75 0 0 0 0-1.06l-3.938-3.938a.75.75 0 0 0-1.061 1.06l2.658 2.658H3.779a.75.75 0 0 0 0 1.5zM18 4.5A1.5 1.5 0 0 1 19.5 6v12a1.5 1.5 0 0 1-1.5 1.5H6A1.5 1.5 0 0 1 4.5 18v-3H3v3a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v3h1.5V6A1.5 1.5 0 0 1 6 4.5z"
            />
          </g>
          <g clipPath="url(#hye7a)">
            <g>
              <path fill="#99a9bb" d="M0 0h24v24H0z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoutIco;
