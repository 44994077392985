import React from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import 'react-toastify/dist/ReactToastify.minimal.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { useEffect } from 'react';
import RestoreModal from '../../components/Modal/RestoreModal';
import { restoreObject } from '../../redux/actions/restore/restoreActions';
import {
  formatNullValue,
  parseTable,
  formatDate,
  truncate,
  columnToReadable,
  renderSectors
} from '../../utils/utils';
import PageHeader from '../../components/PageHeader/PageHeader';

function isEmpty(str) {
  return !str || str.length === 0;
}

function createDescription(table, b) {
  let backupdata = JSON.parse(b);
  b = backupdata.data;

  const description_length = 3;

  if (table === 'acquirers') {
    var noteable_keys = [
      'companyName',
      'anonDescription',
      'companyNumber',
      'companyDescription',
      'notes',
      'type'
    ];
  }

  if (table === 'opportunities') {
    var noteable_keys = [
      'businessName',
      'codeName',
      'description',
      'companyNumber',
      'region',
      'sector',
      'subSector',
      'notes',
      'forSale'
    ];
  }

  if (table === 'acquirerProfiles') {
    var noteable_keys = [
      'description',
      'notes',
      'region',
      'sector',
      'netProfitRangeLow',
      'netProfitRangeHigh',
      'turnoverRangeLow',
      'turnoverRangeHigh'
    ];
  }

  var filled_values = 0;
  var found_keys = [];
  for (var i = 0; i < noteable_keys.length; i++) {
    filled_values = filled_values + 1;
    let key = noteable_keys[i];
    if (!isEmpty(b[key])) {
      found_keys.push(key);
    }
  }
  var description_list = [];
  found_keys.length = description_length;
  found_keys.forEach(function(f) {
    if (Array.isArray(b[f])) {
      b[f] = b[f][0];
    }

    if (f === 'sector') {
      b[f] = renderSectors(b[f], 5);
    }
    description_list.push({
      header: columnToReadable(f),
      description: truncate(b[f])
    });
  });

  const listItems = description_list.map(d => (
    <span class="description">
      <div class="description--header">{d.header}</div>
      <div class="description--body">{d.description}</div>
    </span>
  ));

  return <>{listItems}</>;
}

function formatTable(table) {
  if (table === 'acquirerProfiles') return 'Acquirer Profiles';
  if (table === 'opportunities') return 'Opportunities';
  if (table === 'acquirers') return 'Acquirer';
}

function parseRow(row, data) {
  let row_data = data[parseInt(row.id)];
  if (row.values) {
    row.values.table = formatTable(row_data.table);
    row.values.createdAt = formatNullValue(formatDate(row.values.createdAt));
    row.values.Item = createDescription(row_data.table, row_data.backupdata);
    row.values.Action = (
      <RestoreModal
        id={row_data.id}
        backupdata={row_data.backupdata}
        restoreObject={restoreObject}
        table={row_data.table}
      ></RestoreModal>
    );
  }
}

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'createdAt',
            desc: true
          }
        ]
      }
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <PageHeader title="Restore" nobutton="hiddenbutton" />
      <div className="pagination">
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          className="restore-select"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <button
          className="restore-leftpage"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <span>
          {' '}
          {pageIndex + 1} of {pageOptions.length}
        </span>
        <button
          className="restore-rightpage"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
      </div>
      <table className="restore-table" {...getTableProps()}>
        <thead className="restore-table-header">
          {headerGroups.map(headerGroup => (
            <tr
              className="restore-table-header__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <th
                  className="restore-table-header__column"
                  width={column.width}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="restore-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            parseRow(row, data);
            prepareRow(row);

            return (
              <tr className="restore-table-body__row" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      className="restore-table-body__column"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
    </>
  );
}

const Restore = ({
  data,
  getRestoreData,
  restoreObject,
  isLoading,
  props,
  partnerId,
  setFieldValue,
  limit,
  setLimitItems
}) => {
  useEffect(() => {
    const fetchData = async () => {
      await getRestoreData({});
    };
    fetchData();
    // eslint-disable-next-line
  }, [10, partnerId]);

  data = data.items;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Table',
        accessor: 'table',
        width: '10%'
      },
      {
        Header: 'Deleted At',
        accessor: 'createdAt',
        width: '10%'
      },
      {
        Header: 'Item',
        width: '70%'
      },
      {
        Header: 'Action',
        width: '10%'
      }
    ],
    []
  );
  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  );
};

export default Restore;
