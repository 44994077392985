import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';
import Collapse from '../../components/shared/Collapse/Collapse';
import FormGroup from '../../components/shared/Form/FormGroup';
import Input from '../../components/shared/Form/Input';
import Button from '../../components/shared/Form/Button';
import PageHeader from '../../components/PageHeader/PageHeader';

const Account = ({
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  touched,
  errors,
  rerunMatches
}) => {
  return (
    <>
      <PageHeader title="Account" nobutton="hiddenbutton" />
      <Row>
        <Col md={12} lg={8}>
          <form className="account-page" onSubmit={handleSubmit}>
            <Collapse title="Personal Details" defaultOpen>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FormGroup labelText="First Name" name="firstName" withLabel>
                    <Input
                      type="text"
                      name="firstName"
                      value={get(values, 'firstName', '')}
                      onKeyUp={() => setFieldTouched('firstName', true)}
                      onChange={handleChange}
                    />
                    <p className="form-group__error">
                      {touched.firstName && errors.firstName}
                    </p>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FormGroup labelText="Email Address" name="email">
                    <Input
                      type="email"
                      value={get(values, 'email', '')}
                      name="email"
                      onKeyUp={() => setFieldTouched('email', true)}
                      onChange={handleChange}
                    />
                    <p className="form-group__error">
                      {touched.email && errors.email}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FormGroup labelText="Last Name" name="lastName" withLabel>
                    <Input
                      type="text"
                      name="lastName"
                      onKeyUp={() => setFieldTouched('lastName', true)}
                      value={get(values, 'lastName', '')}
                      onChange={handleChange}
                    />
                    <p className="form-group__error">
                      {touched.lastName && errors.lastName}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Button text="Update" basic success type="submit" />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </form>
        </Col>
      </Row>
      {/* Row for a button that reruns the match process */}
      <Row>
        <Col md={12} lg={8} style={{
          display: 'none'
        }}>
          <form className="account-page" onSubmit={handleSubmit}>
            <Collapse title="Rerun Matches" defaultOpen>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FormGroup>
                    <Button text="Rerun Matches" basic success type="button" 
                    
                    onClick={rerunMatches}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </form>
        </Col>
      </Row>
    </>
  );
};

Account.propTypes = {
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object
};

export default Account;
