import { useEffect } from 'react';

export const useMountAsyncEffect = fun =>
  useEffect(() => {
    async function fetchData() {
      await fun();
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
