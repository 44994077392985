import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';
import { Loader } from 'semantic-ui-react';
import EditLayout from '../../components/EditLayout/EditLayout';
import ActionCard from '../../components/shared/Paper/ActionCard';

import { returnObjectValue } from '../../utils/utils';
import { columnToReadable } from '../../utils/utils';
import EmailModal from '../../components/Modal/EmailModal';

import { Table as TestTable } from 'semantic-ui-react';
import {
  Select,
  Paper,
  Collapse,
  FormGroup,
  Numeral,
  Button
} from '../../components/shared';

import Table from '../../components/shared/Table/Table';
import Routes from '../../constants/routes';
import {
  getDataByLastYear,
  isNumExist,
  renderSectors,
  recursivelyRenderDescription
} from '../../utils/utils';
import StageOptions from '../../Data/Select/StageOptions';
import ApprovalOptions from '../../Data/Select/ApprovalOptions';
import ModalManager from '../../components/Modal/Modal';
import { useAuth0 } from '../../auth/react-auth0-spa';
import partners from '../../Data/partners';
import ScoreList from '../../Data/Select/ScoreList';
import { useSelector } from 'react-redux';
import BasicTable from '../../components/Table/BasicTable';
import { ReactReduxContext } from 'react-redux';

const MatchesEdit = ({
  handleChange,
  props,
  getMatchesDetails,
  deleteMatches,
  deleteMatch,
  getByMatchId,
  setMatchesDetails,
  data,
  values,
  modalIsOpens,
  closeModal,
  openModals,
  matchesIsLoading,
  getUserNameOptions
}) => {
  const { loading } = useAuth0();
  const [state, setState] = useState({
    stage: '',
    notes: '',
    approved: null,
    vendorScore: '',
    acquirerScore: ''
  });

  const [businessFeeShare, setBusinessFeeShare] = useState('');
  const [acquirerFeeShare, setAcquirerFeeShare] = useState('');

  const { store } = useContext(ReactReduxContext);
  let a = store.getState();

  const partnerId = a.user.userAuth.partnerId;

  console.log('On this render :: Partner ID', partnerId);

  const { id } = useParams();
  useEffect(() => {
    console.log(`Use Effecting`);
    if (isNumExist(id) && id) {
      Promise.all([
        getMatchesDetails({
          id
        }), // ALWAYS SHOULD BE FIRST
        getUserNameOptions()
      ]).then(res => {
        if (res && res[0]) {
          const {
            stage,
            notes,
            approved,
            business,
            profile,
            vendorScore,
            acquirerScore
          } = res[0].data;
          setBusinessFeeShare(business.fee);
          setAcquirerFeeShare(profile.fee);
          setState({
            stage,
            notes,
            approved,
            vendorScore,
            acquirerScore
          });
        }
      });
    }
  }, [partnerId]);

  const suspendMatch = async id => {
    await deleteMatch(id);
    props.history.push(Routes.MATCHES);
  };

  const onSaveData = async () => {
    const { id } = data;
    const { notes, stage, approved, vendorScore, acquirerScore } = state;
    const obj = {
      matches: [
        {
          id,
          notes,
          stage,
          approved,
          vendorScore,
          acquirerScore
        }
      ]
    };

    await setMatchesDetails(obj);
  };

  const getFeeShareDetails = partnerId => {
    if (partnerId && partners[partnerId]) {
      return partners[partnerId].feeShare;
    }
    return '';
  };

  const matchValueToStage = (array, value) => {
    if (!value) return false;
    return array.includes(value);
  };

  const arrayStageForVendor = [
    'Interest Expressed',
    'NDA Requested',
    'NDA Sent',
    'NDA Signed',
    'IM Requested',
    'IM Sent',
    'Meeting',
    'Offer',
    'Legals'
  ];

  const arrayStageForAcquirer = [
    'Acquirer Cleared',
    'Opportunity Sent',
    'Interest Expressed',
    'NDA Requested',
    'NDA Sent',
    'NDA Signed',
    'IM Requested',
    'IM Sent',
    'Meeting',
    'Offer',
    'Legals'
  ];

  const [emails, setEmails] = useState([]);
  const [emailPage, setEmailPage] = useState(0);

  useEffect(() => {
    if (data.emailHistory && data.emailHistory.length > 0) {
      const slicedEmails = data.emailHistory.slice(
        emailPage * 10,
        emailPage * 10 + 10
      );
      if (slicedEmails.length > 0) {
        setEmails(slicedEmails);
      } else {
        if (emailPage > 0) {
          setEmailPage(emailPage - 1);
        }
      }
    }
  }, [emailPage, data.emailHistory]);

  return (
    <>
      <form className="form-wrap">
        <EditLayout
          handleChange={handleChange}
          objWithData={state.notes}
          trackingData={data}
          saveText="Save"
          isSubmit={null}
          pageHeaderTitle="Matches"
          handleSubmit={values.clearNames ? openModals : onSaveData}
          history={props.history}
          location={props.location}
          pathBack={Routes.MATCHES}
          setState={setState}
          isMatchesEdit
          statusApproved={values.approved}
          cancelText="Cancel"
        >
          <Loader active={loading || matchesIsLoading} />

          <Paper>
            <Collapse defaultOpen title="Match Details">
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="Approval" name="approved">
                    <Select
                      name="approved"
                      id="approved"
                      placeholder=""
                      options={ApprovalOptions}
                      value={state.approved}
                      onChangeCustom={option => {
                        setState(prevState => {
                          return {
                            ...prevState,
                            approved: option.value
                          };
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="Match Stage" name="stage">
                    <Select
                      name="stage"
                      id="stage"
                      placeholder=""
                      options={StageOptions}
                      value={state.stage}
                      complex
                      onChangeCustom={option => {
                        setState(prevState => {
                          return {
                            ...prevState,
                            stage: option.value
                          };
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </Paper>

          <Paper>
            <Collapse defaultOpen title="Acquirer Criteria Details">
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Acquirer name</div>
                    <div className="matches-info__data">
                      <NavLink to={`${Routes.ACQUIRERS}/${values.acquirerId}`}>
                        {values.acquirerName}
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Response</div>
                    <div className="matches-info__data">
                      <Select
                        name="acquirerScore"
                        id="acquirerScore"
                        placeholder=""
                        options={ScoreList}
                        value={
                          state.acquirerScore &&
                          state.acquirerScore !== 'Created'
                            ? state.acquirerScore
                            : matchValueToStage(
                                arrayStageForAcquirer,
                                state.stage
                              )
                            ? setState({
                                ...state,
                                acquirerScore: 'Yes'
                              })
                            : state.acquirerScore
                        }
                        onChangeCustom={option => {
                          setState(prevState => {
                            return {
                              ...prevState,
                              acquirerScore: option.value
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Description</div>
                    <div className="matches-info__data">
                      {values.acquirerRichDescription &&
                        recursivelyRenderDescription(
                          values.acquirerRichDescription
                        )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Anon Acquirer</div>
                    <div className="matches-info__data">
                      {values.anonDescription}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">
                      Turnover range (£)
                    </div>
                    <div className="matches-info__data">
                      <Numeral value={values.turnoverRangeLow} />
                      -
                      <Numeral value={values.turnoverRangeHigh} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">EBITDA range (£)</div>
                    <div className="matches-info__data">
                      <Numeral value={values.netProfitRangeLow} />
                      -
                      <Numeral value={values.netProfitRangeHigh} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Regions</div>
                    <div className="matches-info__data">
                      {values.regionAcquirer.join(', ')}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Sector</div>
                    <div className="matches-info__data">
                      {renderSectors(values.subSectorAcquirer.toString(), 10)}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Partner</div>
                    <div className="matches-info__data">
                      {values.partnerAcquirer}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Fee share</div>
                    <div className="matches-info__data">
                      {values.acquirerPartnerId
                        ? acquirerFeeShare ||
                          getFeeShareDetails(values.acquirerPartnerId)
                        : ''}
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>
          </Paper>

          <Paper>
            <Collapse defaultOpen title="Opportunity Details">
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Opportunity Name</div>
                    <div className="matches-info__data">
                      <NavLink
                        to={`${Routes.OPPORTUNITIES}/${values.opportunityId}`}
                      >
                        {values.opportunityName}
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Response</div>
                    <div className="matches-info__data">
                      <Select
                        name="vendorScore"
                        id="vendorScore"
                        placeholder=""
                        options={ScoreList}
                        value={
                          state.vendorScore && state.vendorScore !== 'Created'
                            ? state.vendorScore
                            : matchValueToStage(
                                arrayStageForVendor,
                                state.stage
                              )
                            ? setState({
                                ...state,
                                vendorScore: 'Yes'
                              })
                            : state.vendorScore
                        }
                        onChangeCustom={option => {
                          setState(prevState => {
                            return {
                              ...prevState,
                              vendorScore: option.value
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Description</div>
                    <div className="matches-info__data">
                      {values.opportunityRichDescription &&
                        recursivelyRenderDescription(
                          values.opportunityRichDescription
                        )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Anon Opportunity</div>
                    <div className="matches-info__data">{values.codeName}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Turnover (£)</div>
                    <div className="matches-info__data">
                      {values.financials.length && (
                        <Numeral
                          value={getDataByLastYear(
                            values.financials,
                            'fyEnding',
                            'turnover'
                          )}
                          showDashIfZero
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">EBITDA (£)</div>
                    <div className="matches-info__data">
                      {values.financials.length && (
                        <Numeral
                          value={getDataByLastYear(
                            values.financials,
                            'fyEnding',
                            'netProfit'
                          )}
                          showDashIfZero
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Region</div>
                    <div className="matches-info__data">{values.region}</div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Sector</div>
                    <div className="matches-info__data">{values.sectors()}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Partner</div>
                    <div className="matches-info__data">{values.partner}</div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className="matches-info">
                    <div className="matches-info__title">Fee share</div>
                    <div className="matches-info__data">
                      {values.businessPartnerId
                        ? businessFeeShare ||
                          getFeeShareDetails(values.businessPartnerId)
                        : ''}
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>
          </Paper>
          {data.track && data.track.length > 0 && (
            <Paper>
              <Collapse defaultOpen title="Tracker">
                <BasicTable
                  data={data.track}
                  columns={[
                    { Time: 'createdAt' },
                    { Field: 'changedid' },
                    { 'New Value': 'newvalue' }
                  ]}
                />
              </Collapse>
            </Paper>
          )}

          {data.emailHistory && data.emailHistory.length > 0 && (
            <Paper>
              <Collapse defaultOpen title="Email History">
                <TestTable sortable fixed className="mna-table" unstackable>
                  <TestTable.Header className="mna-table__header">
                    <TestTable.Row className="mna-table__row">
                      {['Recipient', 'Sent', 'Email Type', 'Actions'].map(
                        title => (
                          <TestTable.HeaderCell key={title}>
                            {title}
                          </TestTable.HeaderCell>
                        )
                      )}
                    </TestTable.Row>
                  </TestTable.Header>

                  <TestTable.Body className="mna-table__body">
                    {data.emailHistory.length > 0
                      ? emails.map((t, i) => {
                          return (
                            <tr key={i}>
                              <td>{t.recipientEmail}</td>
                              <td>{new Date(t.updatedAt).toLocaleString()}</td>
                              <td>{t.emailType}</td>
                              <td style={{ 'text-align': 'center' }}>
                                {' '}
                                <EmailModal data={t} minify={true} />
                              </td>
                            </tr>
                          );
                        })
                      : [1].map(t => {
                          return (
                            <p className="tracker_edit">
                              <i>
                                This item is yet to be edited... Updated changes
                                will appear here
                              </i>
                            </p>
                          );
                        })}
                  </TestTable.Body>
                </TestTable>
                <div style={{ display: 'flex', 'justify-content': 'flex-end' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '10em',
                      'justify-content': 'space-between',
                      'align-items': 'center'
                    }}
                  >
                    <Button
                      className="mna-pagebutton"
                      text="<"
                      onClick={() => {
                        if (emailPage > 0) {
                          setEmailPage(emailPage - 1);
                        }
                      }}
                    />
                    <span> Page {emailPage + 1}</span>
                    <Button
                      className="mna-pagebutton"
                      text=">"
                      onClick={() => setEmailPage(emailPage + 1)}
                    />
                  </div>
                </div>
              </Collapse>
            </Paper>
          )}
        </EditLayout>

        <ActionCard
          danger
          onClick={() => {
            openModals();
          }}
          buttonText="Suspend"
          title="Suspend"
          text="Once you suspend a match, all stage information will be forgotten. This cannot be undone"
        />
      </form>
      <ModalManager
        header="Approve"
        modalIsOpen={modalIsOpens}
        content={`Suspend this match : ${data.id}`}
        onAction={e => {
          // close model
          suspendMatch(data.id);
          closeModal();
        }}
        btnCancelText="Cancel"
        btnSaveText="Yes"
      />
    </>
  );
};

MatchesEdit.propTypes = {
  handleChange: PropTypes.func,
  getMatchesDetails: PropTypes.func.isRequired,
  getUserNameOptions: PropTypes.func.isRequired,
  setMatchesDetails: PropTypes.func.isRequired,

  props: PropTypes.objectOf(PropTypes.object),
  values: PropTypes.objectOf(PropTypes.any),
  openModals: PropTypes.func,
  modalIsOpens: PropTypes.bool,
  matchesIsLoading: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ])
};

export default MatchesEdit;
