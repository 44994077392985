import { connect } from 'react-redux';
import { getAcquirersData } from '../../redux/actions/acquirers/request';
import Acquirers from '../../pages/Acquirers/Acquirers';
import {
  getPartnersOptions,
  getUserNameOptions,
} from '../../redux/actions/options/optionsActions';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => ({
  data: store.acquirers.data,
  partnerId: store.globalOptions.partnerId,
  isLoading: store.acquirers.isLoading,
  optionsData: store.options,
  limit: store.globalOptions.limit,
  authUser: store.user.userAuth,
});

const mapDispatchToProps = dispatch => ({
  getAcquirersData: params => dispatch(getAcquirersData(params)),
  getPartnersOptions: () => dispatch(getPartnersOptions()),
  getUserNameOptions: params => dispatch(getUserNameOptions(params)),
  setLimitItems: data => dispatch(setLimitItems(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Acquirers);
