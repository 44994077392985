import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup, Select } from '../shared';
import { setNumberIsValueExist } from '../../utils/utils';
import StageOptions from '../../Data/Select/StageOptions';
import Filters from '../../asset/img/filters.svg';
import { Button, Icon } from '../shared';

export const title = 'Select Filters';
export const btnCancelText = 'Cancel';
export const btnSaveText = 'Apply';

const BodyMatchesModal = ({
  setFieldValue,
  values,
  acquirer,
  profiles,
  opportunities,
  contacts,
  getBusinessesOptions,
  getAcquirersOptions,
  getAcquirerCriteriaOptions,
  getContactsOptions,
  isAcquirerCriteria,
  isMatches,
  partnerId,
  authUser,
  setStatuses,
  statuses,
  selectableStatuses
}) => {
  let loadContact = false;
  if (getContactsOptions) {
    loadContact = true;
  }

  const [params, setOptions] = useState({
    acquirerId: null
  });

  useEffect(() => {
    getBusinessesOptions({ partnerId });
    getAcquirersOptions({ partnerId });
    getAcquirerCriteriaOptions({
      partnerId,
      acquirerId: params.acquirerId
    });
    loadContact &&
      getContactsOptions({
        type: ['Partner/Internal'],
        includeNullPartners: true
      });
  }, [
    partnerId,
    getAcquirerCriteriaOptions,
    getAcquirersOptions,
    getBusinessesOptions,
    getContactsOptions,
    params
  ]);

  useEffect(() => {
    StageOptions.forEach(opt => {
      opt.label = (
        <>
          <div
            onClick={() => {
              if (opt.options.length) {
                setFieldValue('stage', opt.options.map(item => item.value));
              }
            }}
            style={{ width: '100%', cursor: 'pointer' }}
          >
            <strong
              className={`group-label ${opt.value.replace(/ /g, '')}`}
              style={{ marginRight: '5px', width: '100%' }}
            >
              {opt.value}
            </strong>
          </div>
        </>
      );
    });
  }, [StageOptions]);

  const onChange = (data, e) => {
    if (!data) {
      setOptions({
        ...params,
        [e.name]: null
      });
      setFieldValue([e.name], '');
    } else {
      setOptions({
        ...params,
        [e.name]: setNumberIsValueExist(data.value)
      });
      setFieldValue([e.name], data.value);
    }
  };

  const [userFilter, setUserFilter] = useState(values.contactId);

  useEffect(() => {
    setUserFilter(values.contactId);
  }, [values.contactId]);

  return (
    <>
      <Row className="flex-content-center">
        {( isMatches) && (
          <>
            <Col xs={6}>
              <FormGroup labelText="Acquirer" name="acquirerId">
                <Select
                  isAsync
                  isClearable
                  placeholder=""
                  options={acquirer}
                  id="acquirerId"
                  name="acquirerId"
                  value={values.acquirerId}
                  onChangeCustom={onChange}
                  setFieldValue={setFieldValue}
                />
              </FormGroup>
            </Col>

          </>
        )}

        {(isAcquirerCriteria) && (
          <>
            <Col xs={12}>
              <FormGroup labelText="Acquirer" name="acquirerId">
                <Select
                  isAsync
                  isClearable
                  placeholder=""
                  options={acquirer}
                  id="acquirerId"
                  name="acquirerId"
                  value={values.acquirerId}
                  onChangeCustom={onChange}
                  setFieldValue={setFieldValue}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup labelText="Status" name="status">
                <Select
                  isAsync
                  placeholder=""
                  options={selectableStatuses}
                  id="status"
                  name="status"
                  onChangeCustom={e => {
                    setStatuses(e.value);
                    setFieldValue('status', e.value);
                  }}
                  value={statuses}
                  setFieldValue={setFieldValue}
                />
              </FormGroup>
            </Col>
          </>
        )}

        {isMatches && (
          <Col xs={6}>
            <FormGroup labelText="Acquirer Criteria" name="profileId">
              <Select
                isAsync
                isClearable
                placeholder=""
                options={profiles}
                id="profileId"
                name="profileId"
                value={values.profileId}
                setFieldValue={setFieldValue}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      {isMatches && (
        <Row>
          <Col xs={6}>
            <FormGroup labelText="Opportunities" name="businessId">
              <Select
                isAsync
                isClearable
                placeholder=""
                options={opportunities}
                id="businessId"
                name="businessId"
                value={values.businessId}
                setFieldValue={setFieldValue}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup labelText="Match Stage" name="stage">
              <Select
                value={values.stage}
                options={StageOptions}
                complex
                id="stage"
                name="stage"
                multiple
                setFieldValue={setFieldValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      {isMatches && (
        <Row>
          <Col xs={6}>
            <FormGroup labelText="User Filter" name="contactId">
              <Select
                isAsync
                isClearable
                placeholder=""
                options={contacts}
                selectValueOption
                authUser={authUser}
                id="contactId"
                name="contactId"
                value={userFilter}
                setFieldValue={setFieldValue}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
};

BodyMatchesModal.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  acquirer: PropTypes.arrayOf(PropTypes.object),
  profiles: PropTypes.arrayOf(PropTypes.object),
  opportunities: PropTypes.arrayOf(PropTypes.object),
  getBusinessesOptions: PropTypes.func,
  getAcquirersOptions: PropTypes.func,
  getAcquirerCriteriaOptions: PropTypes.func,
  isAcquirerCriteria: PropTypes.bool,
  isMatches: PropTypes.bool,
  partnerId: PropTypes.string
};

export default BodyMatchesModal;
