import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { getAcquirerCriteriaData } from '../../redux/actions/acquirerCriteria/request';
import AcquirerCriteria from '../../pages/AcquirerCriteria/AcquirerCriteria';
import {
  getAcquirerCriteriaOptions,
  getAcquirersOptions,
  getBusinessesOptions,
  getPartnersOptions
} from '../../redux/actions/options/optionsActions';
import { closeModal, openModal } from '../../redux/actions/modal/modalActions';
import { setNumberIsValueExist } from '../../utils/utils';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => {
  return {
    data: store.acquirerCriteria.data,
    isLoading: store.acquirerCriteria.isLoading,
    partnerId: store.globalOptions.partnerId,
    optionsData: store.options,
    modalIsOpen: store.modal.modalIsOpen,
    hasPartner: !!store.user.userAuth.partnerId,
    limit: store.globalOptions.limit,
    authUser: store.user.userAuth
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal()),
  getAcquirerCriteriaData: params => dispatch(getAcquirerCriteriaData(params)),
  getPartnersOptions: () => dispatch(getPartnersOptions()),
  getBusinessesOptions: params => dispatch(getBusinessesOptions(params)),
  getAcquirerCriteriaOptions: params =>
    dispatch(getAcquirerCriteriaOptions(params)),
  getAcquirersOptions: params => dispatch(getAcquirersOptions(params)),
  setLimitItems: data => dispatch(setLimitItems(data))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      return {
        partnerId: props.partnerId,
        acquirerId: '',
        businessId: '',
        profileId: ''
      };
    },
    handleSubmit: async (values, { props }) => {
      props.closeModal();

      return props.getAcquirerCriteriaData({
        partnerId: props.partnerId,
        acquirerId: setNumberIsValueExist(values.acquirerId),
        businessId: setNumberIsValueExist(values.businessId),
        profileId: setNumberIsValueExist(values.profileId),
        status: values.status
      });
    }
  })
)(AcquirerCriteria);
