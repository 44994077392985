import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import menuIco from '../../asset/img/menu-ico.svg';
import Account from './Account/Account';
import _debounce from 'lodash.debounce'
import { useAuth0 } from '../../auth/react-auth0-spa';

const SidebarNav = ({
  sidebarIsOpen,
  sidebardData,
  toggleSidebar,
  user,
  logoutWithRedirect,
  items,
  userData,
  fetchUserDetailsByAuthId,
  fetchSelectOptions,
  partner,
  onClickChangePartner,
}) => {
  const { id } = useAuth0();
  const { firstName, lastName, partnerId } = userData;
  useEffect(() => {
    const fetchData = async () => {
      await fetchUserDetailsByAuthId(id);
      await fetchSelectOptions();
    };
    fetchData();
  }, [fetchUserDetailsByAuthId, fetchSelectOptions, id]);

  return (
    <nav className="mna-sidebar__nav full-height">
        
      <ul id="sidebar" className="mna-sidebar__list full-height flex-column">
      
        <li className="mna-sidebar__item">
            <div className="burgerMenu">
            <button
                type="button"
                className="mna-btn--icon"
                id="burgerMenu"
                onClick={toggleSidebar}
            >
            <img src={menuIco} alt="" />
            </button>
            </div>

            <div className="corner_logo">
                <img src={process.env.PUBLIC_URL + '/images/MNA_wide.png'} /> 
            </div>

            <div className="corner_logo--small">
                <img src={process.env.PUBLIC_URL + '/images/MNA3.png'} /> 
            </div>
          
          
        </li>

        {sidebardData.map(item => (
          <li className="mna-sidebar__item" key={item.title}>
            {sidebarIsOpen ? (
              <NavLink
                className="mna-sidebar__link"
                to={item.path}
                activeClassName="mna-sidebar__link--active"
              >
                {item.icon}
                {item.title}
              </NavLink>
            ) : (
              <Popup
                className="mna-tooltip"
                size="tiny"
                offset="0, 10px"
                position="right center"
                content={item.title}
                trigger={
                  <NavLink
                    className="mna-sidebar__link"
                    to={item.path}
                    activeClassName="mna-sidebar__link--active"
                  >
                    {item.icon}
                  </NavLink>
                }
              />
            )}
          </li>
        ))}
      </ul>
      <Account
        partnerId={partnerId}
        user={user}
        firstName={firstName}
        lastName={lastName}
        logoutWithRedirect={logoutWithRedirect}
        items={items}
        currentPartner={partner}
        onClickChangePartner={onClickChangePartner}
      />
    </nav>
  );
};

SidebarNav.propTypes = {
  sidebarIsOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  logoutWithRedirect: PropTypes.func,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  ),
};

export default SidebarNav;
