export default [
  {
    key: 'Aerospace & Defence',
    value: 'Aerospace & Defence',
    label: 'Aerospace & Defence',
  },
  {
    key: 'Agriculture',
    value: 'Agriculture',
    label: 'Agriculture',
  },
  {
    key: 'Automobiles & Parts',
    value: 'Automobiles & Parts',
    label: 'Automobiles & Parts',
  },
  {
    key: 'Banking',
    value: 'Banking',
    label: 'Banking',
  },
  {
    key: 'Building Products',
    value: 'Building Products',
    label: 'Building Products',
  },
  {
    key: 'Chemicals',
    value: 'Chemicals',
    label: 'Chemicals',
  },
  {
    key: 'Commercial Property',
    value: 'Commercial Property',
    label: 'Commercial Property',
  },
  {
    key: 'Construction',
    value: 'Construction',
    label: 'Construction',
  },
  {
    key: 'Consumer',
    value: 'Consumer',
    label: 'Consumer',
  },
  {
    key: 'Distribution',
    value: 'Distribution',
    label: 'Distribution',
  },
  {
    key: 'Engineering',
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    key: 'Financial',
    value: 'Financial',
    label: 'Financial',
  },
  {
    key: 'Food & Beverages',
    value: 'Food & Beverages',
    label: 'Food & Beverages',
  },
  {
    key: 'Franchises',
    value: 'Franchises',
    label: 'Franchises',
  },
  {
    key: 'Health/Pharma',
    value: 'Health/Pharma',
    label: 'Health/Pharma',
  },
  {
    key: 'Healthcare',
    value: 'Healthcare',
    label: 'Healthcare',
  },
  {
    key: 'ICT',
    value: 'ICT',
    label: 'ICT',
  },
  {
    key: 'Industrial',
    value: 'Industrial',
    label: 'Industrial',
  },
  {
    key: 'Legal',
    value: 'Legal',
    label: 'Legal',
  },
  {
    key: 'Leisure',
    value: 'Leisure',
    label: 'Leisure',
  },
  {
    key: 'Manufacturing',
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    key: 'Marketing Communications',
    value: 'Marketing Communications',
    label: 'Marketing Communications',
  },
  {
    key: 'Media',
    value: 'Media',
    label: 'Media',
  },
  {
    key: 'Mining',
    value: 'Mining',
    label: 'Mining',
  },
  {
    key: 'Oil & Gas',
    value: 'Oil & Gas',
    label: 'Oil & Gas',
  },

  {
    key: 'Packaging',
    value: 'Packaging',
    label: 'Packaging',
  },

  {
    key: 'Plastics',
    value: 'Plastics',
    label: 'Plastics',
  },

  {
    key: 'Print',
    value: 'Print',
    label: 'Print',
  },

  {
    key: 'Real Estate',
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    key: 'Recruitment',
    value: 'Recruitment',
    label: 'Recruitment',
  },
  {
    key: 'Retail',
    value: 'Retail',
    label: 'Retail',
  },
  {
    key: 'Support Services',
    value: 'Support Services',
    label: 'Support Services',
  },
  {
    key: 'Soft FM',
    value: 'Soft FM',
    label: 'Soft FM',
  },
  {
    key: 'Tobacco',
    value: 'Tobacco',
    label: 'Tobacco',
  },
  {
    key: 'Transport',
    value: 'Transport',
    label: 'Transport',
  },
  {
    key: 'Travel',
    value: 'Travel',
    label: 'Travel',
  },
  {
    key: 'Utilities',
    value: 'Utilities',
    label: 'Utilities',
  },
];
