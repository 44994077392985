import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

const TypeOpportunity = ({ options, name, values, column, isReadable }) => {
  return (
    <div className={`type-list-options${column ? ' column' : ''}`}>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {options.map((option, i) => (
              <div key={`item-${i}`} className="toggle-form-group">
                <input
                  id={option.name}
                  name={option.name}
                  type="checkbox"
                  value={option.text}
                  checked={values.includes(option.text)}
                  disabled={isReadable}
                  onChange={e => {
                    if (e.target.checked) arrayHelpers.push(option.text);
                    else {
                      const idx = values.indexOf(option.text);
                      arrayHelpers.remove(idx);
                    }
                  }}
                  className="toggle-form-group__input"
                />
                <label
                  className="toggle-form-group__label"
                  htmlFor={option.name}
                >
                  {option.text}
                </label>
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};

TypeOpportunity.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.arrayOf(PropTypes.string),
  column: PropTypes.bool,
  isReadable: PropTypes.bool
};

export default TypeOpportunity;
