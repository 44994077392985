import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import arrowUser from '../../../asset/img/two-arrows-ico.svg';
import Content from './AccountPopoverContent';
import UserIco from '../../../asset/img/UserIco';
import { getCorrectAvatarInitials } from '../../../utils/utils';

const Account = ({
  user,
  logoutWithRedirect,
  items,
  firstName,
  lastName,
  currentPartner,
  onClickChangePartner,
  partnerId,
}) => {
  const [isOpen, setIsMenuOpened] = React.useState(false);
  const handleClose = () => {
    setIsMenuOpened(false);
  };
  const getPartnerName = (arr, id) => {
    const defaultValue = 'All Data';
    if (!id || !arr) return defaultValue;
    const found = arr.find(i => i.value === id);
    return found ? found.label : defaultValue;
  };
  useEffect(() => {
    /*
    const partnerIdString = partnerId ? String(partnerId) : null
    onClickChangePartner({
      partnerId: partnerIdString,
      partnerName: getPartnerName(items, partnerId),
    });
    */
  }, [partnerId, onClickChangePartner, items]);

  const initials = getCorrectAvatarInitials({ firstName, lastName });

  return (
    <>
      <div className="mna-sidebar__account">
        <Popup
          open={isOpen}
          wide
          onClose={handleClose}
          onOpen={() => setIsMenuOpened(true)}
          on="click"
          trigger={
            <button type="button" className="mna-sidebar__account-btn">
              {initials ? (
                <div
                  alt={user.nickname}
                  className="mna-sidebar__ico mna-sidebar__account-ico"
                >
                {initials}
                </div>
              ) : (
                <UserIco className="mna-sidebar__ico mna-sidebar__account-ico" />
              )}
              {currentPartner.partnerName}
              <img
                className="mna-sidebar__account-arrows ml-auto"
                src={arrowUser}
                alt="alt"
              />
            </button>
          }
          content={
            <Content
              partnerId={partnerId}
              onClose={handleClose}
              user={user}
              items={items}
              firstName={firstName}
              lastName={lastName}
              logout={logoutWithRedirect}
              className="mna-popover__content"
              currentPartner={currentPartner}
              onClickChangePartner={onClickChangePartner}
            />
          }
        />
      </div>
    </>
  );
};

Account.propTypes = {
  logoutWithRedirect: PropTypes.func.isRequired,
  onClickChangePartner: PropTypes.func.isRequired,
  currentPartner: PropTypes.objectOf(PropTypes.any),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  partnerId: PropTypes.number,
};

export default Account;
