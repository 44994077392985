import { toast } from 'react-toastify';
import {
  OPPORTUNITY_IS_LOADING,
  OPPORTUNITY_SUCCESS,
  OPPORTUNITY_ERROR,
  OPPORTUNITY_POST_DATA,
  OPPORTUNITY_POST_DATA_SUCCESS,
  OPPORTUNITY_REPORT_IS_LOADING,
  OPPORTUNITY_REPORT_SUCCESS,
  OPPORTUNITY_DELETE_USER
} from '../types';
import { Matches, Opportunity, Trackers } from '../../../api/Api';
import { closeModal } from '../modal/modalActions';
import Routes from '../../../constants/routes';
import { settingsToast } from '../../../utils/settings';
import removeNullValues from '../../../utils/utils';
import {
  arraysEqual,
  formatString,
  columnToReadable,
  isDefined,
  compareDiff
} from '../../../utils/utils';
import { deleteMatches } from '../matches/request';

export function opportunityHasError(payload) {
  return {
    type: OPPORTUNITY_ERROR,
    hasError: payload
  };
}

export function opportunitySuccess(payload) {
  return {
    type: OPPORTUNITY_SUCCESS,
    data: payload.data
  };
}

export function opportunityReportSuccess(payload) {
  return {
    type: OPPORTUNITY_REPORT_SUCCESS,
    data: payload.data
  };
}

export function opportunityIsLoading() {
  return {
    type: OPPORTUNITY_IS_LOADING
  };
}

export const opportunityPostData = () => ({
  type: OPPORTUNITY_POST_DATA
});

export function opportunityReportIsLoading() {
  return {
    type: OPPORTUNITY_REPORT_IS_LOADING
  };
}

export const opportunityPostDataSuccess = payload => ({
  type: OPPORTUNITY_POST_DATA_SUCCESS,
  postResponse: payload
});

export const opportunityDelete = payload => ({
  type: OPPORTUNITY_DELETE_USER,
  deletedResponse: payload
});

export function getOpportunitiesData(params) {
  return async dispatch => {
    dispatch(opportunityIsLoading());
    try {
      params.hidden = true;
      const data = await Opportunity.fetch(params);
      dispatch(opportunitySuccess(data));
    } catch (e) {
      dispatch(opportunityHasError(e));
    }
  };
}

export function getOpportunitiesDetails(id) {
  return async dispatch => {
    dispatch(opportunityIsLoading());
    try {
      const data = await Opportunity.fetchDetails(id);
      let track = await Trackers.getTrackerByAssociatedId(
        data.data.id,
        'opportunities'
      );
      let report = await Opportunity.fetchReport(id);
      data.data.track = track;
      data.data.report = report.data;
      dispatch(opportunitySuccess(data));
    } catch (e) {
      dispatch(opportunityHasError(e));
    }
  };
}

export function getOpportunitiesReport(id) {
  return async dispatch => {
    dispatch(opportunityReportIsLoading());
    try {
      const data = await Opportunity.fetchReport(id);
      dispatch(opportunityReportSuccess(data));
    } catch (e) {
      dispatch(opportunityHasError(e));
    }
  };
}

export default async function createOpportunities(dispatch, data, props) {
  try {
    const response = await Opportunity.create(data);
    dispatch(opportunityPostDataSuccess(response));
    toast.success('Saved', settingsToast);
    dispatch(() => props.history.push(`${Routes.OPPORTUNITIES}`));
  } catch (e) {
    console.error(e);
    toast.error(
      'Something went wrong with creating, check form fields or internet connection',
      settingsToast
    );

    if (
      e.response.data.errorMessage.response &&
      e.response.data.errorMessage.response.name ===
        'SequelizeUniqueConstraintError'
    ) {
      return dispatch(opportunityHasError({ msg: 'Email already exists' }));
    }
    dispatch(opportunityHasError(e));
  }
}

async function updateOpportunities(dispatch, data, props) {
  try {
    const track_keys = [
      'notes',
      'businessName',
      'areaCode',
      'clearNames',
      'codeName',
      'companyNumber',
      'description',
      'fee',
      'financialsVerified',
      'forSale',
      'idVerified',
      'inExclusivity',
      'managed',
      'overseasBuyers',
      'partnerId',
      'peBuyers',
      'remarketedDate',
      'sector',
      'sensitive',
      'richDescription',
      'shareholdingVerified',
      'subSector'
    ];
    const prev_data = await Opportunity.fetchDetails(data.businesses[0].id);
    var tracked_changes = [];
    let old_data = prev_data.data;
    let new_data = data.businesses[0];
    let obj_diff = compareDiff(old_data, new_data);

    Object.keys(obj_diff).forEach(function(e) {
      if (!['richDescription'].includes(e)) {
        let item_id = new_data.id;
        let key = e;
        let new_value = obj_diff[e];
        tracked_changes.push({
          key: key,
          newvalue: formatString(new_value),
          associatedid: item_id
        });
      }
    });
    for (const t of tracked_changes) {
      let payload = {
        user: 1,
        table: 'opportunities',
        changedid: columnToReadable(t.key),
        newvalue: formatString(t.newvalue),
        associatedid: formatString(t.associatedid)
      };
      await Trackers.create(payload);
    }
    const response = await Opportunity.update(data);
    dispatch(opportunityPostDataSuccess(response));
    toast.success('Saved', settingsToast);
    dispatch(() => props.history.push(`${Routes.OPPORTUNITIES}`));
  } catch (e) {
    console.error(e);
    toast.error(
      'Something went wrong with updating, check form fields or internet connection',
      settingsToast
    );
    dispatch(opportunityHasError(e));
  }
}

export function deleteOpportunities(data) {
  return async dispatch => {
    dispatch(opportunityIsLoading());
    try {
      const full_data = await Opportunity.fetchDetails(data.businesses['0']);
      let parsed_update = {
        businesses: [{ id: full_data['data']['id'], hidden: true }]
      };
      // dispatch(deleteMatches({ opportunities: full_data['data']['id'] }));
      const response = await Opportunity.update(
        removeNullValues(parsed_update)
      );

      // No longer delete any records
      //const response = await Opportunity.del(data);
      let payload = {
        user: 1,
        table: 'opportunities',
        trackertype: 'deleted',
        backupdata: JSON.stringify(full_data)
      };
      await Trackers.create(payload);

      dispatch(opportunityDelete(response));
      dispatch(closeModal());
      toast.success('Deleted', settingsToast);
    } catch (e) {
      console.error(e);
      dispatch(opportunityHasError(e));
    }
  };
}

export function requestOpportunitiesData(action, data, props) {
  return async dispatch => {
    dispatch(opportunityIsLoading());

    if (action === 'POST') {
      await createOpportunities(dispatch, data, props);
      return;
    }

    if (action === 'UPDATE') {
      await updateOpportunities(dispatch, data, props);
    }
  };
}
