import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import get from 'lodash/get';
import Contacts from '../../pages/Contacts/Contacts';
import { openModal, closeModal } from '../../redux/actions/modal/modalActions';
import { getValidationSchema } from './ValidationSchema';
import {
  createContact,
  getContactsDetails,
  getContactsData,
  updateContact,
  migrateContact,
  deleteContact,
  tableOptions
} from '../../redux/actions/contacts/contactsActions';
import { createMode, editMode } from '../../redux/actions/mode/modeActions';
import { formatDate } from '../../utils/utils';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => {
  return {
    data: store.contacts.data,
    allData: store.contacts.allData,
    partnerId: store.globalOptions.partnerId,
    isCreate: store.editMode.isCreate,
    currentContact: store.contacts.currentContact,
    contactsLoading: store.contacts.isLoading,
    modalIsOpen: store.modal.modalIsOpen,
    hasError: store.contacts.hasError,
    userAuth: store.user.userAuth,
    sortParams: store.contacts.tableView,
    hasPartner: !!store.user.userAuth.partnerId,
    limit: store.globalOptions.limit
  };
};

const mapDispatchToProps = (dispatch, { props }) => ({
  getContactsData: params => dispatch(getContactsData(params)),
  getContactsDetails: id => dispatch(getContactsDetails(id)),
  openModal: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal()),
  updateContact: (id, data, params) =>
    dispatch(updateContact(id, data, params)),
  migrateContact: (from_id, to_id) => {
    console.log(`migrateContact: ${from_id} ${to_id}`);
    return dispatch(migrateContact(from_id, to_id));
  },
  createContact: (data, params) => dispatch(createContact(data, params)),
  createMode: () => dispatch(createMode()),
  editMode: () => dispatch(editMode()),
  deleteContact: (id, data) => dispatch(deleteContact(id, data)),
  tableOptions: data => dispatch(tableOptions(data)),
  setLimitItems: data => dispatch(setLimitItems(data))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { currentContact, isCreate } = props;
      if (isCreate) {
        return {
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          type: [],
          partnerId: null,
          phone: null,
          dateOfBirth: '',
          langcliffeUserId: null,
          password: '',
          areaCode: '+44',
          status: 'active',
        };
      }
      return {
        id: get(currentContact, 'id', ''),
        firstName: get(currentContact, 'firstName', ''),
        lastName: get(currentContact, 'lastName', ''),
        email: get(currentContact, 'email', ''),
        company: get(currentContact, 'company', ''),
        type: get(currentContact, 'type', []),
        partnerId: get(currentContact, 'partnerId', null),
        phone: get(currentContact, 'phone', ''),
        dateOfBirth:
          formatDate(currentContact && currentContact.dateOfBirth) || '',
        langcliffeUserId: get(currentContact, 'langcliffeUserId', null),
        password: get(currentContact, 'password', ''),
        areaCode: get(currentContact, 'areaCode', ''),
        status: get(currentContact, 'status', ''),
        types: null
      };
    },
    handleSubmit: async (values, { props }) => {
      const { id, dateOfBirth, password, type, types, ...rest } = values;

      const formattedDateOfBirth = dateOfBirth
        ? new Date(dateOfBirth).toISOString()
        : null;

      // Create a new user
      if (props.isCreate) {
        let obj = {
          dateOfBirth: formattedDateOfBirth,
          password,
          type,
          ...rest
        };
        if (type.includes('Partner/Internal')) {
          obj = { ...obj, password };
        }
        return props.createContact(obj, props.sortParams);
      }

      // Update the user
      const data = {
        dateOfBirth: formattedDateOfBirth,
        type,
        //password,
        ...rest
      };

      return props.updateContact(id, data, props.sortParams);
    },
    validationSchema: props => {
      return getValidationSchema(props);
    }
  })
)(Contacts);
