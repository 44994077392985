import { toast } from 'react-toastify';
import { Acquirers, Trackers } from '../../../api/Api';
import {
  acquirersHasError,
  acquirersIsLoading,
  acquirersSuccess,
  acquirersRequestDataSuccess,
  acquirersDelete
} from './acquirersActions';
import Routes from '../../../constants/routes';
import { closeModal } from '../modal/modalActions';
import { settingsToast } from '../../../utils/settings';
import {
  arraysEqual,
  formatString,
  columnToReadable,
  isDefined,
  compareDiff
} from '../../../utils/utils';
import { deleteMatches } from '../matches/request';

// GET BASE DATA AcquirersData for table
export function getAcquirersData(params) {
  return async dispatch => {
    dispatch(acquirersIsLoading());
    try {
      //params.hidden = true
      const data = await Acquirers.fetch(params);
      dispatch(acquirersSuccess(data));
    } catch (e) {
      dispatch(acquirersHasError(e));
    }
  };
}

export function getAcquirersDetails(id) {
  return async dispatch => {
    dispatch(acquirersIsLoading());
    try {
      const data = await Acquirers.fetchDetails(id);
      let track = await Trackers.getTrackerByAssociatedId(
        data.data.id,
        'acquirers'
      );
      let report = await Acquirers.fetchReport(id);
      data.data.report = report.data;
      data.data.track = track.data;
      dispatch(acquirersSuccess(data));
    } catch (e) {
      dispatch(acquirersHasError(e));
    }
  };
}

// CREATE ACQUIRERS
export function createAcquirers(action, data, props) {
  return async dispatch => {
    dispatch(acquirersIsLoading());
    try {
      if (action == 'update') {
        const prev_data = await Acquirers.fetchDetails(data.aсquirers[0].id);
        var tracked_changes = [];
        let old_data = prev_data.data;
        let new_data = data.aсquirers[0];
        let obj_diff = compareDiff(old_data, new_data);
        Object.keys(obj_diff).forEach(function(e) {
          if (!['richDescription'].includes(e)) {
            let item_id = new_data.id;
            let key = e;
            let new_value = obj_diff[e];
            tracked_changes.push({
              key: key,
              newvalue: formatString(new_value),
              associatedid: item_id
            });
          }
        });
        for (const t of tracked_changes) {
          let payload = {
            user: 1,
            table: 'acquirers',
            changedid: columnToReadable(t.key),
            newvalue: formatString(t.newvalue),
            associatedid: formatString(t.associatedid)
          };
          await Trackers.create(payload);
        }
      }
      const response = await Acquirers[action](data);
      dispatch(acquirersRequestDataSuccess(response));
      toast.success(`Saved`, settingsToast);
      dispatch(() => props.history.push(`${Routes.ACQUIRERS}`));
    } catch (e) {
      console.log(e);
      if (
        e.response.data.errorMessage.response &&
        e.response.data.errorMessage.response.name ===
          'SequelizeUniqueConstraintError'
      ) {
        return toast.error(
          'An Acquirer already exists with this email - please use a unique email address',
          settingsToast
        );
      }
      toast.error(
        'Something went wrong, check form fields or internet connection',
        settingsToast
      );
      dispatch(acquirersHasError(e));
    }
  };
}

// DELETE
/*
export function deleteAcquirers(data, props) {
  return async dispatch => {
    dispatch(acquirersIsLoading());
    try {
      const response = await Acquirers.del(data);
      dispatch(acquirersDelete(response));
      dispatch(closeModal());
      toast.success('Deleted', settingsToast);
      dispatch(() => props.history.push(`${Routes.ACQUIRERS}`));
    } catch (e) {
      console.error(e);
      dispatch(acquirersHasError(e));
    }
  };
*/

export function deleteAcquirers(data) {
  return async dispatch => {
    dispatch(acquirersIsLoading());
    try {
      let id = data.acquirers['0'];
      const full_data = await Acquirers.fetchDetails(id);
      dispatch(deleteMatches({ acquirers: full_data['data']['id'] }));
      let parsed_update = {
        acquirers: [{ id: full_data['data']['id'], hidden: true }]
      };
      const response = await Acquirers.hideAcquirer(parsed_update);
      let payload = {
        user: 1,
        table: 'acquirers',
        trackertype: 'deleted',
        backupdata: JSON.stringify(full_data)
      };
      await Trackers.create(payload);

      dispatch(acquirersDelete(response));
      dispatch(closeModal());
      toast.success('Deleted', settingsToast);
    } catch (e) {
      console.error(e);
      dispatch(acquirersHasError(e));
    }
  };
}
