import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import { Loader } from 'semantic-ui-react';
import { useAuth0 } from '../../auth/react-auth0-spa';
import { isNumExist, setPartnerContact } from '../../utils/utils';
import EditLayout from '../../components/EditLayout/EditLayout';
import {
  ActionCard,
  Collapse,
  FormGroup,
  Input,
  Paper,
  Switcher,
  Textarea,
  Select
} from '../../components/shared';
import { typeRadioOptions } from './data';
import BasicTable from '../../components/Table/BasicTable';

import Report from '../../components/Report/Report';

import Type from '../../components/Type/Type';
import ModalManager from '../../components/Modal/Modal';
import * as DeleteModalData from '../../components/Modal/DeleteModalData';
import Routes from '../../constants/routes';
import ContactData from '../../components/ContactData/ContactData';
import Table from '../../components/shared/Table/Table';
import RichEditor from '../../components/RichText/RichEditor';

const AcquirersCreate = ({
  isLoading,
  handleChange,
  touched,
  setFieldValue,
  setFieldTouched,
  props,
  errors,
  handleSubmit,
  values,
  optionsData,
  deleteAcquirers,
  isCreate,
  openModal,
  modalIsOpen,
  data,
  getUserNameOptions,
  getAcquirersDetails,
  getContactsOptions,
  getPartnersOptions,
  partnerId,
  additionalOptions,
  authUser,
  hasPartner,
  createContact,
  getMatchesData,
  matchesData,
}) => {
  const { loading } = useAuth0();
  const { id } = useParams();
  
  const { store } = useContext(ReactReduxContext);
  let a = store.getState();
  const [PEBuyer, setPEBuyer] = useState(values.peBuyer);

  const initialValue = [
    {
      type: 'paragraph',
      default: true,
      children: [{ text: '' }]
    }
  ];

  const [report, setReport] = useState(null);

  useEffect(() => {
    if (!report) {
      setReport(data.report);
    }
  }, [data.report]);

  const [input, setInput] = useState(initialValue);

  const [feeShareDefaultText, setFeeShareDefaultText] = useState('');

  useEffect(() => {
    if (values.feesEnabled && !values.fees) {
      if (optionsData.partners && values.partnerId) {
        for (let i = 0; i < optionsData.partners.length; i++) {
          if (optionsData.partners[i].key == values.partnerId) {
            if (optionsData.partners[i].label == 'Unloq') {
              setFeeShareDefaultText('100% Fees to Unloq');
            }
            if (optionsData.partners[i].label == 'Langcliffe') {
              setFeeShareDefaultText(
                '10% of monthly fees and 0.5% of consideration'
              );
            }
            if (optionsData.partners[i].label == 'Anderson Moore') {
              setFeeShareDefaultText('15% of all fees');
            }
            if (optionsData.partners[i].label == 'TML') {
              setFeeShareDefaultText('30% of success fees');
            }
          }
        }
      }
    }

    if (!values.feesEnabled && !values.fees) {
      setFeeShareDefaultText('');
    }
  }, [values.feesEnabled, values.partnerId]);

  const getFeeShare = () => {
    if (values && values.fee) {
      return values.fee;
    }
  };

  useEffect(() => {
    if (values.richDescription) {
      setInput(values.richDescription);
    }
  }, [values.richDescription]);

  useEffect(() => {
    if (values.type !== 'Private equity') {
      values.peBuyer = false;
      setFieldValue('peBuyer', false);
    } else {
      values.peBuyer = true;
      setFieldValue('peBuyer', true);
    }
  }, [values.type]);

  useEffect(() => {
    setPEBuyer(values.peBuyer);

    if (values.peBuyer === true) {
      values.type = 'Private equity';
    } else {
      values.type = null;
    }
  }, [values.peBuyer]);

  useEffect(() => {
    async function fetchAdditionalOptions() {
      await additionalOptions({
        id: values.partnerId,
        managed: values.managed
      });
      await setFieldValue();
    }

    if (isCreate) {
      fetchAdditionalOptions();
    }
    // eslint-disable-next-line
  }, [values.managed, values.partnerId]);

  useEffect(() => {
    async function fetchOptions() {
      if(a.user.userAuth.partnerId){
      Promise.all([
        getPartnersOptions(),
        getUserNameOptions({
          type: ['Acquirer', 'Opportunity', 'Partner/Internal'],
          partnerId: a.user.userAuth.partnerId,
          includeNullPartners: false
        })
      ]);
    }}
    // edit page by user id
    async function fetchDada() {
      await getAcquirersDetails(id);
    }
    if (isNumExist(id) && id) {
      fetchDada();
    }
    fetchOptions();
    // eslint-disable-next-line
  }, [id, values.partnerId, partnerId,a.user.userAuth.partnerId]);

  const deleteSection = async () => {
    await deleteAcquirers(
      {
        acquirers: [parseInt(id, 10)]
      },
      props
    );
    props.history.goBack();
  };

  const customOptions = [
    ...(optionsData.users || []),
    {
      value: 'custom',
      custom: true,
      link: Routes.CONTACTS,
      title: 'New contact'
    }
  ];

  const fetchAcquirerMatches = async () => {
    await getMatchesData({
      acquirerId: id,
    });

    console.log('MAtches Data', matchesData);

  };

  return (
    <form className="form-wrap">
      <EditLayout
        handleChange={handleChange}
        objWithData={values.notes}
        cancelText="Cancel"
        saveText="Save"
        isSubmit={false}
        exportMatches={true}
        pageHeaderTitle="Acquirers"
        handleSubmit={handleSubmit}
        history={props.history}
        location={props.location}
        pathBack={Routes.ACQUIRERS}
        fetchAcquirerMatches={fetchAcquirerMatches}
        matchesData={matchesData}
      >
        <Loader active={loading || isLoading} />

        <Paper>
          <Collapse defaultOpen title="Details">
            <Row className="margin-bottom">
              <Col xs={12} sm={12} md={12} lg={12} className="flex flex-wrap">
                <FormGroup labelText="managed" name="managed" inline>
                  <Switcher
                    onChange={handleChange}
                    name="managed"
                    id="managed"
                    checked={values.managed}
                  />
                </FormGroup>

                <FormGroup labelText="PE Buyer" name="peBuyer" inline>
                  <Switcher
                    onChange={handleChange}
                    name="peBuyer"
                    id="peBuyer"
                    checked={values.peBuyer}
                  />
                </FormGroup>
                <FormGroup labelText="Id Verified" name="idVerified" inline>
                  <Switcher
                    onChange={handleChange}
                    name="idVerified"
                    id="idVerified"
                    checked={values.idVerified}
                  />
                </FormGroup>
                <FormGroup labelText="Fees" name="feesEnabled" inline>
                  <Switcher
                    onChange={handleChange}
                    name="feesEnabled"
                    id="feesEnabled"
                    checked={values.feesEnabled}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup labelText="Company Name" name="companyName">
                  <Input
                    name="companyName"
                    onChange={handleChange}
                    value={values.companyName || ''}
                    maxLength="1000"
                  />
                  <p className="form-group__error">
                    {touched.companyName && errors.companyName}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup labelText="Anon Acquirer" name="anonDescription">
                  <Input
                    name="anonDescription"
                    onChange={handleChange}
                    value={values.anonDescription}
                    maxLength="1000"
                  />
                  <p className="form-group__error">
                    {touched.anonDescription && errors.anonDescription}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup labelText="company number" name="companyNumber">
                  <Input
                    name="companyNumber"
                    type="text"
                    value={
                      values.companyNumber && values.companyNumber.toString()
                    }
                    onKeyUp={() => setFieldTouched('companyNumber', true)}
                    onChange={handleChange}
                  />
                  <p className="form-group__error">
                    {touched.companyNumber && errors.companyNumber}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <FormGroup labelText="partner" name="partnerId">
                  <Select
                    name="partnerId"
                    id="partnerId"
                    options={
                      hasPartner
                        ? [
                            {
                              label: authUser.partner.name,
                              key: authUser.partner.name,
                              value: authUser.partner.id.toString()
                            }
                          ]
                        : optionsData.partners
                    }
                    value={
                      hasPartner && isCreate
                        ? authUser.partner.id.toString()
                        : values.partnerId.toString()
                    }
                    setFieldValue={setFieldValue}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup labelText="Description" name="description">
                  <RichEditor
                    value={input}
                    setValue={setInput}
                    name="richDescription"
                    setFieldValue={setFieldValue}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </Paper>
        <Paper>
          <Collapse title="Contacts" defaultOpen>
            <ContactData
              errors={errors}
              touched={touched}
              contacts={values.contactRoles}
              contactsOption={customOptions}
              onChange={handleChange}
              defaultContact={false}
              setFieldValue={setFieldValue}
              isCreate={isCreate}
              deletedContactsData={values.deletedContactsData}
              isManaged={values.managed}
              authUser={authUser}
              createContact={createContact}
              getUserNameOptions={getUserNameOptions}
              getContactsOptions={getContactsOptions}
              userType={'Acquirer'}
            />
          </Collapse>
        </Paper>

        <Paper>
          <Collapse defaultOpen title="Fee Share">
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Textarea
                    id="feeShare"
                    name="fee"
                    partnerId={values.partnerId}
                    value={getFeeShare() ? getFeeShare() : feeShareDefaultText}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </Paper>

        <Paper>
          <Collapse title="Type">
            <Type
              options={typeRadioOptions}
              values={values.type}
              onChange={handleChange}
              name="type"
            />
          </Collapse>
        </Paper>

        {report && (
          <Paper>
            <Collapse title="Report">
              {report && <Report data={report} />}
            </Collapse>
          </Paper>
        )}

        {data.track && data.track.length > 0 && (
          <Paper>
            <Collapse title="History">
              <BasicTable
                data={data.track}
                columns={[
                  { Time: 'createdAt' },
                  { Field: 'changedid' },
                  { 'New Value': 'newvalue' }
                ]}
              />
            </Collapse>
          </Paper>
        )}
      </EditLayout>
      <ActionCard
        danger
        onClick={() => openModal()}
        buttonText="Suspend"
        title="Suspend"
        text="Once you delete an Acquirer, all their Acquirer Criteria and related Matches will also be deleted. This cannot be undone"
      />
      <ModalManager
        header={DeleteModalData.title}
        modalIsOpen={modalIsOpen}
        content={DeleteModalData.bodyText}
        onAction={deleteSection}
        btnCancelText={DeleteModalData.btnCancelText}
        btnSaveText={DeleteModalData.btnSaveText}
      />
    </form>
  );
};
AcquirersCreate.propTypes = {
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  touched: PropTypes.objectOf(PropTypes.any),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  props: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  postResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  acquirersLoading: PropTypes.bool,
  optionsData: PropTypes.objectOf(PropTypes.array),
  deleteAcquirers: PropTypes.func,
  isCreate: PropTypes.bool,
  openModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  getUserNameOptions: PropTypes.func,
  getAcquirersDetails: PropTypes.func,
  getPartnersOptions: PropTypes.func,
  partnerId: PropTypes.string,
  additionalOptions: PropTypes.func,
  authUser: PropTypes.objectOf(PropTypes.any),
  hasPartner: PropTypes.bool
};

export default AcquirersCreate;
