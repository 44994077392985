import {
  ACQUIRER_CRITERIA_IS_LOADING,
  ACQUIRER_CRITERIA_SUCCESS,
  ACQUIRER_CRITERIA_ERROR,
  ACQUIRER_CRITERIA_REMOVE,
  ACQUIRER_CRITERIA_CLONE,
  ACQUIRER_CRITERIA_POST_DATA_SUCCESS,
} from '../types';

export const acquirerCriteriaIsLoading = () => ({
  type: ACQUIRER_CRITERIA_IS_LOADING,
});

export const acquirerCriteriaSuccess = payload => ({
  type: ACQUIRER_CRITERIA_SUCCESS,
  data: payload.data,
});

export const acquirerCriteriaHasError = payload => ({
  type: ACQUIRER_CRITERIA_ERROR,
  hasError: payload,
});

export const acquirerCriteriaDelete = payload => ({
  type: ACQUIRER_CRITERIA_REMOVE,
  deletedResponse: payload,
});

export const acquirerCriteriaClone = payload => ({
  type: ACQUIRER_CRITERIA_CLONE,
  cloneResponse: payload,
});

// CREATE / UPDATE data
export const acquirerCriteriaRequestDataSuccess = payload => ({
  type: ACQUIRER_CRITERIA_POST_DATA_SUCCESS,
  postResponse: payload,
});
