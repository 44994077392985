import * as Yup from 'yup';

export const getValidationSchema = ({ isCreate }) => {
  const ValidationSchema = Yup.object().shape({
    richDescription: Yup.string(),
    financials: Yup.array()
      .of(
        Yup.object().shape(
          {
            fyEnding: Yup.number().when(['netProfit', 'turnover'], {
              is: (netProfit, turnover) => netProfit || turnover,
              then: Yup.number().required(),
              otherwise: Yup.number().notRequired(),
            }),
            turnover: Yup.number().when(['fyEnding', 'netProfit'], {
              is: (fyEnding, netProfit) => fyEnding || netProfit,
              then: Yup.number().required(),
              otherwise: Yup.number().notRequired(),
            }),
            netProfit: Yup.number().when(['fyEnding', 'turnover'], {
              is: (fyEnding, turnover) => fyEnding || turnover,
              then: Yup.number().required(),
              otherwise: Yup.number().notRequired(),
            }),
            projectedNumbers: Yup.boolean().when(['netProfit', 'turnover'], {
              is: (fyEnding, turnover) => fyEnding || turnover,
              then: Yup.boolean().required(),
              otherwise: Yup.boolean().notRequired(),
            }),
          },
          [
            ['netProfit', 'turnover'],
            ['fyEnding', 'netProfit'],
            ['fyEnding', 'turnover'],
            ['fyEnding', 'turnover'],
          ]
        )
      )
      .notRequired(),
    contactRoles: Yup.array()
      .of(
        Yup.object().shape(
          {
            role: Yup.string().when('contactId', {
              is: contactId => contactId,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
            contactId: Yup.string().when('role', {
              is: role => role,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
          },
          ['contactId', 'role']
        )
      )
      .test('findRole', 'Required', function(value) {
        function checkedRole(role) {
          return value && value.length && value.some(o => o.role === role);
        }

        if (!checkedRole('Owner') && !checkedRole('Partner Contact')) {
          return false;
        }
        if (!this.parent.managed && !checkedRole('Email Recipient')) {
          return false;
        }

        return true;
      })
      .required('Required'),
  });
  return ValidationSchema;
};
