import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({
  text,
  basic,
  success,
  medium,
  successWithoutBg,
  dangerWithoutBg,
  danger,
  icon,
  transparent,
  children,
  type,
  className,
  small,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={clsx({
        'mna-btn': basic,
        'mna-btn--success': success,
        'mna-btn--medium': medium,
        'mna-btn--success-without-bg': successWithoutBg,
        'mna-btn--danger-without-bg': dangerWithoutBg,
        'mna-btn--danger': danger,
        'mna-btn--icon': icon,
        'mna-btn--transparent': transparent,
        'mna-btn--small': small,
        [`${className}`]: className,
      })}
      {...rest}
    >
      {text || children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  basic: PropTypes.bool,
  type: PropTypes.string,
  success: PropTypes.bool,
  medium: PropTypes.bool,
  successWithoutBg: PropTypes.bool,
  dangerWithoutBg: PropTypes.bool,
  transparent: PropTypes.bool,
  danger: PropTypes.bool,
  icon: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.any,
};

export default Button;
