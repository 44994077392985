const createBaseReducer = (name, initialState) => (
  state = initialState,
  action
) => {
  const actionType = name.toUpperCase();
  switch (action.type) {
    case `${actionType}_ERROR`:
      return { ...state, isLoading: false, hasError: action.hasError };

    case `${actionType}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };

    case `${actionType}_DETAILS`:
      return {
        ...state,
        data: action.data,
      };

    case `${actionType}_SUCCESS_EDIT`:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };

    case `${actionType}_IS_LOADING`:
      return {
        ...state,
        isLoading: true,
      };

    case `${actionType}_POST_DATA_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        postResponse: action.postResponse,
      };
    case `${actionType}_POST_DATA_ERROR`:
      return { ...state, isLoading: false, hasError: action.hasError };

    case `${actionType}_REMOVE`:
      return {
        ...state,
        isLoading: false,
        deletedResponse: action.deletedResponse,
      };

    case `${actionType}_OPTIONS_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        options: action.options,
      };

    case `${actionType}_CLONE`:
      return { ...state, isLoading: false, hasError: action.hasError };

    case `${actionType}_AUTH_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        userAuth: action.data,
      };

    case `${actionType}_CURRENT_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        currentContact: action.data,
      };

    case `${actionType}_TABLE_VIEW`:
      return {
        ...state,
        isLoading: false,
        tableView: action.data,
      };

    case `${actionType}_ADDITIONAL_OPTIONS`:
      return {
        ...state,
        isLoading: false,
        additionalOptions: action.data,
      };

    default:
      return state;
  }
};

export default createBaseReducer;
