import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getMatchesData,
} from '../../redux/actions/matches/request';
import {
  getMatchHistoryData
} from '../../redux/actions/matchHistory/request';
import MatchHistory from '../../pages/MatchHistory/MatchHistory';
import { openModal, closeModal } from '../../redux/actions/modal/modalActions';

const mapStateToProps = store => {
  return {
  data: store.matches.data,
  modalIsOpen: store.modal.modalIsOpen,
  isLoading: store.matches.isLoading,
  authUser: store.user.userAuth
  }
};

const mapDispatchToProps = dispatch => ({
  getMatchesData: params => dispatch(getMatchesData(params)),
  getMatchHistoryData: params => dispatch(getMatchHistoryData(params)),
  openModal: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(MatchHistory);
