import {
  SELECT_OPTIONS_ERROR,
  PARTNERS_OPTIONS_SUCCESS,
  BUSINESSES_OPTIONS_SUCCESS,
  ACQUIRER_CRITERIA_OPTIONS_SUCCESS,
  ACQUIRERS_OPTIONS_SUCCESS,
  USER_NAME_OPTIONS_SUCCESS,
  CONTACTS_OPTIONS_SUCCESS
} from '../types';
import {
  Partners,
  User,
  Acquirers,
  AcquirerCriteria,
  Opportunity,
  Contacts
} from '../../../api/Api';
import { createDynamicOptions, createDynamicNamesOptions } from '../../../utils/utils';

export const SelectOptionsHasError = payload => ({
  type: SELECT_OPTIONS_ERROR,
  hasError: payload,
});

export const PartnersOptionsIsSuccess = payload => ({
  type: PARTNERS_OPTIONS_SUCCESS,
  data: payload,
});

export const BusinessesOptionsIsSuccess = payload => ({
  type: BUSINESSES_OPTIONS_SUCCESS,
  data: payload,
});

export const AcquirerCriteriaOptionsIsSuccess = payload => ({
  type: ACQUIRER_CRITERIA_OPTIONS_SUCCESS,
  data: payload,
});

export const AcquirersOptionsIsSuccess = payload => ({
  type: ACQUIRERS_OPTIONS_SUCCESS,
  data: payload,
});

export const UserNameOptionsIsSuccess = payload => ({
  type: USER_NAME_OPTIONS_SUCCESS,
  data: payload,
});

export const ContactsOptionsIsSuccess = payload => ({
  type: CONTACTS_OPTIONS_SUCCESS,
  data: payload,
});

export function getPartnersOptions() {
  return async dispatch => {
    try {
      const isPartners = true;
      const response = await Partners.fetch();
      const data = await createDynamicOptions(response.data.items, isPartners);
      dispatch(PartnersOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}

export function getBusinessesOptions(params) {
  return async dispatch => {
    try {
      const isPartners = true;
      const response = await Opportunity.fetch({ limit: 200, ...params });
      const data = await createDynamicOptions(response.data.items, isPartners);
      dispatch(BusinessesOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}

export function getAcquirerCriteriaOptions(params) {
  return async dispatch => {
    try {
      const isPartners = false;
      const isProfile = true;
      const response = await AcquirerCriteria.fetch({ limit: 200, ...params });
      
      const data = await createDynamicOptions(
        response.data.items,
        isPartners,
        isProfile
      );
      dispatch(AcquirerCriteriaOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}

export function getAcquirersOptions(params) {
  return async dispatch => {
    try {
      
      const isPartners = true;
      const response = await Acquirers.fetch({ limit: 200, ...params });
      const data = await createDynamicOptions(response.data.items, isPartners);
      dispatch(AcquirersOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}

export function getUserNameOptions(params) {
  return async dispatch => {
    try {
      const response = await User.fetchAll({ limit: 200, ...params });
      const data = await createDynamicNamesOptions(response.data.items);
      dispatch(UserNameOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}

export function getContactsOptions(params) {
  return async dispatch => {
    try {
      const response = await User.fetchAll({ ...params });
      const data = await createDynamicNamesOptions(response.data.items);
      dispatch(ContactsOptionsIsSuccess(data));
    } catch (e) {
      dispatch(SelectOptionsHasError(e));
    }
  };
}
