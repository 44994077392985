import React from 'react';
import PropTypes from 'prop-types';

const TypeItem = ({ labelText, name, value, onChange, checked }) => {
  return (
    <div className="toggle-form-group">
      <input
        type="radio"
        name={name}
        id={value}
        value={labelText}
        onChange={onChange}
        checked={checked}
        className="toggle-form-group__input"
      />
      <label className="toggle-form-group__label" htmlFor={value}>
        {labelText}
      </label>
    </div>
  );
};

TypeItem.propTypes = {
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default TypeItem;
