export const contactTypes = [
  {
    key: 'Owner',
    label: 'Record Owner',
    value: 'Owner',
  },
  {
    key: 'Partner',
    label: 'Client Lead',
    value: 'Partner Contact',
  },
  {
    key: 'Email Recipient',
    label: 'Client Email',
    value: 'Email Recipient',
  },
  {
    key: 'Referred by',
    label: 'Referred by',
    value: 'Referred by',
  },
]

export const contactTypesCustom = [
  {
    key: 'Owner',
    label: 'Record Owner',
    value: 'Owner',
  },
  {
    key: 'Partner',
    label: 'Client Lead',
    value: 'Partner Contact',
  },
  {
    key: 'Email Recipient',
    label: 'Client Email',
    value: 'Email Recipient',
    isDisabled: true,
  },
  {
    key: 'Referred by',
    label: 'Referred by',
    value: 'Referred by',
  },
]
