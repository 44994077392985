import { MatchHistory } from '../../../api/Api';
import {
  matchesHasError,
  matchesIsLoading,
  matchesSuccess,
} from '../matches/matchesActions';

export function getMatchHistoryData(params) {
  return async dispatch => {
    dispatch(matchesIsLoading());

    try {
      const data = await MatchHistory.fetch(params);

      return dispatch(matchesSuccess(data));
    } catch (e) {
      dispatch(matchesHasError(e));
    }
  };
}
