import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Routes from '../../../constants/routes';
import AccountRadioButtonList from '../../shared/Form/AccountRadioButtonList/AccountRadioButtonList';
import LogoutIco from '../../../asset/img/LogoutIco';
import Button from '../../shared/Form/Button';
import UserIco from '../../../asset/img/UserIco';
import { getCorrectAvatarInitials } from '../../../utils/utils';
import PatternIcon from '@mui/icons-material/Pattern';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RestoreIcon from '@mui/icons-material/Restore';
import EmailIcon from '@mui/icons-material/Email';

const Content = ({
  className,
  user,
  logout,
  items,
  firstName,
  lastName,
  onClose,
  currentPartner,
  onClickChangePartner,
  partnerId
}) => {
  const initials = getCorrectAvatarInitials({ firstName, lastName });

  return (
    <div className={`${className}`}>
      <header className="mna-popover__header flex">
        <div className="mna-popover__header-avatar">
          {initials ? (
            <div alt={user.nickname} className="mna-popover__avatar">
              {initials}
            </div>
          ) : (
            <UserIco className="mna-popover__avatar" />
          )}
        </div>
        <div className="mna-popover__header-info flex-column">
          <div className="mna-popover__header-name">
            {firstName} {lastName}
          </div>

        </div>
      </header>


      <div class="account-row">
        <div class="account-row__button">
          <Link
            onClick={onClose}
            to={Routes.ACCOUNT}
            title="Account"
          >
            <AccountCircleIcon fontSize="large" />
          </Link>
        </div>
        <div class="account-row__button">
          <Link
            onClick={onClose}
            to={'/restore'}
            title="Restore"
          >
            <RestoreIcon fontSize="large" />
          </Link>
        </div>
        <div class="account-row__button">
          <Link
            onClick={onClose}
            to={'/email-history'}
            title="Email History"
          >
            <EmailIcon fontSize="large" />
          </Link>
        </div>
      </div>

        <div className="mna-popover__body">
          <div className="mna-popover__body-inputs">
            <AccountRadioButtonList
              items={items}
              name="partnerId"
              value={currentPartner}
              onClickChangePartner={onClickChangePartner}
            />
          </div>
        </div>
      
      <footer className="mna-popover__footer">
        <Button onClick={logout} transparent>
          <LogoutIco /> Logout
        </Button>
      </footer>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  currentPartner: PropTypes.objectOf(PropTypes.any),
  onClickChangePartner: PropTypes.func,
  logout: PropTypes.func,
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  partnerId: PropTypes.number
};

export default Content;
