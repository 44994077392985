import React, { useEffect, useState } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import '../../asset/styles/components/modal-tree.scss';
import SubSectorOptions from '../../Data/Select/SubSectorOptions';


function parseSectors(sectors) {
  sectors = JSON.parse(sectors)
  sectors.forEach((s) => {
    if (s.options) {
      s.options.forEach((o) => {
        o.checked = true
      })
    }
    else {
      s.checked = true
    }

  })
  return sectors
}

const SectorTree = ({ sectors, setFieldValue, setFieldTouched, errors }) => {

    SubSectorOptions.forEach(function (s) {
      s.value = s.label
      s.children = s.options
    })

    var [treeData, setTreeData] = useState([])

    var [currentNode, setCurrentNode] = useState(false)

    var [selectedTreeData, setSelectedTreeData] = useState('[]')

    useEffect(() => {
      if (sectors) {
        setSelectedTreeData(sectors)
      }
    }, [sectors])

    useEffect(() => {

      if (selectedTreeData) {
        // Parse sectors to JSON
        var JSONSectors = parseSectors(selectedTreeData)

        // Creates an array of the sub sectors
        let sectorKeys = []
        JSONSectors.forEach((sector) => {
            sectorKeys.push(sector.label)
            if (sector.options) {
              sector.options.forEach((subSector) => {
                sectorKeys.push(subSector)
              })
            }
        })
        
        // Loop through all possible keys
        SubSectorOptions.forEach((sector) => {

          // Loop through the sub sectors of each sector first
          sector.options.forEach((subSector) => {
            sector.className = ''
            // Mark subsectors checked
            if (sectorKeys.includes(subSector.label)) {
              sector.className = 'subsector-checked'
              subSector.checked = true;
            } else {
              subSector.checked = false;
            }

            if (subSector.label === currentNode.label) {
              sector.expanded = true;
            }


          })

          // Mark sectors checked
          if (sectorKeys.includes(sector.label)) {
            sector.checked = true;

            sector.options.forEach((subSector) => {
              subSector.checked = true;
            })


          } else {
            sector.checked = false;
          }
        })

        setTreeData([...SubSectorOptions, SubSectorOptions])
        
      }
    }, [selectedTreeData])

    const onChange = (currentNode, selectedNodes) => {

        setCurrentNode(currentNode)

        setFieldValue('subSector', JSON.stringify(selectedNodes))
        setFieldValue('sector', JSON.stringify(selectedNodes))
        setFieldValue('sectors', JSON.stringify(selectedNodes))

        setSelectedTreeData(JSON.stringify(selectedNodes), currentNode)
    }

    return (
        <>
            <DropdownTreeSelect data={treeData} onChange={onChange}/>
        </>
    )

}

export default SectorTree


// { label: "Aerospace & Defence", options: (6) […], value: "Aerospace & Defence", children: undefined, checked: false, _depth: 0, _id: "rdts1-0", _children: (6) […], _focused: true, expanded: true }