/* eslint-disable no-shadow */
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withFormik } from 'formik';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import numeral from 'numeral';
import OpportunitiesEdit from '../../pages/Opportunities/OpportunitiesEdit';
import { getBasicFields } from './FormikHelpers';
import { fetchUserDetailsData } from '../../redux/actions/user/userActions';
import {
  requestOpportunitiesData,
  deleteOpportunities,
  getOpportunitiesDetails,
  getOpportunitiesReport,
} from '../../redux/actions/opportunities/opportunitiesActions';
import {createContact} from '../../redux/actions/contacts/contactsActions'
import {
  getUserNameOptions,
  getContactsOptions,
  getPartnersOptions,
} from '../../redux/actions/options/optionsActions';
import { openModal } from '../../redux/actions/modal/modalActions';
import { formatDate } from '../../utils/utils';
import { getValidationSchema } from './ValidationSchema';
import { additionalOptions } from '../../redux/actions/contacts/contactsActions';

const mapStateToProps = store => {
  
  return {
    data: store.opportunity.data,
    partnerId: store.globalOptions.partnerId,
    isLoading: store.user.isLoading,
    opportunityLoading: store.opportunity.isLoading,
    optionsData: store.options,
    modalIsOpen: store.modal.modalIsOpen,
    postResponse: store.opportunity.postResponse,
    hasError: store.opportunity.hasError,
    authId: store.user.userAuth.id,
    authPartnerId: store.user.userAuth.partnerId,
    authUser: store.user.userAuth,
    contactOptions: store.contacts.additionalOptions,
    hasPartner: !!store.user.userAuth.partnerId,
  };
};



const mapDispatchToProps = (dispatch, { props }) => {
  return {
    ...bindActionCreators(
      {
        fetchUserDetailsData,
        openModal,
        deleteOpportunities,
        getUserNameOptions,
        getOpportunitiesReport,
        getOpportunitiesDetails,
        getPartnersOptions,
        additionalOptions,
      },
      dispatch
    ),
    handleRequestOpportunities: (method, data) => dispatch(requestOpportunitiesData(method, data, props)),
    createContact: (data, params) => dispatch(createContact(data, params)),
    getContactsOptions: params => dispatch(getContactsOptions(params)),
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const current = props.data;
      const financialsArray = sortBy(
        get(current, 'financials', []),
        x => x.fyEnding
      );
      const contactRoles = get(current, 'contacts', []);



      if (props.isCreate) {
        return {
          ...getBasicFields()
        };
      }
      return {
        remarketedDate: formatDate(current && current.remarketedDate) || '',
        idVerified: get(current, 'idVerified', false),
        shareholdingVerified: get(current, 'shareholdingVerified', false),
        feesEnabled: get(current, 'feesEnabled', false),
        partnerId: get(current, 'partnerId', ''),
        businessName: get(current, 'businessName', ''),
        description: get(current, 'description', ''),
        richDescription: get(current, 'richDescription', ''),
        companyNumber: get(current, 'companyNumber', ''),
        sector: get(current, 'sector', ''),
        subSector: get(current, 'subSector', ''),
        region: get(current, 'region', ''),
        forSale: get(current, 'forSale', 'true'),
        financialsVerified: get(current, 'financialsVerified', false),
        notes: get(current, 'notes', ''),
        sensitive: get(current, 'sensitive', false),
        clearNames: get(current, 'clearNames', false),
        clearNamesVia: get(current, 'clearNamesVia', ''),
        overseasBuyers: get(current, 'overseasBuyers', false),
        peBuyers: get(current, 'peBuyers', false),
        inExclusivity: get(current, 'inExclusivity', false),
        status: get(current, 'status', 'forSale'),
        sourceUser: isNull(get(current, 'sourceUser'))
          ? ''
          : get(current, 'sourceUser'),
        codeName: get(current, 'codeName', ''),
        areaCode: get(current, 'areaCode', ''),
        financials: financialsArray.map(item => {
          return {
            id: item.id,
            turnover: parseInt(numeral(item.turnover).format('1000'), 10),
            netProfit: parseInt(numeral(item.netProfit).format('1000'), 10),
            fyEnding: item.fyEnding,
            projectedNumbers: item.projectedNumbers,
          };
        }),
        deletedFinancials: [],
        contactRoles: contactRoles.length
          ? contactRoles.map(item => {
              return {
                role: item.role,
                contactId: item.contact.id.toString(),
              };
            })
          : [],
        deletedContactsData: [],
        type: isNull(get(current, 'type')) ? [] : get(current, 'type'),
        fee: get(current, 'fee', null),
        managed: get(current, 'managed', false)
      };
    },
    handleSubmit: async (values, { props }) => {
      let {
        remarketedDate,
        forSale,
        clearNamesVia,
        sourceUser,
        financials,
        deletedFinancials,
        deletedContactsData,
        contactRoles,
        companyNumber,
        partnerId,
        richDescription,
        ...rest
      } = values;


      if (!richDescription) {
        richDescription = [
          {
            type: "paragraph",
            default : true,
            children: [
              { text: "" },
            ]
          },
        ];
      }

      const richDescriptionParsed = JSON.stringify(richDescription)

      // Handles the financials
      const filteredFinancials = financials.filter(
        item => Object.keys(item).length
      );
      const validatedFinancials = filteredFinancials.map(item => {
        if (item.id) {
          return {
            id: item.id,
            turnover: parseInt(numeral(item.turnover).format('1000'), 10),
            netProfit: parseInt(numeral(item.netProfit).format('1000'), 10),
            fyEnding: item.fyEnding,
            projectedNumbers: item.projectedNumbers,
          };
        }
        return {
          turnover: parseInt(numeral(item.turnover).format('1000'), 10),
          netProfit: parseInt(numeral(item.netProfit).format('1000'), 10),
          fyEnding: item.fyEnding,
          projectedNumbers: item.projectedNumbers,
        };
      });

      //
      const formattedRemarketedDate = remarketedDate
        ? new Date(remarketedDate).toISOString()
        : null;
      const parsedForSale = JSON.parse(forSale);
      const clearNamesViaToNum = parseInt(clearNamesVia, 10);
      const sourceUserToNum = parseInt(sourceUser, 10);
      const validatedContactRoles = contactRoles.map(item => {
        return {
          role: item.role,
          contactId: parseInt(item.contactId, 10),
        };
      });
      if (props.isCreate) {
        const {
          companyNumber,
          partnerId,
          deletedFinancials,
          contactRoles,
          ...userDetails
        } = values;

        const obj = {
          ...userDetails,
          partnerId: parseInt(partnerId, 10),
          remarketedDate: formattedRemarketedDate,
          companyNumber,
          forSale: parsedForSale,
          clearNamesVia: clearNamesViaToNum,
          sourceUser: sourceUserToNum,
          financials: validatedFinancials,
          contactRoles: validatedContactRoles,
          richDescription: richDescriptionParsed,
        };

        // Submits it
        return props.handleRequestOpportunities('POST', obj);
      }
      // if edit screen
      const data = {
        businesses: [
          {
            id: props.data.id,
            ...rest,
            partnerId: parseInt(partnerId, 10),
            companyNumber,
            remarketedDate: formattedRemarketedDate,
            forSale: parsedForSale,
            clearNamesVia: clearNamesViaToNum,
            sourceUser: sourceUserToNum,
            contactRoles: validatedContactRoles,
            richDescription: richDescriptionParsed,
            financials: deletedFinancials
              ? [...validatedFinancials, ...deletedFinancials]
              : validatedFinancials.filter(item => Object.keys(item).length),
          },
        ],
      };
      return props.handleRequestOpportunities('UPDATE', data);
    },
    validationSchema: props => {
      return getValidationSchema(props);
    },
  })
)(OpportunitiesEdit);
