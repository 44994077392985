export function totalMatches(data) {
  const total = data.matches.length;
  const cold = data.cold.length;
  const active = data.active.length;

  return {
    labels: ['Total', 'Cold', 'Active'],
    datasets: [
      {
        label: 'Matches',
        data: [total, cold, active],
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)'
      }
    ]
  };
}

export function matchHistory(data, dateRange) {
  // Get data within dateRange
  const startDate = dateRange[0];
  const endDate = dateRange[1];

  // Find data within dateRange
  const historyData = data.matches.filter(match => {
    const matchDate = new Date(match.match.createdAt);
    return matchDate >= startDate && matchDate <= endDate;
  })

  // Order historyData by createdAt
  historyData.sort((a, b) => {
    return new Date(a.match.createdAt) - new Date(b.match.createdAt);
  });

  // Create labels from start date and end date
  const labels = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  while (start <= end) {
    labels.push(start.toLocaleDateString());
    start.setDate(start.getDate() + 1);
  }

  // Create cumulative dataset
  const dataset = [];
  let total = 0;
  labels.forEach(label => {
    const matches = historyData.filter(match => {
      const matchDate = new Date(match.match.createdAt);
      return matchDate.toLocaleDateString() === label;
    });

    total += matches.length;

    dataset.push(total);
  }
  );

  return {
    labels,
    datasets: [
      {
        label: 'Matches',
        data: dataset,
        fill: true,
        backgroundColor: '#8BC640',
        borderColor: '#8BC640'
      }
    ]
  };
}

export function matchHistoryOld(data, dateIterations) {
  const vendorScore = data.vendorScore;

  if (!vendorScore.length) {
    return {};
  }

  // find smallest created at in vendorScore
  const firstCreatedAt = vendorScore.reduce((min, curr) => {
    return curr.createdAt < min ? curr.createdAt : min;
  }, vendorScore[0].createdAt);

  // find largest created at in vendorScore
  const lastCreatedAt = vendorScore.reduce((max, curr) => {
    return curr.createdAt > max ? curr.createdAt : max;
  }, vendorScore[0].createdAt);

  const dates = [];
  const firstDate = new Date(firstCreatedAt);
  const lastDate = new Date();


  for (let i = 0; i < dateIterations; i++) {
    let date = new Date(
      firstDate.getTime() +
        ((lastDate.getTime() - firstDate.getTime()) / dateIterations) * i
    );
    dates.push(date);
  }

  // Create a loop of dates with next date index
  const dateLoop = dates.map((date, index) => {
    return {
      date: date,
      nextDate: dates[index + 1],
      count: 0
    };
  });

  dateLoop[dateLoop.length - 1].nextDate = lastDate;


  const isBetween = (date, start, end) => {
    return date >= new Date(start) && date <= new Date(end);
  };

  vendorScore.forEach(score => {
    let createdAt = new Date(score.createdAt);
    dateLoop.forEach(dates => {
      if (isBetween(createdAt, dates.date, dates.nextDate)) {
        dates.count++;
      }
    });
  });

  // Create array with count from dateloop
  const countArray = dateLoop.map(dates => {
    return dates.count;
  });

  // Convert Dates to Day Month Year
  const labels = dates.map(date => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  });

  return {
    labels: labels,
    datasets: [
      {
        label: 'Activity',
        data: countArray,
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)'
      }
    ]
  };
}


/*

Dev notes



DO
$do$
BEGIN 
   FOR i IN 1..25 LOOP
INSERT INTO public."Trackers"(
	"user", "table", changedid, previousvalue, newvalue, "createdAt", "updatedAt", associatedid)
	VALUES (1, 'matches', 'vendorScore', 'Yes', 'No', 
	timestamp '2022-01-10 20:00:00' +
       random() * (timestamp '2020-01-20 20:00:00' -
                   timestamp '2021-12-12 10:00:00'), 
		timestamp '2022-01-10 20:00:00' +
       random() * (timestamp '2020-01-20 20:00:00' -
                   timestamp '2021-12-12 10:00:00'), '794');
   END LOOP;
END
$do$;


*/