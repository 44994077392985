export const getBasicFields = () => {
  return {
    ownerId: '',
    acquirerId: '',
    notes: '',
    description: '',
    turnoverRangeLow: '',
    turnoverRangeHigh: '',
    netProfitRangeLow: '',
    netProfitRangeHigh: '',
    region: [],
    keywords: [],
    sectors: '',
    acquisitionTitle: '',
    fee: null,
    managed: false,
  };
};
