import React from 'react';
import PropTypes from 'prop-types';
import TypeItem from './TypeItem';

const TypeList = ({ options, name, currentValue, onChange }) => {
  return (
    <div className="type-list-options">
      {options.map((item, i) => (
        <TypeItem
          name={name}
          labelText={item.text}
          value={item.key}
          key={i}
          onChange={onChange}
          currentValue={currentValue}
          checked={currentValue === item.text}
        />
      ))}
    </div>
  );
};

TypeList.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
};

export default TypeList;
