import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      ticks: {
        callback: function(value) {
          if (value % 1 === 0) {
            return value;
          }
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Cumulative Approved Matches'
    }
  }
};

const LineGraph = ({ data }) => {
  return <Line options={options} data={data} />;
};

export default LineGraph;
