import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import PageHeader from '../PageHeader/PageHeader';
import Paper from '../shared/Paper/Paper';
import NotesBox from '../NotesBox/NotesBox';
import Table from '../shared/Table/Table';


const EditLayout = ({
  location,
  history,
  isInitialValid,
  handleSubmit,
  setState,
  children,
  pageHeaderTitle,
  cancelText,
  saveText,
  exportMatches,
  objWithData,
  trackingData,
  handleChange,
  isSubmit,
  pathBack,
  isMatchesEdit,
  fetchAcquirerMatches,
  matchesData,
}) => {
  return (
    <>
      <PageHeader
        location={location}
        title={pageHeaderTitle}
        history={history}
        btnCancelText={cancelText}
        btnSaveText={saveText}
        isSubmit={isSubmit}
        edit
        isDisabled={isInitialValid}
        onClick={handleSubmit}
        pathBack={pathBack}
        exportMatches={exportMatches}
        fetchAcquirerMatches={fetchAcquirerMatches}
        matchesData={matchesData}
      />
      <Row>
        <Col xs={12} md={12} lg={8}>
          {children}
        </Col>
        <Col xs={12} md={12} lg={4}>
          <Paper fullHeight className="flex flex-column">
            <NotesBox
              value={(objWithData && objWithData) || ''}
              onChange={e => {
                if (isMatchesEdit) {
                  setState(prevState => {
                    return {
                      ...prevState,
                      notes: e.target.value,
                    };
                  });
                }
                handleChange(e);
              }}
              name="notes"
            />
          </Paper>
        </Col>
      </Row>


    </>
  );
};

EditLayout.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func,
  setState: PropTypes.func,
  children: PropTypes.node,
  pageHeaderTitle: PropTypes.string,
  cancelText: PropTypes.string,
  saveText: PropTypes.string,
  objWithData: PropTypes.string,
  handleChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  pathBack: PropTypes.string,
  isMatchesEdit: PropTypes.bool,
};

export default EditLayout;
