import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { closeModal } from '../../redux/actions/modal/modalActions';
import Button from '../shared/Form/Button';
import Filters from '../../asset/img/filters.svg';
import { Icon } from '../shared';

const isPropsEqual = (prev, next) => 
    prev.modalIsOpen === next.modalIsOpen && prev.content === next.content;



const ModalManager = memo(
  ({
    filters,
    header,
    modalIsOpen,
    content,
    btnCancelText,
    btnSaveText,
    closeModal,
    onAction,
    onFilterData,
  }) => {
    return (
      <Modal open={modalIsOpen} onClose={closeModal} className="mna-modal">
        <header className="mna-modal__header text-center">
          <Icon icon={Filters} size="40px" className="mna-modal__icon" />
          <h2 className="title title-main">{header}</h2>
          <div></div>
        </header>
        <div className="mna-modal__content">{content}</div>
        <div className="mna-modal__actions">
          <Button
            basic
            successWithoutBg
            text={btnCancelText}
            onClick={closeModal}
          />
          {filters ? (
            <Button basic success text={btnSaveText} onClick={onFilterData} />
          ) : (
            <Button basic success text={btnSaveText} onClick={onAction} />
          )}
        </div>
      </Modal>
    );
  },
  isPropsEqual
);

ModalManager.propTypes = {
  filters: PropTypes.bool,
  header: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  content: PropTypes.node,
  btnCancelText: PropTypes.string.isRequired,
  btnSaveText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onFilterData: PropTypes.func,
};

const mapStateToProps = store => ({
  modalIsOpen: store.modal.modalIsOpen,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalManager);
