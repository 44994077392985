import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '../Icon/Icon';

const Textarea = ({ placeholder, large, medium, basic, icon, value, partnerId, getPartnersOptions, ...rest }) => {

return (
  
  <div
    className={`mna-textarea ${clsx({
      'mna-textarea--large': large,
      'mna-textarea--medium': medium,
      'mna-textarea--basic': basic,
      'mna-textarea--icon': icon,
    })}`}
  >
    {icon && (
      <div className="mna-textarea__icon">
        <Icon icon={icon} size="24px" />
      </div>
    )}
    
    < textarea {...rest} value={value.toString()}>{value.toString()}</textarea>
     
  </div>
);
    }

Textarea.propTypes = {
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  basic: PropTypes.bool,
  icon: PropTypes.string,
};

export default Textarea;
