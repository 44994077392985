import React from 'react';
import PropTypes from 'prop-types';

const ToggleInput = ({ title, name, onChange, type, value, id, checked }) => {
  return (
    <div className="toggle-form-group">
      <input
        type={type}
        name={name}
        value={value}
        id={id}
        className="toggle-form-group__input"
        onChange={onChange}
        checked={checked}
      />
      <label className="toggle-form-group__label" htmlFor={id}>
        {title}
      </label>
    </div>
  );
};

ToggleInput.defaultProps = {
  type: 'radio',
};

ToggleInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default ToggleInput;
