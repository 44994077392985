import { TOGGLE_SIDEBAR } from '../../actions/types';

const initialState = {
  sidebarIsOpen: true,
};

export function sidebar(state = initialState, action) {

    if (window.innerWidth < 1000) {
        state.sidebarIsOpen = false
    }
    
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };
    default:
      return state;
  }
}