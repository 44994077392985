import { connect } from 'react-redux';
import { getOpportunitiesData } from '../../redux/actions/opportunities/opportunitiesActions';
import Opportunities from '../../pages/Opportunities/Opportunities';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => {
  return {
    data: store.opportunity.data,
    isLoading: store.opportunity.isLoading,
    partnerId: store.globalOptions.partnerId,
    limit: store.globalOptions.limit,
  };
};

const mapDispatchToProps = dispatch => ({
  getOpportunitiesData: (params, stale) => dispatch(getOpportunitiesData(params)),
  setLimitItems: data => dispatch(setLimitItems(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Opportunities);
