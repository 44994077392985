import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Col, Row } from 'react-flexbox-grid';
import { FormGroup, Select } from '../shared';
import Button from '../shared/Form/Button';

const ContactFilterModal = ({
  header,
  modalIsOpen,
  btnCancelText,
  btnSaveText,
  closeModal,
  onFilterData,
  setFieldValue,
  values,
  options
}) => {
  return (
    <Modal open={modalIsOpen} onClose={closeModal} className="mna-modal">
      <header className="mna-modal__header text-center">
        <h2 className="title title-main">{header}</h2>
      </header>
      <div className="mna-modal__content">
        <Row className="flex-content-center">
          <Col xs={6}>
            <FormGroup labelText="Type" name="types">
              <Select
                placeholder=""
                options={options}
                id="types"
                name="types"
                value={values.types || ''}
                setFieldValue={setFieldValue}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="mna-modal__actions">
        <Button
          basic
          successWithoutBg
          text={btnCancelText}
          onClick={closeModal}
        />
        <Button basic success text={btnSaveText} onClick={onFilterData} />
      </div>
    </Modal>
  )
};

ContactFilterModal.propTypes = {
  header: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  btnCancelText: PropTypes.string.isRequired,
  btnSaveText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onFilterData: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.object),
};

export default ContactFilterModal;
