import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccountRadioButton from './AccountRadioButttonItem';
import { setNumberIsValueExist } from '../../../../utils/utils';

const AccountRadioButtonList = ({
  items,
  name,
  value,
  onClickChangePartner
}) => {
  return (
    <div className="account-radio-list account-radio-list--column">
      <AccountRadioButton
        name={name}
        labelText="All Data"
        value={null}
        onChange={e => {
          const data = {
            partnerId: setNumberIsValueExist(e.target.id),
            partnerName: e.target.value
          };
          onClickChangePartner(data);
        }}
        currentValue={value.partnerId}
      />

      {items
        ? items.map((item, i) => (
            <AccountRadioButton
              name={name}
              labelText={item.label}
              value={item.value}
              key={i}
              onChange={e => {
                const data = {
                  partnerId: e.target.id,
                  partnerName: e.target.value
                };

                onClickChangePartner(data);
              }}
              currentValue={value.partnerId}
            />
          ))
        : ''}
    </div>
  );
};

AccountRadioButtonList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.string),
  onClickChangePartner: PropTypes.func
};

export default AccountRadioButtonList;
