import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../shared/Form/Button';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Rodal from 'rodal';
import RodalModal from '../Modal/RodalModal';
import { openModal } from '../../redux/actions/modal/modalActions';
import { Col, Row } from 'react-flexbox-grid';
import Filters from '../../asset/img/filters.svg';
import { FormGroup, Input } from 'semantic-ui-react';
import { Icon } from '../shared';

const PageHeader = ({
  title,
  path,
  linkText,
  btnCancelText,
  isOpportunities,
  btnSaveText,
  history,
  filterStaleOpportunities,
  removeFilterStaleOpportunities,
  edit,
  stale,
  setStale,
  stateText,
  onClick,
  location,
  isSubmit,
  onExport,
  isMatches,
  pathBack,
  nobutton,
  exportMatches,
  fetchAcquirerMatches,
  matchesData
}) => {
  const [showMatchesModal, setShowMatchesModal] = useState(false);

  return (
    <>
      {(location && location.state) || edit ? (
        <div className="page-header">
          <Breadcrumb history={history} location={location} title={title} />
          <div className="page-header__links">
            <NavLink
              className="mna-btn mna-btn--success-without-bg"
              to={pathBack}
            >
              {btnCancelText}
            </NavLink>

            {exportMatches && (
              <Button
                basic
                className="btn-export"
                success
                text="Export"
                onClick={async () => {
                  await fetchAcquirerMatches();
                  setShowMatchesModal(true);
                }}
                medium
              />
            )}
            <Button
              basic
              success
              text={isSubmit ? 'Saving...' : btnSaveText}
              disabled={isSubmit}
              onClick={onClick}
              type="button"
            />
          </div>
        </div>
      ) : (
        <div className="page-header">
          <h2 className="page-header__title">{title}</h2>
          <div className="page-header__links">
            {isOpportunities && !stale && (
              <Button
                successBg
                basic
                text="Stale Filter"
                onClick={() => {
                  setStale(true);
                  filterStaleOpportunities();
                }}
                medium
                className="btn-export"
              />
            )}

            {isOpportunities && stale && (
              <Button
                successWithoutBg
                basic
                text="Stale Filter"
                onClick={() => {
                  removeFilterStaleOpportunities();
                  setStale(false);
                }}
                medium
                className="btn-export"
              />
            )}

            {isMatches ? (
              <>
                <RodalModal />
                <Button
                  successWithoutBg
                  basic
                  text="Export"
                  onClick={onExport}
                  medium
                  className="btn-export"
                />
              </>
            ) : linkText && !onClick ? (
              <NavLink
                className="mna-btn mna-btn--success"
                to={{
                  pathname: path,
                  state: {
                    // prevPath: location.pathname.replace(/\//g, ''),
                    title: stateText
                  }
                }}
              >
                {linkText}
              </NavLink>
            ) : (
              <Button
                basic
                className={nobutton}
                success
                text={linkText}
                onClick={onClick}
                medium
              />
            )}
          </div>
        </div>
      )}
      {exportMatches && (
        <>
          <Rodal
            visible={showMatchesModal}
            onClose={() => {
              setShowMatchesModal(false);
            }}
            closeOnEsc={true}
            closeOnOverlay={true}
            showCloseButton={true}
            showCloseIcon={true}
            width={500}
            height={500}
          >
            <div className="modal-content">
              <div className="modal-header">
                <Icon icon={Filters} size="40px" className="mna-modal__icon" />
                <h2 className="title title-main">Acquirer Matches</h2>
                <div></div>
              </div>
              <div className="modal-body">
                <div className={`modal-body-matches ${matchesData.items && matchesData.items.length > 0 ? '' : 'empty'}`}>
                  {matchesData.items &&
                    matchesData.items.length > 0 &&
                    matchesData.items.map(match => (
                      // format of [Business Name] - [Stage] - [Date Introduced]

                      <div className="modal-body-item">
                        <div className="modal-body-item-col">
                          {match.business.businessName}
                        </div>
                        <div className="modal-body-item-col">{match.stage}</div>
                        <div className="modal-body-item-col">
                          {match.createdAt.split('T')[0]}
                        </div>
                      </div>
                    ))}

                  {matchesData.items && matchesData.items.length === 0 && (
                    <div className="modal-body-item">
                      <div className="modal-body-item-col">
                        No matches found
                      </div>
                    </div>
                  )}
                </div>
                <Row className="modal-body-button">
                  <div className="modal-body-footer">
                    <Button
                      success
                      disabled={!matchesData.items || matchesData.items.length === 0}
                      basic
                      text="Export as CSV"
                      onClick={async () => {
                        const convertToCSV = arr => {
                          const headings = [
                            'Business Name',
                            'Stage',
                            'Date Introduced'
                          ];
                          const csv = arr.map(item => {
                            return [
                              item.business.businessName,
                              item.stage,
                              item.createdAt.split('T')[0]
                            ];
                          });

                          csv.unshift(headings);

                          return csv.map(e => e.join(',')).join('\n');
                        };

                        const csv = convertToCSV(matchesData.items);
                        const blob = new Blob([csv], {
                          type: 'text/csv;charset=utf-8;'
                        });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        const acquirerName =
                          matchesData.items[0].acquirer.companyName;
                        link.download = `${acquirerName}-matches.csv`;
                        link.click();
                      }}
                      medium
                      className="btn-export"
                    />
                    <Button
                      success
                      basic
                      disabled={!matchesData.items || matchesData.items.length === 0}
                      text="Copy to Clipboard"
                      onClick={() => {
                        // copy the matches to clipboard
                        let matches = [];
                        matchesData.items &&
                          matchesData.items.length > 0 &&
                          matchesData.items.map(match => {
                            const text = `${match.business.businessName} - ${
                              match.stage
                            } - ${match.createdAt.split('T')[0]}`;
                            matches.push(text);
                          });

                        const textArea = document.createElement('textarea');
                        textArea.value = matches.join('\n');
                        document.body.appendChild(textArea);
                        textArea.select();
                        document.execCommand('copy');
                        textArea.remove();
                      }}
                      medium
                      className="btn-export"
                    />
                  </div>
                </Row>
              </div>
            </div>
          </Rodal>
        </>
      )}
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  btnCancelText: PropTypes.string,
  btnSaveText: PropTypes.string,
  stateText: PropTypes.string,
  path: PropTypes.string,
  edit: PropTypes.bool, // if editable or creating new item
  linkText: PropTypes.string,
  isSubmit: PropTypes.bool,
  pathBack: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  onExport: PropTypes.func,
  isMatches: PropTypes.bool,
  state: PropTypes.bool
};

export default PageHeader;
