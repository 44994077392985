import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ToggleInput from './ToggleInput';

const RadioInputList = ({
  options,
  onChange,
  column,
  row,
  name,
  values,
  fixedHeight,
  type,
  wrap
}) => {
  return (
    <ul
      className={`type-input-list flex ${clsx({
        'flex-column': column,
        'type-input-list--fixedHeight': fixedHeight,
        'flex-row': row,
        'flex-wrap': wrap,
      })}`}
    >
      {options.map(input => {
        // values[input.key]  - getting this from backend side true || false
        const checked = (type === 'checkbox' && values[input.key]) || false;
        const value = input.value || '';
        //   const checked = (type === 'checkbox') || false;
        return (
          <li key={input.key} className="type-input-list__item">
            <ToggleInput
              onChange={onChange}
              value={value}
              name={input.name}
              type={type}
              id={input.key}
              title={input.text}
              checked={checked}
            />
          </li>
        );
      })}
    </ul>
  );
};

RadioInputList.propTypes = {
  onChange: PropTypes.func,
  column: PropTypes.bool,
  row: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  wrap: PropTypes.bool,
};

export default RadioInputList;
