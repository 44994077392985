import React from 'react';
import PropTypes from 'prop-types';
import TypeList from './TypeList';
import TypeOpportunity from './TypeOpportunity';

const Type = ({ options, name, values, onChange, type, column, isReadable }) => {
  return (
    <>
      {type === 'checkbox' ? (
        <TypeOpportunity
          column={column}
          onChange={onChange}
          options={options}
          values={values}
          name={name}
          isReadable={isReadable}
        />
      ) : (
        <TypeList
          options={options}
          currentValue={values}
          name={name}
          onChange={onChange}
        />
      )}
    </>
  );
};

Type.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  column: PropTypes.bool,
  isReadable: PropTypes.bool
};

export default Type;
