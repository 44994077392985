import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';
import { Loader } from 'semantic-ui-react';
import Table from '../../components/shared/Table/Table';
import Routes from '../../constants/routes';
import FinancialData from '../../components/FinancialData/FinancialData';
import ContactData from '../../components/ContactData/ContactData';
import { typeRadioOptions, radioOptionsBuyers, statusOption } from './data';
import KeywordInput from '../../components/shared/Form/KeywordInput/KeywordInput';
import { useAuth0 } from '../../auth/react-auth0-spa';
import BasicTable from '../../components/Table/BasicTable';

import partners from '../../Data/partners';
import {
  getSubSectorOptions,
  isNumExist,
  setPartnerContact
} from '../../utils/utils';
import EditLayout from '../../components/EditLayout/EditLayout';
import ModalManager from '../../components/Modal/Modal';
import * as DeleteModalData from '../../components/Modal/DeleteModalData';
import SectorOptions from '../../Data/Select/SectorOptions';
import SubSectorOptions from '../../Data/Select/SubSectorOptions';
import {
  Select,
  Paper,
  Collapse,
  FormGroup,
  Input,
  Switcher,
  Textarea,
  RadioInputList,
  ActionCard
} from '../../components/shared';
import RegionsOptions from '../../Data/Select/RegionsOptions';
import Type from '../../components/Type/Type';
import SectorsData from '../../components/Sectors/Sectors';
import Report from '../../components/Report/Report';
import RichEditor from '../../components/RichText/RichEditor';

const OpportunitiesEdit = ({
  data,
  isLoading,
  errors,
  handleSubmit,
  handleChange,
  props,
  values,
  setFieldValue,
  deleteOpportunities,
  openModal,
  getContactsOptions,
  modalIsOpen,
  touched,
  setFieldTouched,
  getUserNameOptions,
  optionsData,
  opportunityLoading,
  isCreate,
  hasError,
  getOpportunitiesReport,
  getOpportunitiesDetails,
  getPartnersOptions,
  partnerId,
  additionalOptions,
  authUser,
  hasPartner,
  createContact
}) => {
  const { loading } = useAuth0();
  const { id } = useParams();
  const [form, setFormData] = useState({
    subSectorOptions: null
  });

  const [report, setReport] = useState(null);

  useEffect(() => {
    if (!report) {
      setReport(data.report);
    }
  }, [data.report]);

  const obj = values || false;

  const [feeShareDefaultText, setFeeShareDefaultText] = useState('');

  const initialValue = [
    {
      type: 'paragraph',
      default: true,
      children: [{ text: '' }]
    }
  ];

  useEffect(() => {
    async function fetchData() {
      if (id) {
        let a = await getOpportunitiesReport(id);
        setReport(a);
      }
    }

    fetchData();
  }, []);

  const [input, setInput] = useState(initialValue);

  useEffect(() => {
    if (obj.richDescription) {
      setInput(obj.richDescription);
    }
  }, [obj.richDescription]);

  const test = useSelector(state => state.options);

  useEffect(() => {
    async function fetchOptions() {
      Promise.all([
        getPartnersOptions(),
        getUserNameOptions({
          type: ['Acquirer', 'Opportunity', 'Partner/Internal']
        })
      ]);
    }

    // edit page by user id
    async function fetchDada() {
      await getOpportunitiesDetails(id);
      await setFormData({
        ...form,
        subSectorOptions: getSubSectorOptions(
          SubSectorOptions,
          obj && obj.sector
        )
      });
    }

    if (isNumExist(id) && id) {
      fetchDada();
    }
    let b = fetchOptions();
    // eslint-disable-next-line
  }, [id, partnerId, obj.partnerId]);

  const deleteSection = async () => {
    const { id } = data;
    await deleteOpportunities({
      businesses: [id]
    }).then(() => {
      props.history.push('/opportunities');
    });
  };

  useEffect(() => {
    if (obj.feesEnabled && !obj.fees) {
      if (test.partners && obj.partnerId) {
        for (let i = 0; i < test.partners.length; i++) {
          if (test.partners[i].key == obj.partnerId) {
            if (test.partners[i].label == 'Unloq') {
              setFeeShareDefaultText('100% Fees to Unloq');
            }
            if (test.partners[i].label == 'Langcliffe') {
              setFeeShareDefaultText(
                '10% of monthly fees and 0.5% of consideration'
              );
            }
            if (test.partners[i].label == 'Anderson Moore') {
              setFeeShareDefaultText('15% of all fees');
            }
            if (test.partners[i].label == 'TML') {
              setFeeShareDefaultText('30% of success fees');
            }
          }
        }
      }
    }

    if (!obj.feesEnabled && !obj.fees) {
      setFeeShareDefaultText('');
    }
  }, [obj.feesEnabled, obj.partnerId]);

  const getFeeShare = () => {
    if (obj && obj.fee) {
      return obj.fee;
    }
    if (
      partners &&
      data &&
      data.business &&
      data.business.partnerId &&
      partners[data.business.partnerId] &&
      partners[data.business.partnerId].feeShare
    ) {
      return partners[data.business.partnerId].feeShare;
    }
    return '';
  };
  const customOptions = [...(optionsData.users || [])];
  

  return (
    <>
      <form className="form-wrap">
        <EditLayout
          handleChange={handleChange}
          objWithData={obj.notes}
          cancelText="Cancel"
          saveText="Save"
          isSubmit={opportunityLoading}
          pageHeaderTitle="Opportunities"
          handleSubmit={handleSubmit}
          history={props.history}
          location={props.location}
          pathBack={Routes.OPPORTUNITIES}
        >
          <Loader active={loading || isLoading} />

          <Paper>
            <Collapse defaultOpen title="Details">
              <Row className="margin-bottom">
                <Col xs className="flex flex-wrap">
                  <FormGroup labelText="managed" name="managed" inline>
                    <Switcher
                      onChange={handleChange}
                      name="managed"
                      id="managed"
                      checked={obj.managed}
                    />
                  </FormGroup>
                  <FormGroup labelText="sensitive" name="sensitive" inline>
                    <Switcher
                      onChange={handleChange}
                      name="sensitive"
                      id="sensitive"
                      checked={obj.sensitive}
                    />
                  </FormGroup>
                  <FormGroup labelText="Clear names" name="clearNames" inline>
                    <Switcher
                      onChange={handleChange}
                      name="clearNames"
                      id="clearNames"
                      checked={obj.clearNames}
                    />
                  </FormGroup>
                  <FormGroup labelText="Fees" name="feesEnabled" inline>
                    <Switcher
                      onChange={handleChange}
                      name="feesEnabled"
                      id="feesEnabled"
                      checked={obj.feesEnabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="Company Name" name="businessName">
                    <Input
                      name="businessName"
                      onChange={handleChange}
                      value={obj.businessName}
                      maxLength="1000"
                    />
                    <p className="form-group__error">
                      {touched.businessName && errors.businessName}
                    </p>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="Anon Opportunity" name="codeName">
                    <Input
                      name="codeName"
                      value={obj.codeName || ''}
                      onChange={handleChange}
                      maxLength="1000"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="clear names via" name="clearNamesVia">
                    <Select
                      isAsync
                      options={optionsData && optionsData.users}
                      id="clearNamesVia"
                      value={
                        obj.clearNamesVia ? obj.clearNamesVia.toString() : ''
                      }
                      name="clearNamesVia"
                      setFieldValue={setFieldValue}
                      placeholder=""
                      isDisabled={!obj.clearNames}
                    />
                    <p className="form-group__error">
                      {touched.clearNamesVia && errors.clearNamesVia}
                    </p>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="company number" name="companyNumber">
                    <Input
                      name="companyNumber"
                      type="text"
                      value={
                        obj.companyNumber ? obj.companyNumber.toString() : ''
                      }
                      onKeyUp={() => setFieldTouched('companyNumber', true)}
                      onChange={handleChange}
                    />
                    <p className="form-group__error">
                      {touched.companyNumber && errors.companyNumber}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup labelText="Add region" name="region">
                    <Select
                      options={RegionsOptions}
                      id="region"
                      value={obj.region}
                      name="region"
                      setFieldValue={setFieldValue}
                      isSearchable
                    />
                    <p className="form-group__error">
                      {touched.region && errors.region}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup labelText="Sectors" name="sector">
                    <Select
                      options={SectorOptions}
                      id="sector"
                      name="sector"
                      placeholder=""
                      value={obj.sector}
                      onChangeCustom={(e, data) => {
                        const subSectorOptions = getSubSectorOptions(
                          SubSectorOptions,
                          e.value
                        );
                        setFormData({
                          ...form,
                          subSectorOptions
                        });
                        setFieldValue(data.name, e.value);
                      }}
                    />
                    <p className="form-group__error">
                      {touched.sector && errors.sector}
                    </p>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup labelText="Sub-Sectors" name="subSector">
                    <Select
                      id="subSector"
                      options={form.subSectorOptions}
                      value={obj.subSector}
                      name="subSector"
                      isDisabled={!form.subSectorOptions}
                      setFieldValue={setFieldValue}
                    />
                    <p className="form-group__error">
                      {touched.subSector && errors.subSector}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="status" name="status">
                    <Select
                      name="status"
                      id="status"
                      options={statusOption}
                      value={obj.status}
                      setFieldValue={setFieldValue}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <FormGroup labelText="partner" name="partnerId">
                    <Select
                      name="partnerId"
                      id="partnerId"
                      options={
                        hasPartner
                          ? [
                              {
                                label: authUser.partner.name,
                                key: authUser.partner.name,
                                value: authUser.partner.id.toString()
                              }
                            ]
                          : optionsData.partners
                      }
                      value={
                        hasPartner && isCreate
                          ? authUser.partner.id.toString()
                          : obj.partnerId.toString()
                      }
                      setFieldValue={setFieldValue}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormGroup labelText="Description" name="description">
                    <RichEditor
                      value={input}
                      setValue={setInput}
                      name="richDescription"
                      setFieldValue={setFieldValue}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </Paper>

          {/*<Paper>
            <Collapse title="Sectors" defaultOpen>
              <SectorsData
                sectors={SectorOptions}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
              />
            </Collapse>
          </Paper>*/}

          <Paper>
            <Collapse title="Financial Data" defaultOpen>
              <FinancialData
                errors={errors}
                touched={touched}
                financials={obj.financials}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                isCreate={isCreate}
                deletedFinancials={obj.deletedFinancials}
              />
            </Collapse>
          </Paper>

          <Paper>
            <Collapse defaultOpen title="Fee Share">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Textarea
                      id="feeShare"
                      name="fee"
                      partnerId={obj.partnerId}
                      value={
                        getFeeShare() ? getFeeShare() : feeShareDefaultText
                      }
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </Paper>

          <Paper>
            <Collapse title="Contacts" defaultOpen>
              <Row className="margin-bottom">
                <Col xs className="flex flex-wrap">
                  <FormGroup labelText="ID Verified" name="idVerified" inline>
                    <Switcher
                      onChange={handleChange}
                      name="idVerified"
                      id="idVerified"
                      checked={obj.idVerified}
                    />
                  </FormGroup>
                  <FormGroup
                    labelText="Shareholding Verified"
                    name="shareholdingVerified"
                    inline
                  >
                    <Switcher
                      onChange={handleChange}
                      name="shareholdingVerified"
                      id="shareholdingVerified"
                      checked={obj.shareholdingVerified}
                    />
                  </FormGroup>
                  <FormGroup
                    labelText="Financials Verified"
                    name="financialsVerified"
                    inline
                  >
                    <Switcher
                      onChange={handleChange}
                      name="financialsVerified"
                      id="financialsVerified"
                      checked={obj.financialsVerified}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ContactData
                errors={errors}
                touched={touched}
                contacts={obj.contactRoles}
                contactsOption={customOptions || []}
                onChange={handleChange}
                defaultContact={true}
                setFieldValue={setFieldValue}
                isCreate={isCreate}
                deletedContactsData={obj.deletedContactsData}
                getContactsOptions={getContactsOptions}
                isManaged={obj.managed}
                authUser={authUser}
                createContact={createContact}
                getUserNameOptions={getUserNameOptions}
                userType={'Opportunity'}
              />
            </Collapse>
          </Paper>

          <Paper>
            <Collapse title="Buyers">
              <Row middle="xs">
                <Col xs={12} className="margin-bottom">
                  <RadioInputList
                    row
                    wrap
                    options={radioOptionsBuyers}
                    values={obj}
                    type="checkbox"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup labelText="Remarketed Date" name="remarketedDate">
                    <Input
                      type="date"
                      name="remarketedDate"
                      value={obj.remarketedDate}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </Paper>
          <Paper>
            <Collapse title="Type">
              <Type
                options={typeRadioOptions}
                values={obj.type}
                onChange={handleChange}
                name="type"
                type="checkbox"
              />
            </Collapse>
          </Paper>
          {report && report.length && (
            <Paper>
              <Collapse title="Report">
                {report && <Report data={report} />}
              </Collapse>
            </Paper>
          )}

          {data.track && data.track.data && data.track.data.length && (
            <Paper>
              <Collapse title="History">
                <BasicTable
                  data={data.track.data}
                  columns={[
                    { Time: 'createdAt' },
                    { Field: 'changedid' },
                    { 'New Value': 'newvalue' }
                  ]}
                />
              </Collapse>
            </Paper>
          )}
        </EditLayout>
        {!isCreate && (
          <ActionCard
            danger
            onClick={() => openModal()}
            buttonText="Suspend"
            title="Suspend"
            text="Once you delete an Opportunity, all related Matches will also be deleted. This cannot be undone"
          />
        )}
      </form>
      <ModalManager
        header={DeleteModalData.title}
        modalIsOpen={modalIsOpen}
        content={DeleteModalData.bodyText}
        onAction={deleteSection}
        btnCancelText={DeleteModalData.btnCancelText}
        btnSaveText={DeleteModalData.btnSaveText}
      />
    </>
  );
};

OpportunitiesEdit.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  getUserNameOptions: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.object),
  values: PropTypes.objectOf(PropTypes.any),
  deleteOpportunities: PropTypes.func,
  openModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  touched: PropTypes.objectOf(PropTypes.any),
  optionsData: PropTypes.objectOf(PropTypes.array),
  opportunityLoading: PropTypes.bool,
  getOpportunitiesDetails: PropTypes.func,
  getPartnersOptions: PropTypes.func,
  isCreate: PropTypes.bool,
  hasError: PropTypes.any,
  partnerId: PropTypes.string,
  additionalOptions: PropTypes.func,
  authUser: PropTypes.objectOf(PropTypes.any),
  hasPartner: PropTypes.bool
};

export default OpportunitiesEdit;
