import { toast } from 'react-toastify';
import {
  USER_IS_LOADING,
  USER_ERROR,
  USER_SUCCESS,
  USER_OPTIONS_SUCCESS,
  USER_AUTH_SUCCESS
} from '../types';
import {
  Partners,
  User,
  Acquirers,
  AcquirerCriteria,
  Opportunity
} from '../../../api/Api';
import { createDynamicOptions } from '../../../utils/utils';
import { settingsToast } from '../../../utils/settings';

export const UserIsLoading = () => ({
  type: USER_IS_LOADING
});

export const UserDetailsHasError = payload => ({
  type: USER_ERROR,
  hasError: payload
});

export const UserDetailsIsSuccess = payload => ({
  type: USER_SUCCESS,
  data: payload.data
});

export const UserAuthDetailsIsSuccess = payload => ({
  type: USER_AUTH_SUCCESS,
  data: payload.data
});

export const SelectOptionsIsSuccess = payload => ({
  type: USER_OPTIONS_SUCCESS,
  options: payload
});

export function fetchUserDetailsData(id) {
  return async dispatch => {
    dispatch(UserIsLoading());
    try {
      const data = await User.fetchDetails(id);
      return dispatch(UserDetailsIsSuccess(data));
    } catch (e) {
      console.error(e);
      dispatch(UserDetailsHasError(e));
    }
  };
}

export function fetchUserDetailsByAuthId(id) {
  return async dispatch => {
    dispatch(UserIsLoading());
    try {
      const data = await User.fetchByAuthId(id);
      return dispatch(UserAuthDetailsIsSuccess(data));
    } catch (e) {
      console.error(e);
      dispatch(UserDetailsHasError(e));
    }
  };
}

export function updateUserDetailsData(id, obj) {
  return async dispatch => {
    dispatch(UserIsLoading());
    try {
      const data = await User.update(id, obj);
      toast.success(`Saved`, settingsToast);
      return dispatch(UserDetailsIsSuccess(data));
    } catch (e) {
      console.error(e);
      toast.error(`Error`, settingsToast);
      dispatch(UserDetailsHasError(e));
    }
  };
}

export function rerunMatches() {
  return async dispatch => {
    dispatch(UserIsLoading());
    try {
      const data = await User.rerunMatches();
      toast.success(`Matches rerun`, settingsToast);
      return dispatch(UserDetailsIsSuccess(data));
    } catch (e) {
      console.error(e);
      toast.error(`Error`, settingsToast);
      dispatch(UserDetailsHasError(e));
    }
  };
}

export function fetchSelectOptions(params) {
  return async dispatch => {
    try {
      const partners = await Partners.fetch(); // partners === associated partner
      const clearNamesVia = await User.fetchAll(params); // owner === clearNames === sourse - It's all the same
      const acquirers = await Acquirers.fetch(params);
      const profiles = await AcquirerCriteria.fetch(params);
      const businesses = await Opportunity.fetch(params);
      const options = {
        partners: await createDynamicOptions(partners.data.items, true),
        clearNamesVia: await createDynamicOptions(clearNamesVia.data.items),
        acquirers: await createDynamicOptions(acquirers.data.items, true),
        profiles: await createDynamicOptions(profiles.data.items, false, true),
        businesses: await createDynamicOptions(businesses.data.items, true)
      };
      dispatch(SelectOptionsIsSuccess(options));
    } catch (e) {
      console.error(e);
      dispatch(UserDetailsHasError(e));
    }
  };
}
