const BASE_URL = process.env.REACT_APP_API_URL;
const OPPORTUNITY_URL = '/businesses'; // businesses === opportunity
const OPPORTUNITY_UPDATE_URL = `${OPPORTUNITY_URL}/update`;
const OPPORTUNITY_UPDATESINGLE_URL = `${OPPORTUNITY_URL}/updateSingle`;

const OPPORTUNITY_DELETE_URL = `${OPPORTUNITY_URL}/delete`;
const OPPORTUNITY_CREATE_URL = `${OPPORTUNITY_URL}/create-user`;

const USERS_URL = '/users';
const CREATE_URL = `${USERS_URL}/create-user`;
const UPDATE_URL = `${USERS_URL}`;
const PARTNERS_URL = '/partners';

const ACQUIRERS_URL = '/acquirers';
const ACQUIRERS_UPDATE_URL = `${ACQUIRERS_URL}/update`;
const ACQUIRERS_DELETE_URL = `${ACQUIRERS_URL}/delete`;
const ACQUIRERS_HIDEACQUIRER_URL = `${ACQUIRERS_URL}/hideAcquirer`;
const ACQUIRERS_SHOWACQUIRER_URL = `${ACQUIRERS_URL}/showAcquirer`;



const ACQUIRERS_UPDATESINGLE_URL = `${ACQUIRERS_URL}/updateSingle`;

const ACQUIRER_CRITERIA_URL = '/acquirer-profiles'; // acquirer-profiles === acquirer-criteria
const ACQUIRER_CRITERIA_UPDATE_URL = `${ACQUIRER_CRITERIA_URL}`;
const ACQUIRER_CRITERIA_DELETE_URL = `${ACQUIRER_CRITERIA_URL}/delete`;
const ACQUIRER_CRITERIA_CLONE_URL = `${ACQUIRER_CRITERIA_URL}/clone`;
const ACQUIRER_CRITERIA_CREATE_URL = `${ACQUIRER_CRITERIA_URL}`;
const ACQUIRER_CRITERIA_HIDEACQUIRER_URL =  `${ACQUIRER_CRITERIA_URL}/hide`;
const ACQUIRER_CRITERIA_SHOWACQUIRER_URL =  `${ACQUIRER_CRITERIA_URL}/show`;

const EMAIL_HISTORY_URL = '/email-history';

const MATCHES_URL = '/matches';
const MATCHES_UPDATE_URL = `${MATCHES_URL}/update`;
//const MATCHES_DELETE_URL = `${MATCHES_URL}/delete`;

const REMINDERS_URL = '/reminders';

const MATCH_HISTORY_URL = '/match-history';

const TRACKERS_URL = '/tracker';
const TRACKERS_DELETE_URL = '/tracker/delete';

const CONTACTS_URL = '/users';
const CONTACTS_CREATE_URL = `${USERS_URL}/create-user`;
const CONTACTS_DELETE_URL = `${USERS_URL}/delete`;

const FETCH = 'fetch';
const CREATE = 'create';
const DEL = 'del';
const UPDATE = 'update';

export {
  BASE_URL,
  OPPORTUNITY_URL,
  OPPORTUNITY_UPDATE_URL,
  REMINDERS_URL,
  OPPORTUNITY_UPDATESINGLE_URL,
  OPPORTUNITY_DELETE_URL,
  OPPORTUNITY_CREATE_URL,
  USERS_URL,
  CREATE_URL,
  UPDATE_URL,
  ACQUIRERS_URL,
  ACQUIRERS_UPDATE_URL,
  ACQUIRERS_UPDATESINGLE_URL,
  ACQUIRERS_DELETE_URL,
  ACQUIRERS_SHOWACQUIRER_URL,
  ACQUIRERS_HIDEACQUIRER_URL,
  ACQUIRER_CRITERIA_URL,
  ACQUIRER_CRITERIA_UPDATE_URL,
  ACQUIRER_CRITERIA_DELETE_URL,
  ACQUIRER_CRITERIA_CLONE_URL,
  ACQUIRER_CRITERIA_CREATE_URL,
  ACQUIRER_CRITERIA_HIDEACQUIRER_URL,
  ACQUIRER_CRITERIA_SHOWACQUIRER_URL,
  
  PARTNERS_URL,
  MATCHES_URL,
  MATCH_HISTORY_URL,
  MATCHES_UPDATE_URL,
  TRACKERS_URL,
  FETCH,
  CREATE,
  DEL,
  UPDATE,
  CONTACTS_URL,
  CONTACTS_CREATE_URL,
  CONTACTS_DELETE_URL,
  TRACKERS_DELETE_URL,
  EMAIL_HISTORY_URL
};
