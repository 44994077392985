export default [
  {
    label: 'Interested',
    key: 'Yes',
    value: 'Yes',
  },
  {
    label: 'Not interested',
    key: 'No',
    value: 'No',
  },
  {
    label: 'Saved',
    key: 'Maybe',
    value: 'Maybe',
  },
]