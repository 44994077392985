const headerTitles = [
  '#',
  'company',
  'description',
  'mandates',
  'type',
  'company number',
  'owner',
  'partner'
];

export const typeRadioOptions = [
  {
    key: 'corporate',
    name: 'corporate',
    text: 'Corporate',
    checked: false
  },
  {
    key: 'privateEquity',
    name: 'privateEquity',
    text: 'Private equity',
    checked: false
  },
  {
    key: 'chairman',
    name: 'chairman',
    text: 'Chairman',
    checked: false
  },
  {
    key: 'mbi',
    name: 'mbi',
    text: 'MBI',
    checked: false
  },
  {
    key: 'hnwi',
    name: 'hnwi',
    text: 'HNWI',
    checked: false
  },
  {
    key: 'peOwned',
    name: 'peOwned',
    text: 'PE Owned',
    checked: false
  }
];

export const radioOptionsBuyers = [
  {
    key: 'overseasBuyers',
    name: 'overseasBuyers',
    checked: false,
    text: 'Overseas Buyers'
  },
  {
    key: 'inExclusivity',
    name: 'inExclusivity',
    checked: false,
    text: 'Exclusivity'
  },
  {
    key: 'peBuyers',
    name: 'peBuyers',
    checked: false,
    text: 'PE Buyers'
  }
];

export const statusOption = [
  {
    key: 'forSale',
    name: 'forSaleOption',
    value: true,
    text: 'For Sale'
  },
  {
    key: 'offMarket',
    name: 'offMarketOption',
    value: false,
    text: 'Off market'
  }
];

export default headerTitles;
