import { toast } from 'react-toastify';
import { EmailHistory, Matches, Trackers } from '../../../api/Api';
import {
  matchesHasError,
  matchesIsLoading,
  matchesSuccess,
  matchesRequestDataSuccess,
  matchesDelete
} from './matchesActions';
import { closeModal } from '../modal/modalActions';
import Routes from '../../../constants/routes';
import { settingsToast } from '../../../utils/settings';
import { formatString } from '../../../utils/utils';

export function getMatchesData(params) {
  return async dispatch => {
    dispatch(matchesIsLoading());

    try {
      const data = await Matches.fetch(params);
      return dispatch(matchesSuccess(data));
    } catch (e) {
      dispatch(matchesHasError(e));
    }
  };
}

export function getMatchesDetails(params) {
  console.log(`getMatchesDetails params: ${JSON.stringify(params)}`);

  const id = params.id;

  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      let data = await Matches.fetchDetails(id);
      let emailHistory = await EmailHistory.getByMatchId(id);
      let track = await Trackers.getTrackerByAssociatedId(
        data.data.id,
        'matches'
      );
      data.data.track = track.data;
      data.data.emailHistory = emailHistory.data;
      return dispatch(matchesSuccess(data));
    } catch (e) {
      dispatch(matchesHasError(e));
    }
  };
}

export function deleteMatches(data) {
  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      // Get Matches IDs
      var matches;

      if ('opportunities' in data) {
        matches = await Matches.fetch({ businessId: data.opportunities });
      }
      if ('acquirerCriteria' in data) {
        matches = await Matches.fetch({ profileId: data.acquirerCriteria });
      }
      if ('acquirers' in data) {
        matches = await Matches.fetch({ acquirerId: data.acquirers });
      }

      let matches_data = matches.data.items;
      var ids = [];
      for (var i in matches_data) {
        ids.push(matches_data[i].id);
      }

      if (ids.length > 0) {
        await Matches.del({ matches: ids });
        return dispatch(matchesDelete('Done'));
      }

      return dispatch(matchesDelete('Failed'));
    } catch (e) {
      console.log(e);
    }
  };
}

export function deleteMatch(id) {
  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      await Matches.del({ matches: [id] });
      return dispatch(matchesDelete('Done'));
    } catch (e) {
      console.log(e);
    }
  };
}

// Approve / Unapprove
export function onRequestApproveMatches(obj, props) {
  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      const data = await Matches.update(obj);
      dispatch(matchesRequestDataSuccess(data));
      toast.success('Saved', settingsToast);
      if (props) {
        dispatch(closeModal());
        dispatch(() => props.history.push(`${Routes.MATCHES}`));
      }
    } catch (e) {
      toast.error('Error', settingsToast);
      dispatch(matchesHasError(e));
    }
  };
}

export function setNewUpdatedAt(obj) {
  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      const data = await Matches.update(obj);
      dispatch(matchesRequestDataSuccess(data));
    } catch (e) {
      dispatch(matchesHasError(e));
    }
  };
}

// Saving data
export function setMatchesDetails(obj, props) {
  return async dispatch => {
    dispatch(matchesIsLoading());
    try {
      const track_keys = [
        'notes',
        'stage',
        'approved',
        'response',
        'acquirerScore',
        'vendorScore'
      ];

      const prev_data = await Matches.fetchDetails(obj.matches[0].id);
      const data = await Matches.update(obj);

      var tracked_changes = [];

      track_keys.forEach(function(k) {
        let old_value = formatString(prev_data['data'][k]);
        let new_value = formatString(obj['matches'][0][k]);
        let id = formatString(obj['matches'][0]['id']);
        if (old_value !== new_value) {
          tracked_changes.push({
            key: k,
            oldvalue: old_value,
            newvalue: new_value,
            associatedid: id
          });
        }
      });

      for (const t of tracked_changes) {
        let payload = {
          user: 1,
          table: 'matches',
          changedid: t.key,
          previousvalue: formatString(t.oldvalue),
          newvalue: formatString(t.newvalue),
          associatedid: t.associatedid
        };

        await Trackers.create(payload);
      }

      dispatch(matchesRequestDataSuccess(data));
      toast.success('Saved', settingsToast);
      if (props) {
        dispatch(closeModal());
        dispatch(() => props.history.push(`${Routes.MATCHES}`));
      }
    } catch (e) {
      toast.error('Error', settingsToast);
      dispatch(matchesHasError(e));
    }
  };
}
