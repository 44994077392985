export const headerTitles = [
  '#',
  'acquirer',
  'status',
  'sectors',
  'regions',
  'turnover',
  'EBITDA',
  'notes',
  'partner'
];

export const criteriaStatusOptions = [
  {
    key: 'active',
    name: 'active',
    value: 'active',
    label: 'Active'
  },
  {
    key: 'onHold',
    name: 'onHold',
    value: 'onHold',
    label: 'On Hold'
  },
  {
    key: 'cold',
    name: 'cold',
    value: 'cold',
    label: 'Cold'
  },

];
