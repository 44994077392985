import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import clsx from 'clsx';
import InfoButton from '../Icon/Icon';
import Info from '../../../asset/img/info-ico.svg';

const FormGroup = memo(
  ({ children, name, labelText, inline, reverse, tooltip, content }) => {
    const label = labelText && (
      <label className="form-group__label" htmlFor={name}>
        {labelText}
        {tooltip && (
          <Popup
            className="mna-tooltip"
            size="tiny"
            position="right center"
            content={content}
            trigger={
              <button className="mna-btn--icon">
                <InfoButton icon={Info} size="16px" />
              </button>
            }
          />
        )}
      </label>
    );
    return (
      <div
        className={`form-group ${clsx({
          'form-group--inline': inline,
          'form-group--reverse': reverse,
        })}`}
      >
        {label}
        {children}
      </div>
    );
  }
);

FormGroup.defaultProps = {
  withLabel: true,
};

FormGroup.propTypes = {
  withLabel: PropTypes.bool,
  children: PropTypes.node,
  labelText: PropTypes.string,
  inline: PropTypes.bool,
  reverse: PropTypes.bool,
  name: PropTypes.string,
  tooltip: PropTypes.bool,
  content: PropTypes.node,
};

export default FormGroup;
