// all screens have the same logic behavior, with the same states
class BaseState {
  constructor() {
    if (!BaseState.instance) {
      this.data = [];
      this.isLoading = false;
      this.hasError = false;
      this.postResponse = [];
      this.deletedResponse = [];
      this.options = [];
      this.cloneResponse = [];
      BaseState.instance = this;
    }

    return BaseState.instance;
  }
}

const instance = new BaseState();
Object.freeze(instance);

class InitialState {
  static opportunity = instance;

  static user = instance;

  static acquirers = instance;

  static acquirerCriteria = instance;

  static matches = instance;

  static restore = instance;

  static trackers = instance;

  static contacts = instance;

  static emailHistory = instance;
}

export default InitialState;
