import React from 'react';
import { Table } from 'semantic-ui-react';
import { Icon, Numeral } from '../../components/shared';
import Notes from '../../asset/img/notes-ico.svg';
import PartnerLogo from '../../components/shared/PartnerLogo/PartnerLogo';
import { Tooltip } from '@material-ui/core';
import DescriptionIcon from '@mui/icons-material/Description';
import Label from '../../components/shared/Label/Label';
import { criteriaStatusOptions } from './data';

const TableBody = ({ data, redirectToDetailsPage }) => {
  const renderSectors = (sector, subSector) => {
    if (sector) {
      var sectorArray = [];
      let parsedSector = JSON.parse(sector);
      const parsedSubSectors = parsedSector.filter(s => !!s);
      if (parsedSubSectors.length) {
        for (var i = 0; i < parsedSubSectors.length; i++) {
          if (i < 3) {
            sectorArray.push(parsedSubSectors[i].label);
          } else {
            sectorArray.push('...');
            break;
          }
        }
        return sectorArray.join(', ');
      }
      return '';
    }
  };

  const getStatusLabel = status => {
    const statusLabel = criteriaStatusOptions.find(
      item => item.value === status
    );
    return statusLabel ? statusLabel.label : '';
  };

  return (
    <>
      {data.items.map(
        ({
          id,
          netProfitRangeLow,
          netProfitRangeHigh,
          turnoverRangeLow,
          turnoverRangeHigh,
          acquirer,
          notes,
          sector,
          subSector,
          region,
          status
        }) => (
          <Table.Row key={id} onClick={() => redirectToDetailsPage(id)}>
            <Table.Cell>
              <div className="table-icons">{id}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table title--name">
                {acquirer.companyName}
              </div>
              {acquirer.firstName} {acquirer.lastName}
            </Table.Cell>
            <Table.Cell>
              <Label
                text={getStatusLabel(status)}
                basic
                success={status === 'active'}
                primary={status === 'offMarket'}
                warning={status === 'cold' || status === 'onHold'}
                danger={status === 'suspend'}
              />
            </Table.Cell>
            <Table.Cell>
              <div className="mna-table__sectors">
                {renderSectors(sector, subSector)}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">
                {region &&
                  region
                    .map(item => {
                      return item
                        .split(' - ')
                        .slice(-2)
                        .join(' - ');
                    })
                    .join(', ')}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">
                <Numeral value={turnoverRangeLow} /> -{' '}
                <Numeral value={turnoverRangeHigh} />
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">
                {netProfitRangeLow > 0 || netProfitRangeHigh > 0 ? (
                  <>
                    <Numeral value={netProfitRangeLow} /> -{' '}
                    <Numeral value={netProfitRangeHigh} />
                  </>
                ) : (
                  ''
                )}
              </div>
            </Table.Cell>
            <Table.Cell className="table-icons">
              <div className="table-icons__wrapper table-icons__notes">
                {notes && (
                  <Tooltip title="Notes">
                    <DescriptionIcon />
                  </Tooltip>
                )}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="mna-table__logo">
                <PartnerLogo id={acquirer.partnerId} />
              </div>
            </Table.Cell>
          </Table.Row>
        )
      )}
    </>
  );
};
export default TableBody;
