
import { Trackers, Opportunity, AcquirerCriteria, Acquirers } from '../../../api/Api';

import {
    RESTORE_SUCCESS
  } from '../types';

  export function restoreSuccess(payload) {
    return {
      type: RESTORE_SUCCESS,
      data: payload.data,
    };
  }

  export function tableSuccess(payload) {
    return {
      type: 'TABLE_SUCCESS',
      data: payload.data,
    };
  }
  
export function getRestoreData(params) {
  return async dispatch => {
    try {
      const data = await Trackers.fetch(params);
      dispatch(restoreSuccess(data));
    } catch (e) {
      console.log('Error', e)
    }
  };
}


export async function restoreObject(table, data, trackerid, props) {

    trackerid = {'id':trackerid}

    if (table==='opportunities') {
        data = JSON.parse(data)
        data = {'id':data.data['id']}
        await Promise.all([Opportunity.updateSingle(data), Trackers.del(trackerid)]);
    }
    if (table==='acquirers') {
        data = JSON.parse(data)
        data = {'id':data.data['id']}
        await Promise.all([Acquirers.showAcquirer(data), Trackers.del(trackerid)]);
    }
    if (table=='acquirerProfiles') {
        data = JSON.parse(data)
        data = {'id':data.data['id']}
        await Promise.all([AcquirerCriteria.showAcquirer(data), Trackers.del(trackerid)]);
    }

}