import React, { useEffect, useState } from 'react';
import LineGraph from '../Charts/LineGraph';
import { matchHistory } from '../Charts/Parse/Parse';

const Report = ({ data }) => {
  const [matchHistoryData, setMatchHistoryData] = useState([]);
  const [showMatchHistory, setShowMatchHistory] = useState(false);

  const [dateRange, setDateRange] = useState([
    new Date(new Date().setDate(new Date().getDate() - 1000)),
    new Date()
  ]);

  const [firstMatchDate, setFirstMatchDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1000))
  );

  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(),
    new Date()
  ]);

  const findEarliestMatchDate = () => {
    const earliestMatchDate = data.matches.reduce((earliest, match) => {
      const matchDate = new Date(match.match.createdAt);
      if (matchDate < earliest) {
        return matchDate;
      }
      return earliest;
    }, new Date());
    return earliestMatchDate;
  };

  useEffect(() => {
    const earliest = findEarliestMatchDate();
    // take two days from earliest date
    setFirstMatchDate(new Date(earliest.setDate(earliest.getDate() - 2)));
  }, [data]);

  // For Graph
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let historyData = matchHistory(data, dateRange);
      setMatchHistoryData(historyData);

      if (historyData && Object.keys(historyData).length) {
        setShowMatchHistory(true);
      }
    }
  }, [data, dateRange]);

  // For boxes

  const [approvedMatches, setApprovedMatches] = useState(data.matches.length);
  const [acceptedMatches, setAcceptedMatches] = useState(data.vendorAccepted.length);
  const [introducedMatches, setIntroducedMatches] = useState(data.introduced.length);
  const [dealCompleteMatches, setDealCompleteMatches] = useState(data.dealComplete.length);
  
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      // Find matches played between dateRange
      const approvedMatches = data.matches.filter(match => {
        const matchDate = new Date(match.match.createdAt);
        return matchDate >= dateRange[0] && matchDate <= dateRange[1];
      });

      const acceptedMatches = data.vendorAccepted.filter(match => {
        const matchDate = new Date(match.createdAt);
        return matchDate >= dateRange[0] && matchDate <= dateRange[1];
      });
      
      // Find introduced matches between dateRange
      const introducedMatches = data.introduced.filter(match => {
        const matchDate = new Date(match.createdAt);
        return matchDate >= dateRange[0] && matchDate <= dateRange[1];
      });

      // Find Deal complete between dateRange
      const dealCompleteMatches = data.dealComplete.filter(match => {
        const matchDate = new Date(match.createdAt);
        return matchDate >= dateRange[0] && matchDate <= dateRange[1];
      });
      
      setApprovedMatches(approvedMatches.length);
      setAcceptedMatches(acceptedMatches.length);
      setIntroducedMatches(introducedMatches.length);
      setDealCompleteMatches(dealCompleteMatches.length);
    }
  }, [data, dateRange]);



  return (
    <>
      <div className="report__row">
        <div className="report__col">
          <div className="report__title">Total Approved Matches</div>
          <div className="report__subtitle">{approvedMatches}</div>
        </div>
        <div className="report__col">
          <div className="report__title">Accepted</div>
          <div className="report__subtitle">{acceptedMatches}</div>
        </div>

        <div className="report__col">
          <div className="report__title">Introduced</div>
          <div className="report__subtitle">{introducedMatches}</div>
        </div>
        <div className="report__col">
          <div className="report__title">Deal Complete</div>
          <div className="report__subtitle">{dealCompleteMatches}</div>
        </div>
      </div>

      {showMatchHistory && (
        <span>
          <div className="report__graph-datetime">
            <form className="datepicker">
              <select
                className="datepicker-dropdown"
                onChange={e => {
                  if (e.target.value === 'Last Month') {
                    setSelectedDateRange([
                      new Date(new Date().setDate(new Date().getDate() - 60)),
                      new Date(new Date().setDate(new Date().getDate() - 30))
                    ]);
                  }
                  if (e.target.value === 'Last Quarter') {
                    setSelectedDateRange([
                      new Date(new Date().setDate(new Date().getDate() - 90)),
                      new Date()
                    ]);
                  }

                  if (e.target.value === 'This Month') {
                    setSelectedDateRange([
                      new Date(new Date().setDate(new Date().getDate() - 1)),
                      new Date()
                    ]);
                  }

                  if (e.target.value === 'All Time') {
                    setSelectedDateRange([firstMatchDate, new Date()]);
                  }
                  //setSelectedDateRange(e.target.value);
                }}
              >
                <option value="">Select Date Range</option>
                <option value="Last Quarter">Last Quarter</option>
                <option value="This Month">This Month </option>
                <option value="Last Month">Last Month</option>
                <option value="All Time">All Time</option>
              </select>
              <input
                type="date"
                className="datepicker-input"
                value={selectedDateRange[0].toISOString().substring(0, 10)}
                onChange={e => {
                  const startDate = new Date(e.target.value);
                  setSelectedDateRange([startDate, selectedDateRange[1]]);
                }}
              />
              <input
                type="date"
                className="datepicker-input"
                value={selectedDateRange[1].toISOString().substring(0, 10)}
                onChange={e => {
                  const endDate = new Date(e.target.value);
                  setSelectedDateRange([selectedDateRange[0], endDate]);
                }}
              />
              <button
                className="datepicker-button"
                onClick={e => {
                  e.preventDefault();

                  setDateRange([selectedDateRange[0], selectedDateRange[1]]);
                }}
              >
                Apply
              </button>
            </form>
          </div>

          <LineGraph data={matchHistoryData} />
        </span>
      )}
    </>
  );
};

export default Report;
