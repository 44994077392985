import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

const Toast = ({ position, autoClose }) => {
  return (
    <div className="mna-toast text-center">
      <ToastContainer autoClose={autoClose} position={position} />
    </div>
  );
};

Toast.propTypes = {
  autoClose: PropTypes.number,
  position: PropTypes.string,
};

export default Toast;
