import React from 'react';
import PropTypes from 'prop-types';
import SelectedIcon from '../../../../asset/img/SlectedIcon';

const AccountRadioButtonItem = ({
  labelText,
  name,
  value,
  currentValue,
  onChange,
  onClick,
}) => {
  const checked = currentValue === value;
  return (
    <div className="account-radio-list__item">
      <input
        type="radio"
        name={name}
        id={value}
        value={labelText}
        onChange={onChange}
        checked={checked}
        onClick={onClick}
      />
      <label htmlFor={value}>
        {labelText}
        <SelectedIcon className="account-radio-list__ico" />
      </label>
    </div>
  );
};

AccountRadioButtonItem.propTypes = {
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default AccountRadioButtonItem;
