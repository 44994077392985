import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import numeral from 'numeral';
import AcquirerCriteriaEdit from '../../pages/AcquirerCriteria/AcquirerCriteriaEdit';
import {
  getAcquirersOptions,
  getUserNameOptions,
  getContactsOptions,
  getPartnersOptions,
} from '../../redux/actions/options/optionsActions';
import {
  createAcquirerCriteria,
  deleteAcquirerCriteria,
  cloneAcquirerCriteria,
  getAcquirerCriteriaDetails,
} from '../../redux/actions/acquirerCriteria/request';
import {createContact} from '../../redux/actions/contacts/contactsActions'
import { openModal } from '../../redux/actions/modal/modalActions';
import { CREATE, UPDATE } from '../../constants/api';
import { getValidationSchema } from './ValidationSchema';
import { getBasicFields } from './FormikHelpers';
import { getAcquirersDetails } from '../../redux/actions/acquirers/request';
import { additionalOptions } from '../../redux/actions/contacts/contactsActions';

const mapStateToProps = store => {
  return {
    data: store.acquirerCriteria.data,
    acquirer: store.acquirers.data,
    acquirerCriteriaLoading: store.acquirerCriteria.isLoading,
    modalIsOpen: store.modal.modalIsOpen,
    authId: store.user.userAuth.id,
    optionsData: store.options,
    hasError: store.acquirerCriteria.hasError,
    items: store.keywords,
    contactOptions: store.contacts.additionalOptions,
    optionsData: store.options,

    partnerId: store.globalOptions.partnerId,
    authUser: store.user.userAuth,
  };
};

const mapDispatchToProps = (dispatch, { props }) => ({
  getAcquirerCriteriaDetails: id => dispatch(getAcquirerCriteriaDetails(id)),
  getAcquirersDetails: id => dispatch(getAcquirersDetails(id)),
  getAcquirersOptions: params => dispatch(getAcquirersOptions(params)),
  getUserNameOptions: params => dispatch(getUserNameOptions(params)),
  getContactsOptions: params => dispatch(getContactsOptions(params)),
  getPartnersOptions: params => dispatch(getPartnersOptions(params)),
  deleteAcquirerCriteria: data => dispatch(deleteAcquirerCriteria(data, props)),
  cloneAcquirerCriteria: id => dispatch(cloneAcquirerCriteria(id, props)),
  additionalOptions: data => dispatch(additionalOptions(data)),

  openModal: () => dispatch(openModal()),
  createAcquirerCriteria: (action, data) =>
    dispatch(createAcquirerCriteria(action, data, props)),
  createContact: (data, params) => dispatch(createContact(data, params)),
  getContactsOptions: params => dispatch(getContactsOptions(params)),
});

const convertNumbers = num => {
  return parseInt(numeral(num).format('1000'), 10);
};

const maxValue = (prev, current) => {
  if (prev > current) {
    return prev;
  }
  return current;
};

const minValue = (prev, current) => {
  if (prev < current) {
    return prev;
  }
  return current;
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const currentAcquirer = props.data;
      const { acquirer } = props;

      let contactRoles = get(currentAcquirer, 'contacts', []);
      if (props.isCreate) {
        contactRoles = get(acquirer, 'contacts', []);
        if (isEmpty(acquirer)) {
          return {
            ...getBasicFields(),
          };
        }
        return {
          ...getBasicFields(),
          acquirerId: get(acquirer, 'id', ''),
          managed: get(acquirer, 'managed', false),
          contactRoles: contactRoles.length
            ? contactRoles.map(item => {
                return {
                  role: item.role,
                  contactId: item.contact.id.toString(),
                };
              })
            : [],
        };
      }
      return {
        acquirerId: get(currentAcquirer, 'acquirerId', ''),
        ownerId: get(currentAcquirer, 'ownerId', ''),
        notes: get(currentAcquirer, 'notes', ''),
        description: get(currentAcquirer, 'description', ''),
        turnoverRangeLow: get(currentAcquirer, 'turnoverRangeLow', ''),
        turnoverRangeHigh: get(currentAcquirer, 'turnoverRangeHigh', ''),
        netProfitRangeLow: get(currentAcquirer, 'netProfitRangeLow', ''),
        netProfitRangeHigh: get(currentAcquirer, 'netProfitRangeHigh', ''),
        region: get(currentAcquirer, 'region', []),
        keywords: get(currentAcquirer, 'keywords', []),
        richDescription: get(currentAcquirer, 'richDescription', ''),
        sector: get(currentAcquirer, 'sector'),
        subSector: get(currentAcquirer, 'subSector'),
        sectors: get(currentAcquirer, 'sector'),
        acquisitionTitle: get(currentAcquirer, 'acquisitionTitle', ''),
        status: get(currentAcquirer, 'status', ''),
        fee: get(currentAcquirer, 'fee', null),
        contactRoles: contactRoles.length
          ? contactRoles.map(item => {
              return {
                role: item.role,
                contactId: item.contact.id.toString(),
              };
            })
          : [],
        deletedContactsData: [],
        managed: get(currentAcquirer, 'acquirer.managed', false),
      };
    },
    handleSubmit: async (values, { props }) => {
      console.log('status', values.status);
      const sector = values.sectors.toString()
      const subSector = values.sectors.toString()

      const richDescriptionParsed = JSON.stringify(values.richDescription)
      const validatedContactRoles = values.contactRoles.map(item => {
        return {
          role: item.role,
          contactId: parseInt(item.contactId, 10),
        };
      });

      if (props.isCreate) {
        const {
          // ownerId,
          acquirerId,
          region,
          notes,
          description,
          turnoverRangeLow,
          turnoverRangeHigh,
          netProfitRangeLow,
          netProfitRangeHigh,
          status,
          keywords,
          fee,
        } = values;

        const data = {
          acquirerId: parseInt(acquirerId, 10),
          ownerId: parseInt(values.ownerId, 10),
          region,
          sector,
          subSector,
          notes,
          description,
          richDescription: richDescriptionParsed,
          turnoverRangeLow: minValue(
            convertNumbers(turnoverRangeLow),
            convertNumbers(turnoverRangeHigh)
          ),
          turnoverRangeHigh: maxValue(
            convertNumbers(turnoverRangeLow),
            convertNumbers(turnoverRangeHigh)
          ),
          netProfitRangeLow: minValue(
            convertNumbers(netProfitRangeLow),
            convertNumbers(netProfitRangeHigh)
          ),
          netProfitRangeHigh: maxValue(
            convertNumbers(netProfitRangeLow),
            convertNumbers(netProfitRangeHigh)
          ),
          keywords,
          fee,
          contactRoles: validatedContactRoles,
          status,
        };


        return props.createAcquirerCriteria(CREATE, data);
      }
      const {
        acquirerId,
        sectors,
        ownerId,
        turnoverRangeLow,
        turnoverRangeHigh,
        netProfitRangeLow,
        netProfitRangeHigh,
        status,
        deletedContactsData,
        contactRoles,
        managed,
        ...restData
      } = values;



      const obj = {
        acquirerProfiles: [
          {
            id: props.data.id,
            ownerId: parseInt(values.ownerId, 10),
            sector:sector.toString(),
            subSector:subSector.toString(),
            richDescription: richDescriptionParsed,
            turnoverRangeLow: minValue(
              convertNumbers(turnoverRangeLow),
              convertNumbers(turnoverRangeHigh)
            ),
            turnoverRangeHigh: maxValue(
              convertNumbers(turnoverRangeLow),
              convertNumbers(turnoverRangeHigh)
            ),
            netProfitRangeLow: minValue(
              convertNumbers(netProfitRangeLow),
              convertNumbers(netProfitRangeHigh)
            ),
            netProfitRangeHigh: maxValue(
              convertNumbers(netProfitRangeLow),
              convertNumbers(netProfitRangeHigh)
            ),
            contactRoles: validatedContactRoles,
            status,
            ...restData,
          },
        ],
      };

      return props.createAcquirerCriteria(UPDATE, obj);
    },
    validationSchema: props => {
      return getValidationSchema(props);
    },
  })
)(AcquirerCriteriaEdit);
