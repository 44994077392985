import React from 'react';
import PropTypes from 'prop-types';

const Keyword = ({ item, index, onRemoveItem }) => (
  <div>
    <div className="mna-keyword__item">
      {item}
      <button type="button" className="mna-keyword__btn" onClick={() => onRemoveItem(index)}>
        X
      </button>
    </div>
  </div>
);

Keyword.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
};

export default Keyword;
