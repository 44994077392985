export default [
  {
    label: 'None',
    key: 'None',
    value: 'None',
  },
  {
    label: 'Opportunity',
    key: 'Opportunity',
    value: 'Opportunity',
  },
  {
    label: 'Acquirer',
    key: 'Acquirer',
    value: 'Acquirer',
  },
  {
    label: 'Partner/Internal',
    key: 'Partner/Internal',
    value: 'Partner/Internal',
  }
]