const defaultState = {
  partnerId: null,
  partnerName: 'All Data',
  limit: 10,
};

export default function globalOptionsReducer(state = defaultState, action) {


  switch (action.type) {
    case 'SET_PARTNER': {
      return {
        ...state,
        partnerId: action.data.partnerId,
        partnerName: action.data.partnerName,
      };
    }
    case 'SET_LIMIT_ITEMS': {
      return {
        ...state,
        limit: action.data,
      };
    }
    default:
      return state;
  }
}
