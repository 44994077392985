import {
  ACQUIRERS_IS_LOADING,
  ACQUIRERS_SUCCESS,
  ACQUIRERS_ERROR,
  ACQUIRERS_REMOVE,
  ACQUIRERS_POST_DATA_SUCCESS,
} from '../types';

export const acquirersIsLoading = () => ({
  type: ACQUIRERS_IS_LOADING,
});

export const acquirersSuccess = payload => ({
  type: ACQUIRERS_SUCCESS,
  data: payload.data,
});

export const acquirersHasError = payload => ({
  type: ACQUIRERS_ERROR,
  hasError: payload,
});

// CREATE / UPDATE data
export const acquirersRequestDataSuccess = payload => ({
  type: ACQUIRERS_POST_DATA_SUCCESS,
  postResponse: payload,
});

export const acquirersDelete = payload => ({
  type: ACQUIRERS_REMOVE,
  deletedResponse: payload,
});
