import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import sidebardData from './Siderbar.data';
import SidebarNav from './SidebarNav';
import { useAuth0 } from '../../auth/react-auth0-spa';
import { onClickToggleSidebar } from '../../redux/actions/sidebar/sidebarActions';
import { fetchUserDetailsByAuthId } from '../../redux/actions/user/userActions';
import { changePartner } from '../../redux/actions/global/globalActions';
import { getPartnersOptions } from '../../redux/actions/options/optionsActions';

const SidebarContainer = ({
  sidebarIsOpen,
  onClickToggleSidebar,
  fetchUserDetailsByAuthId,
  getPartnersOptions,
  userData,
  partners,
  partner,
  onClickChangePartner,
  authDetails
}) => {
  const { user, logout } = useAuth0();
  const toggleSidebar = () => {
    onClickToggleSidebar();
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  return (
    <div
      className={`mna-sidebar flex-column ${clsx({
        'mna-sidebar--open': sidebarIsOpen
      })}`}
    >
      <SidebarNav
        sidebarIsOpen={sidebarIsOpen}
        sidebardData={sidebardData}
        toggleSidebar={toggleSidebar}
        logoutWithRedirect={logoutWithRedirect}
        user={user}
        userData={authDetails}
        items={partners}
        fetchUserDetailsByAuthId={fetchUserDetailsByAuthId}
        fetchSelectOptions={getPartnersOptions}
        partner={partner}
        onClickChangePartner={onClickChangePartner}
      />
    </div>
  );
};

const mapStateToProps = store => {
  return {
    userData: store.user.data,
    sidebarIsOpen: store.sidebar.sidebarIsOpen,
    partners: store.options.partners,
    partner: store.globalOptions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClickToggleSidebar: () => dispatch(onClickToggleSidebar()),
    fetchUserDetailsByAuthId: id => dispatch(fetchUserDetailsByAuthId(id)),
    getPartnersOptions: () => dispatch(getPartnersOptions()),
    onClickChangePartner: data => dispatch(changePartner(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarContainer);
