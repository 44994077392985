import * as Yup from 'yup';

export const getValidationSchema = ({ isCreate }) => {
  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    partnerId: Yup.string().required('Required'),
    email: isCreate
      ? Yup.string()
          .email('Email must be an email')
          .required('Required')
      : Yup.string().notRequired(),
    type: isCreate
      ? Yup.array().required('Required')
      : Yup.array().nullable().notRequired(),
    password: isCreate
      ? Yup.string().when('type', {
          is: type => type.includes('Partner/Internal'),
          then: Yup.string()
            .required('Required')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
              'Must Contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
            ),
          otherwise: Yup.string().notRequired(),
        })
      : Yup.string().notRequired(),
  });
  return ValidationSchema;
};
