import * as Yup from 'yup';

export const getValidationSchema = ({ isCreate }) => {
  const ValidationSchema = Yup.object().shape({
    acquirerId: Yup.string().required('Required'),
    region: Yup.array()
      .required('Required')
      .max(5, 'Please select max 5 regions'),
    turnoverRangeLow: Yup.number().required('Required'),
    turnoverRangeHigh: Yup.number().required('Required'),
    acquisitionTitle: Yup.string().nullable(),
    // netProfitRangeLow: Yup.number().required('Required'),
    // netProfitRangeHigh: Yup.number().required('Required'),
    contactRoles: Yup.array()
      .of(
        Yup.object().shape(
          {
            role: Yup.string().when('contactId', {
              is: contactId => contactId,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
            contactId: Yup.string().when('role', {
              is: role => role,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().notRequired(),
            }),
          },
          ['contactId', 'role']
        )
      )
      .test('findRole', 'Required', function(value) {
        function checkedRole(role) {
          return value && value.length && value.some(o => o.role === role);
        }

        if (!checkedRole('Owner') && !checkedRole('Partner Contact')) {
          return false;
        }
        if (!this.parent.managed && !checkedRole('Email Recipient')) {
          return false;
        }

        return true;
      })
      .required('Required'),
  });
  return ValidationSchema;
};
