import { connect } from 'react-redux';
import { getEmailHistoryData, restoreObject } from '../../redux/actions/emailHistory/emailHistoryActions';
import EmailHistory from '../../pages/EmailHistory/EmailHistory';
import { setLimitItems } from '../../redux/actions/global/globalActions';

const mapStateToProps = store => {
  return {
    data : store.options.emailHistory,
    isLoading: store.restore.isLoading,
    partnerId: store.globalOptions.partnerId,
    limit: store.globalOptions.limit
  };
};

const mapDispatchToProps = (dispatch, {props}) => ({
  getEmailHistoryData: params => dispatch(getEmailHistoryData(params)),
  setLimitItems: data => dispatch(setLimitItems(data)),
  restoreObject: (method, data, trackerid) => dispatch(restoreObject(method, data, trackerid, props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailHistory);
