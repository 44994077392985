import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Toolbar from '../Toolbar/Toolbar';

const options = [
  { key: '10', label: 'Show 10', value: '10' },
  { key: '20', label: 'Show 20', value: '20' },
  { key: '50', label: 'Show 50', value: '50' },
];

// const directionConverter = order => {
//   if (order === 'asc') {
//     return 'ascending';
//   }
//   if (order === 'desc') {
//     return 'descending';
//   }
//   return null;
// };

const TableWrap = ({
  headerTitles,
  data,
  changeItemsOnPage,
  limit,
  searchQuery,
  setSearchTerm,
  currentPage,
  tableBody,
  onPageChange,
  openModal,
  isFilters,
  setFieldValue,
  countFilterOptions,
  onChangeActivePage
}) => {
  return (
    <div className="flex-row">
      <Toolbar
        tableData={data.items}
        setSearchTerm={setSearchTerm}
        onChangeLimit={changeItemsOnPage}
        value={searchQuery}
        currentPage={currentPage}
        showItemsOnPage={limit}
        totalPages={Math.ceil(data.totalSize / +limit)}
        options={options}
        onPageChange={onPageChange}
        openModal={openModal}
        isFilters={isFilters}
        setFieldValue={setFieldValue}
        countFilterOptions={countFilterOptions}
        onChangeActivePage={onChangeActivePage}
      />
      {data.items.length ? (
        <div className="table-wrap">
          <div className="table-wrap-holder">
            <Table sortable fixed className="mna-table" unstackable>
              <Table.Header className="mna-table__header">
                <Table.Row className="mna-table__row">
                  {headerTitles.map(title => (
                    <Table.HeaderCell
                      key={title}
                      width={
                        title === 'notes'  || title === 'User'  || title === 'Table' || title === 'Deleted At' || title === 'Actions'  || title === "partner"
                          ? 'one'
                          : (title === 'sector' || title === 'sectors' || title === 'regions' || title === 'Item' || title === 'vendor' || title === "Notes" )
                          ? 'three'
                          : ( title === '#')
                          ? 'half'
                          : 'two'
                      }
                    >
                      {title}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body className="mna-table__body">{tableBody}</Table.Body>
            </Table>
          </div>
        </div>
      ) : (
        'Not found'
      )}
    </div>
  );
};

TableWrap.propTypes = {
  headerTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  changeItemsOnPage: PropTypes.func,
  limit: PropTypes.string,
  searchQuery: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  tableBody: PropTypes.node,
  withNotes: PropTypes.bool,
  onPageChange: PropTypes.func,
  openModal: PropTypes.func,
  isFilters: PropTypes.bool,
  countFilterOptions: PropTypes.number,
  onChangeActivePage: PropTypes.func,
};

export default TableWrap;
