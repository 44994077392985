import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Masonry from 'react-masonry-component';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';
import { useAuth0 } from '../../auth/react-auth0-spa';
import PageHeader from '../../components/PageHeader/PageHeader';
import Routes from '../../constants/routes';
import { Button, Label, Paper, Numeral } from '../../components/shared';
import Toolbar from '../../components/Toolbar/Toolbar';
import PartnerLogo from '../../components/shared/PartnerLogo/PartnerLogo';
import CheckIco from '../../asset/img/check-mark-button.svg';
import CrossIco from '../../asset/img/cross-mark-button.svg';
import ModalManager from '../../components/Modal/Modal';
import StageOptions from '../../Data/Select/StageOptions';
import BodyMatchesModal, {
  title,
  btnCancelText,
  btnSaveText
} from '../../components/Modal/MatchesModalData';
import {
  getDataByLastYear,
  kFormatter,
  getDataByLastAvailableYear,
  setNumberIsValueExist,
  renderSectors,
  recursivelyRenderDescription,
  columnToReadable
} from '../../utils/utils';
import { Icon } from '@iconify/react';
import FlagIcon from '@mui/icons-material/Flag';
import _debounce from 'lodash.debounce';
import bxSave from '@iconify-icons/bx/bx-save';
import baselineThumbUpOffAlt from '@iconify-icons/ic/baseline-thumb-up-off-alt';
import baselineThumbDownOffAlt from '@iconify-icons/ic/baseline-thumb-down-off-alt';
import { Tooltip } from '@material-ui/core';
import { ReactReduxContext } from 'react-redux';
const masonryOptions = {
  transitionDuration: 0
};
const convertToCSV = arr => {
  const headings = [
    'Match ID',
    'Acquirer ID',
    'Profile ID',
    'Business ID',
    'Acquirer Name',
    'Business Name',
    'Stage',
    'Approved',
    'Created At',
    'Last Updated At'
  ];
  const csv = arr.map(item => {
    return [
      item.id,
      item.acquirerId,
      item.profileId,
      item.businessId,
      item.acquirer.companyName,
      item.business.businessName,
      item.stage,
      item.approved,
      item.createdAt.split('T')[0],
      item.updatedAt.split('T')[0]
    ];
  });
  csv.unshift(headings);
  return csv.map(e => e.join(',')).join('\n');
};
const exportToCsv = data => {
  const { items } = data;
  const csv = convertToCSV(items);
  const blob = new Blob([csv], { type: 'text/csv' });
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', 'projects.csv');
  link.click();
};
const Matches = ({
  partnerId,
  isLoading,
  location,
  getMatchesData,
  data,
  props,
  openModal,
  modalIsOpen,
  setMatchesDetails,
  onRequestApproveMatches,
  optionsData,
  setFieldValue,
  values,
  handleSubmit,
  handleChange,
  getBusinessesOptions,
  getPartnersOptions,
  getAcquirersOptions,
  getAcquirerCriteriaOptions,
  getContactsOptions,
  hasPartner,
  authUser,
  setNewUpdatedAt
}) => {
  const { loading, user } = useAuth0();
  const { store } = useContext(ReactReduxContext);
  let a = store.getState();
  const [searchQuery, setSearchQuery] = useState('');
  const [table, setTableData] = useState({
    limit: 20,
    search: '',
    activePage: 1
  });
  const [filters, setFilters] = useState({
    count: 0
  });
  function getDefaultParams() {
    let params = {};
    let sessionPreferences = JSON.parse(
      window.sessionStorage.getItem('preferences')
    );
    if (sessionPreferences) {
      for (const [key, value] of Object.entries(sessionPreferences)) {
        if (value) {
          if (key !== 'stage') {
            setFieldValue(key, value);
            params[key] = setNumberIsValueExist(value);
          } else {
            params[key] = value;
            setFieldValue('stage', value);
          }
        }
      }
    }
    return params;
  }
  const getLabelMeta = stage => {
    let parentLabel = null;
    StageOptions.forEach(parent => {
      const found = parent.options.find(option => option.value === stage);
      if (found) {
        parentLabel = parent.value;
      }
    });
    return {
      complete: parentLabel === 'Deal Complete',
      success: parentLabel === 'Live',
      warning: parentLabel === 'On Hold',
      danger: parentLabel === 'Cold'
    };
  };
  useEffect(() => {
    const countFilterOptions = val => {
      // const defaultOption = hasPartner ? 1 : 0;
      const count = compact(Object.values(val)).length;

      return setFilters({ count });
    };
    countFilterOptions(values);
  }, [values, hasPartner]);
  useEffect(() => {
    // if a.user.userAuth.partnerId is undefined or null
    if (a.user.userAuth.partnerId) {
      Promise.all([
        getMatchesData({
          limit: table.limit,
          partnerId: a.user.userAuth.partnerId,
          order: 'DESC',
          search: searchQuery,
          ...getDefaultParams()
        }),
        getBusinessesOptions(),
        getAcquirerCriteriaOptions(),
        getAcquirersOptions(),
        getContactsOptions()
      ]);
    }
    // eslint-disable-next-line
  }, [table.limit, partnerId, a.user.userAuth.partnerId]);
  const setSearchTerm = async e => {
    const { value } = e.target;
    setSearchQuery(value);
    setTableData({
      ...table,
      search: value
    });
    return debounce(async () => {
      await getMatchesData({
        search: value,
        limit: table.limit,
        partnerId: a.user.userAuth.partnerId,
        order: 'DESC',
        ...getDefaultParams()
      });
    }, 500)();
  };
  const redirectToDetailsPage = (e, id) => {
    return props.history.push(`${Routes.MATCHES}/${id}`);
  };
  const returnManaged = (item, type) => {
    if (item[type]['managed']) {
      return 'MAN';
    } else {
      return null;
    }
  };
  const returnOwner = (item, type) => {
    // loop through contacts and find record owner
    let name;
    item[type]['contacts'].forEach(contact => {
      if (contact.role === 'Owner') {
        name =
          contact.contact.firstName.charAt(0).toUpperCase() +
          contact.contact.lastName.charAt(0).toUpperCase();
      }
    });
    return name;
  };
  const returnMarketStatus = item => {
    if (item.business.status === 'offMarket') {
      return 'OFF MARKET';
    } else if (item.business.status === 'forSale') {
      return 'FOR SALE';
    } else if (item.business.forSale == true) {
      return 'FOR SALE';
    } else if (item.business.forSale == false) {
      return 'OFF MARKET';
    }
  };
  const match_stale_duration = {
    Matched: 5,
    'Clearing Acquirer': 5,
    'Clearing Opportunity': 5,
    Introduced: 10,
    Meeting: 10,
    Offer: 20,
    Legals: 30
  };
  const checkFlagged = match => {
    function getDateXDaysAgo(days) {
      return new Date(Date.now() - 1000 * 60 * 60 * 24 * days);
    }
    if (match_stale_duration[match.stage]) {
      if (
        new Date(match.updatedAt) <
        getDateXDaysAgo(match_stale_duration[match.stage])
      ) {
        return true;
      }
    }
    return false;
  };
  const resetUpdatedAt = async match => {
    const { id } = match;
    const { notes, stage, approved, vendorScore, acquirerScore } = match;
    const obj = {
      matches: [
        {
          id,
          notes,
          stage,
          approved,
          vendorScore,
          acquirerScore
        }
      ]
    };
    await setMatchesDetails(obj);
  };
  async function handleScroll() {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom) {
      table.limit += 10;
      setTableData({
        ...table,
        limit: table.limit
      });
    }
  }
  React.useEffect(() => {
    const handleScrollController = () => {
      handleScroll(a.user.userAuth.partnerId);
    };
    window.addEventListener('scroll', handleScrollController, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handleScrollController);
    };
  }, []);
  return (
    <div>
      <PageHeader
        title="Matches"
        linkText="Approve All"
        stateText="Create"
        location={location}
        isMatches
        onExport={() => exportToCsv(data)}
        path={`${Routes.MATCHES}/create`}
      />
      <Toolbar
        isFilters
        noPagination
        matches
        tableData={data.items}
        setSearchTerm={setSearchTerm}
        setSearchQuery={setSearchQuery}
        value={table.searchQuery}
        openModal={() => openModal()}
        countFilterOptions={filters.count}
        matchesCount={data.totalSize}
      />
      <Loader active={loading || !user || isLoading} />
      <Masonry className="matches-masonry" options={masonryOptions}>
        {data.items &&
          data.items.map((item, index) => {
            const scoreLabel = data => {
              switch (data) {
                case 'Yes':
                  return (
                    <>
                      <Icon
                        className="sideboxlogo"
                        icon={baselineThumbUpOffAlt}
                      />
                    </>
                  );
                case 'No':
                  return (
                    <>
                      <Icon
                        className="sideboxlogo"
                        icon={baselineThumbDownOffAlt}
                      />
                    </>
                  );
                case 'Maybe':
                  return (
                    <>
                      <Icon className="sideboxlogo" icon={bxSave} />
                    </>
                  );
                default:
                  return null;
              }
            };
            const getData = approved => {
              return {
                matches: [
                  {
                    id: item.id,
                    approved
                    // approved:
                    //   get(postResponse[0], 'id') && item.id === +target.id
                    //     ? !postResponse[0].approved
                    //     : !item.approved,
                  }
                ]
              };
            };
            return (
              <Paper
                key={item.id}
                className="matches"
                onClick={e => redirectToDetailsPage(e, item.id)}
              >
                <div className="matches__header">
                  <Label
                    basic
                    matchHeader
                    text={item.stage || 'New match'}
                    success={getLabelMeta(item.stage).success}
                    warning={getLabelMeta(item.stage).warning}
                    danger={getLabelMeta(item.stage).danger}
                    complete={getLabelMeta(item.stage).complete}
                  />
                </div>
                <div className="card">
                  <div className={`card-left ${item.approved ? 'tight' : ''}`}>
                    <div className="card-left-logo">
                      <PartnerLogo
                        className={`card-left-logo-img${
                          item.approved ? '-no-logo' : ''
                        }`}
                        id={item.business.partnerId}
                      />
                    </div>
                    <div className="card-left-heading">
                      <div className="card-left-heading-copy"></div>
                      <div className="card-left-heading-title">
                        <div className="card-left-heading-title-type">
                          Opportunity
                        </div>
                        <Tooltip
                          placement="left"
                          title="Copy to Clipboard"
                          onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            var clipboard = [];
                            // Anonymised Opportunity
                            if ('codeName' in item.business) {
                              var codeName = item.business.codeName;
                              clipboard.push(codeName);
                            }
                            // Rich Description
                            if ('richDescription' in item.business) {
                              var richDescription =
                                item.business.richDescription;
                              if (richDescription) {
                                let description = recursivelyRenderDescription(
                                  richDescription
                                );
                                if (description) clipboard.push(description);
                              }
                            }
                            // Sector
                            if ('sector' in item.business) {
                              var sector = item.business.subSector;
                              if (sector) {
                                if (!sector[0]) {
                                  sector = item.business.sector;
                                }
                                clipboard.push('Sector: ' + sector);
                              }
                            }
                            // Region
                            if ('region' in item.business) {
                              var region = item.business.region;
                              clipboard.push('Region: ' + region);
                            }
                            // Turnover (All with Years)
                            if ('financials' in item.business) {
                              var turnover = getDataByLastAvailableYear(
                                item.business.financials
                              ).turnover;
                              clipboard.push('Turnover: £' + turnover);
                            }
                            // EBITDA (All with Years)
                            if ('financials' in item.business) {
                              var ebitda = getDataByLastAvailableYear(
                                item.business.financials
                              ).ebitda;
                              clipboard.push('EBITDA: £' + ebitda);
                            }
                            var text = clipboard.join('\r\n');
                            // Text
                            // var text = business_name + '\r\n' + description + '\r\n' + sector + '\r\n' + region + '\r\n' + turnover + '\r\n' + netProfit
                            var dummy = document.createElement('textarea');
                            document.body.appendChild(dummy);
                            dummy.value = text;
                            dummy.select();
                            document.execCommand('copy');
                            document.body.removeChild(dummy);
                          }}
                        >
                          <div className="card-left-heading-title-company">
                            {item.business.businessName}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="card-left-heading-icons">
                        <div className="card-left-heading-icons--icon">
                          {returnOwner(item, 'business') != null ? (
                            <div className="matches__details-subtitle match-icon owner-circle">
                              {' '}
                              {returnOwner(item, 'business')}{' '}
                            </div>
                          ) : null}
                          {returnManaged(item, 'business') != null ? (
                            <div className="matches__details-subtitle match-icon">
                              {' '}
                              {returnManaged(item, 'business')}{' '}
                            </div>
                          ) : null}
                        </div>
                        <div className="card-left-heading-icons--icon">
                          {scoreLabel(item.vendorScore)}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-left-body${
                        item.approved ? '-tight' : ''
                      }`}
                    >
                      {item.approved === null ? (
                        <>
                          <ul className="matches__details-list">
                            <li className="matches__details-list-li">
                              <strong>Turnover: </strong>
                              <div>
                                {item.business.financials && (
                                  <Numeral
                                    value={getDataByLastYear(
                                      item.business.financials,
                                      'fyEnding',
                                      'turnover'
                                    )}
                                    showDashIfZero
                                  />
                                )}
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> EBITDA: </strong>
                              <div>
                                {item.business.financials && (
                                  <Numeral
                                    value={getDataByLastYear(
                                      item.business.financials,
                                      'fyEnding',
                                      'netProfit'
                                    )}
                                    showDashIfZero
                                  />
                                )}
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> Region: </strong>
                              <div>
                                {item.business.region &&
                                  item.business.region
                                    .split(' - ')
                                    .slice(-2)
                                    .join(' - ')}
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> Sector: </strong>
                              <div>
                                {item.business.subSector ||
                                  item.business.sector ||
                                  ''}
                              </div>
                            </li>
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className={`card-icon ${item.approved ? 'hidden' : ''}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      className="injected-svg"
                      data-src="/static/media/match.d9424de9.svg"
                    >
                      <defs>
                        <path
                          d="M4.76605469,9.84689416 L17.3829899,9.84689416 L13.5524541,6.23357861 C13.2532912,5.95138044 13.2532912,5.4938468 13.5524541,5.21164863 C13.8516171,4.92945046 14.3366561,4.92945046 14.6358191,5.21164863 L19.774092,10.0585428 C20.2566796,10.513764 19.9148914,11.2921214 19.2324096,11.2921214 L4.76605469,11.2921214 C4.34297437,11.2921214 4,10.9685963 4,10.5695078 C4,10.1704193 4.34297437,9.84689416 4.76605469,9.84689416 Z M19.2339453,14.1531058 L6.61701008,14.1531058 L10.4475459,17.7664214 C10.7467088,18.0486196 10.7467088,18.5061532 10.4475459,18.7883514 C10.1483829,19.0705495 9.66334391,19.0705495 9.36418094,18.7883514 L4.22590798,13.9414572 C3.74332044,13.486236 4.0851086,12.7078786 4.76759045,12.7078786 L19.2339453,12.7078786 C19.6570256,12.7078786 20,13.0314037 20,13.4304922 C20,13.8295807 19.6570256,14.1531058 19.2339453,14.1531058 Z"
                          id="path-matches"
                        ></path>
                      </defs>
                      <g
                        id="icon/functional/match"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g id="Group">
                          <rect
                            id="border"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <mask id="mask-matches" fill="white">
                            <use xlinkHref="#path-matches"></use>
                          </mask>
                          <g id="Combined-Shape" fillRule="nonzero"></g>
                          <g
                            id="color/grey"
                            mask="url(#mask-matches)"
                            fill="#99A9BB"
                          >
                            <rect
                              id="Rectangle"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            ></rect>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className={`card-right ${item.approved ? 'tight' : ''}`}>
                    {checkFlagged(item) === true && !item.hideFlag && (
                      <div
                        className={`card-right-flag${
                          item.approved ? ' minimised-card-flag' : ''
                        }`}
                      >
                        <Tooltip
                          title={
                            item.stage +
                            ' matches are flagged to be checked after ' +
                            match_stale_duration[item.stage] +
                            ' days'
                          }
                        >
                          <FlagIcon
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              resetUpdatedAt(item);
                              item.hideFlag = true;
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                    <div className="card-right-logo">
                      <PartnerLogo
                        className={`card-right-logo-img${
                          item.approved ? '-no-logo' : ''
                        }`}
                        id={item.acquirer.partnerId}
                      />
                    </div>
                    <div className="card-right-heading">
                      <div className="card-right-heading-copy"></div>
                      <div className="card-right-heading-title">
                        <div className="card-right-heading-title-type">
                          Acquirer Criteria
                        </div>
                        <Tooltip
                          placement="left"
                          title="Copy to Clipboard"
                          onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            var clipboard = [];
                            // Anonymised Opportunity
                            if ('companyName' in item.acquirer) {
                              var business_name = item.acquirer.anonDescription;
                              clipboard.push(business_name);
                            }
                            // Rich Description
                            if ('richDescription' in item.profile) {
                              var richDescription =
                                item.acquirer.richDescription;
                              if (richDescription) {
                                let description = recursivelyRenderDescription(
                                  richDescription
                                );
                                if (description) clipboard.push(description);
                              }
                            }
                            // Sector
                            if ('sector' in item.profile) {
                              var sector = renderSectors(
                                item.profile.subSector,
                                10
                              );
                              if (sector) {
                                if (!sector[0]) {
                                  sector = renderSectors(
                                    item.profile.sector,
                                    10
                                  );
                                }
                                clipboard.push('Sector: ' + sector);
                              }
                            }
                            // Region
                            if ('region' in item.profile) {
                              var region = item.profile.region;
                              clipboard.push('Region: ' + region);
                            }
                            // Turnover (All with Years)
                            if ('turnoverRangeLow' in item.profile) {
                              var turnover =
                                '£' +
                                kFormatter(item.profile.turnoverRangeLow) +
                                '-' +
                                '£' +
                                kFormatter(item.profile.turnoverRangeHigh);
                              clipboard.push('Turnover: ' + turnover);
                            }
                            // EBITDA (All with Years)
                            if ('netProfitRangeLow' in item.profile) {
                              var netProfit =
                                '£' +
                                kFormatter(item.profile.netProfitRangeLow) +
                                '-' +
                                '£' +
                                kFormatter(item.profile.netProfitRangeHigh);
                              clipboard.push('EBITDA: ' + netProfit);
                            }
                            var text = clipboard.join('\r\n');
                            var dummy = document.createElement('textarea');
                            document.body.appendChild(dummy);
                            dummy.value = text;
                            dummy.select();
                            document.execCommand('copy');
                            document.body.removeChild(dummy);
                          }}
                        >
                          <div className="card-right-heading-title-company">
                            {item.acquirer.companyName}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="card-right-heading-icons">
                        <div className="card-left-heading-icons--icon">
                          {returnOwner(item, 'acquirer') != null ? (
                            <div className="matches__details-subtitle match-icon owner-circle">
                              {' '}
                              {returnOwner(item, 'acquirer')}{' '}
                            </div>
                          ) : null}
                          {returnManaged(item, 'acquirer') != null ? (
                            <div className="matches__details-subtitle match-icon">
                              {' '}
                              {returnManaged(item, 'acquirer')}{' '}
                            </div>
                          ) : null}
                        </div>
                        <div className="card-left-heading-icons--icon">
                          {scoreLabel(item.acquirerScore)}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-right-body${
                        item.approved ? '-tight' : ''
                      }`}
                    >
                      {item.approved === null ? (
                        <>
                          <ul className="matches__details-list">
                            <li className="matches__details-list-li">
                              <strong>Turnover: </strong>
                              <div>
                                <Numeral
                                  value={item.profile.turnoverRangeLow}
                                />{' '}
                                -{' '}
                                <Numeral
                                  value={item.profile.turnoverRangeHigh}
                                />
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> EBITDA: </strong>
                              <div>
                                <Numeral
                                  value={item.profile.netProfitRangeLow}
                                />{' '}
                                -{' '}
                                <Numeral
                                  value={item.profile.netProfitRangeHigh}
                                />
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> Region: </strong>
                              <div>
                                {item.profile.region &&
                                  item.profile.region
                                    .map(r => {
                                      return r
                                        .split(' - ')
                                        .slice(-2)
                                        .join(' - ');
                                    })
                                    .join(', ')}
                              </div>
                            </li>
                            <li className="matches__details-list-li">
                              <strong> Sector: </strong>
                              <div>
                                {renderSectors(item.profile.subSector, 4)}
                                {/*item.profile.sector .map( ( s, i ) => { const subsector = item .profile .subSector[ i ]; return ( subsector || s || "" ); } ) .join( ", " )*/}
                              </div>
                            </li>
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="matches__footer">
                  {item.approved === null && (
                    <>
                      <div className="matches__footer-left">
                        <div className="market_status">
                          {returnMarketStatus(item)}
                        </div>
                        <div className="match_type">
                          {item.matchtype
                            ? item.matchtype.toUpperCase()
                            : 'None'}
                        </div>
                      </div>
                      <div className="card__footer-box">
                        <Button
                          id={item.id}
                          className="card__footer-button"
                          basic
                          small
                          text="Deny"
                          danger
                          onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            await onRequestApproveMatches(getData(false));
                            await getMatchesData({
                              limit: table.limit,
                              partnerId: a.user.userAuth.partnerId,
                              search: searchQuery,
                              order: 'DESC'
                            });
                          }}
                        />
                        <Button
                          id={item.id}
                          className="card__footer-button"
                          basic
                          small
                          text="Approve"
                          success
                          onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            await onRequestApproveMatches(getData(true));
                            await getMatchesData({
                              limit: table.limit,
                              partnerId: a.user.userAuth.partnerId,
                              search: searchQuery,
                              order: 'DESC'
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                  {item.approved === true && (
                    <>
                      <div className="matches__footer-left-tightened">
                        <div className="market_status">
                          {returnMarketStatus(item)}
                        </div>
                        <div className="match_type">
                          {item.matchtype
                            ? item.matchtype.toUpperCase()
                            : 'None'}
                        </div>
                      </div>
                      <span className="approved">
                        <Icon icon={CheckIco} size="18px" />
                        Approved
                      </span>
                    </>
                  )}
                  {item.approved === false && (
                    <>
                      <span></span>
                      <span className="denied">
                        <Icon icon={CrossIco} size="18px" />
                        Denied
                      </span>
                    </>
                  )}
                </div>
              </Paper>
            );
          })}
        <ModalManager
          filters
          header={title}
          modalIsOpen={modalIsOpen}
          onFilterData={() => {
            handleSubmit(values, table.limit);
          }}
          btnCancelText={btnCancelText}
          btnSaveText={btnSaveText}
          content={
            <BodyMatchesModal
              isMatches
              handleChange={handleChange}
              acquirer={optionsData.acquirers}
              contacts={optionsData.contacts}
              partnerId={partnerId}
              profiles={optionsData.profiles}
              opportunities={optionsData.businesses}
              setFieldValue={setFieldValue}
              getBusinessesOptions={getBusinessesOptions}
              getPartnersOptions={getPartnersOptions}
              getAcquirersOptions={getAcquirersOptions}
              getAcquirerCriteriaOptions={getAcquirerCriteriaOptions}
              getContactsOptions={getContactsOptions}
              values={values}
              authUser={authUser}
            />
          }
        />
      </Masonry>
      <div className="loadmore">----- Load More -----</div>
    </div>
  );
};
Matches.propTypes = {
  state: PropTypes.bool,
  partnerId: PropTypes.string,
  isLoading: PropTypes.bool,
  getMatchesData: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  onRequestApproveMatches: PropTypes.func,
  optionsData: PropTypes.objectOf(PropTypes.array).isRequired,
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getBusinessesOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  getAcquirersOptions: PropTypes.func.isRequired,
  getAcquirerCriteriaOptions: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.string),
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  props: PropTypes.objectOf(PropTypes.object),
  openModal: PropTypes.func,
  hasPartner: PropTypes.bool
};
export default Matches;
