import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import isNull from 'lodash/isNull';
import PartnerLogo from '../../components/shared/PartnerLogo/PartnerLogo';

const TableBody = ({ data, redirectToDetailsPage }) => {
  const getOwnerLabel = items => {
    if (isNull(items)) return '';
    const contacts = items && items.find(i => i.role === 'Owner');
    if (!contacts) return '';
    return `${contacts.contact.firstName} ${contacts.contact.lastName}`;
  };

  return (
    <>
      {data.items.map(
        ({
          companyName,
          anonDescription,
          type,
          id,
          partnerId,
          companyNumber,
          profileCount,
          contacts
        }) => (
          <Table.Row key={id} onClick={() => redirectToDetailsPage(id)}>
            <Table.Cell>
              <div className="table-icons">{id}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--ellipsis title--name">
                {companyName}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">{anonDescription}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">{profileCount}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">{type}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">{companyNumber}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="title title--table">
                {getOwnerLabel(contacts)}
              </div>
            </Table.Cell>

            <Table.Cell>
              <div className="mna-table__logo">
                <PartnerLogo id={partnerId} />
              </div>
            </Table.Cell>
          </Table.Row>
        )
      )}
    </>
  );
};

TableBody.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      items: PropTypes.array,
      totalSize: PropTypes.number
    })
  ]),
  redirectToDetailsPage: PropTypes.func
};

export default TableBody;
