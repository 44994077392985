import React, { useState, useEffect } from 'react';
import { Table as TestTable } from 'semantic-ui-react';
import { columnToReadable, returnObjectValue } from '../../utils/utils';
import { Select, Paper, Collapse, FormGroup, Numeral, Button } from '../shared';

const BasicTable = ({ data, columns }) => {
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const slicedTrack = data.slice(page * 10, page * 10 + 10);
      if (slicedTrack.length > 0) {
        setPageData(slicedTrack);
      } else {
        if (page > 0) {
          setPage(page - 1);
        }
      }
    }
  }, [page, data.track]);

  return (
    <>
      {data && data.length > 0 && (
        <div>
          <TestTable sortable fixed className="mna-table" unstackable>
            <TestTable.Header className="mna-table__header">
              <TestTable.Row className="mna-table__row">
                {columns.map(title => (
                  <TestTable.HeaderCell key={title}>
                    {Object.keys(title)[0]}
                  </TestTable.HeaderCell>
                ))}
              </TestTable.Row>
            </TestTable.Header>

            <TestTable.Body className="mna-table__body">
              {data.length > 0
                ? pageData.map((t, i) => {
                    return (
                      <tr key={i}>
                        {columns.map((column, j) => {
                          let columnName = Object.keys(column)[0]
                          let dataitem = t[column[Object.keys(column)[0]]];

                          if (columnName === 'Time') {
                            dataitem = new Date(dataitem).toLocaleString();
                          }
                          if (columnName === 'Field') {
                            dataitem = columnToReadable(dataitem);
                          }
                          if (columnName === 'New Value') {
                            dataitem = returnObjectValue(dataitem);
                          }
                          return <td key={i + '-' + j}>{dataitem}</td>;
                        })}
                      </tr>
                    );
                  })
                : [1].map(t => {
                    return (
                      <p className="tracker_edit">
                        <i>
                          This item is yet to be edited... Updated changes will
                          appear here
                        </i>
                      </p>
                    );
                  })}
            </TestTable.Body>
          </TestTable>
          <div style={{ display: 'flex', 'justify-content': 'flex-end' }}>
            <div
              style={{
                display: 'flex',
                width: '10em',
                'justify-content': 'space-between',
                'align-items': 'center'
              }}
            >
              <Button
                className="mna-pagebutton"
                text="<"
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
              />
              <span> Page {page + 1}</span>
              <Button
                className="mna-pagebutton"
                text=">"
                onClick={() => setPage(page + 1)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicTable;
