import {
  PARTNERS_OPTIONS_SUCCESS,
  BUSINESSES_OPTIONS_SUCCESS,
  ACQUIRER_CRITERIA_OPTIONS_SUCCESS,
  ACQUIRERS_OPTIONS_SUCCESS,
  USER_NAME_OPTIONS_SUCCESS,
  CONTACTS_OPTIONS_SUCCESS,
  EMAIL_HISTORY_SUCCESS
} from '../../actions/types';

const defaultState = {};

export default function optionsReducer(state = defaultState, action) {
  switch (action.type) {
    case PARTNERS_OPTIONS_SUCCESS: {
      return {
        ...state,
        partners: action.data,
      };
    }
    case BUSINESSES_OPTIONS_SUCCESS: {
      return {
        ...state,
        businesses: action.data,
      };
    }
    case ACQUIRER_CRITERIA_OPTIONS_SUCCESS: {
      return {
        ...state,
        profiles: action.data,
      };
    }
    case ACQUIRERS_OPTIONS_SUCCESS: {
      return {
        ...state,
        acquirers: action.data,
      };
    }
    case USER_NAME_OPTIONS_SUCCESS: {
      return {
        ...state,
        users: action.data,
      };
    }
    case CONTACTS_OPTIONS_SUCCESS: {
      return {
        ...state,
        contacts: action.data,
      };
    }
    case EMAIL_HISTORY_SUCCESS: {
      return {
        ...state,
        emailHistory: action.data,
      }
    }
    default:
      return state;
  }
}
