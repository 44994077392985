export function createMode() {
  return {
    type: 'MODE_CREATE',
  };
}

export function editMode() {
  return {
    type: 'MODE_EDIT',
  };
}
