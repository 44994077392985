import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Paper = ({ onClick, medium, children, fullHeight, className }) => (
  <div
    onClick={onClick}
    className={`paper ${clsx({
      'paper--medium': medium,
      'full-height': fullHeight,
      [`${className}`]: className,
    })}`}
  >
    {children}
  </div>
);

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  medium: PropTypes.bool,
  fullHeight: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Paper;