import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import FormGroup from '../shared/Form/FormGroup';
import Select from '../shared/Form/Select/Select';
import SelectCustom from '../shared/Form/Select/SelectCustom';
import removeIco from '../../asset/img/remove.svg';
import Button from '../shared/Form/Button';
import Open from '../../asset/img/open.svg';
import Loading from '../../asset/img/Loading_icon.gif'
import {
  contactTypesCustom,
  contactTypes,
} from '../../Data/Select/ContactTypes';
import NewContactForm from './NewContactForm';

const ContactData = ({
  contacts,
  contactsOption,
  setFieldValue,
  errors,
  defaultContact,
  deletedContactsData,
  isCreate,
  isManaged,
  authUser,
  createContact,
  getUserNameOptions,
  userType,
  setReloadContacts,
  getContactsOptions,
})  => {

  const [newContact, setNewContact] = useState({
    'type': [userType],
    'firstName': null,
    'lastName': null,
    'email': null
  })
  const [contactForm, showContactForm] = useState(false)
  const [contactLoading, setContactLoading] = useState(false)

  function addContact() {
    setContactLoading(true)
    createContact(newContact, {}).then(() => {
      
      getUserNameOptions({
        type: ['Acquirer', 'Opportunity', 'Partner/Internal'],
      })
      
    }).then(() => {
      setContactLoading(false)
      showContactForm(false)

      getContactsOptions && getContactsOptions({
        type: ['Acquirer', 'Opportunity', 'Partner/Internal'],
      })

    })
  }

  const returnDefaultOwner = (authUser, contacts) => {
      if (authUser.id) {
        if (contacts) {
          if (!contacts.length) {
            setFieldValue(`contactRoles[0].role`, "Owner")
            setFieldValue(`contactRoles[0].contactId`, authUser.id.toString())
    
            setFieldValue(`contactRoles[1].role`, "Email Recipient")
            setFieldValue(`contactRoles[1].contactId`, authUser.id.toString())
    
            setFieldValue(`contactRoles[2].role`, "Partner Contact")
            setFieldValue(`contactRoles[2].contactId`, authUser.id.toString())
        }

        }
        else {
          setFieldValue(`contactRoles[0].role`, "Owner")
          setFieldValue(`contactRoles[0].contactId`, authUser.id.toString())
  
          setFieldValue(`contactRoles[1].role`, "Email Recipient")
          setFieldValue(`contactRoles[1].contactId`, authUser.id.toString())
  
          setFieldValue(`contactRoles[2].role`, "Partner Contact")
          setFieldValue(`contactRoles[2].contactId`, authUser.id.toString())


        }


      }    
  }

  useEffect(() => {
    returnDefaultOwner(authUser, contacts)
  }, [authUser, contacts, isCreate])


  return (

    // Contact Wrapper
    <div className="contact-data">

      {/* Errors */}
      {errors.contactRoles && typeof errors.contactRoles === 'string' && (
        <p className="form-group__error">
          {`Owner, Partner Contact${!isManaged ? ' and Email Recipient' : ''}
          should be filled`}
        </p>
      )}

      {/* Adds rows by taking from arrayHelpers, this also defines arrayHelpers */}
      <FieldArray
        name="contactRoles"
        render={arrayHelpers => (
          <>
            {contacts &&
              contacts.map((item, i) =>
                i === 0 ? (
                  <div key={i}>
                    {/* Contact Row */}
                    <Row middle="xs">
                      <Col
                        md={11}
                        className="form-group__area form-group__area-financial"
                      >
                        <FormGroup
                          labelText="role"
                          name={`contactRoles[${i}].role`}
                        >
                          <Select
                            setFieldValue={setFieldValue}
                            options={
                              isManaged ? contactTypesCustom : contactTypes
                            }
                            placeholder="None"
                            name={`contactRoles[${i}].role`}
                            value={item.role}
                            type="number"
                          />
                        </FormGroup>
                        <FormGroup
                          labelText="Contact name"
                          name={`contactRoles[${i}].contactId`}
                        >
                          <SelectCustom
                            isSearchable
                            name={`contactRoles[${i}].contactId`}
                            value={item.contactId}
                            options={contactsOption}
                            placeholder="Name"
                            setFieldValue={setFieldValue}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <Button
                          icon
                          onClick={() => {
                            arrayHelpers.remove(i)
                          }}
                          className="margin-top__small mna-btn--icon-removed"
                        >
                          <img src={removeIco} alt="remove row" />
                        </Button>
                      </Col>
                    </Row>

                    {errors.contactRoles &&
                      Array.isArray(errors.contactRoles) &&
                      errors.contactRoles[i] && (
                        <Row className="margin-bottom__medium">
                          <Col md={12}>
                            <p className="form-group__error">
                              All fields have to be filled
                            </p>
                          </Col>
                        </Row>
                      )}
                  </div>
                ) : (
                  <div key={i}>
                    <Row middle="xs">
                      <Col
                        md={11}
                        className="form-group__area form-group__area-financial"
                      >
                        <FormGroup name={`contactRoles[${i}].role`}>
                          <Select
                            setFieldValue={setFieldValue}
                            options={
                              isManaged ? contactTypesCustom : contactTypes
                            }
                            placeholder="None"
                            name={`contactRoles[${i}].role`}
                            value={item.role}
                            type="number"
                          />
                        </FormGroup>
                        <FormGroup name={`contactRoles[${i}].contactId`}>
                          <SelectCustom
                            isSearchable
                            name={`contactRoles[${i}].contactId`}
                            value={item.contactId}
                            options={contactsOption}
                            placeholder="Name"
                            setFieldValue={setFieldValue}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <Button
                          icon
                          onClick={() => {
                            arrayHelpers.remove(i)
                          }}
                          className="margin-bottom__medium mna-btn--icon-removed"
                        >
                          <img src={removeIco} alt="remove row" />
                        </Button>
                      </Col>
                    </Row>
                    {errors.contactRoles &&
                      Array.isArray(errors.contactRoles) &&
                      errors.contactRoles[i] && (
                        <Row className="margin-bottom__medium">
                          <Col md={12}>
                            <p className="form-group__error">
                              All fields have to be filled
                            </p>
                          </Col>
                        </Row>
                      )}
                  </div>
                )
              )}

            {/* Adds row by appending to arrayHelpers */}
            <Row>
              <Col xs>
                <Button
                  onClick={() => arrayHelpers.push({})}
                  icon
                  type="button"
                  className="financial-data__add-row"
                >
                  <img src={Open} alt="" />
                  Add row
                </Button>
              </Col>
            </Row>
            <br/><br></br>
            <Row>
              <Col xs>
                <Button 
                  onClick={() => {

                    if (contactForm === false) showContactForm(true)
                    if (contactForm === true) showContactForm(false)
                  }}
                  icon
                  type="button"
                  className="financial-data__add-row">
                  <img src={Open} alt=""/>
                  Add Contact
                </Button>

              </Col>
            </Row>
            {contactLoading && (
                  <>
                      
                    <div className="loadingContainer">
                      {<img src={Loading} className="loadingIcon" alt="Loading..." />}
                    </div>

                  </>
                )}

            {contactForm && !contactLoading && 
            <>
              <NewContactForm
                title = 'Add New Contact'
                handleChange = {function handle(event) {
                  const { name, value } = event.target;
                  setNewContact(prevState => ({
                      ...prevState,
                      [name]: value
                  }));
                }}
                addContact={addContact}
              ></NewContactForm>
            </>
            }
          </>
        )}
      />
    </div>
  );
};

ContactData.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object),
  contactsOption: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.any),
  deletedContactsData: PropTypes.arrayOf(PropTypes.object),
  isCreate: PropTypes.bool,
  isManaged: PropTypes.bool,
};

export default ContactData;
